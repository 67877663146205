import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, Form, Input, Switch, Select, Tabs, DatePicker } from 'antd';
import { CONSTANT_VARIABLES } from '../../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import CustomFormLabel from '../../../common/CustomFormLabel';
import { PlusCircle } from "react-feather";
import commonActions from '../../../../core/Services/common/actions'
import * as commonSelectors from '../../../commonSelectors'

const OtherInformationTab = (props) => {
    const { form, currentModalData, handleCustomFieldsData, isUpdate, optionsData } = props;
    const { nationalities } = optionsData;
    const dispatch = useDispatch();

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const [isCountrySelected, setIsCountrySelected] = useState(false)
    const [isStateSelected, setIsStateSelected] = useState(false)

    const allStatesData = useSelector(state => commonSelectors.setAllStates(state));
    const statesData = allStatesData ? allStatesData.toJS() : [];

    const allCitiesData = useSelector(state => commonSelectors.setAllCities(state));
    const citiesData = allCitiesData ? allCitiesData.toJS() : [];

    const handleCountryChange = (selectedCountryId) => {
        dispatch(commonActions.getAllStatesByCountryId({ stateParams: { id: selectedCountryId } }))
        setIsCountrySelected(true);
    }

    const handleStateChange = (selectedStateId) => {
        debugger
        dispatch(commonActions.getAllCitiesByStateId({ cityParams: {id: selectedStateId}}));
        setIsStateSelected(true);
    };

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    emergencyContact1: currentModalData.formData?.emergencyContact1,
                    emergencyContact2: currentModalData.formData?.emergencyContact2,
                    address: currentModalData.formData?.address,
                    countryId: currentModalData.formData?.countryId,
                    stateId: currentModalData.formData?.stateId,
                    cityId: currentModalData.formData?.cityId,
                    zipCode: currentModalData.formData?.zipCode,
                }}
                name="forForm" className='productActions_addModalWrapper'>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="emergencyContact1"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.emergency.no.one"
                                        popoverkey="hrm.employee.form.label.popover.emergency.no.one"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.emergency.no.one" />,
                                    },
                                ]}
                            >
                                <input type="text" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="emergencyContact2"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.emergency.no.two"
                                        popoverkey="hrm.employee.form.label.popover.emergency.no.two"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.emergency.no.two" />,
                                    },
                                ]}
                            >
                                <input type="text" />
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="address"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.address"
                                        popoverkey="hrm.employee.form.label.popover.address"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.address" />,
                                    },
                                ]}
                            >
                                <input type="text" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="countryId"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.country"
                                        popoverkey="hrm.employee.form.label.popover.country"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.country" />,
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={nationalities.map(s => ({ label: s.name, value: s.id }))}
                                    placeholder="Choose"
                                    className="input-select"
                                    onChange={handleCountryChange}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="stateId"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.state"
                                        popoverkey="hrm.employee.form.label.popover.state"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.state" />,
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={statesData.map(s => ({ label: s.name, value: s.id }))}
                                    placeholder="Choose"
                                    className="input-select"
                                    disabled={!isCountrySelected}
                                    onChange={handleStateChange}
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="cityId"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.city"
                                        popoverkey="hrm.employee.form.label.popover.city"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.city" />,
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={citiesData.map(c => ({ label: c.name, value: c.id }))}
                                    placeholder="Choose"
                                    className="input-select"
                                    disabled={!isStateSelected}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="zipCode"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.zipcode"
                                        popoverkey="hrm.employee.form.label.popover.zipcode"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.zipcode" />,
                                    },
                                    {
                                        pattern: /^\d+$/,
                                        message: "Zip Code must be a number",
                                    },
                                ]}
                            >
                                <input type="text" />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default OtherInformationTab;
