import { all } from 'redux-saga/effects';

// User Management
import userManagementSaga from "../Services/userManagement/saga";
//People
import customersSaga from "../Services/people/customers/saga";
import suppliersSaga from "../Services/people/suppliers/saga";
import storesSaga from "../Services/people/stores/saga";
import brandsSaga from "../Services/inventory/brands/saga";
import unitsSaga from "../Services/inventory/units/saga";
import categorySaga from "../Services/inventory/categories/saga";
import productSaga from "../Services/inventory/products/saga";
import variantSaga from "../Services/inventory/variant/saga";
import warrantySaga from "../Services/inventory/warranty/saga";
import subCategorySaga from "../Services/inventory/subCategory/saga";
import warehousesSaga from "../Services/people/warehouses/saga";
import commonSaga from "../Services/common/saga";
import financeAccountsexpenseSaga from "../Services/financeAccounts/expense/saga";
import financeAccountsexpensesCategorySaga from "../Services/financeAccounts/expensesCategory/saga";
import purchaseSaga from "../Services/purchases/purchase/saga";
import quotationSaga from "../Services/purchases/quotations/saga";
import purchaseInvoiceSaga from "../Services/purchases/purchaseInvoice/saga";
import purchaseReceiveSaga from "../Services/purchases/purchaseReceive/saga";
import purchaseReturnSaga from "../Services/purchases/purchaseReturn/saga";
import assettypesSaga from "../Services/purchases/assettypes/saga";
import headsSaga from "../Services/purchases/heads/saga";
import employeesSaga from "../Services/hrm/employee/saga";
import departmentSaga from "../Services/hrm/department/saga"
import leaveSaga from "../Services/hrm/leave/saga"
import designationSaga from "../Services/hrm/designation/saga";
import shiftsSaga from "../Services/hrm/shifts/saga";
import attendanceSaga from "../Services/hrm/attendance/saga";
import paymentVoucherSaga from "../Services/purchases/paymentVoucher/saga";
import creditNotesSaga from "../Services/purchases/creditnotes/saga";
import denominationSaga from "../Services/pos/cashclosing/cashdenomination/saga";
import todayExpensesSaga from "../Services/pos/cashclosing/todayexpenses/saga";

import sellingTypeListSaga from "../Services/inventory/sellingtype/saga";


import  salequotationSaga from "../Services/sales/salequotations/saga";
import  saleInvoiceSaga from "../Services/sales/saleInvoice/saga";
import  saleReceiveSaga from "../Services/sales/saleReceive/saga";
import  saleReturnSaga from "../Services/sales/saleReturn/saga";
import  saleOrderSaga from "../Services/sales/salesOrder/saga";
import  debitNoteSaga from "../Services/sales/debitnotes/saga";
import holidaySaga from "../Services/hrm/holiday/saga";
import employeeleaveSaga from "../Services/hrm/employeeleave/saga";
import payrollSaga from "../Services/hrm/payroll/saga";
//import apiResponseSaga from "../Services/apiResponse/saga";

export default function* rootSaga() {
    yield all([
        userManagementSaga(),
        customersSaga(),
        suppliersSaga(),
        storesSaga(),
        brandsSaga(),
        unitsSaga(),
        categorySaga(),
        productSaga(),
        subCategorySaga(),
        variantSaga(),
        warrantySaga(),
        warehousesSaga(),
        commonSaga(),
        financeAccountsexpenseSaga(),
        financeAccountsexpensesCategorySaga(),
        purchaseSaga(),
        quotationSaga(),
        purchaseInvoiceSaga(),
        purchaseReceiveSaga(),
        purchaseReturnSaga(),
        assettypesSaga(),
        headsSaga(),
        employeesSaga(),
        departmentSaga(),
        leaveSaga(),
        designationSaga(),
        shiftsSaga(),
        attendanceSaga(),
        creditNotesSaga(),
        paymentVoucherSaga(),
        denominationSaga(),  
        sellingTypeListSaga(),
        todayExpensesSaga(),  
        salequotationSaga(),
        saleInvoiceSaga(),
        saleReceiveSaga(),
        saleReturnSaga(),
        saleOrderSaga(),
        debitNoteSaga(), 
        holidaySaga(),
        employeeleaveSaga(),
        payrollSaga(),
    ]);
}