import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setPurchaseReceives = (state, { allPurchaseReceiveData }) => {
    if (!allPurchaseReceiveData) return state;
    return state.merge({ allPurchaseReceiveData });
}

const setPurchaseReceiveById = (state, { purchaseReceiveParam }) => {
    return state.merge({ purchaseReceiveParam });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

const setQuotationNoById = (state, { QuotationData }) => {
    return state.merge({ QuotationData });
}

export const callbacks = [
    {
        actionType: ActionTypes.SET_PURCHASES_RECEIVE,
        actionFunction: setPurchaseReceives,
    },
    {
        actionType: ActionTypes.SET_PURCHASE_RECEIVE_BY_ID,
        actionFunction: setPurchaseReceiveById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
    {
        actionType: ActionTypes.SET_QUOTATION_NO_BY_ID,
        actionFunction: setQuotationNoById,
    },
];
