import React from 'react'
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const TextEditor = ({ data, onChange }) => {
  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        data={data}
        onChange={onChange}
      />
    </div>
  )
}

export default TextEditor
