export const ActionTypes = {
  ADD_UPDATE_CREDIT_NOTE: 'services/purchases/creditNote/add_update_creditNote',
  FETCH_CREDIT_NOTE: 'services/purchases/creditNote/fetch_creditNotes',
  SET_CREDIT_NOTES: 'services/purchases/creditNote/set_creditNotes',
  DELETE_CREDIT_NOTE: 'services/purchases/creditNote/delete_creditNote',
  GET_CREDIT_NOTE_BY_ID: 'services/purchases/creditNote/get_creditNote_by_id',
  SET_CREDIT_NOTE_BY_ID: 'services/purchases/creditNote/set_creditNote_by_id',
  SET_QUOTATION_NO_BY_ID: 'services/purchases/creditNote/set_quotation_no_by_id',
  GET_QUOTATION_NO_BY_ID: 'services/purchases/creditNote/get_quotation_no_by_id',
  SET_LOADER: 'services/inventory/inventory/set_loader',
}

const setLoader = ({ showLoader }) => ({
  showLoader,
  type: ActionTypes.SET_LOADER,
});

const fetchAllCreditNotes = ({ creditNoteParams }) => ({
  creditNoteParams,
  type: ActionTypes.FETCH_CREDIT_NOTE,
})

const setCreditNotes = ({ allCreditNoteData }) => ({
  allCreditNoteData,
  type: ActionTypes.SET_CREDIT_NOTES,
})

const deleteCreditNote = ({ creditNoteParam }) => ({
  creditNoteParam,
  type: ActionTypes.DELETE_CREDIT_NOTE,
})

const getQuotationNoById = ({ QnParam }) => ({
  QnParam,
  type: ActionTypes.GET_QUOTATION_NO_BY_ID,
})

const setQuotationNoById = ({ QuotationData }) => ({
  QuotationData,
  type: ActionTypes.SET_QUOTATION_NO_BY_ID,
})

const addUpdateCreditNote = ({ creditNoteData, actionName, UserParams }) => ({
  creditNoteData,
  actionName,
  UserParams,
  type: ActionTypes.ADD_UPDATE_CREDIT_NOTE,
})

const getCreditNoteById = ({ creditNoteParam }) => ({
  creditNoteParam,
  type: ActionTypes.GET_CREDIT_NOTE_BY_ID,
})

const setCreditNoteById = ({ creditNoteParam }) => ({
  creditNoteParam,
  type: ActionTypes.SET_CREDIT_NOTE_BY_ID,
})

export default {
  addUpdateCreditNote,
  fetchAllCreditNotes,
  setCreditNotes,
  deleteCreditNote,
  getCreditNoteById,
  setCreditNoteById,
  setQuotationNoById,
  getQuotationNoById,
  setLoader
};
