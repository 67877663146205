export const ActionTypes = {
  ADD_UPDATE_SALE_ORDER: 'services/sales/saleOrder/add_update_sale_order',
  FETCH_SALE_ORDERS: 'services/sales/saleOrder/fetch_sale_orders',
  SET_SALE_ORDERS: 'services/sales/saleOrder/set_sale_orders',
  DELETE_SALE_ORDERS: 'services/sales/saleOrder/delete_sale_orders',
  GET_SALE_ORDER_BY_ID: 'services/sales/saleOrder/get_sale_order_by_id',
  SET_SALE_ORDER_BY_ID: 'services/sales/saleOrder/set_sale_order_by_id',
  SET_QUOTATION_NO_BY_ID: 'services/sales/saleOrder/set_quotation_no_by_id',
  GET_QUOTATION_NO_BY_ID: 'services/sales/saleOrder/get_quotation_no_by_id',
  SET_LOADER: 'services/inventory/inventory/set_loader',
}

const setLoader = ({ showLoader }) => ({
  showLoader,
  type: ActionTypes.SET_LOADER,
});

const fetchAllSaleOrders = ({ saleOrderParams }) => ({
  saleOrderParams,
  type: ActionTypes.FETCH_SALE_ORDERS,
})

const setSaleOrders = ({ allSaleOrderData }) => ({
  allSaleOrderData,
  type: ActionTypes.SET_SALE_ORDERS,
})

const deleteSaleOrderById = ({ saleOrderParam }) => ({
  saleOrderParam,
  type: ActionTypes.DELETE_SALE_ORDERS,
})

const GetSaleQuotationByQNById = ({ saleOrderParamId }) => ({
  saleOrderParamId,
  type: ActionTypes.GET_QUOTATION_NO_BY_ID,
})

const SetSaleQuotationByQNById = ({ allsaleQuotationIdData }) => ({
  allsaleQuotationIdData,
  type: ActionTypes.SET_QUOTATION_NO_BY_ID,
})

const addUpdateSaleOrder = ({ saleOrderData, actionName, UserParams }) => ({
  saleOrderData,
  actionName,
  UserParams,
  type: ActionTypes.ADD_UPDATE_SALE_ORDER,
})

const getSaleOrderById = ({ saleOrderParamId }) => ({
  saleOrderParamId,
  type: ActionTypes.GET_SALE_ORDER_BY_ID,
})

const setSaleOrderById = ({ saleOrderData }) => ({
  saleOrderData,
  type: ActionTypes.SET_SALE_ORDER_BY_ID,
})

export default {
  addUpdateSaleOrder,
  fetchAllSaleOrders,
  setSaleOrders,
  deleteSaleOrderById,
  getSaleOrderById,
  setSaleOrderById,
  GetSaleQuotationByQNById,
  SetSaleQuotationByQNById,
  setLoader
};
