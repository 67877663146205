import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../../purchases/purchaseReturn/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdatePurchaseReturn({ purchaseReturnData, actionName, purchaseReturnParams }) {
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/purchases/edit-purchase`,
        data: purchaseReturnData,
      });
    } else {
      yield call(api.post, {
        route: '/purchases/create-purchase',
        data: purchaseReturnData,
      });
    }
    
    yield put(Actions.fetchAllPurchaseReturns({ purchaseReturnParams }));
    notification('success', actionName === 'update' ? CONSTANT_VARIABLES.PURCHASE_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.PURCHASE_CREATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllPurchaseReturns({ purchaseReturnParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch purchase returns
    const { data } = yield call(api.get, {
      route: '/purchases/all-purchases',
      params: purchaseReturnParams,
    });

    // Store the fetched data in Redux store
    const allPurchaseReturnData = data;
    yield put(Actions.setPurchaseReturns({ allPurchaseReturnData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deletePurchaseReturnById({ purchaseReturnParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/purchases/delete-purchase/` + purchaseReturnParam.id,
    });
    yield put(Actions.fetchAllPurchaseReturns({ purchaseReturnParam }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.PURCHASE_DELETED_SUCCESSFULLY);
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function* GetQuotationByQNById({ QnParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    const { data } = yield call(api.delete, {
      route: `/Purchases/all-quotationByQN?QuotationNo=` + QnParam.id,
    });
    const QuotationData = data;
    yield put(Actions.SetQuotationByQNById({ QuotationData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_PURCHASE_RETURN, fetchAllPurchaseReturns),
    takeLatest(ActionTypes.ADD_UPDATE_PURCHASE_RETURN, addUpdatePurchaseReturn),
    takeLatest(ActionTypes.DELETE_PURCHASES_RETURN, deletePurchaseReturnById),
    takeLatest(ActionTypes.GET_QUOTATION_NO_BY_ID, GetQuotationByQNById)
  ]);
}
