import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Switch } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import * as inventorySelectors from '../InventorySelectors';
import unitActions from '../../../core/Services/inventory/units/actions';
import { CONSTANT_VARIABLES } from '../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../shared/components/utility/intlMessages';
import CustomFormLabel from '../../common/CustomFormLabel';
import * as ApiResponseSelectors from '../../selectors';
import './units.css';
import '../inventory.css';

const AddUnitModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, unitParams } = props;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [isError, setIsError] = useState(false);

    const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));

    const handleRecord = (actionName, unitData) => {
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            unitData.id = currentModalData.formData.id;
            unitData.companyId = currentModalData.formData.companyId;
        }
        debugger
        unitData.isActive = unitData.isActive ? true : false;
        dispatch(unitActions.addUpdateUnits({ unitData, actionName, UnitParams: unitParams }));
        toggleModal();
    };

    return (
        <Modal
            open={isModalOpen}
            onCancel={() => toggleModal(true)}
            onOk={() => {
                form.validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            title={isUpdate ? <IntlMessages id="unit.inventory.form.modal.title.update" /> : <IntlMessages id="unit.inventory.form.modal.title.add" />}
            okText={isUpdate ? <IntlMessages id="unit.inventory.form.modal.update" /> : <IntlMessages id="unit.inventory.form.modal.add" />}
            cancelText={<IntlMessages id="unit.inventory.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name="unitForm"
                className='unitActions_addModalWrapper'
                initialValues={{
                    companyId: currentModalData.formData ? currentModalData.formData.companyId : 0,
                    unitName: currentModalData.formData.unitName,
                    unitShortName: currentModalData.formData.unitShortName,
                    isActive: currentModalData.formData.isActive,
                }}
            >
                <div className='row'>
                    <div className="col-lg-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="unitName"
                                className='actions_addModalFormItem'
                                label={<CustomFormLabel labelkey="unit.inventory.form.unit.label.name" popoverkey="unit.inventory.form.label.popover.unit.name" />}
                                rules={[{ required: true, message: <IntlMessages id="unit.inventory.modal.validation.name" /> }]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="unitShortName"
                                className='actions_addModalFormItem'
                                label={<CustomFormLabel labelkey="unit.inventory.form.unit.label.short.name" popoverkey="unit.inventory.form.label.popover.short.name" />}
                                rules={[{ required: true, message: <IntlMessages id="unit.inventory.modal.validation.short.name" /> }]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="addUnit_form--status_area">
                            <span className="status-label"><IntlMessages id="unit.inventory.modal.status" /></span>
                            <Form.Item
                                name="isActive"
                                className='actions_addModalFormItem'
                                valuePropName="checked"
                            >
                                <Switch size="small" />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default AddUnitModal;
