export const ActionTypes = {
    ADD_UPDATE_EMPLOYEELEAVE: 'services/hrm/employeeleave/add_update_employeeleave',
    FETCH_EMPLOYEELEAVE: 'services/hrm/employeeleave/fetch_employeeleaves',
    SET_EMPLOYEELEAVES: 'services/hrm/employeeleave/set_employeeleaves',
    DELETE_EMPLOYEELEAVES: 'services/hrm/employeeleave/delete_employeeleaves',
    GET_EMPLOYEELEAVE_BY_ID: 'services/hrm/employeeleave/get_employeeleave_by_id',
    SET_EMPLOYEELEAVE_BY_ID: 'services/employeeleave/set_employeeleave_by_id',
    SET_LOADER: 'services/hrm/employeeleave/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllEmployeeleaves = ({ employeeleaveParams }) => ({
    employeeleaveParams,
    type: ActionTypes.FETCH_EMPLOYEELEAVE,
})

const setEmployeeleaves = ({ allEmployeeleaveData }) => ({
    allEmployeeleaveData,
    type: ActionTypes.SET_EMPLOYEELEAVES,
})

const deleteEmployeeleaves = ({ employeeleaveParam }) => ({
    employeeleaveParam,
    type: ActionTypes.DELETE_EMPLOYEELEAVES,
})

const addUpdateEmployeeleaves = ({ employeeleaveData, actionName, employeeleaveParams }) => ({
    employeeleaveData,
    actionName,
    employeeleaveParams,
    type: ActionTypes.ADD_UPDATE_EMPLOYEELEAVE,
})

export default {
    addUpdateEmployeeleaves,
    fetchAllEmployeeleaves,
    setEmployeeleaves,
    deleteEmployeeleaves,
    setLoader
};
