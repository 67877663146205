import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, Form, Input, Switch, Select, Tabs } from 'antd';
import { CONSTANT_VARIABLES } from '../../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import CustomFormLabel from '../../../common/CustomFormLabel';
import { PlusCircle } from "react-feather";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Flex, message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button } from 'react-bootstrap';
import commonActions from '../../../../core/Services/common/actions'
import * as commonSelectors from '../../../commonSelectors'

const SalarySummaryInformationTab = (props) => {
    const { form, currentModalData, handleCustomFieldsData, isUpdate, employeeOptionsData } = props;
    const dispatch = useDispatch();

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                initialValues={{}}
                style={{ marginTop: '20px' }}
            >
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks add-product">
                            <div className="row">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="totalAllowance"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="Total Allowance"
                                                popoverkey="Total Allowance"
                                            />
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Total Allowance Required",
                                            },
                                            {
                                                pattern: /^\d+$/,
                                                message: "Total Allowance must be a number",
                                            },
                                        ]}
                                    >
                                        <input type='text' placeholder='Add Total Allowance'/>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks add-product">
                            <div className="row">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="totalDeduction"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="Total Deduction"
                                                popoverkey="Total Deduction"
                                            />
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Total Deduction Required",
                                            },
                                            {
                                                pattern: /^\d+$/,
                                                message: "Total Deduction must be a number",
                                            },
                                        ]}
                                    >
                                        <input type='text' placeholder='Add Total Deduction'/>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="">
                                    <Form.Item
                                        name="netSalary"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="Net Salary"
                                                popoverkey="Net Salary"
                                            />
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Net Salary Required",
                                            },
                                            {
                                                pattern: /^\d+$/,
                                                message: "Net Salary must be a number",
                                            },
                                        ]}
                                    >
                                        <input type='text' placeholder='Add Net Salary'/>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default SalarySummaryInformationTab;
