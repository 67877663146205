import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setUnits = (state, { allUnitData }) => {
    if (!allUnitData) return state;
    return state.merge({ allUnitData });
}

const setUnitById = (state, { unitParam }) => {
    return state.merge({ unitParam });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_UNITS,
        actionFunction: setUnits,
    },
    {
        actionType: ActionTypes.SET_UNIT_BY_ID,
        actionFunction: setUnitById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
