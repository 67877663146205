import Immutable from 'immutable';
import { ActionTypes } from "./actions.js";
export const initialState = Immutable.fromJS({});

const setDenominations = (state, { allDenominationData }) => {
    if (!allDenominationData) return state;
    return state.merge({ allDenominationData });
}

const setDenominationById = (state, { denominationParam }) => {
    return state.merge({ denominationParam });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_DENOMINATIONS,
        actionFunction: setDenominations,
    },
    {
        actionType: ActionTypes.SET_DENOMINATION_BY_ID,
        actionFunction: setDenominationById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
