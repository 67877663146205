import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, Form, Input, Switch, Select, Tabs } from 'antd';
import { CONSTANT_VARIABLES } from '../../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import CustomFormLabel from '../../../common/CustomFormLabel';
import { PlusCircle } from "react-feather";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Flex, message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button } from 'react-bootstrap';
import commonActions from '../../../../core/Services/common/actions'
import * as commonSelectors from '../../../commonSelectors'

const DeductionInformationTab = (props) => {
    const { form, currentModalData, handleCustomFieldsData, isUpdate, employeeOptionsData } = props;
    const dispatch = useDispatch();

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    pfDeduction: currentModalData?.formData?.pfDeduction,
                    professionalTax: currentModalData?.formData?.professionalTax,
                    tds: currentModalData?.formData?.tds,
                    loans: currentModalData?.formData?.loans,
                }}
                style={{ marginTop: '20px' }}
            >

                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks add-product">
                            <div className="row">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="pfDeduction"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="PF"
                                                popoverkey="PF"
                                            />
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "PF Required",
                                            },
                                            {
                                                pattern: /^\d+$/,
                                                message: "PF must be a number",
                                            },
                                        ]}
                                    >
                                        <input type='text' placeholder='Add PF'/>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks add-product">
                            <div className="row">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="professionalTax"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="Professional Tax"
                                                popoverkey="Professional Tax"
                                            />
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Professional Tax Required",
                                            },
                                            {
                                                pattern: /^\d+$/,
                                                message: "Professional Tax must be a number",
                                            },
                                        ]}
                                    >
                                        <input type='text' placeholder='Add Professional Tax'/>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks add-product">
                            <div className="row">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="tds"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="TDS"
                                                popoverkey="TDS"
                                            />
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "TDS Required",
                                            },
                                            {
                                                pattern: /^\d+$/,
                                                message: "TDS must be a number",
                                            },
                                        ]}
                                    >
                                        <input type='text' placeholder='Add TDS'/>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks add-product">
                            <div className="row">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="loans"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="Loans & Others"
                                                popoverkey="Loans & Others"
                                            />
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Loans Required",
                                            },
                                            {
                                                pattern: /^\d+$/,
                                                message: "Loans must be a number",
                                            },
                                        ]}
                                    >
                                        <input type='text' placeholder='Add Loans & Others'/>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default DeductionInformationTab;
