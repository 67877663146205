export const ActionTypes = {
  ADD_UPDATE_SALEQUOTATION: 'services/sales/salequotations/add_update_salequotation',
  FETCH_SALEQUOTATION: 'services/sales/salequotations/fetch_salequotations',
  SET_SALEQUOTATIONS: 'services/sales/salequotations/set_salequotations',
  DELETE_SALEQUOTATIONS: 'services/sales/salequotations/delete_salequotations',
  GET_SALEQUOTATION_BY_ID: 'services/sales/salequotations/get_salequotation_by_id',
  SET_SALEQUOTATION_BY_ID: 'services/sales/salequotations/set_salequotation_by_id',
  SET_LOADER: 'services/sales/salequotations/set_loader',
}

const setLoader = ({ showLoader }) => ({
  showLoader,
  type: ActionTypes.SET_LOADER,
});

const fetchAllSaleQuotations = ({ salequotationParams }) => ({
  salequotationParams,
  type: ActionTypes.FETCH_SALEQUOTATION,
})

const setSaleQuotations = ({ allSaleQuotationData }) => ({
  allSaleQuotationData,
  type: ActionTypes.SET_SALEQUOTATIONS,
})

const deleteSaleQuotationById = ({ salequotationParam }) => ({
  salequotationParam,
  type: ActionTypes.DELETE_SALEQUOTATIONS,
})

const getSaleQuotationById = ({ salequotationParam }) => ({
  salequotationParam,
  type: ActionTypes.GET_SALEQUOTATION_BY_ID,
})

const setSaleQuotationById = ({ salequotationParam }) => ({
  salequotationParam,
  type: ActionTypes.SET_SALEQUOTATION_BY_ID,
})

const addUpdateSaleQuotations = ({ salequotationData, actionName, UserParams }) => ({
  salequotationData,
  actionName,
  UserParams,
  type: ActionTypes.ADD_UPDATE_SALEQUOTATION,
})

export default {
  addUpdateSaleQuotations,
  fetchAllSaleQuotations,
  setSaleQuotations,
  setSaleQuotationById,
  deleteSaleQuotationById,
  getSaleQuotationById,
  setSaleQuotationById,
  setLoader
}
