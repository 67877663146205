import { combineReducers } from 'redux';
import { createReducer } from '../utils/reducer';

// User Management
import * as userManagementReducer from "../Services/userManagement/reducer";
import * as customerReducer from "../Services/people/customers/reducer";
import * as supplierReducer from "../Services/people/suppliers/reducer";
import * as storesReducer from "../Services/people/stores/reducer";
import * as brandReducer from "../Services/inventory/brands/reducer";
import * as unitReducer from "../Services/inventory/units/reducer";
import * as variantReducer from "../Services/inventory/variant/reducer";
import * as warrantyReducer from "../Services/inventory/warranty/reducer";
import * as productReducer from "../Services/inventory/products/reducer";
import * as categoryReducer from "../Services/inventory/categories/reducer";
import * as subCategoryReducer from "../Services/inventory/subCategory/reducer";
import * as warehouseReducer from "../Services/people/warehouses/reducer";
import * as commonReducer from "../Services/common/reducer";
import * as financeAccountsexpenseReducer from "../Services/financeAccounts/expense/reducer";
import * as financeAccountsexpensesCategoryReducer from "../Services/financeAccounts/expensesCategory/reducer";
import * as apiResponseReducer from "../Services/apiResponse/reducer";
import * as purchaseReducer from "../Services/purchases/purchase/reducer";
import * as quotationReducer from "../Services/purchases/quotations/reducer";
import * as purchaseInvoiceReducer from "../Services/purchases/purchaseInvoice/reducer";
import * as purchaseReceiveReducer from "../Services/purchases/purchaseReceive/reducer";
import * as purchaseReturnReducer from "../Services/purchases/purchaseReturn/reducer";
import * as assettypesReducer from "../Services/purchases/assettypes/reducer";
import * as headsReducer from "../Services/purchases/heads/reducer";
import * as employeesReducer from "../Services/hrm/employee/reducer";
import * as designationReducer from "../Services/hrm/designation/reducer";
import * as departmentReducer from "../Services/hrm/department/reducer";
import * as leaveReducer from "../Services/hrm/leave/reducer"
import * as shiftsReducer from "../Services/hrm/shifts/reducer";
import * as sellingTypeListReducer from "../Services/inventory/sellingtype/reducer"; 
import * as attendanceReducer from "../Services/hrm/attendance/reducer";
import * as paymentVoucherReducer from "../Services/purchases/paymentVoucher/reducer";
import * as creditNotesReducer from "../Services/purchases/creditnotes/reducer";
import * as denominationReducer from "../Services/pos/cashclosing/cashdenomination/reducer"
import * as todayExpensesReducer from "../Services/pos/cashclosing/todayexpenses/reducer"

import * as salequotationReducer from "../Services/sales/salequotations/reducer";
import * as saleInvoiceReducer from "../Services/sales/saleInvoice/reducer";
import * as saleReceiveReducer from "../Services/sales/saleReceive/reducer";
import * as saleReturnReducer from "../Services/sales/saleReturn/reducer";
import * as saleOrderReducer from "../Services/sales/salesOrder/reducer";
import * as debitNoteReducer from "../Services/sales/debitnotes/reducer";
import * as holidayReducer from "../Services/hrm/holiday/reducer";
import * as employeeleaveReducer from "../Services/hrm/employeeleave/reducer";
import * as payrollReducer from "../Services/hrm/payroll/reducer";

export default combineReducers({
    services: combineReducers({
        userManagement: createReducer(userManagementReducer),
        customers: createReducer(customerReducer),
        suppliers: createReducer(supplierReducer),
        stores: createReducer(storesReducer),
        brands: createReducer(brandReducer),
        units: createReducer(unitReducer),
        categories: createReducer(categoryReducer),
        variant: createReducer(variantReducer),
        warranty: createReducer(warrantyReducer),
        products: createReducer(productReducer),
        subcategory: createReducer(subCategoryReducer),
        warehouses: createReducer(warehouseReducer),
        common: createReducer(commonReducer),
        expense: createReducer(financeAccountsexpenseReducer),
        expensesCategory: createReducer(financeAccountsexpensesCategoryReducer),
        purchase: createReducer(purchaseReducer),
        quotations: createReducer(quotationReducer),
        apiResponse: createReducer(apiResponseReducer),
        purchaseInvoice:createReducer(purchaseInvoiceReducer),
        purchaseReceive:createReducer(purchaseReceiveReducer),
        purchaseReturn:createReducer(purchaseReturnReducer),
        assettypes:createReducer(assettypesReducer),
        heads:createReducer(headsReducer),
        employee:createReducer(employeesReducer),
        designation:createReducer(designationReducer),
        department:createReducer(departmentReducer),
        leave:createReducer(leaveReducer),
        shifts:createReducer(shiftsReducer),
        attendance:createReducer(attendanceReducer),
        creditNotes:createReducer(creditNotesReducer),
        paymentVoucher:createReducer(paymentVoucherReducer),
        denominations:createReducer(denominationReducer),
        todayexpenses:createReducer(todayExpensesReducer),
        sellingType: createReducer(sellingTypeListReducer),
        salequotations:createReducer(salequotationReducer),
        saleInvoice:createReducer(saleInvoiceReducer),
        saleReceive:createReducer(saleReceiveReducer),
        saleReturn:createReducer(saleReturnReducer),
        saleOrder:createReducer(saleOrderReducer),
        debitNote:createReducer(debitNoteReducer),
        holiday:createReducer(holidayReducer),
        employeeleave:createReducer(employeeleaveReducer),
        payroll:createReducer(payrollReducer),
    }),
});