import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, Form, Input, Switch, Select, Tabs } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import * as inventorySelectors from '../../InventorySelectors';
import productActions from '../../../../core/Services/inventory/products/actions';
import { CONSTANT_VARIABLES } from '../../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import CustomFormLabel from '../../../common/CustomFormLabel';
import * as ApiResponseSelectors from '../../../selectors';
import AddCategoryModal from '../../categories/addcategory';
import AddUnitModal from '../../units/addunit';
import AddBrandModal from '../../brands/addbrand';
import '../products.css';
import '../../inventory.css';
// import { useFormikContext } from 'formik';
import {
  ArrowLeft,
  Calendar,
  ChevronDown,
  ChevronUp,
  Info,
  LifeBuoy,
  List,
  PlusCircle,
  Trash2,
  X,
} from "feather-icons-react/build/IconComponents";
import * as commonSelectors from '../../../commonSelectors';
import commonAction from '../../../../core/Services/common/actions';

const ProductInformationTab = (props) => {
  const { form , currentModalData, handleCustomFieldsData } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(commonAction.getAllCategory());
    dispatch(commonAction.getAllBrands({}));
    dispatch(commonAction.getAllStores({}));
    dispatch(commonAction.getAllUnits({}));
    dispatch(commonAction.getAllWarehouses({}));
    dispatch(commonAction.getAllSellingTypes({}));
    dispatch(commonAction.getAllWarehouses({}));
    dispatch(commonAction.getAllbarcodesymbologies({}));
  }, []);

  const categoryDataSource = useSelector(state => commonSelectors.setAllCategory(state));
  const categories = categoryDataSource ? categoryDataSource.toJS() : [];
  const warehouseDataSource = useSelector(state => commonSelectors.setAllWarehouses(state));
  const warehouses = warehouseDataSource ? warehouseDataSource.toJS() : [];
  const storesDataSource = useSelector(state => commonSelectors.setAllStores(state));
  const stores = storesDataSource ? storesDataSource.toJS() : [];
  const brandsDataSource = useSelector(state => commonSelectors.setAllBrands(state));
  const brands = brandsDataSource ? brandsDataSource.toJS() : [];
  const unitsDataSource = useSelector(state => commonSelectors.setAllUnits(state));
  const units = unitsDataSource ? unitsDataSource.toJS() : [];
  const sellingTypesDataSource = useSelector(state => commonSelectors.setAllSellingTypes(state));
  const sellingTypes = sellingTypesDataSource ? sellingTypesDataSource.toJS() : [];
  const barCodeSymbologyDataSource = useSelector(state => commonSelectors.setAllbarcodesymbologies(state));
  const barcodeSymbology = barCodeSymbologyDataSource ? barCodeSymbologyDataSource.toJS() : [];
  const subCategoriesDataSouce = useSelector(state => commonSelectors.setAllSubCategory(state));
  const subCategories = subCategoriesDataSouce ? subCategoriesDataSouce.toJS() : [];

  const subcategory = [
    { value: 1, label: "Shoes" },
    { value: 2, label: "Lenovo" },
  ];

  const [isCategoryModalOpen, setCategoryModalOpen] = useState(false);
  const [currentCategoryModalData, setCurrentCategoryModalData] = useState({});
  const handleModal = (categoryData, actionName) => {
    const categoryModalObj = {
      formData: {},
    };
    setCurrentCategoryModalData(categoryModalObj);
    setCategoryModalOpen(true);
  };
  const toggleCategoryModal = () => {
    dispatch(commonAction.getAllCategory());
    setCategoryModalOpen(false);
  };

  const [isBrandModalOpen, setBrandModalOpen] = useState(false);
  const [currentBrandModalData, setCurrentBrandModalData] = useState({});
  const handleBrandModal = (BrandData, actionName) => {
    const BrandModalObj = {
      formData: {},
    };
    setCurrentBrandModalData(BrandModalObj);
    setBrandModalOpen(true);
  };
  const toggleBrandModal = () => {
    dispatch(commonAction.getAllBrands({}));
    setBrandModalOpen(false);
  };

  const [isUnitModalOpen, setUnitModalOpen] = useState(false);
  const [currentUnitModalData, setCurrentUnitModalData] = useState({});
  const handleUnitModal = (UnitData, actionName) => {
    const UnitModalObj = {
      formData: {},
    };
    setCurrentUnitModalData(UnitModalObj);
    setUnitModalOpen(true);
  };
  const toggleUnitModal = () => {
    dispatch(commonAction.getAllUnits({}));
    setUnitModalOpen(false);
  };


  const [slug, setSlug] = useState('');
  useEffect(() => {
    form.setFieldsValue({
      productSlug: slugify(form.getFieldValue('productName')),
    });
  }, [form.getFieldValue('productName')]);

  const handleProductNameChange = (e) => {
    const productName = e.target.value;
    form.setFieldsValue({
      productSlug: slugify(productName),
    });
  }
  const slugify = (text) => {
    if (!text) return '';
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  };

  // const { setFieldValue, getFieldValue } = useFormikContext();

  const handleGenerateSKU = () => {
    const productSlug = form.getFieldValue('productSlug');
    const randomNumber = Math.floor(1000 + Math.random() * 9000);
    const generatedSKU = `${productSlug}-${randomNumber}`;
    form.setFieldsValue({
      productSKU: slugify(generatedSKU),
    });
  };

  const handleGenerateItemCode = () => {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const randChars = Array.from({ length: 8 }, () => chars.charAt(Math.floor(Math.random() * chars.length))).join('');
    const generatedItemCode = `${randChars}`;

    form.setFieldsValue({
      itemCode: slugify(generatedItemCode),
    });
  };

  const handleCategoryChange = (CategoryId) => {
    dispatch(commonAction.getAllSubCategory({ CategoryId }));
    form.setFieldsValue({ subCategoryId: undefined });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        storeId: currentModalData.formData.storeId,
        warehouseId: currentModalData.formData.warehouseId,
        productName: currentModalData.formData.productName,
        productSlug: currentModalData.formData.productSlug,
        productSKU: currentModalData.formData.productSKU,
        categoryId: currentModalData.formData.categoryId,
        subCategoryId: currentModalData.formData.subCategoryId,
        brandId: currentModalData.formData.brandId,
        unitId: currentModalData.formData.unitId,
        sellingTypeId: currentModalData.formData.sellingTypeId,
        barcodeSymbologyId: currentModalData.formData.barcodeSymbologyId,
        itemCode: currentModalData.formData.itemCode,
        description: currentModalData.formData.description
      }}
      name="forForm" className='productActions_addModalWrapper'>
      <div className="row">
        <div className="col-lg-4 productModalForm">
          <div className="input-blocks">
            <Form.Item
              name="storeId"
              className="actions_addModalFormItem"
              label={
                <CustomFormLabel
                  labelkey="store.inventory.form.label.name"
                  popoverkey="store.inventory.form.label.popover.name"
                />
              }
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="store.inventory.modal.validation.name" />,
                },
              ]}
            >
              <Select
                classNamePrefix="react-select"
                options={stores.map(s => ({ label: s.name, value: s.id }))}
                placeholder="Choose"
                className="input-select"
              />
            </Form.Item>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="input-blocks">
            <Form.Item
              name="warehouseId"
              className="actions_addModalFormItem"
              label={
                <CustomFormLabel
                  labelkey="warehouse.inventory.form.label.name"
                  popoverkey="warehouse.inventory.form.label.popover.name"
                />
              }
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="warehouse.inventory.modal.validation.name" />,
                },
              ]}
            >
              <Select
                classNamePrefix="react-select"
                options={warehouses.map(f => ({ label: f.name, value: f.id }))}
                placeholder="Choose"
                className="input-select"
              />
            </Form.Item>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4">
          <div className="input-blocks">
            <Form.Item
              name="productName"
              className="actions_addModalFormItem"
              label={
                <CustomFormLabel
                  labelkey="productName.inventory.form.label.name"
                  popoverkey="productName.inventory.form.label.popover.name"
                />
              }
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="productName.inventory.modal.validation.name" />,
                },
              ]}
            >
              <Input onChange={handleProductNameChange} />
            </Form.Item>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="input-blocks">
            <Form.Item
              name="productSlug"
              className="actions_addModalFormItem"
              label={
                <CustomFormLabel
                  labelkey="slug.inventory.form.label.name"
                  popoverkey="slug.inventory.form.label.popover.name"
                />
              }
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="slug.inventory.modal.validation.name" />,
                },
              ]}
            >
              <Input value={slug} readOnly />
            </Form.Item>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="input-blocks list">
            <Form.Item
              name="productSKU"
              className="actions_addModalFormItem"
              label={
                <CustomFormLabel
                  labelkey="sku.inventory.form.label.name"
                  popoverkey="sku.inventory.form.label.popover.name"
                />
              }
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="sku.inventory.modal.validation.name" />,
                },
              ]}
            >
              <Input placeholder="Enter SKU" />
            </Form.Item>
            <Link onClick={() => handleGenerateSKU()}
              className="btn btn-primaryadd product_form_generate_code--button"
            >
              <IntlMessages id="inventory.modal.form.product.info.generate.code.button.text" />
            </Link>
          </div>
        </div>
      </div>

      <div className="addservice-info">
        <div className="row">
          <div className="col-lg-4">
            <div className="row align-items-center">
              <div className="col-lg-10">
                <div className="input-blocks">
                  <Form.Item
                    name="categoryId"
                    className="actions_addModalFormItem"
                    label={
                      <CustomFormLabel
                        labelkey="category.inventory.form.label.name"
                        popoverkey="category.inventory.form.label.popover.name"
                      />
                    }
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="category.inventory.modal.validation.name" />,
                      },
                    ]}
                  >
                    <Select
                      classNamePrefix="react-select"
                      options={categories.map(f => ({ label: f.name, value: f.id }))}
                      placeholder="Choose"
                      className="input-select"
                      onChange={handleCategoryChange}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="col-lg-2 ps-0">
                <div className="add-icon tab mt-1">
                  <Link
                    className="btn btn-filter"
                    onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                  >
                    <PlusCircle className="feather feather-plus-circle" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="input-blocks">
              <Form.Item
                name="subCategoryId"
                className="actions_addModalFormItem"
                label={
                  <CustomFormLabel
                    labelkey="subcategory.inventory.form.label.name"
                    popoverkey="subcategory.inventory.form.label.popover.name"
                  />
                }
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="subcategory.inventory.modal.validation.name" />,
                  },
                ]}
              >
                <Select
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  classNamePrefix="react-select"
                  options={subCategories.map(f => ({ label: f.name, value: f.id }))}
                  placeholder="Choose"
                  className="input-select"
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>

      <div className="add-product-new">
        <div className="row">
          <div className="col-lg-4">
            <div className="row align-items-center">
              <div className="col-lg-10">
                <div className="input-blocks">
                  <Form.Item
                    name="brandId"
                    className="actions_addModalFormItem"
                    label={
                      <CustomFormLabel
                        labelkey="brand.inventory.form.label.name"
                        popoverkey="brand.inventory.form.label.popover.name"
                      />
                    }
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="brand.inventory.modal.validation.name" />,
                      },
                    ]}
                  >
                    <Select
                      classNamePrefix="react-select"
                      options={brands.map(s => ({ label: s.name, value: s.id }))}
                      placeholder="Choose"
                      className="input-select"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="col-lg-2 ps-0">
                <div className="add-icon tab mt-1">
                  <Link
                    className="btn btn-filter"
                    onClick={() => handleBrandModal({}, CONSTANT_VARIABLES.ADD)}
                  >
                    <PlusCircle className="feather feather-plus-circle" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="row align-items-center">
              <div className="col-lg-10">
                <div className="input-blocks">
                  <Form.Item
                    name="unitId"
                    className="actions_addModalFormItem"
                    label={
                      <CustomFormLabel
                        labelkey="unit.inventory.form.label.name"
                        popoverkey="unit.inventory.form.label.popover.name"
                      />
                    }
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="unit.inventory.modal.validation.name" />,
                      },
                    ]}
                  >
                    <Select
                      classNamePrefix="react-select"
                      options={units.map(s => ({ label: s.name, value: s.id }))}
                      placeholder="Choose"
                      className="input-select"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="col-lg-2 ps-0">
                <div className="add-icon tab mt-1">
                  <Link
                    className="btn btn-filter"
                    onClick={() => handleUnitModal({}, CONSTANT_VARIABLES.ADD)}
                  >
                    <PlusCircle className="feather feather-plus-circle" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="input-blocks">
              <Form.Item
                name="sellingTypeId"
                className="actions_addModalFormItem"
                label={
                  <CustomFormLabel
                    labelkey="sellingType.inventory.form.label.name"
                    popoverkey="sellingType.inventory.form.label.popover.name"
                  />
                }
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="sellingType.inventory.modal.validation.name" />,
                  },
                ]}
              >
                <Select
                  classNamePrefix="react-select"
                  options={sellingTypes.map(s => ({ label: s.name, value: s.id }))}
                  placeholder="Choose"
                  className="input-select"
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <div className="input-blocks">
            <Form.Item
              name="barcodeSymbologyId"
              className="actions_addModalFormItem"
              label={
                <CustomFormLabel
                  labelkey="barcodeSymbology.inventory.form.label.name"
                  popoverkey="barcodeSymbology.inventory.form.label.popover.name"
                />
              }
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="barcodeSymbology.inventory.modal.validation.name" />,
                },
              ]}
            >
              <Select
                classNamePrefix="react-select"
                options={barcodeSymbology.map(s => ({ label: s.name, value: s.id }))}
                placeholder="Choose"
                className="input-select"
              />
            </Form.Item>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="input-blocks list">
            <Form.Item
              name="itemCode"
              className="actions_addModalFormItem"
              label={
                <CustomFormLabel
                  labelkey="itemCode.inventory.form.label.name"
                  popoverkey="itemCode.inventory.form.label.popover.name"
                />
              }
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="itemCode.inventory.modal.validation.name" />,
                },
              ]}
            >
              <Input placeholder="Please Enter Item Code" />
            </Form.Item>
            <Link onClick={() => handleGenerateItemCode()}
              className="btn btn-primaryadd product_form_generate_code--button"
            >
              <IntlMessages id="inventory.modal.form.product.info.generate.code.button.text" />

            </Link>
          </div>
        </div>
      </div>

      <div className="col-lg-12">
        <div className="input-blocks summer-description-box transfer mb-3">
          <Form.Item
            name="description"
            className="actions_addModalFormItem"
            label={
              <CustomFormLabel
                labelkey="description.inventory.form.label.name"
                popoverkey="description.inventory.form.label.popover.name"
              />
            }
          >
            <textarea
              className="form-control h-100"
              rows={5}
              maxLength={60}
            />
          </Form.Item>
          <p className="mt-1">Maximum 60 Characters</p>
        </div>
      </div>
      {isCategoryModalOpen ? (
        <AddCategoryModal
          currentModalData={currentCategoryModalData}
          isModalOpen={isCategoryModalOpen}
          isUpdate={false}
          toggleModal={toggleCategoryModal}
        />
      ) : null}

      {isBrandModalOpen ? (
        <AddBrandModal
          currentModalData={currentBrandModalData}
          isModalOpen={isBrandModalOpen}
          isUpdate={false}
          toggleModal={toggleBrandModal}
        />
      ) : null}

      {isUnitModalOpen ? (
        <AddUnitModal
          currentModalData={currentUnitModalData}
          isModalOpen={isUnitModalOpen}
          isUpdate={false}
          toggleModal={toggleUnitModal}
        />
      ) : null}
    </Form>
  );
};

export default ProductInformationTab;
