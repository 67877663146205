import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setApiResponse = (state, {apiStatus}) => {
    if (!apiStatus) return state;
    return state.merge({apiStatus})
}

export const callbacks = [
    {
        actionType: ActionTypes.SET_API_RESPONSE,
        actionFunction: setApiResponse,
    },
]