export const setCreditNotes = state => {
    return state.services.debitNote.getIn(['allCreditNoteData']);
 };
export const setLoader = state => {
    return state.services.debitNote.getIn(['showLoader']);
 };
 export const setSaleInvoices = state => {
    return state.services.saleInvoice.getIn(['allSaleInvoiceData']);
 };
 export const setSaleQuotations = state => {
    return state.services.salequotations.getIn(['allSaleQuotationData']);
 };
 export const setSaleReceives = state => {
    return state.services.saleReceive.getIn(['allSaleReceiveData']);
 };
 export const setSaleReturns = state => {
    return state.services.saleReturn.getIn(['allSaleReturnData']);
 };
 export const setSaleOrders = state => {
    return state.services.saleOrder.getIn(['allSaleOrderData']);
 };
 export const SetSaleQuotationByQNById = state => {
   return state.services.saleOrder.getIn(['allsaleQuotationIdData']);
};