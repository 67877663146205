import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setLeave = (state, { allLeaveData }) => {
    if (!allLeaveData) return state;
    return state.merge({ allLeaveData });
}

const setLeaveTypes = (state, { allLeaveTypeData }) => {
    if (!allLeaveTypeData) return state;
    return state.merge({ allLeaveTypeData });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_LEAVE,
        actionFunction: setLeave,
    },
    {
        actionType: ActionTypes.SET_LEAVE_TYPE,
        actionFunction: setLeaveTypes,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
