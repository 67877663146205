import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { Form, Checkbox } from 'antd';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import CustomFormLabel from '../../../common/CustomFormLabel';
import '../products.css';
import '../../inventory.css';

const CustomFieldsTab = (props) => {
    const { form, currentModalData, isUpdate } = props;
    const [manufacturerDate, setManufacturerDate] = useState(null);
    const [expiryOn, setExpiryOn] = useState(null);

    useEffect(() => {
        if (isUpdate && currentModalData) {
            setManufacturerDate(currentModalData.formData.manufacturerDate ? new Date(currentModalData.formData.manufacturerDate) : null);
            setExpiryOn(currentModalData.formData.expiryOn ? new Date(currentModalData.formData.expiryOn) : null);
        }
    }, [isUpdate, currentModalData]);

    const handleManufacturerDateChange = (date) => {
        setManufacturerDate(date);
        form.setFieldsValue({ manufacturerDate: date ? moment(date).format('YYYY-MM-DD') : null });
    };

    const handleExpiryOnChange = (date) => {
        setExpiryOn(date);
        form.setFieldsValue({ expiryOn: date ? moment(date).format('YYYY-MM-DD') : null });
    };

    const validateDates = (rule, value, callback) => {
        const { manufacturerDate: newManufacturerDate, expiryOn: newExpiryOn } = form.getFieldsValue();
        if (newExpiryOn && newManufacturerDate && new Date(newExpiryOn) < new Date(newManufacturerDate)) {
            callback(<IntlMessages id="expiry.before.manufacture.date" />);
        } else {
            callback();
        }
    };

    return (
        <Form
            form={form} layout="vertical"
            initialValues={{
                isWarranty: currentModalData.formData.isWarranty,
                isManufacturer: currentModalData.formData.isManufacturer,
                isExpiry: currentModalData.formData.isExpiry,
                manufacturerDate: manufacturerDate,
                expiryOn: expiryOn,
            }}
            name="forForm" className='productActions_addModalWrapper'
        >
            <div className="accordion-body">
                <div className="col-lg-10">
                    <div className="col-lg-4">
                        <div className="addCategory_form--status_area">
                            <span className="status-label"> <IntlMessages id="warranties.inventory.modal.status" /></span>
                            <Form.Item
                                name="isWarranty"
                                className='actions_addModalFormItem'
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="addCategory_form--status_area">
                            <span className="status-label"> <IntlMessages id="manufacturer.inventory.modal.status" /></span>
                            <Form.Item
                                name="isManufacturer"
                                className='actions_addModalFormItem'
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="addCategory_form--status_area">
                            <span className="status-label"> <IntlMessages id="expiry.inventory.modal.status" /></span>
                            <Form.Item
                                name="isExpiry"
                                className='actions_addModalFormItem'
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="input-blocks">
                            <Form.Item
                                name="manufacturerDate"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="manufactureddate.inventory.form.label.name"
                                        popoverkey="manufactureddate.inventory.form.label.popover.name"
                                    />
                                }
                                rules={[
                                    {
                                        required: !isUpdate, 
                                        message: <IntlMessages id="manufactureddate.inventory.modal.validation.name" />,
                                    },
                                ]}
                            >
                                <DatePicker
                                    selected={manufacturerDate}
                                    onChange={handleManufacturerDateChange}
                                    dateFormat="YYYY-MM-DD"
                                    placeholderText="Select Date"
                                    className="actions_addModalFormItem"
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="input-blocks">
                            <Form.Item
                                name="expiryOn"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="expirydate.inventory.form.label.name"
                                        popoverkey="expirydate.inventory.form.label.popover.name"
                                    />
                                }
                                rules={[
                                    {
                                        required: !isUpdate,
                                        message: <IntlMessages id="expirydate.inventory.modal.validation.name" />,
                                    },
                                    {
                                        validator: validateDates,
                                    },
                                ]}
                            >
                                <DatePicker
                                    selected={expiryOn}
                                    onChange={handleExpiryOnChange}
                                    dateFormat="YYYY-MM-DD"
                                    placeholderText="Select Date"
                                    className="actions_addModalFormItem"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
};

export default CustomFieldsTab;
