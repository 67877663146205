import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from './actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdatePaymentVoucher({ paymentVoucherData, actionName, paymentVoucherParams }) {
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/purchases/edit-paymentVoucher`,
        data: paymentVoucherData,
      });
    } else {
      yield call(api.post, {
        route: '/purchases/create-paymentVoucher',
        data: paymentVoucherData,
      });
    }
    
    yield put(Actions.fetchAllPaymentVouchers({ paymentVoucherParams }));
    notification('success', actionName === 'update' ? CONSTANT_VARIABLES.PAYMENT_VOUCHER_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.PAYMENT_VOUCHER_CREATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllPaymentVouchers({ paymentVoucherParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch payment vouchers
    const { data } = yield call(api.get, {
      route: '/purchases/all-paymentVouchers',
      params: paymentVoucherParams,
    });

    // Store the fetched data in Redux store
    const allPaymentVoucherData = data;
    yield put(Actions.setPaymentVouchers({ allPaymentVoucherData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deletePaymentVoucherById({ paymentVoucherParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/purchases/delete-paymentVoucher/` + paymentVoucherParam.id,
    });
    yield put(Actions.fetchAllPaymentVouchers({ paymentVoucherParam }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.PAYMENT_VOUCHER_DELETED_SUCCESSFULLY);
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function* getQuotationByQNById({ QnParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    const { data } = yield call(api.get, {
      route: `/Purchases/all-quotationByQN?QuotationNo=` + QnParam.id,
    });
    const QuotationData = data;
    yield put(Actions.setQuotationNoById({ QuotationData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_PAYMENT_VOUCHER, fetchAllPaymentVouchers),
    takeLatest(ActionTypes.ADD_UPDATE_PAYMENT_VOUCHER, addUpdatePaymentVoucher),
    takeLatest(ActionTypes.DELETE_PAYMENT_VOUCHER, deletePaymentVoucherById),
    takeLatest(ActionTypes.GET_QUOTATION_NO_BY_ID, getQuotationByQNById)
  ]);
}
