import React, { useState, useRef, useEffect, Fragment } from 'react';
import * as PeopleManagementSelectors from '../PeopleManagementSelectors';
import warehouseActions from '../../../core/Services/people/warehouses/actions';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Input, Select, Upload, Image, Checkbox, Modal } from 'antd';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import CustomFormLabel from "../../common/CustomFormLabel";
import { PlusOutlined } from '@ant-design/icons';
import '../peopleManagement.css';
//import 'antd/dist/antd.css';
import './warehouses.css';
import TextArea from 'antd/es/input/TextArea';
import * as ApiResponseSelectors from '../../selectors';
import { string } from 'prop-types';
import {countriesList}  from "../../../core/Common/Constant_Variables/common";
import axios from 'axios';

const AddWarehouse = (props) => {
  const { currentModalData, isModalOpen, isUpdate, toggleModal, warehouseParams } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const imageRef = useRef(null);
  const [modal] = Modal.useModal();

  const [imageUrl, setImageUrl] = useState('');
  const [isError, setisError] = useState(false);
  const [imageURL, setimageURL] = useState("");
  const [state, setState] = useState({});
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [value, setValue] = useState('')
  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
  const [allcountryList, setAllcountryList] = useState([])
 
  useEffect (() =>{
    setAllcountryList(countriesList());
  },[countriesList])

  const changeHandler = value => {
    setValue(value)
  }
  const handleRecord = (actionName, warehouseData) => {
    if (actionName === CONSTANT_VARIABLES.UPDATE){
      warehouseData.id = currentModalData.formData.id;
      warehouseData.companyId = currentModalData.formData.companyId;
    }else{
      warehouseData.companyId = 0;
    }
    warehouseData.warehouseImage = fileList[0];
    dispatch(warehouseActions.addUpdateWarehouses({ warehouseData, actionName, WarehouseParams: warehouseParams }));
    toggleModal();
  };

  const checkEmailValidator = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email) ? true : false;
  }

  useEffect(() => {
    if (isUpdate) {
      uploadFromURL(currentModalData.formData?.profileImgUrl)
    }
  }, [currentModalData]);

  const uploadFromURL = async (url) => {
    try {
      const response = await axios.get(url, { responseType: 'blob' });
      const file = new File([response.data], 'downloaded-image.png', { type: 'image/png' });
      const newFileList = [
        ...fileList,
        {
          uid: `-${fileList.length}`,
          name: file.name,
          status: 'done',
          url: URL.createObjectURL(file),
          originFileObj: file,
        },
      ];
      console.log(file);
      setFileList(newFileList);

      if (!newFileList.url || !newFileList.preview) {
        file.preview = await getBase64(newFileList);
      }
      setPreviewImage(file.url || file.preview);
    } catch (error) {
      console.error('Error downloading the image', error);
    }
  };

  useEffect(() => {
    if (isUpdate) {
      setImageUrl(currentModalData.formData?.imageUrl);
    }
  }, [currentModalData.formData?.imageUrl]);



  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  return (
    <Modal
      open={isModalOpen}
      onClose={() => toggleModal(true)}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
          })
          .catch(info => {
            console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
          });
      }}
      onCancel={() => toggleModal(true)}
      title={
        isUpdate ? (
          <IntlMessages id="warehouse.management.form.modal.title.update" />
        ) : (
          <IntlMessages id="warehouse.management.form.modal.title.add" />
        )
      }
      okText={
        isUpdate ? (
          <IntlMessages id="warehouse.management.form.modal.update" />
        ) : (
          <IntlMessages id="warehouse.management.form.modal.add" />
        )
      }
      cancelText={<IntlMessages id="warehouse.management.form.modal.cancelText" />}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        name="warehouseForm"
        className='warehouseActions_addModalWrapper'
        initialValues={{
          companyId: currentModalData.formData.companyId,
          warehouseName: currentModalData.formData.warehouseName,
          warehouseContactPerson: currentModalData.formData.warehouseContactPerson,
          warehouseEmail: currentModalData.formData.warehouseEmail,
          warehousePhone: currentModalData.formData.warehousePhone,
          warehouseWorkPhone: currentModalData.formData.warehouseWorkPhone,
          warehouseAddress1: currentModalData.formData.warehouseAddress1,
          warehouseAddress2: currentModalData.formData.warehouseAddress2,
          warehouseCityId: currentModalData.formData.warehouseCityId,
          warehouseStateId: currentModalData.formData.warehouseStateId,
          warehouseCountryId: currentModalData.formData.warehouseCountryId,
          warehouseZipCode: currentModalData.formData.warehouseZipCode,
          warehouseDescription: currentModalData.formData.warehouseDescription,
          profileImageURL: "",
        }}
      >
        <div className="modal-title-head my-3">
          <h6>
            <span>
              <i data-feather="info" className="feather-info me-2" />
            </span>
            Warehouse Info
          </h6>
        </div>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="profileImageURL"
              label={<IntlMessages id="customer.management.form.label.uploadImage" />}
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    return isError
                      ? Promise.reject(<IntlMessages id="master.operator.form.label.uploadImage.warnings" />)
                      : Promise.resolve();
                  },
                }),
              ]}
            >
              <Upload
                listType="picture-circle"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
              {previewImage && (
                <Image
                  wrapperStyle={{
                    display: 'none',
                  }}
                  preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                  }}
                  src={previewImage}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <div className='row'>

          <div className="col-lg-6">
            <div className="input-blocks">
              <Form.Item
                name="warehouseName"
                className='warehouseActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="warehouse.management.form.warehouse.label.name"
                    popoverkey="warehouse.management.form.label.popover.warehouse.name"
                  />
                }
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="warehouse.management.modal.validation.name" />,
                  },
                ]}
              >
                <Input placeholder={CONSTANT_VARIABLES.WAREHOUSE_NAME} />
              </Form.Item>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="input-blocks">
              <Form.Item
                name="warehouseContactPerson"
                className='warehouseActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="warehouse.management.form.warehouse.label.contact.person"
                    popoverkey="warehouse.management.form.label.popover.warehouse.contact.person"
                  />
                }
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="warehouse.management.modal.validation.contact.person" />,
                  },
                ]}
              >
                <Input placeholder={CONSTANT_VARIABLES.WAREHOUSE_CONTACT_PERSON} />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-blocks">
              <Form.Item
                name="warehousePhone"
                className='warehouseActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="warehouse.management.form.label.phone.number"
                    popoverkey="warehouse.management.form.label.popover.phone.number"
                  />
                }
              >
                <Input placeholder={CONSTANT_VARIABLES.USER_PHONE_NUMBER} />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-blocks">
              <Form.Item
                name="warehouseWorkPhone"
                className='warehouseActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="warehouse.management.form.label.work.phone.number"
                    popoverkey="warehouse.management.form.label.popover.work.phone.number"
                  />
                }
              >
                <Input placeholder={CONSTANT_VARIABLES.WAREHOUSE_WORK_PHONE_NUMBER} />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="input-blocks">
              <Form.Item
                name="warehouseEmail"
                className='warehouseActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="warehouse.management.form.label.email"
                    popoverkey="warehouse.management.form.label.popover.email"
                  />
                }
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="warehouse.management.modal.validation.email" />,
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      return checkEmailValidator(value) ? Promise.resolve() : Promise.reject();
                    }
                  })
                ]}
              >
                <Input placeholder={CONSTANT_VARIABLES.USER_EMAIL} />

              </Form.Item>
            </div>
          </div>
        </div>
        <div className="modal-title-head">
          <h6>
            <span>
              <i data-feather="map-pin" />
            </span>
            Location
          </h6>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="input-blocks">
              <Form.Item
                name="warehouseAddress1"
                className='warehouseActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="warehouse.management.form.label.address1"
                    popoverkey="warehouse.management.form.label.popover.address1"
                  />
                }
              >
                <Input placeholder={CONSTANT_VARIABLES.WAREHOUSE_ADDRESS} />
              </Form.Item>
            </div>
          </div>
          <div className="col-md-12">
            <div className="input-blocks">
              <Form.Item
                name="warehouseAddress2"
                className='warehouseActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="warehouse.management.form.label.address2"
                    popoverkey="warehouse.management.form.label.popover.address2"
                  />
                }
              >
                <Input placeholder={CONSTANT_VARIABLES.WAREHOUSE_ADDRESS} />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-6 col-sm-10 col-10">
            <div className="input-blocks">
              <Form.Item
                name="warehouseCityId"
                className='warehouseActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="warehouse.management.form.label.city"
                    popoverkey="warehouse.management.form.label.popover.city"
                  />
                }
              >
                <Input placeholder={CONSTANT_VARIABLES.CUSTOMER_CITY} />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-6 col-sm-10 col-10">
            <div className="input-blocks">
              <Form.Item
                name="warehouseCountryId"
                className='warehouseActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="warehouse.management.form.label.country"
                    popoverkey="warehouse.management.form.label.popover.country"
                  />
                }
              >
                   <Select
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  classNamePrefix="react-select"
                  options={allcountryList.map(f => ({ label: f.label, value: f.id }))}
                  placeholder={CONSTANT_VARIABLES.CUSTOMER_COUNTRY}
                  onChange={changeHandler}
                   className="input-select"
                />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-6 col-sm-10 col-10">
            <div className="input-blocks">
              <Form.Item
                name="warehouseStateId"
                className='warehouseActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="warehouse.management.form.label.state"
                    popoverkey="warehouse.management.form.label.popover.state"
                  />
                }
              >
                <Input placeholder={CONSTANT_VARIABLES.WAREHOUSE_STATE} />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-6 col-sm-10 col-10">
            <div className="input-blocks">
              <Form.Item
                name="warehouseZipCode"
                className='warehouseActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="warehouse.management.form.label.zipcode"
                    popoverkey="warehouse.management.form.label.popover.zipcode"
                  />
                }
              >
                <Input placeholder={CONSTANT_VARIABLES.WAREHOUSE_ZIPCODE} />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="input-blocks">
              <Form.Item
                name="warehouseDescription"
                className='warehouseActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="warehouse.management.form.label.description"
                    popoverkey="warehouse.management.form.label.popover.description"
                  />
                }
              >
                <TextArea placeholder={CONSTANT_VARIABLES.CUSTOMER_DESCRIPTION} />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>

    </Modal >
  )
}

export default AddWarehouse
