import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../stores/actions';
import notification from "../../../../shared/components/Notification";
import {CONSTANT_VARIABLES} from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';


function* uploadProfileImage ({storeImage}){
    try {
      const { data } = yield call(api.storeUploadImage, {
        route: `/Storage/upload`,
        data: storeImage,
      });
      storeData.imageUrl = data;
      notification('success', CONSTANT_VARIABLES.UPLOAD_PROFILE_IMAGE_SUCCESSFULLY);
    } catch (error) {
      notificationArray(error);
      console.log(error);
    }
  }
  
  function* addUpdateStores({storeData, actionName, StoreParams}){
    try {
      if (storeData.storeImage) {
        const file = storeData.storeImage.originFileObj;
        const formData = new FormData();
        formData.append('file', file); // Use 'file' instead of 'File'
  
        const { data } = yield call(api.UploadImage, {
          route: `/Storage/upload`,
          data: formData,
        });
        const imageUrl = data.toJS();
        delete storeData.storeImage;
  
        if (imageUrl) {
          storeData.profileImageUrl = imageUrl.url;
        }
      }

      if(actionName === 'update') {
        yield call(api.put, {
          route: `/Setup/edit-store`,
          data: storeData,
        });
      } else {
        yield call(api.post, {
          route: `/Setup/create-store`,
          data: storeData,
        });
      }
      
      yield put(Actions.fetchAllStores({ StoreParams }));
      const apiStatus = 200;
      yield put(apiActions.setApiResponse({apiStatus}));
      notification('success', actionName === 'update' ? CONSTANT_VARIABLES.STORE_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.STORE_CREATED_SUCCESSFULLY);
    } catch (error) {
      const apiStatus = error.details.response.status;
      yield put(apiActions.setApiResponse({apiStatus}));
      handleNotification(error);
      console.log(error);
    }
  }
  
  function* fetchAllStores({ storeParams }) {
    try {
      // Show loader before starting the API call
      yield put(Actions.setLoader({ showLoader: true }));
  
      // Make the API call to fetch stores
      const { data } = yield call(api.get, {
        route: `/Setup/all-stores`,
        params: storeParams,
      });
  
      // Store the fetched data in Redux store
      const allStoresData = data;
      yield put(Actions.setStores({ allStoresData }));
      yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
      // Handle notification for the error
      handleNotification(error);
  
      // Log the error for debugging purposes
      console.log(error);
    } finally {
      // Hide loader after data is fetched or if an error occurs
      yield put(Actions.setLoader({ showLoader: false }));
    }
  }
  
  function* deleteStoreById({storeParam}){
    try {
      yield put(Actions.setLoader({ showLoader: true }));
      yield call(api.delete, {
        route: `/Setup/delete-store/` + storeParam.id,
      });
      yield put(Actions.fetchAllStores({storeParam}));
      // yield put(Actions.setLoader({ showLoader: false }));
      notification('success', CONSTANT_VARIABLES.STORE_DELETED_SUCCESSFULLY);
    } catch (error) {
      yield put(Actions.setLoader({ showLoader: false }));
      handleNotification(error);
      console.log(error);
    }
  }

  function handleNotification(message) {
    if (message.details.response.data.errors.length > 0)
    {
      message.details.response.data.errors.map((value) => (
        notification('error',value)
      ));
    }
    else{
      notification('error','SERVE-POS API Issue.')
    }
   }

   export default function* rootSaga() {
    yield all([
      takeLatest(ActionTypes.FETCH_STORES, fetchAllStores),
      takeLatest(ActionTypes.UPLOAD_PROFILE_IMAGE, uploadProfileImage),
        takeLatest(ActionTypes.ADD_UPDATE_STORES, addUpdateStores),
        takeLatest(ActionTypes.DELETE_STORES,deleteStoreById),
    ])
 }