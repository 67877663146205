import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setBrands = (state, { allBrandData }) => {
    if (!allBrandData) return state;
    return state.merge({ allBrandData });
}

const setBrandById = (state, { brandParam }) => {
    return state.merge({ brandParam });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

const setUploadProfileImage = (state, { brandImage }) => {
    if (!brandImage) return state;
    return state.merge({ brandImage });
  };

export const callbacks = [
    {
        actionType: ActionTypes.SET_BRANDS,
        actionFunction: setBrands,
    },
    {
        actionType: ActionTypes.SET_BRAND_BY_ID,
        actionFunction: setBrandById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
    {
        actionType: ActionTypes.SET_UPLOAD_PROFILE_IMAGE,
        actionFunction: setUploadProfileImage,
    },
];
