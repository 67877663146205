import Immutable from 'immutable';
import { ActionTypes } from "./actions";

export const initialState = Immutable.fromJS({});

const setVariants = (state, { allVariantData }) => {
    if (!allVariantData) return state;
    return state.merge({ allVariantData });
}

const setVariantById = (state, { variantParam }) => {
    return state.merge({ variantParam });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_VARIANTS,
        actionFunction: setVariants,
    },
    {
        actionType: ActionTypes.SET_VARIANT_BY_ID,
        actionFunction: setVariantById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
