export const ActionTypes = {
  ADD_UPDATE_SALE_RETURN: 'services/sales/saleReturn/add_update_saleReturn',
  FETCH_SALE_RETURN: 'services/sales/saleReturn/fetch_saleReturns',
  SET_SALES_RETURN: 'services/sales/saleReturn/set_saleReturns',
  DELETE_SALES_RETURN: 'services/sales/saleReturn/delete_saleReturns',
  GET_SALE_RETURN_BY_ID: 'services/sales/saleReturn/get_saleReturn_by_id',
  SET_SALE_RETURN_BY_ID: 'services/sales/saleReturn/set_saleReturn_by_id',
  SET_QUOTATION_NO_BY_ID: 'services/sales/saleReturn/set_quotation_no_by_id',
  GET_QUOTATION_NO_BY_ID: 'services/sales/saleReturn/get_quotation_no_by_id',
  SET_LOADER: 'services/inventory/inventory/set_loader',
}

const setLoader = ({ showLoader }) => ({
  showLoader,
  type: ActionTypes.SET_LOADER,
});

const fetchAllSaleReturns = ({ saleReturnParams }) => ({
  saleReturnParams,
  type: ActionTypes.FETCH_SALE_RETURN,
})

const setSaleReturns = ({ allSaleReturnData }) => ({
  allSaleReturnData,
  type: ActionTypes.SET_SALES_RETURN,
})

const deleteSaleReturns = ({ saleReturnParam }) => ({
  saleReturnParam,
  type: ActionTypes.DELETE_SALES_RETURN,
})

const getQuotationNoById = ({ QnParam }) => ({
  QnParam,
  type: ActionTypes.GET_QUOTATION_NO_BY_ID,
})

const setQuotationNoById = ({ QuotationData }) => ({
  QuotationData,
  type: ActionTypes.SET_QUOTATION_NO_BY_ID,
})

const addUpdateSaleReturn = ({ saleReturnData, actionName, UserParams }) => ({
  saleReturnData,
  actionName,
  UserParams,
  type: ActionTypes.ADD_UPDATE_SALE_RETURN,
})

const getSaleReturnById = ({ saleReturnParam }) => ({
  saleReturnParam,
  type: ActionTypes.GET_SALE_RETURN_BY_ID,
})

const setSaleReturnById = ({ saleReturnParam }) => ({
  saleReturnParam,
  type: ActionTypes.SET_SALE_RETURN_BY_ID,
})

export default {
  addUpdateSaleReturn,
  fetchAllSaleReturns,
  setSaleReturns,
  deleteSaleReturns,
  getSaleReturnById,
  setSaleReturnById,
  setQuotationNoById,
  getQuotationNoById,
  setLoader
};
