export const ActionTypes = {
    ADD_UPDATE_HOLIDAY: 'services/hrm/holiday/add_update_holiday',
    FETCH_HOLIDAY: 'services/hrm/holiday/fetch_holiday',
    SET_HOLIDAY: 'services/hrm/holiday/set_holiday',
    DELETE_HOLIDAY: 'services/hrm/holiday/delete_holiday',
    SET_LOADER: 'services/hrm/holiday/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllHoliday = ({ holidayParams }) => ({
    holidayParams,
    type: ActionTypes.FETCH_HOLIDAY,
})

const setHoliday = ({ allHolidayData }) => ({
    allHolidayData,
    type: ActionTypes.SET_HOLIDAY,
})

const deleteHoliday = ({ holidayParam }) => ({
    holidayParam,
    type: ActionTypes.DELETE_HOLIDAY,
})

const addUpdateHoliday = ({ holidayData, actionName, UserParams }) => ({
    holidayData,
    actionName,
    UserParams,
    type: ActionTypes.ADD_UPDATE_HOLIDAY,
})

export default {
    addUpdateHoliday,
    fetchAllHoliday,
    setHoliday,
    deleteHoliday,
    setLoader
};
