import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setStores = (state, {allStoresData}) => {
    if (!allStoresData) return state;
    return state.merge({allStoresData});
}

const setStoreById = (state, {storeParam}) => {
    return state.merge({storeParam});
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

const setUploadProfileImage = (state, { storeImage }) => {
    if (!storeImage) return state;
    return state.merge({ storeImage });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_STORES,
        actionFunction: setStores,
    },
    {
        actionType: ActionTypes.SET_STORES_BY_ID,
        actionFunction: setStoreById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
    {
        actionType: ActionTypes.SET_UPLOAD_PROFILE_IMAGE,
        actionFunction: setUploadProfileImage,
    },
];