export const ActionTypes = {
    ADD_UPDATE_SUBCATEGORY: 'services/people/subcategories/add_update_subcategory',
    FETCH_SUBCATEGORY: 'services/people/subcategories/fetch_subcategories',
    SET_SUBCATEGORIES: 'services/people/subcategories/set_subcategories',
    DELETE_SUBCATEGORIES: 'services/people/subcategories/delete_subcategories',
    GET_SUBCATEGORY_BY_ID: 'services/people/subcategories/get_subcategory_by_id',
    SET_SUBCATEGORY_BY_ID: 'services/people/subcategories/set_subcategory_by_id',
    SET_LOADER: 'services/people/people/set_loader',
}
  
const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});
  
const fetchAllSubcategories = ({ subcategoryParams }) => ({
    subcategoryParams,
    type: ActionTypes.FETCH_SUBCATEGORY,
})
  
const setSubcategories = ({ allSubcategoryData }) => ({
    allSubcategoryData,
    type: ActionTypes.SET_SUBCATEGORIES,
})
  
const deleteSubcategories = ({ subcategoryParam }) => ({
    subcategoryParam,
    type: ActionTypes.DELETE_SUBCATEGORIES,
})
  
const getSubcategoryById = ({ subcategoryParam }) => ({
    subcategoryParam,
    type: ActionTypes.GET_SUBCATEGORY_BY_ID,
})
  
const setSubcategoryById = ({ subcategoryParam }) => ({
    subcategoryParam,
    type: ActionTypes.SET_SUBCATEGORY_BY_ID,
})
  
const addUpdateSubcategories = ({ subCategoryData, actionName, UserParams }) => ({
    subCategoryData,
    actionName,
    UserParams,
    type: ActionTypes.ADD_UPDATE_SUBCATEGORY,
})
  
export default {
    addUpdateSubcategories,
    fetchAllSubcategories,
    setSubcategories,
    setSubcategoryById,
    deleteSubcategories,
    getSubcategoryById,
    setSubcategoryById,
    setLoader
}
