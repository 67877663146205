import Immutable from 'immutable';
import { ActionTypes } from "./actions";

export const initialState = Immutable.fromJS({});

const setExpCategories = (state, { allExpCategoryData }) => { // Updated function name and parameter
    if (!allExpCategoryData) return state;
    return state.merge({ allExpCategoryData });
}

const setExpCategoryById = (state, { expCategoryParam }) => { // Updated function name and parameter
    return state.merge({ expCategoryParam });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_EXPCATEGORIES, // Updated action type
        actionFunction: setExpCategories, // Updated function name
    },
    {
        actionType: ActionTypes.SET_EXPCATEGORY_BY_ID, // Updated action type
        actionFunction: setExpCategoryById, // Updated function name
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
