export const ActionTypes = {
  ADD_UPDATE_DENOMINATION: 'services/pos/cashclosing/cashdenomination/add_update_denomination',
  FETCH_DENOMINATION: 'services/pos/cashclosing/cashdenomination/fetch_denominations',
  SET_DENOMINATIONS: 'services/pos/cashclosing/cashdenomination/set_denominations',
  DELETE_DENOMINATIONS: 'services/pos/cashclosing/cashdenomination/delete_denominations',
  GET_DENOMINATION_BY_ID: 'services/pos/cashclosing/cashdenomination/get_denomination_by_id',
  SET_DENOMINATION_BY_ID: 'services/pos/cashclosing/cashdenomination/set_denomination_by_id',
  SET_LOADER: 'services/pos/cashclosing/cashdenomination/set_loader',
}

const setLoader = ({ showLoader }) => ({
  showLoader,
  type: ActionTypes.SET_LOADER,
});

const fetchAllDenominations = ({ denominationParams }) => ({
  denominationParams,
  type: ActionTypes.FETCH_DENOMINATION,
})

const setDenominations = ({ allDenominationData }) => ({
  allDenominationData,
  type: ActionTypes.SET_DENOMINATIONS,
})

const deleteDenominations = ({ denominationParam }) => ({
  denominationParam,
  type: ActionTypes.DELETE_DENOMINATIONS,
})

const getDenominationById = ({ denominationParam }) => ({
  denominationParam,
  type: ActionTypes.GET_DENOMINATION_BY_ID,
})

const setDenominationById = ({ denominationParam }) => ({
  denominationParam,
  type: ActionTypes.SET_DENOMINATION_BY_ID,
})

const addUpdateDenominations = ({ denominationData, actionName, UserParams }) => ({
  denominationData,
  actionName,
  UserParams,
  type: ActionTypes.ADD_UPDATE_DENOMINATION,
})

export default {
  addUpdateDenominations,
  fetchAllDenominations,
  setDenominations,
  setDenominationById,
  deleteDenominations,
  getDenominationById,
  setDenominationById,
  setLoader
}