/* eslint-disable react/prop-types */
import React from 'react';
import {image_path} from '../../environment';
 
const ImageWithBasePath = (props) => {
  const altText = String(props?.alt);
  let fullSrc ='';
  if(props.isProfileImage)
  {
     fullSrc = String(`${props?.src}`);
  }else{
     fullSrc = String(`${image_path}${props?.src}`);
  }
  
  // Combine the base path and the provided src to create the full image source URL
  return (
    <div>
    <img alt={altText} src={fullSrc} />
    </div>
  );
};

export default ImageWithBasePath;
