import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../designation/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdateDesignations({ designationData, actionName, designationParams }) {
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/HRM/edit-designation`,
        data: designationData,
      });
    } else {
      yield call(api.post, {
        route: '/HRM/create-designation',
        data: designationData,
      });
    }
    
    yield put(Actions.fetchAllDesignations({ designationParams }));
    notification('success', actionName === 'update' ? CONSTANT_VARIABLES.DESIGNATION_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.DESIGNATION_CREATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllDesignations({ designationParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch designations
    const { data } = yield call(api.get, {
      route: '/HRM/all-designations',
      params: designationParams,
    });

    // Store the fetched data in Redux store
    const allDesignationData = data;
    yield put(Actions.setDesignations({ allDesignationData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deleteDesignationById({ designationParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/HRM/delete-designation/` + designationParam.id,
    });
    yield put(Actions.fetchAllDesignations({designationParam}));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.DESIGNATION_DELETED_SUCCESSFULLY);
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_DESIGNATION, fetchAllDesignations),
    takeLatest(ActionTypes.ADD_UPDATE_DESIGNATION, addUpdateDesignations),
    takeLatest(ActionTypes.DELETE_DESIGNATIONS, deleteDesignationById)
  ]);
}
