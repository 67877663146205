import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, Form, Input, Switch, Select, Tabs } from 'antd';
import { CONSTANT_VARIABLES } from '../../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import CustomFormLabel from '../../../common/CustomFormLabel';
import { PlusCircle } from "react-feather";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Flex, message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button } from 'react-bootstrap';
import commonActions from '../../../../core/Services/common/actions'
import * as commonSelectors from '../../../commonSelectors'

const EmployeeInformationTab = (props) => {
    const { form, currentModalData, handleCustomFieldsData, isUpdate, optionsData } = props;
    const { genders, nationalities, shifts, departments, designations, bloodGroups } = optionsData;
    const dispatch = useDispatch();

    const [dob, setDob] = useState(null)
    const [joiningDate, setJoiningDate] = useState(null)
    const [imageUrl, setImageUrl] = useState();
    const [uploading, setUploading] = useState(false);

    const profileImageData = useSelector(state => commonSelectors.setUploadProfileImage(state));
    const profileImage = profileImageData ? profileImageData.toJS() : [];

    const handleDobChange = (date) => {
        setDob(date);
        form.setFieldsValue({ dob: date ? moment(date).format('YYYY-MM-DD') : null });
    };

    const handleJoiningDateChange = (date) => {
        setJoiningDate(date);
        form.setFieldsValue({ joiningDate: date ? moment(date).format('YYYY-MM-DD') : null });
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            {uploading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }

        const isLt100KB = file.size / 1024 < 100;
        if (!isLt100KB) {
            message.error('Image must be smaller than 100KB!');
        }

        return isJpgOrPng && isLt100KB;
    };

    const handleChange = ({ file }) => {
        if (file.status === 'uploading') {
            setUploading(true);
            return;
        }
        else {
            UploadProfileImage(file)
            // setImageUrl(profileImage);
        }
    };

    const UploadProfileImage = (file) => {
        dispatch(commonActions.uploadProfileImage({ userImage: file }))
        setUploading(false);
    }

    useEffect(() => {
        if (Array.isArray(profileImage) ? profileImage.length > 0 : profileImage) {
            setImageUrl(profileImage.url);
            form.setFieldsValue({ profileUrl: profileImage.url });
        }
    }, [profileImage]);

    useEffect(() => {
        setImageUrl(currentModalData.formData?.profileUrl)
        form.setFieldsValue({ profileUrl: currentModalData.formData?.profileUrl });
    }, [isUpdate])

    useEffect(() => {
        if (currentModalData && currentModalData.formData && currentModalData.formData.id) {
            dispatch(commonActions.getAllStatesByCountryId({ stateParams: { id: currentModalData.formData?.countryId } }))
            dispatch(commonActions.getAllCitiesByStateId({ cityParams: { id: currentModalData.formData?.stateId } }))
        }
    }, [currentModalData])

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    firstName: currentModalData.formData?.firstName,
                    lastName: currentModalData.formData?.lastName,
                    email: currentModalData.formData?.email,
                    contactNumber: currentModalData.formData?.contactNumber,
                    empcode: currentModalData.formData?.id,
                    dob: currentModalData.formData?.dob ? moment(currentModalData.formData.dob).format('YYYY-MM-DD') : null,
                    genderId: currentModalData.formData?.genderId,
                    nationalityId: currentModalData.formData?.nationalityId,
                    joiningDate: currentModalData.formData?.joiningDate ? moment(currentModalData.formData.joiningDate).format('YYYY-MM-DD') : null,
                    shiftId: currentModalData.formData?.shiftId,
                    departmentId: currentModalData.formData?.departmentId,
                    designationId: currentModalData.formData?.designationId,
                    bloodGroupId: currentModalData.formData?.bloodGroupId,
                }}
                name="forForm" className='productActions_addModalWrapper'>
                <div className="row" style={{ marginBottom: '20px' }}>
                    <div className="col-lg-6">
                        <div className="input-blocks">
                            <Form.Item
                                name="profileUrl"
                                className="actions_addModalFormItem"
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.profile.image" />,
                                    },
                                ]}
                            >
                                <div className='' style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '20px' }}>
                                    <Flex gap="middle" wrap>
                                        <Upload
                                            name="avatar"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            beforeUpload={beforeUpload}
                                            onChange={handleChange}
                                        >
                                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%', height: '100%' }} /> : uploadButton}
                                        </Upload>
                                    </Flex>
                                    <Upload
                                        showUploadList={false}
                                        beforeUpload={beforeUpload}
                                        onChange={handleChange}

                                    >
                                        <Button type="primary" icon={<UploadOutlined />}>
                                            Upload Profile Image
                                        </Button>
                                    </Upload>
                                </div>
                            </Form.Item>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="firstName"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.first.name"
                                        popoverkey="hrm.employee.form.label.popover.first.name"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.first.name" />,
                                    },
                                ]}
                            >
                                <input type="text" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="lastName"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.last.name"
                                        popoverkey="hrm.employee.form.label.popover.last.name"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.last.name" />,
                                    },
                                ]}
                            >
                                <input type="text" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="email"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.email"
                                        popoverkey="hrm.employee.form.label.popover.email"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.email" />,
                                    },
                                ]}
                            >
                                <input type="text" />
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="contactNumber"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.contact.number"
                                        popoverkey="hrm.employee.form.label.popover.contact.number"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.contact.number" />,
                                    },
                                    {
                                        pattern: /^\d+$/,
                                        message: "Contact No. must be a number",
                                    },
                                ]}
                            >
                                <input type="text" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="empcode"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.emp.code"
                                        popoverkey="hrm.employee.form.label.popover.emp.code"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.emp.code" />,
                                    },
                                    {
                                        pattern: /^\d+$/,
                                        message: "Employee Code must be a number",
                                    },
                                ]}
                            >
                                <input type="text" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="dob"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.dob"
                                        popoverkey="hrm.employee.form.label.popover.dob"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.dob" />,
                                    },
                                ]}
                            >
                                <DatePicker
                                    selected={dob}
                                    onChange={handleDobChange}
                                    dateFormat="YYYY-MM-DD"
                                    placeholderText="Select Date"
                                    className="actions_addModalFormItem"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="genderId"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.gender"
                                        popoverkey="hrm.employee.form.label.popover.gender"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.gender" />,
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={genders.map(s => ({ label: s.name, value: s.id }))}
                                    placeholder="Choose"
                                    className="input-select"
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="nationalityId"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.nationality"
                                        popoverkey="hrm.employee.form.label.popover.nationality"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.nationality" />,
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={nationalities.map(s => ({ label: s.name, value: s.id }))}
                                    placeholder="Choose"
                                    className="input-select"
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="joiningDate"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.joining.date"
                                        popoverkey="hrm.employee.form.label.popover.joining.date"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.joining.date" />,
                                    },
                                ]}
                            >
                                <DatePicker
                                    selected={joiningDate}
                                    onChange={handleJoiningDateChange}
                                    dateFormat="YYYY-MM-DD"
                                    placeholderText="Select Date"
                                    className="actions_addModalFormItem"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="shiftId"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.shift"
                                        popoverkey="hrm.employee.form.label.popover.shift"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.shift" />,
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={shifts.map(s => ({ label: s.name, value: s.id }))}
                                    placeholder="Choose"
                                    className="input-select"
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="departmentId"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.department"
                                        popoverkey="hrm.employee.form.label.popover.department"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.department" />,
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={departments.map(s => ({ label: s.name, value: s.id }))}
                                    placeholder="Choose"
                                    className="input-select"
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="designationId"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.designation"
                                        popoverkey="hrm.employee.form.label.popover.designation"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.designation" />,
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={designations.map(s => ({ label: s.name, value: s.id }))}
                                    placeholder="Choose"
                                    className="input-select"
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="bloodGroupId"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.blood.group"
                                        popoverkey="hrm.employee.form.label.popover.blood.group"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.blood.group" />,
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={bloodGroups.map(s => ({ label: s.name, value: s.id }))}
                                    placeholder="Choose"
                                    className="input-select"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default EmployeeInformationTab;
