export const ActionTypes = {
  SET_API_RESPONSE: 'services/userManagement/set_api_response',
  FETCH_API_RESPONSE: 'services/userManagement/fetch_api_response',
}
const setApiResponse = ({ apiStatus }) => ({
  apiStatus,
  type: ActionTypes.SET_API_RESPONSE,
});

const fetchResponse = ({ fetchStatus }) => ({
  fetchStatus,
  type: ActionTypes.FETCH_API_RESPONSE,
});

export default {
  setApiResponse,
  fetchResponse
}