export const denominationLoader = state => {
    return state.services.denominations.getIn(['showLoader']);
 };
 export const setDenomination = state => {
    return state.services.denominations.getIn(['allDenominationData']);
 };
 export const setDenominationById = state => {
    return state.services.denominations.getIn(['denominationData']);
 };
export const todayExpensesLoader = state => {
    return state.services.todayexpenses.getIn(['showLoader']);
 };
 export const setTodayExpenses = state => {
    return state.services.todayexpenses.getIn(['allTodayExpensesData']);
 };
 export const setTodayExpensesById = state => {
    return state.services.todayexpenses.getIn(['todayExpensesData']);
 };
