import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../brands/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* uploadProfileImage ({brandImage}){
  try {
    debugger
    console.log(brandImage)
        const{data} = yield call(api.post,{
          route: `/Storage/upload`,
          data: brandImage,
        });
        userData.imageUrl= data;
        notification('success', CONSTANT_VARIABLES.UPLOAD_PROFILE_IMAGE_SUCCESSFULLY);
  } catch (error) {
    notificationArray(error);
    console.log(error);
  }
 }

function* addUpdateBrands({ brandData, actionName, brandParams }) {
  try {
    debugger;
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/Inventory/edit-brand`,
        data: brandData,
      });
    } else {
      yield call(api.post, {
        route: '/Inventory/create-brand',
        data: brandData,
      });
    }
    
    yield put(Actions.fetchAllBrands({ brandParams }));
    notification('success', actionName === 'update' ? CONSTANT_VARIABLES.BRAND_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.BRAND_CREATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllBrands({ brandParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch brands
    const { data } = yield call(api.get, {
      route: '/Inventory/all-brands',
      params: brandParams,
    });

    // Store the fetched data in Redux store
    const allBrandData = data;
    yield put(Actions.setBrands({ allBrandData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deleteBrandById({ brandParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/Inventory/delete-brand/${brandParam.id}`,
    });
    yield put(Actions.fetchAllBrands({brandParam}));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.BRAND_DELETED_SUCCESSFULLY);
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_BRAND, fetchAllBrands),
     takeLatest(ActionTypes.UPLOAD_PROFILE_IMAGE, uploadProfileImage),
    takeLatest(ActionTypes.ADD_UPDATE_BRAND, addUpdateBrands),
    takeLatest(ActionTypes.DELETE_BRANDS, deleteBrandById)
  ]);
}
