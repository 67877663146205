import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../../sales/salesOrder/actions'; // Updated import path
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdateSaleOrder({ saleOrderData, actionName, saleOrderParams }) { // Updated parameters
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: '/sales/edit-saleorder', // Updated route
        data: saleOrderData,
      });
    } else {
      yield call(api.post, {
        route: '/sales/create-saleorder', // Updated route
        data: saleOrderData,
      });
    }
    
    yield put(Actions.fetchAllSaleOrders({ saleOrderParams })); // Updated action
    notification('success', actionName === 'update' ? CONSTANT_VARIABLES.SALE_ORDER_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.SALE_ORDER_CREATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllSaleOrders({ saleOrderParams }) { // Updated parameters and action
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch sale orders
    const { data } = yield call(api.get, {
      route: '/sales/all-saleorders', // Updated route
      params: saleOrderParams,
    });

    // Store the fetched data in Redux store
    const allSaleOrderData = data; // Updated variable name
    yield put(Actions.setSaleOrders({ allSaleOrderData })); // Updated action
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deleteSaleOrderById({ saleOrderParam }) { // Updated parameters and action
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/sales/delete-saleorder/` + saleOrderParam.id, // Updated route
    });
    yield put(Actions.fetchAllSaleOrders({ saleOrderParam })); // Updated action
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.SALE_ORDER_DELETED_SUCCESSFULLY);
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function* GetSaleOrderById({ saleOrderParamId }) { // Updated parameters and action
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    const { data } = yield call(api.get, {
      route: `/sales/all-sale-order-by-id?Id=` + saleOrderParamId, // Updated route
    });
    const saleOrderData = data; // Updated variable name
    yield put(Actions.setSaleOrderById({ saleOrderData })); // Updated action
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function* GetSaleQuotationByQNById({ saleOrderParamId }) {
  try {
    debugger
    yield put(Actions.setLoader({ showLoader: true }));
    const { data } = yield call(api.get, {
      route: `/sales/all-salequotationByQN?Id=` + saleOrderParamId, // Updated route
    });
    const allsaleQuotationIdData = data;
    yield put(Actions.SetSaleQuotationByQNById({ allsaleQuotationIdData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_SALE_ORDERS, fetchAllSaleOrders), // Updated action type
    takeLatest(ActionTypes.ADD_UPDATE_SALE_ORDER, addUpdateSaleOrder), // Updated action type
    takeLatest(ActionTypes.DELETE_SALE_ORDERS, deleteSaleOrderById), // Updated action type
    takeLatest(ActionTypes.GET_SALE_ORDER_BY_ID, GetSaleOrderById), // Updated action type
    takeLatest(ActionTypes.GET_QUOTATION_NO_BY_ID, GetSaleQuotationByQNById)
  ]);
}
