import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDebounce } from 'use-debounce';
import Table from "../../../../core/pagination/datatable";
import AddDenominationModal from "./adddenomination";
import IntlMessages from "../../../../shared/components/utility/intlMessages";
import Swal from "sweetalert2";
import {
  ChevronUp,
  Filter,
  PlusCircle,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageWithBasePath from "../../../../core/img/imagewithbasebath";
import { setToogleHeader } from "../../../../core/redux/action";
import denominationAction from "../../../../core/Services/pos/cashclosing/cashdenomination/actions.js"
import apiResponseAction from '../../../../core/Services/apiResponse/actions';
import * as posSelectors from '../../posSelectors.js';
import * as ApiResponseSelectors from '../../../selectors';
import { CONSTANT_VARIABLES } from "../../../../core/Common/Constant_Variables/constants";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const CashDenomination = () => {
  const dispatch = useDispatch();
  const [denominationParams, setDenominationParams] = useState({
    pageNo: 1,
    searchText: '',
  });
  const [isDenominationModalOpen, setDenominationModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [debouncedText] = useDebounce(denominationParams, 1000);

  const isLoading = useSelector(state => posSelectors.denominationLoader(state));
  const DenominationData = useSelector(state => posSelectors.setDenomination(state));
  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
  const dataSource = DenominationData ? DenominationData.toJS() : [];
  const data = useSelector((state) => state.toggle_header);

  useEffect(() => {
    if (apiResponseStatus == 200) {
      toggleDenominationModal();
    }
  }, [apiResponseStatus])

  useEffect(() => {
    dispatch(denominationAction.fetchAllDenominations({ denominationParams: debouncedText }));
  }, [dispatch]);

  const toggleDenominationModal = (value) => {
    setDenominationModalOpen();
  }

  const [selectedDate, setSelectedDate] = useState(new Date());

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const handleDataIntoExcel = (filename = 'Denominations.xlsx') => {
    if (dataSource && dataSource.length > 0) {

      const worksheet = XLSX.utils.json_to_sheet(dataSource);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = [['denominationId', 'denominationName', 'denominationSlug', 'isActive']];
    const tableData = dataSource.map((item, index) => [item.id, item.denominationName, item.denominationSlug, item.isActive]);

    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    // Save the PDF using file-saver
    doc.save('Denominations.pdf');
  };

  const handleRefresh = () => {
    dispatch(denominationAction.fetchAllDenominations({ denominationParams }));
  }

  const columns = [
    {
      title:"",
      dataIndex: "denominationName",
      sorter: (a, b) => a.denominationName.length - b.denominationName.length,
    },
    {
      title:"",
      dataIndex: "denominationSlug",
      sorter: (a, b) => a.denominationSlug.length - b.denominationSlug.length,
    },
    {
      title:"",
      dataIndex: "isActive",
      render: (text, row) => {
        return (
          <div>
            <span className={`${text ? "badge badge-linesuccess" : "badge badge-linedanger"}`}>{text ? 'Active' : 'Inactive'}</span>
          </div>
        );
      },
      sorter: (a, b) => a.status.length - b.status.length,
    },

    {
      title:"",
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="me-2 p-2"
              onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
              //data-bs-toggle="modal"
              data-bs-target="#edit-denominations"
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link className="confirm-text p-2">
              <i
                data-feather="trash-2"
                className="feather-trash-2"
                onClick={() => showConfirmationAlert(row)}
              ></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const handleModal = (denominationData, actionName) => {

    const denominationModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? denominationData : {},
    };
    const status = 0;
    dispatch(apiResponseAction.setApiResponse({ apiStatus: status }))
    setCurrentModalData(denominationModalObj);
    setDenominationModalOpen(true);
  };

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (row) => {

    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(denominationAction.deleteDenominations({ denominationParam: row }));
      } else {
        MySwal.close();
      }
    });
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4><IntlMessages id="pos.denominations.table.heading.posdenominations" /></h4>
                <h6><IntlMessages id="pos.denominations.table.label.manageposdenominations" /></h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link
                    onClick={() => handleGeneratePDF()}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={handleRefresh}>
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <a
                onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                className="btn btn-added"
              >
                <PlusCircle className="me-2" />
                <IntlMessages id="pos.denominations.table.button.addnewdenomination" />
              </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
                <div className="search-path">
                  <Link
                    className={`btn btn-filter ${isFilterVisible ? "setclose" : ""
                      }`}
                    id="filter_search"
                  >
                    <Filter
                      className="filter-icon"
                      onClick={toggleFilterVisibility}
                    />
                    <span onClick={toggleFilterVisibility}>
                      <ImageWithBasePath
                        src="assets/img/icons/closes.svg"
                        alt="img"
                      />
                    </span>
                  </Link>
                </div>
              </div>
              {/* /Filter */}

              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                    <div className="input-blocks">
                      <button className="btn btn-filters ms-auto">
                        <i data-feather="search" className="feather-search" />{" "}
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {isDenominationModalOpen ? (
              <AddDenominationModal
                currentModalData={currentModalData}
                isModalOpen={isDenominationModalOpen}
                isUpdate={currentModalData.formData.id ? true : false}
                toggleModal={toggleDenominationModal}
              />
            ) : null}
            {/* /Filter */}
            <div className="table-responsive">
              <Table isLoading={isLoading} columns={columns} dataSource={dataSource} />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
    </>
  );
};

export default CashDenomination;