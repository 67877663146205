import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setSaleReceives = (state, { allSaleReceiveData }) => {
    if (!allSaleReceiveData) return state;
    return state.merge({ allSaleReceiveData });
}

const setSaleReceiveById = (state, { saleReceiveParam }) => {
    return state.merge({ saleReceiveParam });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

const setQuotationNoById = (state, { QuotationData }) => {
    return state.merge({ QuotationData });
}

export const callbacks = [
    {
        actionType: ActionTypes.SET_SALES_RECEIVE,
        actionFunction: setSaleReceives,
    },
    {
        actionType: ActionTypes.SET_SALE_RECEIVE_BY_ID,
        actionFunction: setSaleReceiveById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
    {
        actionType: ActionTypes.SET_QUOTATION_NO_BY_ID,
        actionFunction: setQuotationNoById,
    },
];
