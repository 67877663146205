export const ActionTypes = {
  ADD_UPDATE_CUSTOMER: 'services/people/add_update_customer',
  FETCH_CUSTOMER: 'services/people/fetch_customers',
  SET_CUSTOMERS: 'services/people/set_customers',
  DELETE_CUSTOMERS: 'services/people/delete_customers',
  GET_CUSTOMER_BY_ID: 'services/people/get_customer_by_id',
  SET_CUSTOMER_BY_ID: 'services/people/set_customer_by_id',
  SET_LOADER: 'services/people/set_loader',
  UPLOAD_PROFILE_IMAGE: 'services/people/upload_profile_image',
  SET_UPLOAD_PROFILE_IMAGE: 'services/people/set_upload_profile_image',
}
const setLoader = ({ showLoader }) => ({
  showLoader,
  type: ActionTypes.SET_LOADER,
});

const fetchAllCustomers = ({ customerParams }) => ({
  customerParams,
  type: ActionTypes.FETCH_CUSTOMER,
})

const setCustomers = ({ allCustomerData }) => ({
  allCustomerData,
  type: ActionTypes.SET_CUSTOMERS,
})

const deleteCustomers = ({ customerParam }) => ({
  customerParam,
  type: ActionTypes.DELETE_CUSTOMERS,
})

const getCustomerById = ({ customerParam }) => ({
  customerParam,
  type: ActionTypes.GET_CUSTOMER_BY_ID,
})

const setCustomerById = ({ customerParam }) => ({
  customerParam,
  type: ActionTypes.SET_CUSTOMER_BY_ID,
})

const addUpdateCustomers = ({ customerData, actionName, UserParams }) => ({
  customerData,
  actionName,
  UserParams,
  type: ActionTypes.ADD_UPDATE_CUSTOMER,
})


const uploadProfileImage = ({ customerImage }) => ({
  customerImage,
  type: ActionTypes.UPLOAD_PROFILE_IMAGE
});

const setUploadProfileImage = ({ customerImage }) => ({
  customerImage,
  type: ActionTypes.SET_UPLOAD_PROFILE_IMAGE
});
export default {
  addUpdateCustomers,
  fetchAllCustomers,
  setCustomers,
  setCustomerById,
  deleteCustomers,
  getCustomerById,
  setCustomerById,
  setLoader,
  uploadProfileImage,
  setUploadProfileImage
}
