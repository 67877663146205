import React from 'react'
import { CONSTANT_VARIABLES } from '../../../core/Common/Constant_Variables/constants';
import { Modal, Form, Input, Switch} from 'antd';
import IntlMessages from '../../../shared/components/utility/intlMessages';
import CustomFormLabel from '../../common/CustomFormLabel';
import { useDispatch, useSelector } from 'react-redux';
import sellingTypeAction from "../../../core/Services/inventory/sellingtype/actions"
import * as ApiResponseSelectors from '../../selectors';

const AddSellingModal = (props) => {
  const {currentModalData, isModalOpen, isUpdate, toggleModal, sellingTypeParams } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
 
  const handleRecord = (actionName, sellingTypeData) => {
    if (actionName === CONSTANT_VARIABLES.UPDATE) {
        sellingTypeData.id = currentModalData.formData.id;
        sellingTypeData.companyId = currentModalData.formData.companyId;

    }
    else {
        sellingTypeData.companyId = 1; 
      }
    dispatch(sellingTypeAction.addUpdateSellingType({ sellingTypeData, actionName, sellingTypeParams: sellingTypeParams }));
    toggleModal();
};

  return (


      <Modal
            open={isModalOpen}
            onCancel={() => toggleModal(true)}
            onOk={() => {
              form.validateFields()
                  .then(values => {
                      handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                  })
                  .catch(info => {
                      console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                  });
          }}
            title={isUpdate ? <IntlMessages id="sellingType.inventory.form.modal.title.update" /> : <IntlMessages id="sellingType.inventory.form.modal.title.add" />}
            okText={isUpdate ? <IntlMessages id="unit.inventory.form.modal.update" /> : <IntlMessages id="unit.inventory.form.modal.add" />}
            cancelText={<IntlMessages id="unit.inventory.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name="categoryForm"
                className='categoryActions_addModalWrapper'
                initialValues={{
                    companyId: currentModalData.formData ? currentModalData.formData.companyId : 0,
                    value: currentModalData.formData?.type,
                    
                }}
            >
                <div className='row'>
                    <div className="col-lg-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="value"
                                className='actions_addModalFormItem'
                                label={<CustomFormLabel labelkey="unit.inventory.form.unit.label.name" popoverkey="unit.inventory.form.label.popover.unit.name" />}
                                rules={[{ required: true, message: <IntlMessages id="unit.inventory.modal.validation.name" /> }]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                    </div>
                    
                </div>
            </Form>
        </Modal>

  )
}

export default AddSellingModal