import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../payroll/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdatePayrolls({ payrollData, actionName, payrollParams }) {
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/HRM/edit-payroll`,
        data: payrollData,
      });
    } else {
      yield call(api.post, {
        route: '/HRM/create-payroll',
        data: payrollData,
      });
    }
    
    yield put(Actions.fetchAllPayrolls({ payrollParams }));
    notification('success', actionName === 'update' ? "Payroll Updated Successfully" : "Payroll Created Successfully");
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllPayslip({ employeeId }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch payrolls
    const { data } = yield call(api.get, {
      route: '/HRM/get-payslip?EmployeeId=' + employeeId,
    });

    // Store the fetched data in Redux store
    const allpayslipData = data;
    yield put(Actions.setPayslip({ allpayslipData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* fetchAllPayrolls({ payrollParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch payrolls
    const { data } = yield call(api.get, {
      route: '/HRM/all-payrolls',
      params: payrollParams,
    });

    // Store the fetched data in Redux store
    const allPayrollData = data;
    yield put(Actions.setPayrolls({ allPayrollData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deletePayrollById({ payrollParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/HRM/delete-payroll/` + payrollParam.id,
    });
    yield put(Actions.fetchAllPayrolls({ payrollParam }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', "Payroll Deleted Successfully");
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_PAYROLL, fetchAllPayrolls),
    takeLatest(ActionTypes.FETCH_PAYSLIP, fetchAllPayslip),
    takeLatest(ActionTypes.ADD_UPDATE_PAYROLL, addUpdatePayrolls),
    takeLatest(ActionTypes.DELETE_PAYROLLS, deletePayrollById)
  ]);
}
