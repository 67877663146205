import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import { base_path } from "./environment.js";
import "../src/style/css/feather.css";
import "../src/style/css/line-awesome.min.css";
import "../src/style/scss/main.scss";
import "../src/style/icons/fontawesome/css/fontawesome.min.css";
import "../src/style/icons/fontawesome/css/all.min.css";
import { createRoot } from "react-dom/client";
import { IntlProvider } from 'react-intl';
import { Provider } from "react-redux";
import store from "./core/redux/store.js";
import AllRoutes from "./Router/router.js";
import enMessages from "../src/Translations/en-US.json"
import arMessages from "../src/Translations/ar-AR.json";


const App = () =>{
  const [locale, setLocale] = useState('en');
  const [messages, setMessages] = useState(enMessages);
  const changeLanguage = (newLocale) => {
    setLocale(newLocale);
    switch (newLocale) {
        case 'ar':
            setMessages(arMessages);
            break;
        case 'en':
        default:
            setMessages(enMessages);
            break;
    }
};
const updateSidebarPosition = (isRtl) => {
  const sidebar = document.querySelector('.sidebar');
  if (sidebar) {
    if (isRtl) {
      sidebar.style.right = '0';
      sidebar.style.left = 'auto';
    } else {
      sidebar.style.left = '0';
      sidebar.style.right = 'auto';
    }
  }
};
 // Effect to add/remove RTL class based on locale
 useEffect(() => {
  if (locale === "ar") {
    document.body.classList.add("rtl");
    updateSidebarPosition(true);
  } else {
    document.body.classList.remove("ltr");
    updateSidebarPosition(false);
  }
}, [locale]);

    const rootElement = document.getElementById("root");
    //const locale = 'en';
    
    if (rootElement) {
      const root = createRoot(rootElement);
      root.render(
        <IntlProvider locale={locale} messages={messages}>
             <React.StrictMode>
              <Provider store={store}>
                <BrowserRouter basename={base_path}>
                  <AllRoutes changeLanguage={changeLanguage} />
                </BrowserRouter>
              </Provider>
        </React.StrictMode>
        </IntlProvider>
      );
    } else {
      console.error("Element with id 'root' not found.");
    }
}

export default App;
