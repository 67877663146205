import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Switch, Select, Tabs, Button } from 'antd';
const { TabPane } = Tabs;
import { CONSTANT_VARIABLES } from '../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../shared/components/utility/intlMessages';
import { Info, Lock, DollarSign, MinusCircle, Briefcase, BarChart } from "feather-icons-react/build/IconComponents";
import SalaryInformationTab from './components/SalaryInformationTab';
import AllowanceInformationTab from './components/AllowancesTab';
import DeductionInformationTab from './components/DeductionsTab';
import SalarySummaryInformationTab from './components/SalarySummaryTab';
import payrollActions from '../../../core/Services/hrm/payroll/actions'


const AddUpdatePayrollModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, payrollParams, employeeOptions } = props;

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState("1");

    const handleRecord = (actionName, payrollData) => {
        debugger
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            payrollData.id = currentModalData?.formData?.id
            payrollData.companyId = 1
            payrollData.isPaid = payrollData.isPaid == 1 ? true : false;
        }
        else {
            payrollData.companyId = 1
            payrollData.isPaid = payrollData.isPaid == 1 ? true : false;
        }

        dispatch(payrollActions.addUpdatePayrolls({ payrollData, actionName, payrollParams: payrollParams }));
        toggleModal();
    };

    const handleNext = () => {
        form.validateFields()
            .then(() => {
                if (activeTab === "1") {
                    setActiveTab("2");
                } else if (activeTab === "2") {
                    setActiveTab("3");
                } else if (activeTab === "3") {
                    setActiveTab("4");
                }
            })
            .catch((info) => {
                console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
            });
    };

    const handleTabChange = (nextTabKey) => {
        form.validateFields()
            .then(() => {
                setActiveTab(nextTabKey);
            })
            .catch((info) => {
                console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
            });
    };

    return (
        <Modal
            open={isModalOpen}
            width={700}
            zIndex={999999}
            onCancel={() => toggleModal(true)}
            footer={[
                activeTab !== "4" ? (
                    <Button key="next" type="primary" onClick={handleNext} style={{ marginRight: '5px' }}>
                        <IntlMessages id="product.inventory.form.modal.next" />
                    </Button>
                ) : (
                    <Button
                        key="submit"
                        type="primary"
                        style={{ marginRight: '5px' }}
                        onClick={() => {
                            form.validateFields()
                                .then(values => {
                                    handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                                })
                                .catch(info => {
                                    console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                                });
                        }}
                    >
                        {isUpdate ? <IntlMessages id="product.inventory.form.modal.update" /> : <IntlMessages id="product.inventory.form.modal.add" />}
                    </Button>
                ),
                <Button color='orange' key="cancel" onClick={() => toggleModal(true)}>
                    <IntlMessages id="product.inventory.form.modal.cancelText" />
                </Button>,
            ]}
            title={isUpdate ? "Update Payroll" : "Add New Payroll"}
            okText={isUpdate ? <IntlMessages id="product.inventory.form.modal.update" /> : <IntlMessages id="product.inventory.form.modal.add" />}
            cancelText={<IntlMessages id="product.inventory.form.modal.cancelText" />}
            maskClosable={false}
        >
            <div className="card-body add-product pb-0">
                <Tabs activeKey={activeTab} onChange={handleTabChange}>
                    <TabPane tab={<span><Info className="add-info" /> Salary Information</span>} key="1">
                        <SalaryInformationTab form={form} currentModalData={currentModalData} isUpdate={isUpdate} employeeOptions={employeeOptions} />
                    </TabPane>
                    <TabPane tab={<span><DollarSign className="add-info" /> Allowances</span>} key="2">
                        <AllowanceInformationTab form={form} currentModalData={currentModalData} isUpdate={isUpdate} />
                    </TabPane>
                    <TabPane tab={<span><MinusCircle className="add-info" /> Deductions</span>} key="3">
                        <DeductionInformationTab form={form} currentModalData={currentModalData} />
                    </TabPane>
                    <TabPane tab={<span><BarChart className="add-info" /> Salary Summary</span>} key="4">
                        <SalarySummaryInformationTab form={form} currentModalData={currentModalData} />
                    </TabPane>
                </Tabs>
            </div>
        </Modal>
    );
};

export default AddUpdatePayrollModal;