import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setSubcategories = (state, { allSubcategoryData }) => {
    if (!allSubcategoryData) return state;
    return state.merge({ allSubcategoryData });
}

const setSubcategoryById = (state, { subcategoryParam }) => {
    return state.merge({ subcategoryParam });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_SUBCATEGORIES,
        actionFunction: setSubcategories,
    },
    {
        actionType: ActionTypes.SET_SUBCATEGORY_BY_ID,
        actionFunction: setSubcategoryById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
