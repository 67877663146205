import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../../sales/saleReturn/actions'; // Updated import path
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdateSaleReturn({ saleReturnData, actionName, saleReturnParams }) { // Updated parameters
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/sales/edit-sale`,
        data: saleReturnData,
      });
    } else {
      yield call(api.post, {
        route: '/sales/create-sale',
        data: saleReturnData,
      });
    }
    
    yield put(Actions.fetchAllSaleReturns({ saleReturnParams })); // Updated action
    notification('success', actionName === 'update' ? CONSTANT_VARIABLES.SALE_RETURN_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.SALE_RETURN_CREATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllSaleReturns({ saleReturnParams }) { // Updated parameters and action
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch sale returns
    const { data } = yield call(api.get, {
      route: '/sales/all-sales', // Updated route
      params: saleReturnParams,
    });

    // Store the fetched data in Redux store
    const allSaleReturnData = data; // Updated variable name
    yield put(Actions.setSaleReturns({ allSaleReturnData })); // Updated action
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deleteSaleReturnById({ saleReturnParam }) { // Updated parameters and action
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/sales/delete-sale/` + saleReturnParam.id, // Updated route
    });
    yield put(Actions.fetchAllSaleReturns({ saleReturnParam })); // Updated action
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.SALE_RETURN_DELETED_SUCCESSFULLY);
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function* GetQuotationByQNById({ QnParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    const { data } = yield call(api.get, { // Changed method from delete to get
      route: `/sales/all-quotationByQN?QuotationNo=` + QnParam.id, // Updated route
    });
    const QuotationData = data;
    yield put(Actions.SetQuotationByQNById({ QuotationData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_SALE_RETURN, fetchAllSaleReturns), // Updated action type
    takeLatest(ActionTypes.ADD_UPDATE_SALE_RETURN, addUpdateSaleReturn), // Updated action type
    takeLatest(ActionTypes.DELETE_SALES_RETURN, deleteSaleReturnById), // Updated action type
    takeLatest(ActionTypes.GET_QUOTATION_NO_BY_ID, GetQuotationByQNById)
  ]);
}
