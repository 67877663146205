export const setUploadProfileImage = state => {
    return state.services.common.getIn(['uploadImageUrl']);
 };

 export const setAllStores = state => {
    return state.services.common.getIn(['allStoresData']);
};

export const setAllWarehouses = state => {
    return state.services.common.getIn(['allWarehousesData']);
};

export const setAllCategory = state => {
    return state.services.common.getIn(['allCategoryData']);
};

export const setAllSubCategory = state => {
    return state.services.common.getIn(['allSubCategoryData']);
};

export const setAllBrands = state => {
    return state.services.common.getIn(['allBrandsData']);
};

export const setAllUnits = state => {
    return state.services.common.getIn(['allUnitsData']);
};

export const setAllUsers = state => {
    return state.services.common.getIn(['allUsersData']);
};

export const setAllVAraints = state => {
    return state.services.common.getIn(['allVariantsData']);
};

export const setAllExpenseCategory = state => {
    return state.services.common.getIn(['allExpenseCategoryData']);
};

export const setAllSellingTypes = state => {
    return state.services.common.getIn(['allSellingTypesData']);
};

export const setAllbarcodesymbologies = state => {
    return state.services.common.getIn(['allBarCodeSymbData']);
};

export const setAllDisscountTypes = state => {
    return state.services.common.getIn(['allDisscountTypesData']);
};

export const setAllTaxTypes = state => {
    return state.services.common.getIn(['allTaxTypesData']);
};

export const setAllSuppliers = state => {
    return state.services.common.getIn(['allSupplierData']);
};

export const setAllProductsBySearch = state => {
    return state.services.common.getIn(['allProductsDataBySearch']);
};

export const setLoader = state => {
    return state.services.common.getIn(['showLoader']);
 };

 export const setAllCustomer = state => {
    return state.services.common.getIn(['allCustomerData']);
};

export const setAllCounties = state => {
    return state.services.common.getIn(['allCountriesData']);
};

export const setAllStates = state => {
    return state.services.common.getIn(['allStatesData']);
};

export const setAllCities = state => {
    return state.services.common.getIn(['allCitiesData']);
};

export const setAllEmployee = state => {
    return state.services.common.getIn(['allEmployeeData']);
};

export const setAllLeaveType = state => {
    return state.services.common.getIn(['allLeaveTypeData']);
};

export const setAllDesignation = state => {
    return state.services.common.getIn(['allDesignationData']);
};

export const setAllDepartment = state => {
    return state.services.department.getIn(['allDepartmentData']);
};

export const setAllShift = state => {
    return state.services.common.getIn(['allShiftData']);
};

export const setAllGender = state => {
    return state.services.common.getIn(['allGenderData']);
};

export const setAllWeekoffs = state => {
    return state.services.common.getIn(['allWeekoffData']);
};

export const setAllNationalities = state => {
    return state.services.common.getIn(['allNationalityData']);
};

export const setAllBloodGroup = state => {
    return state.services.common.getIn(['allBloodGroupData']);
};

export const setAllSaleQuotation = state => {
    return state.services.common.getIn(['allSaleQuotationData']);
};

export const setAllQuotation = state => {
    return state.services.common.getIn(['allQuotationData']);
};

export const setAllGrnById = state => {
    return state.services.common.getIn(['allGrnData']);
};

export const setAllLeaveTypes = state => {
    return state.services.common.getIn(['AllLeaveTypesData']);
};