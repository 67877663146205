import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, InputNumber, Modal, Select } from 'antd';
import { Link } from "react-router-dom";
import { PlusCircle } from "feather-icons-react/build/IconComponents";
import { useDebounce } from 'use-debounce';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants.js";
import returnActions from '../../../core/Services/purchases/purchaseReturn/actions.js';
import commonAction from '../../../core/Services/common/actions.js';
import ReactSelect from 'react-select';
import IntlMessages from "../../../shared/components/utility/intlMessages.js";
import * as ApiResponseSelectors from '../../selectors.js';
import TextArea from 'antd/es/input/TextArea.js';
import CustomFormLabel from "../../common/CustomFormLabel";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import * as commonSelectors from '../../commonSelectors.js';

import './return.css';

const AddReturn = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, returnParams } = props;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [returnDetails, setReturnDetails] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [productParams, setProductParams] = useState({ searchText: '' });
    const [debouncedText] = useDebounce(productParams.searchText, 1000);
    const [selectedDate, setSelectedDate] = useState(null);

    const [isSupplierModalOpen, setSupplierModalOpen] = useState(false);
    const [currentSupplierModalData, setCurrentSupplierModalData] = useState({});

    const productsBySearchDataSource = useSelector(state => commonSelectors.setAllProductsBySearch(state));
    const searchedOptions = productsBySearchDataSource ? productsBySearchDataSource.toJS() : [];

    useEffect(() => {
        dispatch(commonAction.getAllSuppliers());
    }, []);

    const supplierDataSource = useSelector(state => commonSelectors.setAllSuppliers(state));
    const suppliers = supplierDataSource ? supplierDataSource.toJS() : [];

    useEffect(() => {
        if (isUpdate && currentModalData) {
            setSelectedDate(currentModalData.formData.returnDate ? new Date(currentModalData.formData.returnDate) : null);
        }
    }, [isUpdate, currentModalData]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        form.setFieldsValue({ returnDate: date ? moment(date).format('YYYY-MM-DD') : null });
    };

    useEffect(() => {
        const filteredOptions = searchedOptions.filter(
            product => !returnDetails.some(detail => detail.productId === product.id)
        );

        setProductOptions(filteredOptions?.map(product => ({
            label: product.productName,
            value: product.id,
            purchasePrice: product.purchasePrice,
            productName: product.productName,
        })));
    }, [searchedOptions, returnDetails]);

    useEffect(() => {
        if (debouncedText) {
            dispatch(commonAction.getAllProductsBySearch({ searchText: debouncedText }));
        }
    }, [debouncedText, dispatch]);

    useEffect(() => {
        setReturnDetails(currentModalData.formData.returnDetails);
    }, [currentModalData]);

    const handleProductSearch = textValue => {
        setProductParams({
            ...productParams,
            searchText: textValue,
        });
    };

    const toggleSupplierModal = () => {
        dispatch(commonAction.getAllSuppliers({}));
        setSupplierModalOpen(false);
    };
    const handleModal = (supplierData, actionName) => {
        const supplierModalObj = {
            formData: {},
        };
        setCurrentSupplierModalData(supplierModalObj);
        setSupplierModalOpen(true);
    };

    const handleProductChange = (selectedProduct) => {
        if (selectedProduct) {
            setReturnDetails(prevDetails => [
                ...prevDetails,
                {
                    returnId: 0,
                    productId: selectedProduct.value,
                    productName: selectedProduct.productName,
                    quantity: 1,
                    unitPrice: selectedProduct.purchasePrice,
                    disscount: 0,
                    taxPercentage: 0,
                    taxAmount: 0,
                    totalCost: selectedProduct.purchasePrice,
                }
            ]);
        }
    };

    const handleQuantityChange = (value, index) => {
        const updatedDetails = [...returnDetails];
        const product = updatedDetails[index];
        product.quantity = value;
        product.totalCost = (product.quantity * product.unitPrice) - product.disscount + product.taxAmount;
        setReturnDetails(updatedDetails);
    };

    const handledisscountChange = (value, index) => {
        const updatedDetails = [...returnDetails];
        const product = updatedDetails[index];
        product.disscount = value;
        product.totalCost = (product.quantity * product.unitPrice) - product.disscount + product.taxAmount;
        setReturnDetails(updatedDetails);
    };

    const handleTaxPercentageChange = (value, index) => {
        const updatedDetails = [...returnDetails];
        const product = updatedDetails[index];
        product.taxPercentage = value;
        product.taxAmount = ((product.taxPercentage / 100) * product.unitPrice * product.quantity);
        product.totalCost = (product.quantity * product.unitPrice) - product.disscount + product.taxAmount;
        setReturnDetails(updatedDetails);
    };

    const handleRemoveProduct = (index) => {
        const updatedDetails = [...returnDetails];
        updatedDetails.splice(index, 1);
        setReturnDetails(updatedDetails);
    };

    const handleRecord = (actionName, returnData) => {
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            returnData.id = currentModalData.formData.id;
            returnData.companyId = currentModalData.formData.companyId;
        }
        returnData.companyId = currentModalData.formData ? currentModalData.formData.companyId : 0;
        returnData.returnDetails = returnDetails;
        dispatch(returnActions.addUpdateReturns({ returnData, actionName, ReturnParams: returnParams }));
        toggleModal();
    };

    const status = [
        { value: "choose", label: "Choose" },
        { value: "pending", label: "Pending" },
        { value: "received", label: "Received" },
    ];

    return (
        <Modal
            open={isModalOpen}
            width={1200}
            zIndex={99999}
            onClose={() => toggleModal(true)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    <IntlMessages id="return.inventory.form.modal.title.update" />
                ) : (
                    <IntlMessages id="return.inventory.form.modal.title.add" />
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="return.inventory.form.modal.update" />
                ) : (
                    <IntlMessages id="return.inventory.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="return.inventory.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name="returnForm"
                className="returnActions_addModalWrapper"
                initialValues={{
                    companyId: currentModalData.formData ? currentModalData.formData.companyId : 0,
                    supplierId: currentModalData.formData ? currentModalData.formData.supplierId : 0,
                    returnDate: currentModalData.formData ? currentModalData.formData.returnDate : null,
                    otherTax: currentModalData.formData ? currentModalData.formData.otherTax : 0,
                    disscount: currentModalData.formData ? currentModalData.formData.disscount : 0,
                    shippingCost: currentModalData.formData ? currentModalData.formData.shippingCost : 0,
                    returnNotes: currentModalData.formData ? currentModalData.formData.returnNotes : '',
                    returnDetails: currentModalData.formData ? currentModalData.formData.returnDetails
                        : [{
                            "returnId": 0,
                            "productId": 0,
                            "quantity": 0,
                            "unitPrice": 0,
                            "disscount": 0,
                            "taxPercentage": 0,
                            "taxAmount": 0,
                            "totalCost": 0
                        }]
                }}
            >   <div className="row mt-5">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-blocks add-product">
                            <div className="row align-items-center">
                                <div className="col-lg-10">
                                    <div className="input-blocks">
                                        <Form.Item
                                            name="supplierId"
                                            label={
                                                <IntlMessages id="purchases.returns.form.label.supplier.name" />
                                            }
                                            rules={[
                                                {
                                                    required: true,
                                                    message: <IntlMessages id="purchases.returns.form.validation.supplier.name" />,
                                                },
                                            ]}
                                        >
                                            <Select
                                                options={suppliers?.map(f => ({ label: f.name, value: f.id }))}
                                                classNamePrefix="react-select"
                                                className="input-select"
                                                placeholder={<IntlMessages id="purchases.returns.form.placeholder.choose" />}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-lg-2 ps-0">
                                    <div className="add-icon tab mt-1">
                                        <Link
                                            className="btn btn-filter"
                                            onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                                        >
                                            <PlusCircle className="feather feather-plus-circle" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="returnDate"
                                label={<IntlMessages id="purchases.returns.form.label.purchase.date" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="purchases.returns.form.validation.purchase.date" />,
                                    },
                                ]}
                            >
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    dateFormat="YYYY-MM-DD"
                                    placeholderText="Select Date"
                                    className="actions_addModalFormItem"
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="referenceNumber"
                                label={<IntlMessages id="purchases.returns.form.validation.reference.no" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="purchases.returns.form.validation.reference.no" />,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="input-blocks">
                            <ReactSelect
                                options={productOptions}
                                isClearable
                                onInputChange={handleProductSearch}
                                onChange={handleProductChange}
                                placeholder={CONSTANT_VARIABLES.SELECT_PRODUCT}
                                components={{
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null,
                                }}
                            />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="modal-body-table">
                            <div className="table-responsive">
                                <table className="table datanew">
                                    <thead>
                                        <tr>
                                            <th><IntlMessages id="purchases.returns.form.header.image" /></th>
                                            <th><IntlMessages id="purchases.returns.form.header.date" /></th>
                                            <th><IntlMessages id="purchases.returns.form.header.supplier" /></th>
                                            <th><IntlMessages id="purchases.returns.form.header.reference" /></th>
                                            <th><IntlMessages id="purchases.returns.form.header.status" /></th>
                                            <th><IntlMessages id="purchases.returns.form.header.grandTotal" /></th>
                                            <th><IntlMessages id="purchases.returns.form.header.paid" /></th>
                                            <th><IntlMessages id="purchases.returns.form.header.due" /></th>
                                            <th><IntlMessages id="purchases.returns.form.header.paymentStatus" /></th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {returnDetails?.map((product, index) => (
                                            <tr key={index}>
                                                <td className="p-3">{product.productName}</td>
                                                <td className="p-3">
                                                    <Input
                                                        type="number"
                                                        min={1}
                                                        value={product.quantity}
                                                        onChange={(e) => handleQuantityChange(Number(e.target.value), index)}
                                                    />
                                                </td>
                                                <td className="p-3">
                                                    {product.unitPrice}
                                                </td>
                                                <td className="p-3">
                                                    <Input
                                                        type="number"
                                                        min={0}
                                                        value={product.disscount}
                                                        onChange={(e) => handledisscountChange(Number(e.target.value), index)}
                                                    />
                                                </td>
                                                <td className="p-3">
                                                    <Input
                                                        type="number"
                                                        min={0}
                                                        value={product.taxPercentage}
                                                        onChange={(e) => handleTaxPercentageChange(Number(e.target.value), index)}
                                                    />
                                                </td>
                                                <td className="p-3">{product.taxAmount}</td>
                                                <td className="p-3">{product.totalCost}</td>
                                                <td className="p-3">
                                                    <i
                                                        data-feather="trash-2"
                                                        className="feather-trash-2"
                                                        onClick={() => handleRemoveProduct(index)}
                                                    ></i>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="otherTax"
                                label={<IntlMessages id="purchases.returns.form.label.order.tax" />}
                                initialValue={0}
                                rules={[
                                    {
                                        type: 'number',
                                        min: 0,
                                        message: 'The quantity must be a valid number',
                                    },
                                ]}
                            >
                                <InputNumber min={0} className="input_number" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="disscount"
                                label={<IntlMessages id="purchases.returns.form.label.disscount" />}
                                initialValue={0}
                                rules={[
                                    {
                                        type: 'number',
                                        min: 0,
                                        message: 'The quantity must be a valid number',
                                    },
                                ]}
                            >
                                <InputNumber min={0} className="input_number" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="shippingCost"
                                label={<IntlMessages id="purchases.returns.form.label.shipping" />}
                                initialValue={0}
                                rules={[
                                    {
                                        type: 'number',
                                        min: 0,
                                        message: 'The quantity must be a valid number',
                                    },
                                ]}
                            >
                                <InputNumber min={0} className="input_number" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="input-blocks add-product">
                            <div className="input-blocks">
                                <Form.Item
                                    name="status"
                                    label={
                                        <IntlMessages id="purchases.returns.form.label.status" />
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: <IntlMessages id="purchases.returns.form.validation.status" />,
                                        },
                                    ]}
                                >
                                    <Select
                                        options={status}
                                        classNamePrefix="react-select"
                                        className="input-select"
                                        placeholder={<IntlMessages id="purchases.returns.form.placeholder.choose" />}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="returnNotes"
                                className='warehouseActions_addModalFormItem'
                                label={
                                    <IntlMessages id="purchases.returns.form.label.notes" />
                                }
                            >
                                <TextArea placeholder={CONSTANT_VARIABLES.CUSTOMER_DESCRIPTION} />
                            </Form.Item>
                        </div>
                    </div>
                </div>
                {isSupplierModalOpen ? (
                    <AddSupplierModal
                        currentModalData={currentSupplierModalData}
                        isModalOpen={isSupplierModalOpen}
                        isUpdate={false}
                        toggleModal={toggleSupplierModal}
                    />
                ) : null}
            </Form>
        </Modal>
    );
};

export default AddReturn;
