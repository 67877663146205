import React, { useState, useEffect } from 'react';
import * as posSelectors from '../../posSelectors.js';
import todayExpensesActions from '../../../../core/Services/pos/cashclosing/todayexpenses/actions.js';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Radio, Space, Select, InputNumber, Input, Form } from 'antd';
import { CONSTANT_VARIABLES } from "../../../../core/Common/Constant_Variables/constants.js";
import IntlMessages from "../../../../shared/components/utility/intlMessages.js";
import './todayexpenses.css';
import '../../pos.css';
import * as ApiResponseSelectors from '../../../selectors.js';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const suppliers = [
    { value: "choose", label: "Choose" },
    { value: "Supplier 1", label: "Supplier 1" },
    { value: "unitedStates", label: "Supplier 2" },
];

const employees = [
    { value: "choose", label: "Choose" },
    { value: "Employee 1", label: "Employee 1" },
    { value: "Employee 2", label: "Employee 2" },
];

const expenseTypes = [
    { value: "choose", label: "Choose" },
    { value: "Debit", label: "Debit" },
    { value: "Cash", label: "Cash" },
    { value: "CreditCard", label: "CreditCard" },
];

const expenseHeads = [
    { value: "choose", label: "Choose" },
    { value: "OfficeSupplies", label: "Office Supplies" },
    { value: "Travel", label: "Travel" },
    { value: "Utilities", label: "Utilities" },
    { value: "Other", label: "Other" },
];

const AddExpensesModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, todayExpensesParams } = props;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState(null);
    const [userType, setUserType] = useState("Supplier");
    const todayExpensesData = useSelector(state => posSelectors.setTodayExpenses(state));
    const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));

    useEffect(() => {
        if (isUpdate && currentModalData) {
            setSelectedDate(currentModalData.formData.closingDate ? new Date(currentModalData.formData.closingDate) : null);
            form.setFieldsValue({
                ...currentModalData.formData,
                closingDate: currentModalData.formData.closingDate ? moment(currentModalData.formData.closingDate).format('YYYY-MM-DD') : null,
                userType: currentModalData.formData.userType || "Supplier" 
            });
            setUserType(currentModalData.formData.userType || "Supplier");
        }
    }, [isUpdate, currentModalData, form]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        form.setFieldsValue({ closingDate: date ? moment(date).format('YYYY-MM-DD') : null });
    };

    const handleRadioChange = (e) => {
        setUserType(e.target.value);
        form.setFieldsValue({ userType: e.target.value });
    };

    const handleRecord = (actionName, todayExpensesData) => {
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            todayExpensesData.id = currentModalData.formData.id;
            todayExpensesData.companyId = currentModalData.formData.companyId;
        }
        dispatch(todayExpensesActions.addUpdateTodayexpenses({ todayExpensesData, actionName, TodayExpensesParams: todayExpensesParams }));
        toggleModal();
    };

    return (
        <Modal
            open={isModalOpen}
            onClose={() => toggleModal(true)}
            width={700}
            zIndex={9999}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    <IntlMessages id="todayExpenses.pos.form.modal.title.update" />
                ) : (
                    <IntlMessages id="todayExpenses.pos.form.modal.title.add" />
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="todayExpenses.pos.form.modal.update" />
                ) : (
                    <IntlMessages id="todayExpenses.pos.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="todayExpenses.pos.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name="todayExpensesForm"
                className='todayExpensesActions_addModalWrapper'
                initialValues={{
                    companyId: currentModalData.formData ? currentModalData.formData.companyId : 0,
                    todayExpensesName: currentModalData.formData.todayExpensesName,
                    todayExpensesSlug: currentModalData.formData.todayExpensesSlug,
                    isActive: currentModalData.formData.isActive,
                    userType: currentModalData.formData.userType || "Supplier" 
                }}
            >
                <div className="row mt-5">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="closingDate"
                                label={<IntlMessages id="pos.todayexpenses.form.label.date" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="pos.todayexpenses.form.validation.date" />,
                                    },
                                ]}
                            >
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    dateFormat="YYYY-MM-DD"
                                    placeholderText="Select Date"
                                    className="actions_addModalFormItem"
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="userType"
                                className="actions_addModalFormItem"
                                label={<IntlMessages id="pos.todayexpenses.form.label.user.type" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="pos.todayexpenses.form.label.user.type" />,
                                    },
                                ]}
                            >
                                <Radio.Group onChange={handleRadioChange}>
                                    <Space direction='horizontal'>
                                        <Radio value={"Employee"}>
                                            <IntlMessages id="pos.todayexpenses.form.label.user.type.employee" />
                                        </Radio>
                                        <Radio value={"Supplier"}>
                                            <IntlMessages id="pos.todayexpenses.form.label.user.type.supplier" />
                                        </Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </div>
                </div>
                {userType === "Supplier" && (
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="input-blocks">
                                <Form.Item
                                    name="supplierId"
                                    label={<IntlMessages id="pos.todayexpenses.form.label.supplier.name" />}
                                    rules={[
                                        {
                                            required: true,
                                            message: <IntlMessages id="pos.todayexpenses.form.validation.supplier.name" />,
                                        },
                                    ]}
                                >
                                    <Select
                                        options={suppliers}
                                        classNamePrefix="react-select"
                                        className="input-select"
                                        placeholder={<IntlMessages id="pos.todayexpenses.form.placeholder.choose" />}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                )}
                {userType === "Employee" && (
                    <>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="input-blocks">
                                    <Form.Item
                                        name="employeeId"
                                        label={<IntlMessages id="pos.todayexpenses.form.label.employee.name" />}
                                        rules={[
                                            {
                                                required: true,
                                                message: <IntlMessages id="pos.todayexpenses.form.validation.employee.name" />,
                                            },
                                        ]}
                                    >
                                        <Select
                                            options={employees}
                                            classNamePrefix="react-select"
                                            className="input-select"
                                            placeholder={<IntlMessages id="pos.todayexpenses.form.placeholder.choose" />}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="input-blocks">
                                    <Form.Item
                                        name="expenseType"
                                        label={<IntlMessages id="pos.todayexpenses.form.label.expenseType" />}
                                        rules={[
                                            {
                                                required: true,
                                                message: <IntlMessages id="pos.todayexpenses.form.validation.expenseType" />,
                                            },
                                        ]}
                                    >
                                        <Select
                                            options={expenseTypes}
                                            classNamePrefix="react-select"
                                            className="input-select"
                                            placeholder={<IntlMessages id="pos.todayexpenses.form.placeholder.choose" />}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="amount"
                                label={<IntlMessages id="pos.todayexpenses.form.label.amount" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="pos.todayexpenses.form.validation.amount" />,
                                    },
                                ]}
                            >
                                <InputNumber
                                    min={0}
                                    className="input_number"
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="expenseHead"
                                label={<IntlMessages id="pos.todayexpenses.form.label.expenseHead" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="pos.todayexpenses.form.validation.expenseHead" />,
                                    },
                                ]}
                            >
                                <Select
                                    options={expenseHeads}
                                    classNamePrefix="react-select"
                                    className="input-select"
                                    placeholder={<IntlMessages id="pos.todayexpenses.form.placeholder.choose" />}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                className="actions_addModalFormItem"
                                name="description"
                                label={<IntlMessages id="pos.todayexpenses.form.label.description" />}
                            >
                                <Input.TextArea
                                    className="actions_addModalFormItem"
                                    rows={4}
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
}

export default AddExpensesModal;
