export const ActionTypes = {
    ADD_UPDATE_CATEGORY: 'services/inventory/categories/add_update_category',
    FETCH_CATEGORY: 'services/inventory/categories/fetch_categories',
    SET_CATEGORIES: 'services/inventory/categories/set_categories',
    DELETE_CATEGORIES: 'services/inventory/categories/delete_categories',
    GET_CATEGORY_BY_ID: 'services/inventory/categories/get_category_by_id',
    SET_CATEGORY_BY_ID: 'services/inventory/categories/set_category_by_id',
    SET_LOADER: 'services/inventory/inventory/set_loader',
  }
  
  const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
  });
  
  const fetchAllCategories = ({ categoryParams }) => ({
    categoryParams,
    type: ActionTypes.FETCH_CATEGORY,
  })
  
  const setCategories = ({ allCategoryData }) => ({
    allCategoryData,
    type: ActionTypes.SET_CATEGORIES,
  })
  
  const deleteCategories = ({ categoryParam }) => ({
    categoryParam,
    type: ActionTypes.DELETE_CATEGORIES,
  })
  
  const getCategoryById = ({ categoryParam }) => ({
    categoryParam,
    type: ActionTypes.GET_CATEGORY_BY_ID,
  })
  
  const setCategoryById = ({ categoryParam }) => ({
    categoryParam,
    type: ActionTypes.SET_CATEGORY_BY_ID,
  })
  
  const addUpdateCategories = ({ categoryData, actionName, UserParams }) => ({
    categoryData,
    actionName,
    UserParams,
    type: ActionTypes.ADD_UPDATE_CATEGORY,
  })
  
  export default {
    addUpdateCategories,
    fetchAllCategories,
    setCategories,
    setCategoryById,
    deleteCategories,
    getCategoryById,
    setCategoryById,
    setLoader
  }