import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import TextEditor from "../../inventory/texteditor";
import { DatePicker, Modal, Form, Input, Select } from "antd";
import { Calendar, PlusCircle } from "feather-icons-react/build/IconComponents";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import CustomFormLabel from "../../common/CustomFormLabel";
import saleActions from '../../../core/Services/sales/salesOrder/actions';
import commonAction from "../../../core/Services/common/actions";
import * as saleSelectors from '../saleSelectors';
import * as commonSelector from '../../commonSelectors';
import moment from 'moment';

const AddSaleOrderModal = (props) => {
  const { currentModalData, isModalOpen, isUpdate, toggleModal, saleOrderParams } = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [editorData, setEditorData] = useState();
  const allSaleQuotationData = useSelector(state => commonSelector.setAllSaleQuotation(state));
  const quotationNos = allSaleQuotationData ? allSaleQuotationData.toJS() : [];

  const customerData = useSelector(state => commonSelector.setAllCustomer(state));
  const allCustomerData = customerData ? customerData.toJS() : [];

  const quotationNoData = useSelector(state => saleSelectors.SetSaleQuotationByQNById(state));
  const quotation = quotationNoData ? quotationNoData.toJS() : [];

  console.log(quotation);
  useEffect(() => {
    if (isUpdate && currentModalData) {
      let editorFetchedData = currentModalData.formData?.notesHtml.replace(/""/g, '');
      setEditorData(editorFetchedData);
    }
}, [isUpdate, currentModalData]);

useEffect(() => {
  if (isUpdate) {
      setDeliveryDate(moment(currentModalData.formData.deliveryDate).format('YYYY-MM-DD'));
      setSelectedDate(moment(currentModalData.formData.saleOrderDate).format('YYYY-MM-DD'));
  }
}, [currentModalData]);

  useEffect(() => {
    const value = form.getFieldValue('saleQuotationId');
    if (quotation.id && value) {
      form.setFieldsValue({
        customerId: quotation.customerId,
        otherTax: quotation.otherTax,
        disscount: quotation.disscount,
        saleQuotationDate: quotation.saleQuotationDate,
        notesHtml: quotation.saleQuotationNotes
      });
      setSelectedDate(quotation.saleQuotationDate)
    }
  }, [quotationNoData && !isUpdate])

  useEffect(() => {
    dispatch(commonAction.getAllSaleQuotation());
    dispatch(commonAction.getAllCustomer());
  }, [])

  const handleDateChange = (date) => {
    setSelectedDate(date);
    form.setFieldsValue({ saleOrderDate: date ? moment(date).format('YYYY-MM-DD') : null });
  };

  const handleDeliveryDateChange = (date) => {
    setDeliveryDate(date);
    form.setFieldsValue({ deliveryDate: date ? moment(date).format('YYYY-MM-DD') : null });
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  const handleRecord = (actionName, saleOrderData) => {
    if (actionName === CONSTANT_VARIABLES.UPDATE) {
        saleOrderData.id = currentModalData.formData?.id;
        saleOrderData.companyId = currentModalData.formData?.companyId;
        saleOrderData.saleOrderDetails = currentModalData.formData?.saleOrderDetails;
        saleOrderData.notesHtml = editorData;
    } else {
        saleOrderData.companyId = 1;
        saleOrderData.saleOrderDate = selectedDate;
        saleOrderData.deliveryDate = deliveryDate;
        saleOrderData.saleOrderDetails = quotation.saleQuotationDetails;
        saleOrderData.notesHtml = editorData;
    }
    dispatch(saleActions.addUpdateSaleOrder({ saleOrderData, actionName, saleOrderParams: saleOrderParams }));
    toggleModal();
  };

  const handleChangeQuotation = (id) => {
    dispatch(saleActions.GetSaleQuotationByQNById({ saleOrderParamId: id }));
  }

  return (
    <Modal
      open={isModalOpen}
      width={1140}
      zIndex={99999}
      onClose={() => toggleModal(true)}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
          })
          .catch(info => {
            console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
          });
      }}
      onCancel={() => toggleModal(true)}
      title={
        isUpdate ? (
          <IntlMessages id="sales.sale.order.form.modal.title.update" />
        ) : (
          <IntlMessages id="sales.sale.order.form.modal.title.add" />
        )
      }
      okText={
        isUpdate ? (
          <IntlMessages id="purchases.purchase.form.modal.update" />
        ) : (
          <IntlMessages id="purchases.purchase.form.modal.add" />
        )
      }
      cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          saleQuotationId: currentModalData.formData?.id,
          customerId: currentModalData.formData?.customerId,
          referenceNo: currentModalData.formData?.referenceNo,
          otherTax: currentModalData.formData?.otherTax,
          disscount: currentModalData.formData?.disscount,
          shippingCost: currentModalData.formData?.shippingCost,
          notesHtml: currentModalData.formData?.purchaseNotes
        }}
      >
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="input-blocks add-product">
              <div className="row">
                <div className="col-lg-10 col-sm-10 col-10">
                  <Form.Item
                    name="saleQuotationId"
                    label={
                      <CustomFormLabel
                        labelkey="purchases.purchase.form.purchase.label.search"
                        popoverkey="purchase.purchase.form.label.popover.purchase.search"
                      />
                    }
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="purchase.purchase.modal.validation.search" />,
                      },
                    ]}
                  >

                    <Select
                      options={quotationNos?.map(f => ({ label: f.name, value: f.id }))}
                      classNamePrefix="react-select"
                      placeholder="Choose"
                      onChange={handleChangeQuotation}
                    //disabled={isUpdate}
                    />

                    
                  </Form.Item>
                 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="input-blocks add-product">
              <div className="row">
                <div className="col-lg-10 col-sm-10 col-10">              
                  <Form.Item
                    name="customerId"
                    label={
                      <CustomFormLabel
                        labelkey="sales.sale.order.form.purchase.label.customer.name"
                        popoverkey="sales.sale.order.form.label.popover.purchase.customer.name"
                      />
                    }
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="sales.sale.order.modal.validation.customer.name" />,
                      },
                    ]}
                  >

                    <Select
                      options={allCustomerData?.map(f => ({ label: f.name, value: f.id }))}
                      classNamePrefix="react-select"
                      placeholder="Choose"
                    //disabled={isUpdate}
                    />

                  </Form.Item>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="input-blocks">
              <div className="input-groupicon calender-input">
                <Form.Item
                  name="saleOrderDate"
                  label={
                    <CustomFormLabel
                      labelkey="sales.sale.order.form.purchase.label.sale.order.date"
                      popoverkey="sales.sale.order.form.label.popover.sale.order.date"
                    />
                  }
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="sales.sale.order.modal.validation.sale.order.date" />,
                    },
                    ({
                      getFieldValue,
                    }) => ({
                      validator(rule, value) {
                        const selectedDate = getFieldValue('purchaseDate'); // Assuming this is the date field you're validating
                        if (moment(selectedDate).isSameOrAfter(moment(), 'day')) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error(<IntlMessages id="purchase.purchase.modal.validation.purchase.validation" />)
                          );
                        }
                      },
                    })
                  ]}
                >
                  <Calendar className="info-img" />
                  <DatePicker
                    selected={moment(selectedDate)}
                    onChange={handleDateChange}
                    dateFormat="YYYY-MM-DD"
                    placeholderText="Select Date"
                    className="actions_addModalFormItem"
                  />
                </Form.Item>

              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">       
            <div className="input-blocks">
              <div className="input-groupicon calender-input">
                <Form.Item
                  name="deliveryDate"
                  label={
                    <CustomFormLabel
                      labelkey="sales.sale.order.form.purchase.label.deliver.date"
                      popoverkey="sales.sale.order.form.label.popover.deliver.date"
                    />
                  }
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="sales.sale.order.modal.validation.deliver.date" />,
                    },
                    ({
                      getFieldValue,
                    }) => ({
                      validator(rule, value) {
                        const selectedDate = getFieldValue('purchaseDate'); // Assuming this is the date field you're validating
                        if (moment(selectedDate).isSameOrAfter(moment(), 'day')) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error(<IntlMessages id="purchase.purchase.modal.validation.purchase.validation" />)
                          );
                        }
                      },
                    })
                  ]}
                >
                  <Calendar className="info-img" />
                  <DatePicker
                    selected={moment(deliveryDate)}
                    onChange={handleDeliveryDateChange}
                    dateFormat="YYYY-MM-DD"
                    placeholderText="Select Date"
                    className="actions_addModalFormItem"
                  />
                </Form.Item>

              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="input-blocks">
              <Form.Item
                name="referenceNo"
                label={
                  <CustomFormLabel
                    labelkey="purchases.purchase.form.purchase.label.reference.no"
                    popoverkey="purchase.purchase.form.label.popover.purchase.reference.no"
                  />
                }
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="purchase.purchase.modal.validation.reference.no" />,
                  },
                ]}
              >
                <Input type="text" className="form-control" />
              </Form.Item>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="modal-body-table">
              <div className="table-responsive">
                <table className="table  datanew">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Qty</th>
                      <th>Discount($)</th>
                      <th>Tax(%)</th>
                      <th>Tax Amount($)</th>
                      <th>Unit Price($)</th>
                      <th>Total Cost(%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {form.getFieldValue('saleQuotationId') ?(isUpdate ? currentModalData.formData?.saleOrderDetails?.map((item, index) => (
                      <tr key={index}>
                        <td className="p-5">{item.productName}</td>
                        <td className="p-5">{item.quantity}</td>
                        <td className="p-5">{item.disscount}</td>
                        <td className="p-5">{item.taxPercentage}</td>
                        <td className="p-5">{item.taxAmount}</td>
                        <td className="p-5">{item.unitPrice}</td>
                        <td className="p-5">{item.totalCost}</td>
                      </tr>
                    )) : quotation.saleQuotationDetails?.map((item, index) => (
                      <tr key={index}>
                        <td className="p-5">{item.productName}</td>
                        <td className="p-5">{item.quantity}</td>
                        <td className="p-5">{item.disscount}</td>
                        <td className="p-5">{item.taxPercentage}</td>
                        <td className="p-5">{item.taxAmount}</td>
                        <td className="p-5">{item.unitPrice}</td>
                        <td className="p-5">{item.totalCost}</td>
                      </tr>
                    ))) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="input-blocks">
                <Form.Item
                  name="otherTax"
                  label={
                    <CustomFormLabel
                      labelkey="purchases.purchase.form.purchase.label.order.tax"
                      popoverkey="purchase.purchase.form.label.popover.purchase.order.tax"
                    />
                  }
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="purchase.purchase.modal.validation.order.tax" />,
                    },
                  ]}
                >
                  <Input type="text" defaultValue={0} />
                </Form.Item>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="input-blocks">
                <Form.Item
                  name="disscount"
                  label={
                    <CustomFormLabel
                      labelkey="purchases.purchase.form.purchase.label.discount"
                      popoverkey="purchase.purchase.form.label.popover.purchase.discount"
                    />
                  }
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="purchase.purchase.modal.validation.discount" />,
                    },
                  ]}
                >
                  <Input type="text" defaultValue={0} />
                </Form.Item>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="input-blocks">
                <Form.Item
                  name="shippingCost"
                  label={
                    <CustomFormLabel
                      labelkey="purchases.purchase.form.purchase.label.shipping"
                      popoverkey="purchase.purchase.form.label.popover.purchase.shipping"
                    />
                  }
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="purchase.purchase.modal.validation.shipping" />,
                    },
                  ]}
                >
                  <Input type="text" defaultValue={0} />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="input-blocks summer-description-box">
            <Form.Item
              name="notesHtml"
              label={
                <CustomFormLabel
                  labelkey="purchases.purchase.form.purchase.label.notes"
                  popoverkey="purchase.purchase.form.label.popover.purchase.note"
                />
              }
            >
              <TextEditor data={editorData}
                onChange={handleEditorChange} />
            </Form.Item>

          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AddSaleOrderModal;
