import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Switch, Select, Tabs } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import * as inventorySelectors from '../InventorySelectors';

import productActions from '../../../core/Services/inventory/products/actions';
import { CONSTANT_VARIABLES } from '../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../shared/components/utility/intlMessages';
import CustomFormLabel from '../../common/CustomFormLabel';
import * as ApiResponseSelectors from '../../selectors';
import './products.css';
import ProductInformationTab from "./components/productInformationTab";
import ProductPricingAndStockTab from "./components/productPricingAndStockTab";
import CustomFieldsTab from "./components/customFieldsTab";
import '../inventory.css';
import { Info, LifeBuoy, List } from "feather-icons-react/build/IconComponents";


const { TabPane } = Tabs;

// const {productPricingTabData, setProductPricingTabData} = useState({});
// const {productCustomFieldsTabData, setCustomFieldsTabData} = useState({});

// const handleProductPricingData = (value) =>{
//     setProductPricingTabData(value);
// }

// const handleCustomFieldsData = (value) =>{
//     setCustomFieldsTabData(value);
// }
const ProductModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, productParams } = props;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [isError, setIsError] = useState(false);
    const [imagesList, setImagesList] = useState([]);

    const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
    const handleRecord = (actionName, productData) => {
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            productData.id = currentModalData.formData.id;
            productData.companyId = currentModalData.formData.companyId;
            productData.variantId = productData.variantId == null ? currentModalData.formData.variantId : productData.variantId;
            productData.ProductType = productData.variantId == null ? currentModalData.formData.productType : productData.ProductType;
        
            productData.quantity = productData.quantity == null ? currentModalData.formData.quantity : productData.quantity;
            productData.price = productData.price == null ? currentModalData.formData.price : productData.price;
            productData.taxTypeId = productData.taxTypeId == null ? currentModalData.formData.taxTypeId : productData.taxTypeId;
            productData.disscountTypeId = productData.disscountTypeId == null ? currentModalData.formData.disscountTypeId : productData.disscountTypeId;
            productData.disscountValue = productData.disscountValue == null ? currentModalData.formData.disscountValue : productData.disscountValue;
            productData.quantityAlert = productData.quantityAlert == null ? currentModalData.formData.quantityAlert : productData.quantityAlert;
            productData.variantId = productData.variantId == null ? currentModalData.formData.variantId : productData.variantId;
            productData.imagesUrls = productData.imagesUrls == null ? currentModalData.formData.imagesUrls : productData.uploadImagesUrls = imagesList;
        
            productData.isWarranty = productData.isWarranty == null ? currentModalData.formData.isWarranty : productData.isWarranty;
            productData.isManufacturer = productData.isManufacturer == null ? currentModalData.formData.isManufacturer : productData.isManufacturer;
            productData.isExpiry = productData.isExpiry == null ? currentModalData.formData.isExpiry : productData.isExpiry;
            productData.manufacturerDate = productData.manufacturerDate == null ? currentModalData.formData.manufacturerDate : productData.manufacturerDate;
            productData.expiryOn = productData.expiryOn == null ? currentModalData.formData.expiryOn : productData.expiryOn;
            productData.productType = productData.productType == null ? currentModalData.formData.expiryOn : productData.expiryOn;
        
            console.log("product data:",productData);
        }
        else {
            productData.uploadImagesUrls = imagesList;
            productData.isWarranty = productData.isWarranty ? true : false;
            productData.isManufacturer = productData.isManufacturer ? true : false;
            productData.isExpiry = productData.isExpiry ? true : false;
            productData.companyId = 0;
        }

        dispatch(productActions.addUpdateProducts({ productData, actionName, ProductParams: productParams }));
        toggleModal();
    };
    const handlUploadImage = (uploadImageList) => {
        setImagesList(uploadImageList);
    }

    return (
        <Modal
            open={isModalOpen}
            width={900}
            zIndex={999999}
            onCancel={() => toggleModal(true)}
            onOk={() => {
                form.validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            title={isUpdate ? <IntlMessages id="product.inventory.form.modal.title.update" /> : <IntlMessages id="product.inventory.form.modal.title.add" />}
            okText={isUpdate ? <IntlMessages id="product.inventory.form.modal.update" /> : <IntlMessages id="product.inventory.form.modal.add" />}
            cancelText={<IntlMessages id="product.inventory.form.modal.cancelText" />}
            maskClosable={false}
        >
            <div className="card-body add-product pb-0">
                <Tabs defaultActiveKey="1">
                    <TabPane
                        tab={<span><Info className="add-info" /> Product Information</span>}
                        key="1"
                    >
                        <ProductInformationTab form={form} currentModalData={currentModalData}  />
                    </TabPane>
                    <TabPane
                        tab={<span><LifeBuoy className="add-info" /> Pricing & Stocks</span>}
                        key="2"
                    >
                        <ProductPricingAndStockTab form={form} onUplaodImage={handlUploadImage} currentModalData={currentModalData} isUpdate={currentModalData.formData?.id ? true : false} />
                    </TabPane>
                    <TabPane
                        tab={<span><List className="add-info" /> Custom Fields</span>}
                        key="3"
                    >
                        <CustomFieldsTab form={form} currentModalData={currentModalData} isUpdate={currentModalData.formData.id ? true : false} />
                    </TabPane>
                </Tabs>
            </div>
        </Modal>
    );
};

export default ProductModal;
