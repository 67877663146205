import Immutable from 'immutable';
import { ActionTypes } from "./actions";

export const initialState = Immutable.fromJS({});

const setAttendance = (state, { allAttendanceData }) => {
    if (!allAttendanceData) return state;
    return state.merge({ allAttendanceData });
}

const setEmployeeAttendanceById = (state, { employeeAttendanceData }) => {
    return state.merge({ employeeAttendanceData });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_ATTENDANCE,
        actionFunction: setAttendance,
    },
    {
        actionType: ActionTypes.SET_EMPLOYEE_ATTENDANCE_BY_ID,
        actionFunction: setEmployeeAttendanceById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
