import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, Form, Input, Switch, Select, Tabs, DatePicker } from 'antd';
import { CONSTANT_VARIABLES } from '../../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import CustomFormLabel from '../../../common/CustomFormLabel';
import { PlusCircle } from "react-feather";

const PasswordTab = (props) => {
    const { form, currentModalData, handleCustomFieldsData } = props;
    const dispatch = useDispatch();

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                initialValues={{}}
                name="forForm" className='productActions_addModalWrapper'>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="input-blocks">
                            <Form.Item
                                name="password"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.password"
                                        popoverkey="hrm.employee.form.label.popover.password"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.password" />,
                                    },
                                ]}
                            >
                                <input type="password" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="input-blocks">
                            <Form.Item
                                name="confirmpassword"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.confirm.password"
                                        popoverkey="hrm.employee.form.label.popover.confirm.password"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.confirm.password" />,
                                    },
                                ]}
                            >
                                <input type="password" />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default PasswordTab;
