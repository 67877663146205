export const ActionTypes = {
    ADD_UPDATE_HEAD: 'services/inventory/heads/add_update_head',
    FETCH_HEAD: 'services/inventory/heads/fetch_heads',
    SET_HEADS: 'services/inventory/heads/set_heads',
    DELETE_HEADS: 'services/inventory/heads/delete_heads',
    SET_LOADER: 'services/inventory/inventory/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllHeads = ({ headParams }) => ({
    headParams,
    type: ActionTypes.FETCH_HEAD,
})

const setHeads = ({ allHeadData }) => ({
    allHeadData,
    type: ActionTypes.SET_HEADS,
})

const deleteHeads = ({ headParam }) => ({
    headParam,
    type: ActionTypes.DELETE_HEADS,
})

const addUpdateHeads = ({ headData, actionName, UserParams }) => ({
    headData,
    actionName,
    UserParams,
    type: ActionTypes.ADD_UPDATE_HEAD,
})

export default {
    addUpdateHeads,
    fetchAllHeads,
    setHeads,
    deleteHeads,
    setLoader
};
