import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../utils/ss-api';
import Actions, { ActionTypes } from '../common/actions';
import notification from "../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../Common/Constant_Variables/constants";

function* uploadProfileImage({ userImage }) {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const file = userImage.originFileObj;
        const formData = new FormData();
        formData.append('file', file); // Use 'file' instead of 'File'

        const { data } = yield call(api.UploadImage, {
            route: `/Storage/upload`,
            data: formData,
        });
        const uploadImageUrl = data;
        yield put(Actions.setUploadProfileImage({ uploadImageUrl }));
        yield put(Actions.setLoader({ showLoader: false }));
        notification('success', CONSTANT_VARIABLES.UPLOAD_PROFILE_IMAGE_SUCCESSFULLY);
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllStores() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Setup/all-storesdrp' });
        const allStoresData = data;
        yield put(Actions.setAllStores({ allStoresData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllWarehouses() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Setup/all-warehousesdrp' });
        const allWarehousesData = data;
        yield put(Actions.setAllWarehouses({ allWarehousesData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllCategory() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Inventory/all-categoriesdrp' });
        const allCategoryData = data;
        yield put(Actions.setAllCategory({ allCategoryData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllSubCategory({ CategoryId }) {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Inventory/all-subcategoriesdrp?CategoryId=' + CategoryId });
        const allSubCategoryData = data;
        yield put(Actions.setAllSubCategory({ allSubCategoryData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllBrands() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Inventory/all-brandsdrp' });
        const allBrandsData = data;
        yield put(Actions.setAllBrands({ allBrandsData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllUnits() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Inventory/all-unitsdrp' });
        const allUnitsData = data;
        yield put(Actions.setAllUnits({ allUnitsData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllUsers() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/UserMGT/Getusers' });
        const allUsersData = data;
        yield put(Actions.setAllUsers({ allUsersData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllExpenseCategory() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/FinanceAccounts/all-expcategoriesdrp' });
        const allExpenseCategoryData = data;
        yield put(Actions.setAllExpenseCategory({ allExpenseCategoryData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllVariants() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Inventory/all-variantsdrp' });
        const allVariantsData = data;
        yield put(Actions.setAllVariants({ allVariantsData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllSellingTypes() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Inventory/all-sellingtypesdrp' });
        const allSellingTypesData = data;
        yield put(Actions.setAllSellingTypes({ allSellingTypesData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllbarcodesymbologies() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Inventory/all-barcodesymbologydrp' });
        const allBarCodeSymbData = data;
        yield put(Actions.setAllbarcodesymbologies({ allBarCodeSymbData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllDisscountTypes() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Inventory/all-disscounttypesdrp' });
        const allDisscountTypesData = data;
        yield put(Actions.setAllDisscountTypes({ allDisscountTypesData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllTaxTypes() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Inventory/all-taxtypesdrp' });
        const allTaxTypesData = data;
        yield put(Actions.setAllTaxTypes({ allTaxTypesData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}
function* getAllCities() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Setup/all-citiesdrp' });
        const allCitiesData = data;
        yield put(Actions.setAllCities({ allCitiesData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllCitiesByStateId({ cityParams }) {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: `/Setup/all-citiesdrp/?StateId=${cityParams.id}` });
        const allCitiesData = data;
        yield put(Actions.setAllCities({ allCitiesData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllStates() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Setup/all-statesdrp' });
        const allStatesData = data;
        yield put(Actions.setAllStates({ allStatesData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllStatesByCountryId({ stateParams }) {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
        route: `/Setup/all-statesdrp/?CountryId=${stateParams.id}`,
        // params: stateParams?.id,
     });
        const allStatesData = data;
        yield put(Actions.setAllStates({ allStatesData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllCountries() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Setup/all-countriesdrp' });
        const allCountriesData = data;
        yield put(Actions.setAllCountries({ allCountriesData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllCustomer() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Setup/all-customersdrp' });
        const allCustomerData = data;
        yield put(Actions.setAllCustomer({ allCustomerData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* GetAllQuotation() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/Purchases/all-quotationsdrp' ,
        });
        const allQuotationData = data;
        yield put(Actions.setAllQuotation({ allQuotationData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* getAllSuppliers() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Setup/all-suppliersdrp' });
        const allSupplierData = data;

        yield put(Actions.setAllSuppliers({ allSupplierData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllProductsBySearch({productParams}) {
    console.log("saga product params", productParams);
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/Inventory/all-productsearch?SearchText=' + productParams,
         });
        const allProductsDataBySearch = data;

        yield put(Actions.setAllProductsBySearch({ allProductsDataBySearch }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* GetAllEmployee() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/HRM/all-employeesdrp',
        });
        const allEmployeeData = data;
        yield put(Actions.setAllEmployee({ allEmployeeData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* GetAllLeaveType() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/HRM/all-leavetypesdrp',
        });
        const allLeaveTypeData = data;
        yield put(Actions.setAllLeaveType({ allLeaveTypeData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* GetAllDesignation() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/HRM/all-designationsdrp',
        });
        const allDesignationData = data;
        yield put(Actions.setAllDesignation({ allDesignationData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* GetAllDepartment() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/HRM/all-departmentsdrp',
        });
        const allDepartmentData = data;
        yield put(Actions.setAllDepartment({ allDepartmentData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* GetAllShift() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/HRM/all-shiftsdrp',
        });
        const allShiftData = data;
        yield put(Actions.setAllShift({ allShiftData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* GetAllGender() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/HRM/all-gendersdrp',
        });
        const allGenderData = data;
        yield put(Actions.setAllGender({ allGenderData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* GetAllWeekoffs() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/HRM/all-weekoffsdrp',
        });
        const allWeekoffData = data;
        yield put(Actions.setAllWeekoffs({ allWeekoffData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* GetAllNationalities() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/HRM/all-nationalitiesdrp',
        });
        const allNationalityData = data;
        yield put(Actions.setAllNationalities({ allNationalityData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* GetAllBloodGroup() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/HRM/all-bloodgroupsdrp',
        });
        const allBloodGroupData = data;
        yield put(Actions.setAllBloodGroup({ allBloodGroupData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* getAllGrnById() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/HRM/all-blood-groupsdrp',
        });
        const allGrnData = data;
        yield put(Actions.setAllGrnById({ allGrnData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* getAllLeaveTypes() {
    try {
        debugger
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/HRM/all-leavetypesdrp' });
        const AllLeaveTypesData = data;
        yield put(Actions.setAllLeaveTypes({ AllLeaveTypesData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* GetAllSaleQuotation() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/Sales/all-salequotationsdrp' ,
        });
        const allSaleQuotationData = data;
        yield put(Actions.setAllSaleQuotation({ allSaleQuotationData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function handleNotification(message) {
    if (message?.details?.response?.data?.errors.length > 0) {
        message.details.response.data.errors.map((value) => (
            notification('error', value)
        ));
    }
    else {
        notification('error', 'SERVE-POS API Issue.')
    }
}
export default function* rootSaga() {
    yield all([
        takeLatest(ActionTypes.UPLOAD_PROFILE_IMAGE, uploadProfileImage),
        takeLatest(ActionTypes.GET_ALL_STORES, getAllStores),
        takeLatest(ActionTypes.GET_ALL_WAREHOUSES, getAllWarehouses),
        takeLatest(ActionTypes.GET_ALL_CATEGORY, getAllCategory),
        takeLatest(ActionTypes.GET_ALL_SUBCATEGORY, getAllSubCategory),
        takeLatest(ActionTypes.GET_ALL_BRANDS, getAllBrands),
        takeLatest(ActionTypes.GET_ALL_UNITS, getAllUnits),
        takeLatest(ActionTypes.GET_ALL_USERS, getAllUsers),
        takeLatest(ActionTypes.GET_ALL_EXPENSE_CATEGORY, getAllExpenseCategory),
        takeLatest(ActionTypes.GET_ALL_VARIANTS, getAllVariants),
        takeLatest(ActionTypes.GET_ALL_SELLING_TYPES, getAllSellingTypes),
        takeLatest(ActionTypes.GET_ALL_BAR_CODE_SYMBOLOGIES, getAllbarcodesymbologies),
        takeLatest(ActionTypes.GET_ALL_TAX_TYPES, getAllTaxTypes),
        takeLatest(ActionTypes.GET_ALL_DISSCOUNT_TYPES, getAllDisscountTypes),
        takeLatest(ActionTypes.GET_ALL_CITY, getAllCities),
        takeLatest(ActionTypes.GET_ALL_STATE, getAllStates),
        takeLatest(ActionTypes.GET_ALL_COUNTRY, getAllCountries),
        takeLatest(ActionTypes.GET_ALL_CUSTOMER, getAllCustomer),
        takeLatest(ActionTypes.GET_ALL_QUOTATION, GetAllQuotation),
        takeLatest(ActionTypes.GET_ALL_SUPPLIER, getAllSuppliers),
        takeLatest(ActionTypes.GET_ALL_PRODUCTS_BY_SEARCH, getAllProductsBySearch),
        takeLatest(ActionTypes.GET_ALL_EMPLOYEE_DRP, GetAllEmployee),
        takeLatest(ActionTypes.GET_ALL_DESIGNATION_DRP, GetAllDesignation),
        takeLatest(ActionTypes.GET_ALL_DEPARTMENT_DRP, GetAllDepartment),
        takeLatest(ActionTypes.GET_ALL_GENDER_DRP, GetAllGender),
        takeLatest(ActionTypes.GET_ALL_WEEKOFFS_DRP, GetAllWeekoffs),
        takeLatest(ActionTypes.GET_ALL_NATIONALITIES_DRP, GetAllNationalities),
        takeLatest(ActionTypes.GET_ALL_BLOOD_GROUP_DRP, GetAllBloodGroup),
        takeLatest(ActionTypes.GET_ALL_SHIFTS_DRP, GetAllShift),
        takeLatest(ActionTypes.GET_ALL_STATES_BY_COUNTRY_ID, getAllStatesByCountryId),
        takeLatest(ActionTypes.GET_ALL_CITIES_BY_STATE_ID, getAllCitiesByStateId),
        takeLatest(ActionTypes.GET_ALL_LEAVE_TYPE_DRP, getAllLeaveTypes),
        takeLatest(ActionTypes.GET_ALL_SALE_QUOTATION, GetAllSaleQuotation),
         // Assuming setLoader is a saga function
    ]);
}