export const ActionTypes = {
    ADD_UPDATE_EXPENSE: 'services/financeAccounts/expenses/add_update_expense',
    FETCH_EXPENSE: 'services/financeAccounts/expenses/fetch_expenses',
    SET_EXPENSES: 'services/financeAccounts/expenses/set_expenses',
    DELETE_EXPENSES: 'services/financeAccounts/expenses/delete_expenses',
    GET_EXPENSE_BY_ID: 'services/financeAccounts/expenses/get_expense_by_id',
    SET_EXPENSE_BY_ID: 'services/financeAccounts/expenses/set_expense_by_id',
    SET_LOADER: 'services/people/financeAccounts/set_loader',
  }
  
  const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
  })
  
  const fetchAllExpenses = ({ expenseParams }) => ({
    expenseParams,
    type: ActionTypes.FETCH_EXPENSE,
  })
  
  const setExpenses = ({ allExpenseData }) => ({
    allExpenseData,
    type: ActionTypes.SET_EXPENSES,
  })
  
  const deleteExpenseById = ({ expenseParam }) => ({
    expenseParam,
    type: ActionTypes.DELETE_EXPENSES,
  })
  
  const getExpenseById = ({ expenseParam }) => ({
    expenseParam,
    type: ActionTypes.GET_EXPENSE_BY_ID,
  })
  
  const setExpenseById = ({ expenseParam }) => ({
    expenseParam,
    type: ActionTypes.SET_EXPENSE_BY_ID,
  })
  
  const addUpdateExpenses = ({ expenseData, actionName, UserParams }) => ({
    expenseData,
    actionName,
    UserParams,
    type: ActionTypes.ADD_UPDATE_EXPENSE,
  })
  
  export default {
    addUpdateExpenses,
    fetchAllExpenses,
    setExpenses,
    deleteExpenseById,
    getExpenseById,
    setExpenseById,
    setLoader
  }
  