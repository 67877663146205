import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDebounce } from 'use-debounce';
import { useDispatch, useSelector } from "react-redux";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Spin } from "antd";
import jsPDF from "jspdf";
import moment from "moment";
import * as XLSX from 'xlsx';
import {
  ChevronUp,
  PlusCircle,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import departmentActions from '../../../core/Services/hrm/department/actions';
import * as departmentSelectors from '../../commonSelectors';
import { setToogleHeader } from "../../../core/redux/action";
import Table from "../../../core/pagination/datatable";
import AddPurchaseInvoice from "../../../feature-module/purchases/invoice/addpurchaseinvoice";
import apiResponseAction from '../../../core/Services/apiResponse/actions';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import AddUpdateDepartmentModal from "./addUpdateDepartmentModal";


const DepartmentList = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [isDepartmentModalOpen, setDepartmentModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [departmentParams, setDepartmentInvoiceParams] = useState({
    pageNo: 1,
    searchText: '',
  });

  const [debouncedText] = useDebounce(departmentParams, 1000);

  const isLoading = useSelector(state => departmentSelectors.setLoader(state));
  const allDepartmentsData = useSelector(state => departmentSelectors.setAllDepartment(state))
  const departmentsdata = allDepartmentsData ? allDepartmentsData.toJS() : [];
  const data = useSelector((state) => state.toggle_header);

  useEffect(() => {
    dispatch(departmentActions.fetchAllDepartments({ departmentParams: debouncedText }));
  }, [dispatch]);

  const filteredData = Array.isArray(departmentsdata) 
  ? departmentsdata.filter((entry) => {
      return Object.keys(entry).some((key) => {
        return String(entry[key])
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
    })
  : [];
  
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = [['Department Id', 'Company Id', 'Department Name', 'Department SubTitle']];

    const tableData = departmentsdata?.map((item, index) => [item.id, item.companyId, item.name, item.subTitle]);

    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    doc.save('Invoices.pdf');
  };

  const handleDataIntoExcel = (filename = 'departments.xlsx') => {
    if (departmentsdata && departmentsdata.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(departmentsdata);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };

  const handleRefresh = () => {
    dispatch(departmentActions.fetchAllDepartments({ departmentParams }));
  }

  const toggleDepartmentModal = (value) => {
    setDepartmentModalOpen();
  }
  
  const columns = [
    {
      title: "Department Id",
      dataIndex: "id",
    },
    {
      title: "Department Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },

    {
      title: "Subtitle",
      dataIndex: "subTitle",
      sorter: (a, b) => a.subTitle.length - b.subTitle.length,
    },
    {
      title:<IntlMessages id="table.column.actions" />,
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => (
        <div className="action-table-data">
          <div className="edit-delete-action">

            <Link
              className="me-2 p-2"
              onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
              data-bs-target="#edit-units"
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link className="confirm-text p-2">
              <i
                data-feather="trash-2"
                className="feather-trash-2"
                onClick={() => showConfirmationAlert(row)}
              ></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (row) => {
      MySwal.fire({
          title: 'Are you sure?',
          text: 'You won\'t be able to revert this!',
          showCancelButton: true,
          confirmButtonColor: '#00ff00',
          confirmButtonText: 'Yes, delete it!',
          cancelButtonColor: '#ff0000',
          cancelButtonText: 'Cancel',
      }).then((result) => {
          if (result.isConfirmed) {
            dispatch(departmentActions.deleteDepartment({ departmentParam: row }));
          } else {
              MySwal.close();
          }

      });
  };

  const handleModal = (departmentData, actionName) => {
    const departmentModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? departmentData : {},
    };
    setCurrentModalData(departmentModalObj);
    setDepartmentModalOpen(true);
  };

  return (
    <div>
      <div className="page-wrapper">
        {isLoading ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4><IntlMessages id="purchaseinvoice.table.heading.purchaseinvoice" /></h4>
                <h6><IntlMessages id="purchaseinvoice.table.label.managepurchaseinvoice" /></h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link onClick={() => handleGeneratePDF()}>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" onClick={handleRefresh} data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="d-flex purchase-pg-btn">
              <div className="page-btn">
                <Link
                  className="btn btn-added"
                  onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                >
                  <PlusCircle className="me-2" />
                  Add New Department
                </Link>
              </div>
            </div>
          </div>
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
              <div className="search-set">
                              <div className="search-input">
                                <Link to="#" className="btn btn-searchset">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-search"
                                  >
                                    <circle cx={11} cy={11} r={8} />
                                    <line
                                      x1={21}
                                      y1={21}
                                      x2="16.65"
                                      y2="16.65"
                                    />
                                  </svg>
                                </Link>
                                <div
                                  id="DataTables_Table_0_filter"
                                  className="dataTables_filter"
                                >
                                  <label>
                                    {" "}
                                    <input
                                      type="search"
                                      className="form-control form-control-sm"
                                      placeholder="Search"
                                      aria-controls="DataTables_Table_0"
                                      value={searchText}
                                      onChange={handleSearch}
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>
              </div>
              {isDepartmentModalOpen ? (
              <AddUpdateDepartmentModal
                currentModalData={currentModalData}
                isModalOpen={isDepartmentModalOpen}
                isUpdate={currentModalData.formData.id ? true : false}
                toggleModal={toggleDepartmentModal}
                departmentParams={departmentParams}
              />
            ) : null}
              <div className="table-responsive product-list">
                <Table columns={columns} dataSource={filteredData} />
              </div>
            </div>
          </div>
        </div>
       </div>
    </div>
  );
};

export default DepartmentList;
