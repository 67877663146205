import React, { useState, useRef, useEffect, useMemo } from 'react';
import { DatePicker, Modal, Form, Input, Select, Checkbox } from "antd";
import TextEditor from "../../inventory/texteditor";
import { useDispatch, useSelector } from "react-redux";
import { Calendar, Check, PlusCircle } from "feather-icons-react/build/IconComponents";
import { Link } from "react-router-dom";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import CustomFormLabel from "../../common/CustomFormLabel";
import purchaseActions from '../../../core/Services/purchases/purchase/actions';
import commonAction from "../../../core/Services/common/actions";
import * as purchasesSelector from '../purchasesSelector';
import * as commonSelector from '../../commonSelectors';
import moment from 'moment';

const AddPaymentVoucharModal = (props) => {
  const { currentModalData, isModalOpen, isUpdate, toggleModal, purchaseParams } = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const allQuotationData = useSelector(state => commonSelector.setAllQuotation(state));
  const quotationNos = allQuotationData ? allQuotationData.toJS() : [];

  const SupplierData = useSelector(state => commonSelector.setAllSuppliers(state));
  const allSupplierData = SupplierData ? SupplierData.toJS() : [];

  const quotationNoData = useSelector(state => purchasesSelector.SetQuotationByQNById(state));
  const quotation = quotationNoData ? quotationNoData.toJS() : [];

  useEffect(() => {
    const value = form.getFieldValue("quotationId");
    if (quotation && value) {
      form.setFieldValue({
        supplierId: quotation.supplierId,
        purchaseDate: moment(quotation.quotationDate).format('YYYY-MM-DD'),
        otherTax: quotation.otherTax,
        disscount: quotation.disscount,
        shippingCost: quotation.shippingCost,
        notesHtml: quotation.quotationNotes
      });
    }
  }, [quotation])

  useEffect(() => {
    dispatch(commonAction.getAllQuotation());
    dispatch(commonAction.getAllSuppliers());
  }, [])

  const handleDateChange = (date) => {
    setSelectedDate(date);
    form.setFieldsValue({ purchaseDate: date ? moment(date).format('YYYY-MM-DD') : null });
  };

  const handleRecord = (actionName, purchaseData) => {

    if (actionName === CONSTANT_VARIABLES.UPDATE) {
      purchaseData.id = currentModalData.formData?.id;
      purchaseData.companyId = currentModalData.formData?.companyId;
    } else {
      purchaseData.companyId = 1;
      purchaseData.purchaseDate = selectedDate;
    }
    dispatch(purchaseActions.addUpdatePurchase({ purchaseData, actionName, purchaseParams: purchaseParams }));
    toggleModal();
  };

  const handleChangeQuotation = (e) => {
    dispatch(purchaseActions.GetQuotationByQNById({ QnParam: e.value }));
  }

  return (
    <Modal
      open={isModalOpen}
      width={1140}
      zIndex={99999}
      onClose={() => toggleModal(true)}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
          })
          .catch(info => {
            console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
          });
      }}
      onCancel={() => toggleModal(true)}
      title={
        isUpdate ? (
          <IntlMessages id="purchases.purchaseinvoice.form.modal.title.update" />
        ) : (
          <IntlMessages id="purchases.purchaseinvoice.form.modal.title.add" />
        )
      }
      okText={
        isUpdate ? (
          <IntlMessages id="purchases.purchase.form.modal.update" />
        ) : (
          <IntlMessages id="purchases.purchase.form.modal.add" />
        )
      }
      cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          quotationId: currentModalData.formData?.quotationId,
          supplierId: currentModalData.formData?.supplierId,
          purchaseDate: selectedDate,
          referenceNo: currentModalData.formData?.referenceNo,
          otherTax: currentModalData.formData?.otherTax,
          disscount: currentModalData.formData?.disscount,
          shippingCost: currentModalData.formData?.shippingCost,
        }}
      >
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="input-blocks add-product">
              <div className="row">
                <div className="col-lg-10 col-sm-10 col-10">
                  <Form.Item
                    name="grn"
                    label={
                      <CustomFormLabel
                        labelkey="Select Bill"
                        popoverkey="Select Bill"
                      />
                    }
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="purchase.purchase.modal.validation.product.name" />,
                      },
                    ]}
                  >

                    <Select
                      options={quotationNos.map(f => ({ label: f.name, value: f.id }))}
                      classNamePrefix="react-select"
                      placeholder="Choose"
                      onChange={handleChangeQuotation}
                    />
                  </Form.Item>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="input-blocks">
              <div className="input-groupicon calender-input">
                <Form.Item
                  name="billdate"
                  label={
                    <CustomFormLabel
                      labelkey="Bill Date"
                      popoverkey="Bill Date"
                    />
                  }
                >
                  <Calendar className="info-img" />
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="YYYY-MM-DD"
                    placeholderText="Select Date"
                    className="actions_addModalFormItem"
                  />
                </Form.Item>

              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="input-blocks">
              <div className="input-groupicon calender-input">
                <Form.Item
                  name="billduedate"
                  label={
                    <CustomFormLabel
                      labelkey="Bill Due Date"
                      popoverkey="Bill Due Date"
                    />
                  }
                >
                  <Calendar className="info-img" />
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="YYYY-MM-DD"
                    placeholderText="Select Date"
                    className="actions_addModalFormItem"
                  />
                </Form.Item>

              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="input-blocks">
              <div className="input-groupicon calender-input">
              <Form.Item
                    name="supplierdono"
                    label={
                      <CustomFormLabel
                        labelkey="Full Payment"
                        popoverkey="Full Payment"
                      />
                    }
                  >
                    <Checkbox type='checked'></Checkbox>
                  </Form.Item>

              </div>
            </div>
          </div>

         
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="modal-body-table">
              <div className="table-responsive">
                <table className="table  datanew">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Qty</th>
                      <th>Discount($)</th>
                      <th>Tax(%)</th>
                      <th>Tax Amount($)</th>
                      <th>Unit Price($)</th>
                      <th>Total Cost(%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quotation.quotationDetails?.map((item, index) => (
                      <tr key={index}>
                        <td className="p-5">{item.product}</td>
                        <td className="p-5">{item.quantity}</td>
                        <td className="p-5">{item.disscount}</td>
                        <td className="p-5">{item.taxPercentage}</td>
                        <td className="p-5">{item.taxAmount}</td>
                        <td className="p-5">{item.unitCost}</td>
                        <td className="p-5">{item.totalCost}</td>
                      </tr>
                    ))}
                     <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Total Cost:</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Other Tax:</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Discount:</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Grand Total:</td>
                      <td>0</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
         
        </div>

        <div className="col-lg-12">
          <div className="input-blocks summer-description-box">
            <Form.Item
              name="notesHtml"
              label={
                <CustomFormLabel
                  labelkey="purchases.purchase.form.purchase.label.notes"
                  popoverkey="purchase.purchase.form.label.popover.purchase.note"
                />
              }
            >
              <TextEditor />
            </Form.Item>

          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AddPaymentVoucharModal;
