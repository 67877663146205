import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import IntlMessages from "../../shared/components/utility/intlMessages";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { useDebounce } from 'use-debounce';
import { all_routes } from "../../Router/all_routes";
import Table from "../../core/pagination/datatable";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {
  ChevronUp,
  Filter,
  PlusCircle,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import { Spin } from 'antd';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import financeAccountsAction from "../../core/Services/financeAccounts/expensesCategory/actions";
import apiResponseAction from '../../core/Services/apiResponse/actions';
import * as financeAccountsSelectors from './FinanceAccountsSelectors';
import * as ApiResponseSelectors from '../selectors';
import { CONSTANT_VARIABLES } from "../../core/Common/Constant_Variables/constants";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import AddExpenseCategoryModal from "./expensecategory";

const ExpenseCategoryList = () => {

  const dispatch = useDispatch();
  const [expCategoryParams, setExpCategoryParams] = useState({
    pageNo: 1,
    searchText: '',
  });
  const [isExpenseCategoryModalOpen, setExpenseCategoryModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [debouncedText] = useDebounce(expCategoryParams, 1000);

  const isLoading = useSelector(state => financeAccountsSelectors.setLoader(state));
  const expCategoryData = useSelector(state => financeAccountsSelectors.setExpCategories(state));
  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
  const dataSource = expCategoryData ? expCategoryData.toJS() : [];

  useEffect(() => {
    if (apiResponseStatus === 200) {
      toggleModal();
    }
  }, [apiResponseStatus]);

  useEffect(() => {
    dispatch(financeAccountsAction.fetchAllExpCategories({ expCategoryParams: debouncedText }));
  }, [dispatch, debouncedText, expCategoryParams]);

  const toggleModal = (value) => {
    setExpenseCategoryModalOpen(!isExpenseCategoryModalOpen);
  };

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>Pdf</Tooltip>
);

const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>Excel</Tooltip>
);

const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>Printer</Tooltip>
);

const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>Refresh</Tooltip>
);

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (row) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
       dispatch(financeAccountsAction.deleteExpCategoryById({ expCategoryParam: row }))
      } else {
        MySwal.close();
      }
    });
  };
  const columns = [
    {
      title:<IntlMessages id="expensecategory.table.column.categoryName"/>,
      dataIndex: "expenseName",
      sorter: (a, b) => a.categoryName.length - b.categoryName.length,
    },
    {
      title: <IntlMessages id="expensecategory.table.column.description"/>,
      dataIndex: "expenseDescription",
      sorter: (a, b) => a.description.length - b.description.length,
    },

    {
      title:<IntlMessages id="expensecategory.table.button.adnewstore"/>,
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => (
        <div className="action-table-data">
            <div className="edit-delete-action">
                <Link
                    className="me-2 p-2"
                    onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
                >
                    <i data-feather="edit" className="feather-edit"></i>
                </Link>
                <Link
                    className="confirm-text p-2"
                    onClick={() => showConfirmationAlert(row)}
                >
                    <i data-feather="trash-2" className="feather-trash-2"></i>
                </Link>
            </div>
        </div>
    ),
    },
  ];
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const [searchText, setSearchText] = useState("");
 
  const route = all_routes;
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const handleDataIntoExcel = (filename = 'ExpenseCategory.xlsx') => {
    if (dataSource && dataSource.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(dataSource);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = ['id', 'unitName', 'unitShortName', 'isActive'];

    const tableData = dataSource.map((item) => [
      item.id,
      item.unitName,
      item.unitShortName,
      item.isActive
    ]);

    doc.autoTable({
      startY: 30,
      head: [headers],
      body: tableData,
    });

    doc.save('ExpenseCategory.pdf');
  };

  const handleRefresh = () => {
    dispatch(financeAccountsAction.fetchAllExpCategories({ expCategoryParams }));
  };

  const handleModal = (expCategoryData, actionName) => {
    const expCategoryModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? expCategoryData : {},
    };
    const status = 0;
    dispatch(apiResponseAction.setApiResponse({ apiStatus: status }));
    setCurrentModalData(expCategoryModalObj);
    setExpenseCategoryModalOpen(true);
  };

  return (
    <>
      <div>
        <div className="page-wrapper">
          <div className="content">
          {isLoading ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}
            <div className="page-header">
              <div className="add-item d-flex">
                <div className="page-title">
                  <h4><IntlMessages id="expensecategory.table.heading.expensecategory"/></h4>
                  <h6><IntlMessages id="expensecategory.table.label.manageexpensecategory"/></h6>
                </div>
              </div>
              <ul className="table-top-head">
                <li>
                  <OverlayTrigger placement="top" overlay={renderTooltip}>
                    <Link
                      onClick={() => handleGeneratePDF()}
                    >
                      <ImageWithBasePath
                        src="assets/img/icons/pdf.svg"
                        alt="img"
                      />
                    </Link>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger placement="top" overlay={ renderExcelTooltip}>
                    <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                      <ImageWithBasePath
                        src="assets/img/icons/excel.svg"
                        alt="img"
                      />
                    </Link>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                    <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={handleRefresh}>
                      <RotateCcw />
                    </Link>
                  </OverlayTrigger>
                </li>
              </ul>
              <div className="page-btn">
                <a
                  onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                  className="btn btn-added"
                >
                  <PlusCircle className="me-2" />
                  <IntlMessages id="expensecategory.table.button.adnewstore"/>
                </a>
              </div>
            </div>
            {/* /product list */}
            <div className="card table-list-card">
              <div className="card-body">
                <div className="table-top">
                  <div className="search-set">
                    <div className="search-input">
                      <input
                        type="text"
                        placeholder="Search"
                        className="form-control form-control-sm formsearch"
                        aria-controls="DataTables_Table_0"
                        value={searchText}
                        onChange={handleSearch}
                      />
                      <Link to className="btn btn-searchset">
                        <i data-feather="search" className="feather-search" />
                      </Link>
                    </div>
                  </div>
                </div>
                {/* /Filter */}
               
                {isExpenseCategoryModalOpen ? (
              <AddExpenseCategoryModal
                currentModalData={currentModalData}
                isModalOpen={isExpenseCategoryModalOpen}
                isUpdate={currentModalData.formData.id ? true : false}
                toggleModal={toggleModal}
              />
            ) : null}
                {/* /Filter */}
                <div className="table-responsive">
                  <Table columns={columns} dataSource={dataSource} />
                </div>
              </div>
            </div>
            {/* /product list */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpenseCategoryList;
