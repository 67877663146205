import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../suppliers/actions';
import notification from "../../../../shared/components/Notification";
import {CONSTANT_VARIABLES} from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';


function* uploadProfileImage ({supplierImage}){
    try {
      const { data } = yield call(api.supplierUploadImage, {
        route: `/Storage/upload`,
        data: supplierImage,
      });
      supplierData.imageUrl = data;
      notification('success', CONSTANT_VARIABLES.UPLOAD_PROFILE_IMAGE_SUCCESSFULLY);
    } catch (error) {
      notificationArray(error);
      console.log(error);
    }
  }
  
  function* addUpdateSuppliers({supplierData, actionName, SupplierParams}){

    try {
      if (supplierData.supplierImage) {
        const file = supplierData.supplierImage.originFileObj;
        const formData = new FormData();
        formData.append('file', file); // Use 'file' instead of 'File'
  
        const { data } = yield call(api.UploadImage, {
          route: `/Storage/upload`,
          data: formData,
        });
        const imageUrl = data.toJS();
        delete supplierData.supplierImage;
  
        if (imageUrl) {
          supplierData.profileImageUrl = imageUrl.url;
        }
      }

      if(actionName === 'update') {
        yield call(api.put, {
          route: `/Setup/edit-supplier`,
          data: supplierData,
        });
      } else {
        yield call(api.post, {
          route: `/Setup/create-supplier`,
          data: supplierData,
        });
      }
      
      yield put(Actions.fetchAllSuppliers({ SupplierParams }));
      const apiStatus = 200;
      yield put(apiActions.setApiResponse({apiStatus}));
      notification('success', actionName === 'update' ? CONSTANT_VARIABLES.SUPPLIER_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.SUPPLIER_CREATED_SUCCESSFULLY);
    } catch (error) {
      const apiStatus = error.details.response.status;
      yield put(apiActions.setApiResponse({apiStatus}));
      handleNotification(error);
      console.log(error);
    }
  }
  
  function* fetchAllSuppliers({ supplierParams }) {
    try {
      // Show loader before starting the API call
      yield put(Actions.setLoader({ showLoader: true }));
  
      // Make the API call to fetch suppliers
      const { data } = yield call(api.get, {
        route: `/Setup/all-suppliers`,
        params: supplierParams,
      });
  
      // Store the fetched data in Redux store
      const allSupplierData = data;
      yield put(Actions.setSuppliers({ allSupplierData }));
      yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
      // Handle notification for the error
      handleNotification(error);
  
      // Log the error for debugging purposes
      console.log(error);
    } finally {
      // Hide loader after data is fetched or if an error occurs
      yield put(Actions.setLoader({ showLoader: false }));
    }
  }
  
  function* deleteSupplierById({supplierParam}){
    try {
      yield put(Actions.setLoader({ showLoader: true }));
      yield call(api.delete, {
        route: `/Setup/delete-supplier/` + supplierParam.id,
      });
      yield put(Actions.fetchAllSuppliers({supplierParam}));
      // yield put(Actions.setLoader({ showLoader: false }));
      notification('success', CONSTANT_VARIABLES.SUPPLIER_DELETED_SUCCESSFULLY);
    } catch (error) {
      yield put(Actions.setLoader({ showLoader: false }));
      handleNotification(error);
      console.log(error);
    }
  }

  function handleNotification(message) {
    if (message.details.response.data.errors.length > 0)
    {
      message.details.response.data.errors.map((value) => (
        notification('error',value)
      ));
    }
    else{
      notification('error','SERVE-POS API Issue.')
    }
   }

   export default function* rootSaga() {
    yield all([
      takeLatest(ActionTypes.FETCH_SUPPLIERS, fetchAllSuppliers),
      takeLatest(ActionTypes.UPLOAD_PROFILE_IMAGE, uploadProfileImage),
        takeLatest(ActionTypes.ADD_UPDATE_SUPPLIERS, addUpdateSuppliers),
        takeLatest(ActionTypes.DELETE_SUPPLIERS,deleteSupplierById),
    ])
 }