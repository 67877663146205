import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Select } from "antd";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import TextEditor from "../../inventory/texteditor";
import commonAction from "../../../core/Services/common/actions";
import * as commonSelector from '../../commonSelectors';
import IntlMessages from "../../../shared/components/utility/intlMessages";
import headActions from '../../../core/Services/purchases/heads/actions'
import assetActions from '../../../core/Services/purchases/assettypes/actions'
import * as PurchasesSelector from "../purchasesSelector";
import purchaseActions from "../../../core/Services/purchases/purchase/actions"
import purchaseRecAction from "../../../core/Services/purchases/purchaseReceive/actions"

const ReceivePurchases = (props) => {
  const { editModalProps, isModalOpen, toggleModal, isUpdate } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const allPurchaseData = useSelector(state => PurchasesSelector.setPurchases(state));
  const allAssettypeData = useSelector(state => PurchasesSelector.setAssetTypes(state));
  const allHeadData = useSelector(state => PurchasesSelector.setHeads(state));
  const SupplierData = useSelector(state => commonSelector.setAllSuppliers(state));
  const warehouseData = useSelector(state => commonSelector.setAllWarehouses(state));
  const purchaseData = allPurchaseData ? allPurchaseData.toJS() : [];
  const allWarehouses = warehouseData ? warehouseData.toJS() : [];
  const allSupplierData = SupplierData ? SupplierData.toJS() : [];
  const allAssetData = allAssettypeData ? allAssettypeData.toJS() : [];
  const headData = allHeadData ? allHeadData.toJS() : [];
  const [selectedDate, setSelectedDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [selectedPO, setSelectedPO] = useState(null);
  const [editorData, setEditorData] = useState();

  useEffect(() => {
    const purchaseParams = {
      pageNumber: 1,
      pageSize: 10,
    }
    const headParams = {
      version: 1
    }

    dispatch(commonAction.getAllSuppliers());
    dispatch(commonAction.getAllWarehouses());
    dispatch(purchaseActions.fetchAllPurchases(purchaseParams));
    dispatch(headActions.fetchAllHeads(headParams));
    dispatch(assetActions.fetchAllAssetTypes(purchaseParams));
  }, [])

  useEffect(() => {
    if (isUpdate && editModalProps) {
      const poData = purchaseData.filter(e => e.purchaseOrderNo === editModalProps.poNo);
      setSelectedPO(poData[0])
      editModalProps.purchaseNotes ? setEditorData(editModalProps.purchaseNotes) : setEditorData("");
      if (form) {
        form.setFieldValue("recDate", editModalProps.receivedDate);
      }
    }
  }, [isUpdate, editModalProps]);

  useEffect(() => {
    if (selectedPO) {
      const poData = purchaseData.filter(e => e.purchaseOrderNo === editModalProps.poNo);
      setSelectedPO(poData[0])
    }
  }, [allPurchaseData])

  const handleRecord = (actionName, PurchaseReceiveData) => {
    const purchaseData = {
      id: editModalProps ? editModalProps.id : 0,
      companyId: editModalProps ? editModalProps.companyId : 1,
      purchaseId: editModalProps ? editModalProps.purchaseId : PurchaseReceiveData.ponum,
      supplierId: PurchaseReceiveData.supplierId,
      warehouseId: PurchaseReceiveData.warehouse,
      receivingDate: PurchaseReceiveData.recDate ? PurchaseReceiveData.recDate : moment(new Date()).format('YYYY-MM-DD'),
      referenceNo: PurchaseReceiveData.reference,
      otherTax: selectedPO.otherTax,
      disscount: selectedPO.disscount,
      shippingCost: selectedPO.shippingCost,
      statusId: selectedPO.statusId,
      assetTypeId: PurchaseReceiveData.asset,
      headId: PurchaseReceiveData.head,
      notesHtml: editorData,
      paid: 0,
      due: 0,
      grandTotal: 0,
      recievedDetails: [
        {
          id: 0,
          purchaseRecId: selectedPO.id,
          productId: selectedPO.purchaseDetails[0].productId,
          quantity: selectedPO.purchaseDetails[0].quantity,
          purchasePrice: selectedPO.purchaseDetails[0].purchasePrice,
          disscount: selectedPO.purchaseDetails[0].disscount,
          taxPercentage: selectedPO.purchaseDetails[0].taxPercentage,
          taxAmount: selectedPO.purchaseDetails[0].taxAmount,
          unitCost: selectedPO.purchaseDetails[0].unitCost,
          totalCost: selectedPO.purchaseDetails[0].totalCost
        }
      ]
    };

    const purchaseReceiveParams = {
      pageNumber: 1,
      pageSize: 10,
    }

    dispatch(purchaseRecAction.addUpdatePurchaseReceive({ purchaseReceiveData: purchaseData, actionName: actionName, UserParams: purchaseReceiveParams }));

    toggleModal();
  };

  const handlePoChange = (value) => {
    const poData = purchaseData.filter(e => e.id === value);
    setSelectedPO(poData[0])
    form.setFieldsValue({
      ponum: value,
    });
    
    const purchaseReceiveParams = {
      pageNumber: 1,
      pageSize: 10,
    }
    dispatch(purchaseRecAction.fetchAllPurchaseReceives({ purchaseReceiveParams }));
  }

  const handleSupplierChange = (value) => {
    form.setFieldValue("supplier", value.value);
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
    form.setFieldsValue({ recDate: date ? moment(date).format('YYYY-MM-DD') : null });
  };

  const handleRefChange = event => {
    form.setFieldsValue({
      reference: event.target.value,
    });
  }

  const handleAssetChange = (value) => {
    form.setFieldValue("asset", value);
  }

  const handleHeadChange = (value) => {
    form.setFieldValue("head", value);
  }

  const handleWarehouseChange = value => {
    form.setFieldValue("warehouse", value)
  }

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    let stringifyData = `${data}`
    setEditorData(stringifyData);
  };

  return (
    <div>
      <Modal
        open={isModalOpen}
        width={1140}
        zIndex={99999}
        onClose={() => toggleModal(true)}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
            })
            .catch(info => {
              console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
            });
        }}
        onCancel={() => toggleModal(true)}
        title={
          isUpdate ? (
            <IntlMessages id="purchases.purchaseinvoice.form.modal.title.update" />
          ) : (
            <IntlMessages id="purchases.purchaseinvoice.form.modal.title.add" />
          )
        }
        okText={
          isUpdate ? (
            <IntlMessages id="purchases.purchase.form.modal.update" />
          ) : (
            <IntlMessages id="purchases.purchase.form.modal.add" />
          )
        }
        cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
        maskClosable={false}
      >
        <Form
          form={form}
          layout="vertical"
          name="grnForm"
          className="grnActions_addModalWrapper"
          initialValues={{
            ponum: editModalProps ? editModalProps.poNo : null,
            supplierId: editModalProps ? editModalProps.supplierId : null,
            recDate: editModalProps ? moment(editModalProps.receivedDate).format('YYYY-MM-DD') : null,
            reference: editModalProps ? editModalProps.reference : null,
            asset: editModalProps ? editModalProps.assetTypeId : null,
            head: editModalProps ? editModalProps.headId : null,
            warehouse: editModalProps ? editModalProps.headId : null,
            notes: editorData
          }}
        >
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <Form.Item
                name="ponum"
                label={
                  <IntlMessages id="purchasenotes.table.column.ponum" />
                }
              >
                <Select
                  options={purchaseData.map(f => ({ label: f.purchaseOrderNo, value: f.id }))}
                  classNamePrefix="react-select"
                  placeholder="Choose"
                  onChange={handlePoChange}
                />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <Form.Item
                name="supplierId"
                label={
                  <IntlMessages id="purchases.quotations.form.label.supplier.name" />
                }
              >
                <Select
                  options={allSupplierData.map(f => ({ label: f.name, value: f.id }))}
                  placeholder={"Choose"}
                  onChange={handleSupplierChange}
                />
              </Form.Item>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="input-blocks">
                <Form.Item
                  name="recDate"
                  label={
                    <IntlMessages id="purchasenotes.table.column.date" />
                  }
                >
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    className="actions_addModalFormItem"
                    dateFormat="YYYY-MM-DD"
                    placeholder="Choose Date"
                  />
                </Form.Item>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12">
              <Form.Item
                name="reference"
                label={
                  <IntlMessages id="purchasenotes.table.column.reference" />
                }
              >
                <input type="text" onChange={handleRefChange} className="form-control" />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <Form.Item
                name="asset"
                label={
                  <IntlMessages id="purchasenotes.table.column.assets" />
                }
              >
                <Select
                  options={allAssetData.map(f => ({ label: f.name, value: f.id }))}
                  classNamePrefix="react-select"
                  placeholder={"Choose"}
                  onChange={handleAssetChange}
                />
              </Form.Item>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <Form.Item
                name="head"
                label={
                  <IntlMessages id="purchasenotes.table.column.head" />
                }
              >
                <Select
                  options={headData.map(f => ({ label: f.name, value: f.id }))}
                  classNamePrefix="react-select"
                  placeholder="Choose"
                  onChange={handleHeadChange}
                />
              </Form.Item>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <Form.Item
                name="warehouse"
                label={
                  <IntlMessages id="warehouse.inventory.form.label.name" />
                }
              >
                <Select
                  options={allWarehouses.map(f => ({ label: f.name, value: f.id }))}
                  classNamePrefix="react-select"
                  placeholder="Choose"
                  onChange={handleWarehouseChange}
                />
              </Form.Item>
            </div>
          </div>
          <div className="row">

            <div className="col-lg-12">
              <div className="modal-body-table">
                <div className="table-responsive">
                  <table className="table  datanew">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Qty</th>
                        <th>Purchase Price($)</th>
                        <th>Discount($)</th>
                        <th>Tax(%)</th>
                        <th>Tax Amount($)</th>
                        <th>Unit Cost($)</th>
                        <th>Total Cost(%)</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedPO?.purchaseDetails.map((item, index) => (
                        <tr key={`${index}`}>
                          <td>{item.productName}</td>
                          <td>{item.quantity}</td>
                          <td>{item.purchasePrice}</td>
                          <td>{item.discount}</td>
                          <td>{item.taxPercentage}</td>
                          <td>{item.taxAmount}</td>
                          <td>{item.unitCost}</td>
                          <td>{item.totalCost}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>
          <div className="col-lg-12">
            <Form.Item
              name="notes"
              label={
                <IntlMessages id="purchases.quotations.form.label.notes" />
              }
            >
              <div id="summernote" />
              <TextEditor data={editorData} onChange={handleEditorChange} />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default ReceivePurchases;
