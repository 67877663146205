import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Switch, Select, Tabs, Button, TimePicker, Radio } from 'antd';
const { TabPane } = Tabs;
import { CONSTANT_VARIABLES } from '../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../shared/components/utility/intlMessages';
import { Info, Lock } from "feather-icons-react/build/IconComponents";
import employeeLeaveActions from '../../../core/Services/hrm/employeeleave/actions'
import CustomFormLabel from '../../common/CustomFormLabel';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import TextEditor from "../../inventory/texteditor";
import dayjs from 'dayjs';


const AddUpdateEmployeeLeaveModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, employeeleaveParams, employeeOptions, leaveTypeOptions } = props;

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [editorData, setEditorData] = useState();
    const [selectedFromDate, setSelectedFromDate] = useState(null);
    const [selectedToDate, setSelectedToDate] = useState(null);
    const [value, setValue] = useState(1);

    const handleRecord = (actionName, employeeleaveData) => {
        debugger
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            employeeleaveData.id = currentModalData.formData.id
            employeeleaveData.companyId = 1
            employeeleaveData.reason = editorData;
            employeeleaveData.isFullDay = value == 1 ? true : false;
        }
        else {
            employeeleaveData.companyId = 1
            employeeleaveData.reason = editorData;
            employeeleaveData.isFullDay = value == 1 ? true : false;
        }

        dispatch(employeeLeaveActions.addUpdateEmployeeleaves({ employeeleaveData, actionName, employeeleaveParams: employeeleaveParams }));
        toggleModal();
    };

    const handleEmployeeChange = (selectedEmployeeId) => {
        form.setFieldValue({ employeeId: selectedEmployeeId })
    }

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        let stringifyData = `${data}`
        setEditorData(stringifyData);
    };

    const handleFromDateChange = (date) => {
        setSelectedFromDate(date);
        form.setFieldsValue({ fromDate: date ? moment(date).format('YYYY-MM-DD') : null });
    };

    const handleToDateChange = (date) => {
        setSelectedToDate(date);
        form.setFieldsValue({ toDate: date ? moment(date).format('YYYY-MM-DD') : null });
    };

    const onLeaveValueChange = (e) => {
        setValue(e.target.value);
    }

    useEffect(() => {
        if (isUpdate && currentModalData) {
            let editorFetchedData = currentModalData.formData?.reason.replace(/""/g, '');
            setEditorData(editorFetchedData);
        }
    }, [isUpdate, currentModalData]);

    useEffect(() => {
        if (isUpdate && currentModalData && currentModalData.formData) {
            form.setFieldsValue({ fromDate: moment(currentModalData?.formData?.fromDate).format('YYYY-MM-DD'), })
            form.setFieldsValue({ toDate: moment(currentModalData?.formData?.toDate).format('YYYY-MM-DD'), })
        }
    }, [isUpdate])

    useEffect(() => {
        if (isUpdate && currentModalData && currentModalData.formData)
        {
            form.setFieldsValue({isFullDay: currentModalData.formData.isFullDay ? 1 : 2})
        }
    }, [isUpdate, currentModalData])

    return (
        <Modal
            open={isModalOpen}
            // width={500}
            zIndex={99999}
            onClose={() => toggleModal(true)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    "Update Leave"
                ) : (
                    "Apply Leave"
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="purchases.purchase.form.modal.update" />
                ) : (
                    <IntlMessages id="purchases.purchase.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    employeeId: currentModalData?.formData?.employeeId,
                    leaveTypeId: currentModalData?.formData?.leaveTypeId,
                }}
                style={{ marginTop: '50px' }}
            >
                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="employeeId"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="Employee"
                                                popoverkey="Employee Name"
                                            />
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Employee Name Required",
                                            },
                                        ]}
                                    >
                                        <Select
                                            classNamePrefix="react-select"
                                            options={employeeOptions.map(s => ({ label: s.name, value: s.id }))}
                                            placeholder="Choose"
                                            className="input-select"
                                            onChange={handleEmployeeChange}
                                        />
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="fromDate"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="From Date"
                                                popoverkey="From Date"
                                            />
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "From Date Required",
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            selected={selectedFromDate}
                                            onChange={handleFromDateChange}
                                            dateFormat="YYYY-MM-DD"
                                            placeholderText="Select Date"
                                            className="actions_addModalFormItem"
                                        />
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="toDate"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="To Date"
                                                popoverkey="To Date"
                                            />
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "To Date Required",
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            selected={selectedToDate}
                                            onChange={handleToDateChange}
                                            dateFormat="YYYY-MM-DD"
                                            placeholderText="Select Date"
                                            className="actions_addModalFormItem"
                                        />
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="leaveTypeId"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="Select Leave Type"
                                                popoverkey="Leave Type"
                                            />
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Leave Type Required",
                                            },
                                        ]}
                                    >
                                        <Select
                                            classNamePrefix="react-select"
                                            options={leaveTypeOptions.map(s => ({ label: s.name, value: s.id }))}
                                            placeholder="Choose"
                                            className="input-select"
                                            onChange={handleEmployeeChange}
                                        />
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="isFullDay"
                                        className="actions_addModalFormItem"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select",
                                            },
                                        ]}
                                    >
                                        <Radio.Group onChange={onLeaveValueChange} value={value} style={{display: 'flex', alignItems:'center'}}>
                                            <Radio value={1}>Full Day</Radio>
                                            <Radio value={2}>Half Day</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="input-blocks summer-description-box">
                        <Form.Item
                            name="reason"
                            label={
                                <CustomFormLabel
                                    labelkey="purchases.purchase.form.purchase.label.notes"
                                    popoverkey="purchase.purchase.form.label.popover.purchase.note"
                                />
                            }
                        >
                            <TextEditor data={editorData} onChange={handleEditorChange} />
                        </Form.Item>

                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default AddUpdateEmployeeLeaveModal;