export const roledata =[
    {
        "id": 1,
        "label": "Dashboard",
        "ischild": true,
        "children": [
            {
                "id": "1-1",
                "label": "Admin Dashboard",
                "ischild": false
            },
            {
                "id": "1-2",
                "label": "Sales Dashboard",
                "ischild": false
            }
        ]
    }
]