import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setWarehouses = (state, { allWarehousesData }) => {
    if (!allWarehousesData) return state;
    return state.merge({ allWarehousesData });
}

const setWarehouseById = (state, { warehouseParam }) => {
    return state.merge({ warehouseParam });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

const setUploadProfileImage = (state, { warehouseImage }) => {
    if (!warehouseImage) return state;
    return state.merge({ warehouseImage });
  };
export const callbacks = [
    {
        actionType: ActionTypes.SET_WAREHOUSES,
        actionFunction: setWarehouses,
    },
    {
        actionType: ActionTypes.SET_WAREHOUSE_BY_ID,
        actionFunction: setWarehouseById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
    {
        actionType: ActionTypes.SET_UPLOAD_PROFILE_IMAGE,
        actionFunction: setUploadProfileImage,
    },
];