import React, { useState, useRef, useEffect, Fragment } from 'react';
import * as inventorySelectors from '../InventorySelectors';
import categoryActions from '../../../core/Services/inventory/categories/actions.js';
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Modal } from 'antd';
import { Row, Col, Form, Input, Select, Upload, Image, Switch } from 'antd';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import CustomFormLabel from "../../common/CustomFormLabel";
import { PlusOutlined } from '@ant-design/icons';
import './categories.css';
import '../inventory.css';
import TextArea from 'antd/es/input/TextArea';
import * as ApiResponseSelectors from '../../selectors';
import { string } from 'prop-types';

const AddCategoryModal = (props) => {
  const { currentModalData, isModalOpen, isUpdate, toggleModal, categoryParams } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const imageRef = useRef(null);
  const [modal] = Modal.useModal();
  const subCategoryData = useSelector(state => inventorySelectors.setSubcategories(state));
  const DataSource = subCategoryData ? subCategoryData.toJS() : [];
  const [isError, setisError] = useState(false);
  const [state, setState] = useState({});
  const [category, setCategory] = useState('');
  const [slug, setSlug] = useState('');

  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));


  const handleRecord = (actionName, categoryData) => {
    if (actionName === CONSTANT_VARIABLES.UPDATE) {
      categoryData.id = currentModalData.formData.id;
      categoryData.companyId = currentModalData.formData.companyId;
    }
    categoryData.isActive = categoryData.isActive ? true : false;
    console.log(categoryData)
    dispatch(categoryActions.addUpdateCategories({ categoryData, actionName, CategoryParams: categoryParams }));
    toggleModal();
  };



  useEffect(() => {
    form.setFieldsValue({
      categorySlug: slugify(form.getFieldValue('categoryName')),
    });
  }, [form.getFieldValue('categoryName')]);

  const slugify = (text) => {
    if (!text) return '';
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  };

  const handleCategoryNameChange = (e) => {
    const categoryName = e.target.value;
    form.setFieldsValue({
      categorySlug: slugify(categoryName),
    });
  }

  return (
    <Modal
      open={isModalOpen}
      onClose={() => toggleModal(true)}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
          })
          .catch(info => {
            console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
          });
      }}
      onCancel={() => toggleModal(true)}
      title={
        isUpdate ? (
          <IntlMessages id="category.inventory.form.modal.title.update" />
        ) : (
          <IntlMessages id="category.inventory.form.modal.title.add" />
        )
      }
      okText={
        isUpdate ? (
          <IntlMessages id="category.inventory.form.modal.update" />
        ) : (
          <IntlMessages id="category.inventory.form.modal.add" />
        )
      }
      cancelText={<IntlMessages id="category.inventory.form.modal.cancelText" />}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        name="categoryForm"
        className='categoryActions_addModalWrapper'
        initialValues={{
          companyId: currentModalData.formData ? currentModalData.formData.companyId : 0,
          categoryName: currentModalData.formData.categoryName,
          categorySlug: currentModalData.formData.categorySlug,
          isActive: currentModalData.formData.isActive,
        }}
      >

        <div className='row'>

          <div className="col-lg-12">
            <div className="input-blocks">
              <Form.Item
                name="categoryName"
                className='actions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="category.inventory.form.category.label.name"
                    popoverkey="category.inventory.form.label.popover.category.name"
                  />
                }
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="category.inventory.modal.validation.name" />,
                  },
                ]}
              >

                <Input onChange={handleCategoryNameChange}/>

              </Form.Item>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="input-blocks">
              <Form.Item
                name="categorySlug"
                className='actions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="category.inventory.form.category.label.slug.name"
                    popoverkey="category.inventory.form.label.popover.slug.name"
                  />
                }
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="category.inventory.modal.validation.slug.name" />,
                  },
                ]}
              >
                <Input value={slug} readOnly/>
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-12">
            {/* <div className="input-blocks"> */}
              <div className="addCategory_form--status_area">
                <span className="status-label"> <IntlMessages id="category.inventory.modal.status" /></span>
                <Form.Item
                  name="isActive"
                  className='actions_addModalFormItem'
                  valuePropName="checked"
                >
                 <Switch size="small" />
                </Form.Item>
              {/* </div> */}
            </div>
          </div>
        </div>
      </Form>

    </Modal >
  )
}

export default AddCategoryModal