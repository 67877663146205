import React, { useEffect, useState, version } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ImageWithBasePath from '../../../core/img/imagewithbasebath';
import { Link } from 'react-router-dom';
import { Table } from 'antd'
import { ChevronUp, PlusCircle, RotateCcw } from 'feather-icons-react/build/IconComponents';
import { useDispatch, useSelector } from 'react-redux';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import AddDesignation from './adddesignation';
import jsPDF from "jspdf";
import * as XLSX from 'xlsx';
import { setToogleHeader } from '../../../core/redux/action';
import IntlMessages from "../../../shared/components/utility/intlMessages";
import DesignationActions from "../../../core/Services/hrm/designation/actions";
import * as DesignationSelector from "../hrmSelectors";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";

const Designation = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);
  const designationData = useSelector(state => DesignationSelector.setDesignations(state));
  const allDesignationData = designationData ? designationData.toJS() : [];
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [currentModalData, setCurrentModalData] = useState(null);

  useEffect(() => {
    dispatch(DesignationActions.fetchAllDesignations({}));
  }, [])

  useEffect(() => {
    console.log(allDesignationData)
  }, [designationData])

  const handleEditModal = (row, action) => {
    debugger;
    setCurrentModalData(row);
    setIsUpdate(true);
    setModalOpen(true);
  }

  const handleRefresh = () => {
    dispatch(DesignationActions.fetchAllDesignations({}));
  }

  const toggleModal = () => {
    const action = isModalOpen ? false : true;
    if (action === false) {
      setIsUpdate(false)
      setCurrentModalData(null)
    }
    setModalOpen(action);
  }

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const filteredData = Array.isArray(allDesignationData)
    ? allDesignationData.filter((entry) => {
      return Object.keys(entry).some((key) => {
        return String(entry[key])
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
    })
    : [];

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  
  const handleGeneratePDF = () => {
    debugger;
    const doc = new jsPDF();
    const headers = [['members', 'companyId', 'designation', 'isActive']];

    const tableData = allDesignationData?.map((item, index) => [item.members, item.companyId, item.designation, item.isActive]);

    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    doc.save('Designations.pdf');
  };

  const handleDataIntoExcel = (filename = 'Designations.xlsx') => {
    if (allDesignationData && allDesignationData.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(allDesignationData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  const MySwal = withReactContent(Swal);
  const showConfirmationAlert = (row) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      showCancelButton: true,
      confirmButtonColor: '#00ff00',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonColor: '#ff0000',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DesignationActions.deleteDesignations({ designationParam: row }));
      } else {
        MySwal.close();
      }
    });
  };

  const columns = [
    {
      title: <IntlMessages id="hrm.table.designation" />,
      dataIndex: "designation",
      sorter: (a, b) => a.designation.length - b.designation.length,
    },
    {
      title: <IntlMessages id="hrm.table.company" />,
      dataIndex: "company",
      sorter: (a, b) => a.company.length - b.company.length,
      render: (company) => (company ? company : "Serve POS"),
    },
    {
      title: <IntlMessages id="hrm.table.totalmembers" />,
      dataIndex: "totalMembers",
      sorter: (a, b) => a.totalMembers.length - b.totalMembers.length,
    },
    {
      title: <IntlMessages id="hrm.table.createdon" />,
      dataIndex: "createdOn",
      sorter: (a, b) => a.createdOn.length - b.createdOn.length,
    },
    {
      title: "Status",
      dataIndex: "isActive",
      sorter: (a, b) => a.isActive - b.isActive,
      render: (isActive) => (isActive === true ? "Active" : "Inactive"),
    },
    {
      title: <IntlMessages id="table.column.actions" />,
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="me-2 p-2"
              onClick={() => handleEditModal(row, CONSTANT_VARIABLES.UPDATE)}
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link className="confirm-text p-2">
              <i
                data-feather="trash-2"
                className="feather-trash-2"
                onClick={() => showConfirmationAlert(row)}
              ></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Designation</h4>
              <h6>Manage your designation</h6>
            </div>
          </div>
          <ul className="table-top-head">
            <li>
              <OverlayTrigger placement="top" overlay={renderTooltip}>
                <Link onClick={() => handleGeneratePDF()}>
                  <ImageWithBasePath src="assets/img/icons/pdf.svg" alt="img" />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                  <ImageWithBasePath src="assets/img/icons/excel.svg" alt="img" />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>

                <Link data-bs-toggle="tooltip" onClick={handleRefresh} data-bs-placement="top">
                  <RotateCcw />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                <Link
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  id="collapse-header"
                  className={data ? "active" : ""}
                  onClick={() => { dispatch(setToogleHeader(!data)) }}
                >
                  <ChevronUp />
                </Link>
              </OverlayTrigger>
            </li>
          </ul>
          <div className="page-btn">
            <Link
              to="#"
              className="btn btn-added"
              onClick={toggleModal}
            >
              <PlusCircle className="me-2" />
              Add New Designation
            </Link>
          </div>
        </div>

        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <Link to="#" className="btn btn-searchset">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-search"
                    >
                      <circle cx={11} cy={11} r={8} />
                      <line
                        x1={21}
                        y1={21}
                        x2="16.65"
                        y2="16.65"
                      />
                    </svg>
                  </Link>
                  <div
                    id="DataTables_Table_0_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      {" "}
                      <input
                        type="search"
                        className="form-control form-control-sm"
                        placeholder="Search"
                        aria-controls="DataTables_Table_0"
                        value={searchText}
                        onChange={handleSearch}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <Table columns={columns} dataSource={filteredData} />
          </div>
        </div>

      </div>
      {isModalOpen ?
        <AddDesignation
          currentModalData={currentModalData}
          isUpdate={isUpdate}
          toggleModal={toggleModal}
          allDesignationData={allDesignationData}
          isModalOpen={isModalOpen} />
        : null}
    </div>

  )
}

export default Designation
