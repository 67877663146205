export const ActionTypes = {

  UPLOAD_PROFILE_IMAGE: 'services/common/upload_profile_image',
  SET_UPLOAD_PROFILE_IMAGE: 'services/common/set_upload_profile_image',
  SET_LOADER: 'services/common/set_loader',
  GET_ALL_CATEGORY: 'services/common/get_all_category',
  SET_ALL_CATEGORY: 'services/common/set_all_category',
  GET_ALL_SUBCATEGORY: 'services/common/get_all_subcategory',
  SET_ALL_SUBCATEGORY: 'services/common/set_all_subcategory',
  GET_ALL_BRANDS: 'services/common/get_all_brands',
  SET_ALL_BRANDS: 'services/common/set_all_brands',
  GET_ALL_UNITS: 'services/common/get_all_units',
  SET_ALL_UNITS: 'services/common/set_all_units',
  GET_ALL_USERS: 'services/common/get_all_users',
  SET_ALL_USERS: 'services/common/set_all_users',
  GET_ALL_STORES: 'services/common/get_all_stores',
  SET_ALL_STORES: 'services/common/set_all_stores',
  GET_ALL_WAREHOUSES: 'services/common/get_all_warehouses',
  SET_ALL_WAREHOUSES: 'services/common/set_all_warehouses',
  GET_ALL_EXPENSE_CATEGORY: 'services/common/get_all_expense_category',
  SET_ALL_EXPENSE_CATEGORY: 'services/common/set_all_expense_category',
  GET_ALL_VARIANTS: 'services/common/get_all_variants',
  SET_ALL_VARIANTS: 'services/common/set_all_variants',
  GET_ALL_SELLING_TYPES: 'services/common/get_all_sellingTypes',
  SET_ALL_SELLING_TYPES: 'services/common/set_all_sellingTypes',
  GET_ALL_BAR_CODE_SYMBOLOGIES: 'services/common/get_all_bar_code_symbologies',
  SET_ALL_BAR_CODE_SYMBOLOGIES: 'services/common/set_all_bar_code_symbologies',
  GET_ALL_DISSCOUNT_TYPES: 'services/common/get_all_disscountTypes',
  SET_ALL_DISSCOUNT_TYPES: 'services/common/set_all_disscountTypes',
  GET_ALL_TAX_TYPES: 'services/common/get_all_taxTypes',
  SET_ALL_TAX_TYPES: 'services/common/set_all_taxTypes',
  GET_ALL_CITY: 'services/common/get_all_variants',
  GET_ALL_CITIES_BY_STATE_ID: 'services/common/get_all_cities_by_stateId',
  SET_ALL_CITY: 'services/common/set_all_variants',
  GET_ALL_STATE: 'services/common/get_all_variants',
  GET_ALL_STATES_BY_COUNTRY_ID: 'services/common/get_all_states_by_countryId',
  SET_ALL_STATE: 'services/common/set_all_variants',
  GET_ALL_COUNTRY: 'services/common/get_all_variants',
  SET_ALL_COUNTRY: 'services/common/set_all_variants',
  GET_ALL_SUPPLIER: 'services/common/get_all_suppliers',
  SET_ALL_SUPPLIER: 'services/common/set_all_suppliers',
  GET_ALL_CUSTOMER: 'services/common/get_all_customer',
  SET_ALL_CUSTOMER: 'services/common/set_all_customer',
  GET_ALL_QUOTATION: 'services/common/get_all_quotation',
  SET_ALL_QUOTATION: 'services/common/set_all_quotation',
  GET_ALL_PRODUCTS_BY_SEARCH: 'services/common/get_all_products_by_search',
  SET_ALL_PRODUCTS_BY_SEARCH: 'services/common/set_all_products_by_search',
  GET_ALL_EMPLOYEE_DRP: 'services/common/get_all_employee_drp',
  SET_ALL_EMPLOYEE_DRP: 'services/common/set_all_employee_drp',
  GET_ALL_DESIGNATION_DRP: 'services/common/get_all_designation_drp',
  SET_ALL_DESIGNATION_DRP: 'services/common/set_all_designation_drp',
  GET_ALL_DEPARTMENT_DRP: 'services/common/get_all_department_drp',
  SET_ALL_DEPARTMENT_DRP: 'services/common/set_all_department_drp',
  GET_ALL_GENDER_DRP: 'services/common/get_all_gender_drp',
  SET_ALL_GENDER_DRP: 'services/common/set_all_gender_drp',
  GET_ALL_WEEKOFFS_DRP: 'services/common/get_all_weekoffs_drp',
  SET_ALL_WEEKOFFS_DRP: 'services/common/set_all_weekoffs_drp',
  GET_ALL_NATIONALITIES_DRP: 'services/common/get_all_nationalities_drp',
  SET_ALL_NATIONALITIES_DRP: 'services/common/set_all_nationalities_drp',
  GET_ALL_BLOOD_GROUP_DRP: 'services/common/get_all_blood_group_drp',
  SET_ALL_BLOOD_GROUP_DRP: 'services/common/set_all_blood_group_drp',
  GET_ALL_SHIFTS_DRP: 'services/common/get_all_shifts_drp',
  SET_ALL_SHIFTS_DRP: 'services/common/set_all_shifts_drp',
  GET_ALL_GRN_BY_ID: 'services/common/get_all_grn_by_id',
  SET_ALL_GRN_BY_ID: 'services/common/set_all_grn_by_id',
  GET_ALL_LEAVE_TYPE_DRP: 'services/common/get_all_leave_type_drp',
  SET_ALL_LEAVE_TYPE_DRP: 'services/common/set_all_leave_type_drp',

  GET_ALL_SALE_QUOTATION: 'services/common/get_all_sale_quotation',
  SET_ALL_SALE_QUOTATION: 'services/common/set_all_sale_quotation',
}

const getAllLeaveTypes = () => ({
  type: ActionTypes.GET_ALL_LEAVE_TYPE_DRP
});

const setAllLeaveTypes = ({ AllLeaveTypesData }) => ({
  AllLeaveTypesData,
  type: ActionTypes.SET_ALL_LEAVE_TYPE_DRP
});

const uploadProfileImage = ({ userImage }) => ({
  userImage,
  type: ActionTypes.UPLOAD_PROFILE_IMAGE
});

const setUploadProfileImage = ({ uploadImageUrl }) => ({
  uploadImageUrl,
  type: ActionTypes.SET_UPLOAD_PROFILE_IMAGE
});

const getAllStores = () => ({
  type: ActionTypes.GET_ALL_STORES
});

const setAllStores = ({ allStoresData }) => ({
  allStoresData,
  type: ActionTypes.SET_ALL_STORES
});

const getAllWarehouses = () => ({
  type: ActionTypes.GET_ALL_WAREHOUSES
});

const setAllWarehouses = ({ allWarehousesData }) => ({
  allWarehousesData,
  type: ActionTypes.SET_ALL_WAREHOUSES
});

const getAllCategory = () => ({
  type: ActionTypes.GET_ALL_CATEGORY
});

const setAllCategory = ({ allCategoryData }) => ({
  allCategoryData,
  type: ActionTypes.SET_ALL_CATEGORY
});

const getAllSubCategory = ({ CategoryId }) => ({
  CategoryId,
  type: ActionTypes.GET_ALL_SUBCATEGORY
});

const setAllSubCategory = ({ allSubCategoryData }) => ({
  allSubCategoryData,
  type: ActionTypes.SET_ALL_SUBCATEGORY
});

const getAllBrands = () => ({
  type: ActionTypes.GET_ALL_BRANDS
});

const setAllBrands = ({ allBrandsData }) => ({
  allBrandsData,
  type: ActionTypes.SET_ALL_BRANDS
});

const getAllUnits = () => ({
  type: ActionTypes.GET_ALL_UNITS
});

const setAllUnits = ({ allUnitsData }) => ({
  allUnitsData,
  type: ActionTypes.SET_ALL_UNITS
});

const getAllUsers = () => ({
  type: ActionTypes.GET_ALL_USERS
});

const setAllUsers = ({ allUsersData }) => ({
  allUsersData,
  type: ActionTypes.SET_ALL_USERS
});

const getAllExpenseCategory = () => ({
  type: ActionTypes.GET_ALL_EXPENSE_CATEGORY
});

const setAllExpenseCategory = ({ allExpenseCategoryData }) => ({
  allExpenseCategoryData,
  type: ActionTypes.SET_ALL_EXPENSE_CATEGORY
});

const getAllVariants = () => ({
  type: ActionTypes.GET_ALL_VARIANTS
});

const setAllVariants = ({ allVariantsData }) => ({
  allVariantsData,
  type: ActionTypes.SET_ALL_VARIANTS
});

const getAllSellingTypes = () => ({
  type: ActionTypes.GET_ALL_SELLING_TYPES
});

const setAllSellingTypes = ({ allSellingTypesData }) => ({
  allSellingTypesData,
  type: ActionTypes.SET_ALL_SELLING_TYPES
});

const getAllbarcodesymbologies = () => ({
  type: ActionTypes.GET_ALL_BAR_CODE_SYMBOLOGIES
});

const setAllbarcodesymbologies = ({ allBarCodeSymbData }) => ({
  allBarCodeSymbData,
  type: ActionTypes.SET_ALL_BAR_CODE_SYMBOLOGIES
});

const setAllTaxTypes = ({ allTaxTypesData }) => ({
  allTaxTypesData,
  type: ActionTypes.SET_ALL_TAX_TYPES
});

const getAllTaxTypes = () => ({
  type: ActionTypes.GET_ALL_TAX_TYPES
});

const setAllDisscountTypes = ({ allDisscountTypesData }) => ({
  allDisscountTypesData,
  type: ActionTypes.SET_ALL_DISSCOUNT_TYPES
});

const getAllDisscountTypes = () => ({
  type: ActionTypes.GET_ALL_DISSCOUNT_TYPES
});

const getAllCities = () => ({
  type: ActionTypes.GET_ALL_CITY
});

const getAllCitiesByStateId = ({ cityParams }) => ({
  cityParams,
  type: ActionTypes.GET_ALL_CITIES_BY_STATE_ID
});

const setAllCities = ({ allCitiesData }) => ({
  allCitiesData,
  type: ActionTypes.SET_ALL_CITY
});

const getAllStates = () => ({
  type: ActionTypes.GET_ALL_STATE
});

const getAllStatesByCountryId = ({ stateParams }) => ({
  stateParams,
  type: ActionTypes.GET_ALL_STATES_BY_COUNTRY_ID
});

const setAllStates = ({ allStatesData }) => ({
  allStatesData,
  type: ActionTypes.SET_ALL_CITY
});

const getAllCountries = () => ({
  type: ActionTypes.GET_ALL_COUNTRY
});

const setAllCountries = ({ allStatesData }) => ({
  allStatesData,
  type: ActionTypes.SET_ALL_COUNTRY
});

const getAllSuppliers = () => ({
  type: ActionTypes.GET_ALL_SUPPLIER
});

const setAllSuppliers = ({ allSupplierData }) => ({
  allSupplierData,
  type: ActionTypes.SET_ALL_SUPPLIER
});

const getAllCustomer = () => ({
  type: ActionTypes.GET_ALL_CUSTOMER
});

const setAllCustomer = ({ allCustomerData }) => ({
  allCustomerData,
  type: ActionTypes.SET_ALL_CUSTOMER
});

const getAllQuotation = () => ({
  type: ActionTypes.GET_ALL_QUOTATION
});

const setAllQuotation = ({ allQuotationData }) => ({
  allQuotationData,
  type: ActionTypes.SET_ALL_QUOTATION
});

const getAllProductsBySearch = ({ productParams }) => ({
  productParams,
  type: ActionTypes.GET_ALL_PRODUCTS_BY_SEARCH
});

const setAllProductsBySearch = ({ allProductsDataBySearch }) => ({
  allProductsDataBySearch,
  type: ActionTypes.SET_ALL_PRODUCTS_BY_SEARCH
});

const setLoader = ({ showLoader }) => ({
  showLoader,
  type: ActionTypes.SET_LOADER,
});

const getAllEmployee = () => ({
  type: ActionTypes.GET_ALL_EMPLOYEE_DRP
});

const setAllEmployee = ({ allEmployeeData }) => ({
  allEmployeeData,
  type: ActionTypes.SET_ALL_EMPLOYEE_DRP
});

const getAllDesignation = () => ({
  type: ActionTypes.GET_ALL_DESIGNATION_DRP
});

const setAllDesignation = ({ allDesignationData }) => ({
  allDesignationData,
  type: ActionTypes.SET_ALL_DESIGNATION_DRP
});

const getAllDepartment = () => ({
  type: ActionTypes.GET_ALL_DEPARTMENT_DRP
});

const setAllDepartment = ({ allDepartmentData }) => ({
  allDepartmentData,
  type: ActionTypes.SET_ALL_DEPARTMENT_DRP
});

const getAllShift = () => ({
  type: ActionTypes.GET_ALL_SHIFTS_DRP
});

const setAllShift = ({ allShiftData }) => ({
  allShiftData,
  type: ActionTypes.SET_ALL_SHIFTS_DRP
});

const getAllGender = () => ({
  type: ActionTypes.GET_ALL_GENDER_DRP
});

const setAllGender = ({ allGenderData }) => ({
  allGenderData,
  type: ActionTypes.SET_ALL_GENDER_DRP
});

const getAllWeekoffs = () => ({
  type: ActionTypes.GET_ALL_WEEKOFFS_DRP
});

const setAllWeekoffs = ({ allWeekoffData }) => ({
  allWeekoffData,
  type: ActionTypes.SET_ALL_WEEKOFFS_DRP
});

const getAllNationalities = () => ({
  type: ActionTypes.GET_ALL_NATIONALITIES_DRP
});

const setAllNationalities = ({ allNationalityData }) => ({
  allNationalityData,
  type: ActionTypes.SET_ALL_NATIONALITIES_DRP
});

const getAllBloodGroup = () => ({
  type: ActionTypes.GET_ALL_BLOOD_GROUP_DRP
});

const setAllBloodGroup = ({ allBloodGroupData }) => ({
  allBloodGroupData,
  type: ActionTypes.SET_ALL_BLOOD_GROUP_DRP
});


const getAllGrnById = () => ({
  type: ActionTypes.GET_ALL_GRN_BY_ID
});

const setAllGrnById = ({ allGrnData }) => ({
  allGrnData,
  type: ActionTypes.SET_ALL_GRN_BY_ID
});

const getAllSaleQuotation = () => ({
  type: ActionTypes.GET_ALL_SALE_QUOTATION
});

const setAllSaleQuotation = ({ allSaleQuotationData }) => ({
  allSaleQuotationData,
  type: ActionTypes.SET_ALL_SALE_QUOTATION
});

  const getAllLeaveType = () => ({
    type: ActionTypes.GET_ALL_LEAVE_TYPE_DRP
  });
  
  const setAllLeaveType = ({ allLeaveTypeData }) => ({
    allLeaveTypeData,
    type: ActionTypes.SET_ALL_LEAVETYPE_DRP
  });
  
  export default{
  getAllStores,
  setAllStores,
  getAllWarehouses,
  setAllWarehouses,
  getAllCategory,
  setAllCategory,
  getAllSubCategory,
  setAllSubCategory,
  getAllBrands,
  setAllBrands,
  getAllUnits,
  setAllUnits,
  getAllUsers,
  setAllUsers,
  uploadProfileImage,
  setUploadProfileImage,
  getAllExpenseCategory,
  setAllExpenseCategory,
  getAllVariants,
  setAllVariants,
  getAllSellingTypes,
  setAllSellingTypes,
  setAllbarcodesymbologies,
  getAllbarcodesymbologies,
  setAllDisscountTypes,
  getAllDisscountTypes,
  setAllTaxTypes,
  getAllTaxTypes,
  getAllCities,
  getAllCitiesByStateId,
  setAllCities,
  getAllStates,
  getAllStatesByCountryId,
  setAllStates,
  getAllCountries,
  setAllCountries,
  getAllSuppliers,
  setAllSuppliers,
  getAllCustomer,
  setAllCustomer,
  getAllQuotation,
  setAllQuotation,
  getAllProductsBySearch,
  setAllProductsBySearch,
  getAllEmployee,
  setAllEmployee,
  getAllDesignation,
  setAllDesignation,
  getAllDepartment,
  setAllDepartment,
  getAllShift,
  setAllShift,
  getAllGender,
  setAllGender,
  getAllWeekoffs,
  setAllWeekoffs,
  getAllNationalities,
  setAllNationalities,
  getAllBloodGroup,
  setAllBloodGroup,
  getAllGrnById,
  setAllGrnById,
  getAllLeaveTypes,
  setAllLeaveTypes,
  getAllSaleQuotation,
  setAllSaleQuotation,
  setLoader,
  getAllLeaveType,
  setAllLeaveType
};
