import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Select, DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import financeAccountsAction from "../../../core/Services/financeAccounts/expense/actions";
import commonAction from "../../../core/Services/common/actions";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import IntlMessages from '../../../shared/components/utility/intlMessages';
import CustomFormLabel from '../../common/CustomFormLabel';
import * as commonSelectors from '../../commonSelectors';
import * as ApiResponseSelectors from '../../selectors';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';

const AddExpenseModal = (props) => {

    const { currentModalData, isModalOpen, isUpdate, toggleModal, expenseParams } = props;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [isError, setIsError] = useState(false);

    const expenseCategoryData = useSelector(state => commonSelectors.setAllExpenseCategory(state));
    const expCateDataSource = expenseCategoryData ? expenseCategoryData.toJS() : [];
    const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));

    useEffect(() => {
        dispatch(commonAction.getAllExpenseCategory())
    }, [])
    useEffect(() => {
        if (isUpdate) {
            setSelectedDateModal(currentModalData.formData.expenseDatetime)
        }
    }, [])
    const handleRecord = (actionName, expenseData) => {
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            expenseData.id = currentModalData.formData.id;
            expenseData.companyId = currentModalData.formData.companyId;
        } else {
            expenseData.companyId = 0;
        }
        dispatch(financeAccountsAction.addUpdateExpenses({ expenseData, actionName, expenseParams: expenseParams }));
        toggleModal();
    };

    const [selectedDateModal, setSelectedDateModal] = useState(null);

    const handleDateChangeModal = (date) => {
        setSelectedDateModal(date);
    };

    return (
        <Modal
            open={isModalOpen}
            onCancel={() => toggleModal(true)}
            onOk={() => {
                form.validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            title={isUpdate ? <IntlMessages id="expense.finance.account.form.modal.title.update" /> : <IntlMessages id="expense.finance.account.form.modal.title.add" />}
            okText={isUpdate ? <IntlMessages id="expense.finance.account.form.modal.update" /> : <IntlMessages id="expense.finance.account.form.modal.add" />}
            cancelText={<IntlMessages id="expense.finance.account.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name="unitForm"
                className='unitActions_addModalWrapper'
                initialValues={{
                    companyId: currentModalData.formData ? currentModalData.formData.companyId : 0,
                    expenseCategoryId: currentModalData.formData.expenseCategoryId,
                    //expenseDatetime: moment(currentModalData.formData.expenseDatetime),
                    amount: currentModalData.formData.amount,
                    reference: currentModalData.formData.reference,
                    expenseFor: currentModalData.formData.expenseFor,
                    expenseDescription: currentModalData.formData.expenseDescription,
                }}
            >
                <div className='row'>
                    <div className="col-lg-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="expenseCategoryId"
                                className='actions_addModalFormItem'
                                label={
                                    <CustomFormLabel
                                        labelkey="expense.finance.account.form.account.label.expense.category"
                                        popoverkey="expense.finance.account.form.label.popover.account.expense.category"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="expense.finance.account.modal.validation.expense.categoryy" />,
                                    },
                                ]}
                            >
                                <Select
                                    getPopupContainer={triggerNode => triggerNode.parentElement}
                                    classNamePrefix="react-select"
                                    options={expCateDataSource.map(f => ({ label: f.name, value: f.id }))}
                                    placeholder={CONSTANT_VARIABLES.CATEGORY_SELCT}
                                />
                            </Form.Item>
                        </div>
                        <div className="input-blocks">
                            <Form.Item
                                name="expenseDatetime"
                                className='actions_addModalFormItem'
                                label={<CustomFormLabel labelkey="expense.finance.account.form.account.label.expense.date"
                                    popoverkey="expense.finance.account.form.label.popover.account.expense.date" />}
                                rules={[{ required: true, message: <IntlMessages id="expense.finance.account.modal.validation.expense.date" /> }]}
                            >
                                <DatePicker
                                    value= {currentModalData.formData?.expenseDatetime ? moment( currentModalData.formData?.expenseDatetime) : moment(selectedDateModal)}
                                    onChange={handleDateChangeModal}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Choose Date"
                                    className="datetimepicker"
                                />
                            </Form.Item>

                            <div className="input-blocks">
                                <Form.Item
                                    name="reference"
                                    className='actions_addModalFormItem'
                                    label={<CustomFormLabel labelkey="expense.finance.account.form.account.label.reference"
                                        popoverkey="expense.finance.account.form.label.popover.account.reference" />}
                                    rules={[{ required: true, message: <IntlMessages id="expense.finance.account.modal.validation.reference" /> }]}
                                >
                                    <Input />
                                </Form.Item>
                            </div>
                            <div className="input-blocks">
                                <Form.Item
                                    name="amount"
                                    className='actions_addModalFormItem'
                                    label={<CustomFormLabel labelkey="expense.finance.account.form.account.label.amount"
                                        popoverkey="expense.finance.account.form.label.popover.account.amount" />}
                                    rules={[{ required: true, message: <IntlMessages id="expense.finance.account.modal.validation.amount" /> }]}
                                >
                                    <Input />
                                </Form.Item>
                            </div>
                            <div className="input-blocks">
                                <Form.Item
                                    name="expenseFor"
                                    className='actions_addModalFormItem'
                                    label={<CustomFormLabel labelkey="expense.finance.account.form.account.label.expense.for"
                                        popoverkey="expense.finance.account.form.label.popover.account.expense.for" />}
                                    rules={[{ required: true, message: <IntlMessages id="expense.finance.account.modal.validation.expense.for" /> }]}
                                >
                                    <Input />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="expenseDescription"
                                className='actions_addModalFormItem'
                                label={<CustomFormLabel labelkey="exp.category.finance.account.form.account.label.description"
                                    popoverkey="exp.category.finance.account.form.label.popover.account.description" />}
                            >
                                <TextArea />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    )
}
export default AddExpenseModal