import React, { useState, useEffect } from 'react';
import * as posSelectors from '../../posSelectors.js';
import denominationActions from '../../../../core/Services/pos/cashclosing/cashdenomination/actions.js';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Table, InputNumber } from 'antd';
import { Form, Select } from 'antd';
import { CONSTANT_VARIABLES } from "../../../../core/Common/Constant_Variables/constants";
import IntlMessages from "../../../../shared/components/utility/intlMessages";
import './denominations.css';
import '../../pos.css';
import * as ApiResponseSelectors from '../../../selectors';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const cashNotes = [5000, 1000, 500, 100, 50, 20, 10];
const points = [
    { value: "choose", label: "Choose" },
    { value: "unitedKingdom", label: "United Kingdom" },
    { value: "unitedStates", label: "United States" },
];
const AddDenominationModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, denominationParams } = props;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState(null);
    const [tableData, setTableData] = useState(cashNotes.map(note => ({ cashNote: note, numberOfNotes: 0 })));
    const denominationData = useSelector(state => posSelectors.setDenomination(state));
    const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));

    useEffect(() => {
        if (isUpdate && currentModalData) {
            setSelectedDate(currentModalData.formData.closingDate ? new Date(currentModalData.formData.closingDate) : null);
            form.setFieldsValue({
                ...currentModalData.formData,
                closingDate: currentModalData.formData.closingDate ? moment(currentModalData.formData.closingDate).format('YYYY-MM-DD') : null
            });
            const updatedData = cashNotes.map(note => ({
                cashNote: note,
                numberOfNotes: currentModalData.formData[`notes_${note}`] || 0
            }));
            setTableData(updatedData);
        }
    }, [isUpdate, currentModalData, form]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        form.setFieldsValue({ closingDate: date ? moment(date).format('YYYY-MM-DD') : null });
    };

    const handleRecord = (actionName, denominationData) => {
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            denominationData.id = currentModalData.formData.id;
            denominationData.companyId = currentModalData.formData.companyId;
        }
        dispatch(denominationActions.addUpdateDenominations({ denominationData, actionName, DenominationParams: denominationParams }));
        toggleModal();
    };

    const handleInputChange = (value, note) => {
        const updatedTableData = tableData.map(row => row.cashNote === note ? { ...row, numberOfNotes: value } : row);
        setTableData(updatedTableData);
        form.setFieldsValue({
            [`notes_${note}`]: value,
        });
    };

    const columns = [
        {
            title: 'Note',
            dataIndex: 'cashNote',
            key: 'cashNote',
        },
        {
            title: '',
            key: 'multiplicationSign',
            render: () => 'X',
        },
        {
            title: 'Note #',
            key: 'numberOfNotes',
            render: (text, record) => (
                <Form.Item
                    name={`notes_${record.cashNote}`}
                    noStyle
                    rules={[{ required: true, message: 'Please enter the number of notes' }]}
                >
                    <InputNumber
                        min={0}
                        value={record.numberOfNotes}
                        onChange={(value) => handleInputChange(value, record.cashNote)}
                    />
                </Form.Item>
            ),
        },
        {
            title: 'Total',
            key: 'total',
            render: (text, record) => {
                return record.numberOfNotes * record.cashNote;
            },
        },
    ];

    const calculateGrandTotal = () => {
        return tableData.reduce((total, row) => total + (row.numberOfNotes * row.cashNote), 0);
    };

    return (
        <Modal
            open={isModalOpen}
            onClose={() => toggleModal(true)}
            width={900}
            zIndex={9999}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    <IntlMessages id="denomination.pos.form.modal.title.update" />
                ) : (
                    <IntlMessages id="denomination.pos.form.modal.title.add" />
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="denomination.pos.form.modal.update" />
                ) : (
                    <IntlMessages id="denomination.pos.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="denomination.pos.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name="denominationForm"
                className='denominationActions_addModalWrapper'
                initialValues={{
                    companyId: currentModalData.formData ? currentModalData.formData.companyId : 0,
                    denominationName: currentModalData.formData.denominationName,
                    denominationSlug: currentModalData.formData.denominationSlug,
                    isActive: currentModalData.formData.isActive,
                }}
            >
                <div className="row mt-5">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="pointId"
                                label={
                                    <IntlMessages id="pos.denominations.form.label.point.name" />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="pos.denominations.form.validation.point.name" />,
                                    },
                                ]}
                            >
                                <Select
                                    options={points}
                                    classNamePrefix="react-select"
                                    className="input-select"
                                    placeholder={<IntlMessages id="pos.denominations.form.placeholder.choose" />}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="closingDate"
                                label={<IntlMessages id="pos.denominations.form.label.date" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="pos.denominations.form.validation.date" />,
                                    },
                                ]}
                            >
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    dateFormat="YYYY-MM-DD"
                                    placeholderText="Select Date"
                                    className="actions_addModalFormItem"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <Table
                    columns={columns}
                    dataSource={tableData}
                    pagination={false}
                    footer={() => (
                        <div className="table-footer">
                            <strong>Grand Total: {calculateGrandTotal()}</strong>
                        </div>
                    )}
                />
            </Form>
        </Modal>
    );
}

export default AddDenominationModal;
