export const ActionTypes = {
  ADD_UPDATE_WAREHOUSE: 'services/people/add_update_warehouse',
  FETCH_WAREHOUSE: 'services/people/fetch_warehouses',
  SET_WAREHOUSES: 'services/people/set_warehouses',
  DELETE_WAREHOUSES: 'services/people/delete_warehouses',
  GET_WAREHOUSE_BY_ID: 'services/people/get_warehouse_by_id',
  SET_WAREHOUSE_BY_ID: 'services/people/set_warehouse_by_id',
  SET_LOADER: 'services/people/set_loader',
  UPLOAD_PROFILE_IMAGE: 'services/people/upload_profile_image',
  SET_UPLOAD_PROFILE_IMAGE: 'services/people/set_upload_profile_image',
}
const setLoader = ({ showLoader }) => ({
  showLoader,
  type: ActionTypes.SET_LOADER,
});

const fetchAllWarehouses = ({ warehouseParams }) => ({
  warehouseParams,
  type: ActionTypes.FETCH_WAREHOUSE,
})

const setWarehouses = ({ allWarehousesData }) => ({
  allWarehousesData,
  type: ActionTypes.SET_WAREHOUSES,
})

const deleteWarehouses = ({ warehouseParam }) => ({
  warehouseParam,
  type: ActionTypes.DELETE_WAREHOUSES,
})

const getWarehouseById = ({ warehouseParam }) => ({
  warehouseParam,
  type: ActionTypes.GET_WAREHOUSE_BY_ID,
})

const setWarehouseById = ({ warehouseParam }) => ({
  warehouseParam,
  type: ActionTypes.SET_WAREHOUSE_BY_ID,
})

const addUpdateWarehouses = ({ warehouseData, actionName, WarehouseParams }) => ({
  warehouseData,
  actionName,
  WarehouseParams,
  type: ActionTypes.ADD_UPDATE_WAREHOUSE,
})


const uploadProfileImage = ({ warehouseImage }) => ({
  warehouseImage,
  type: ActionTypes.UPLOAD_PROFILE_IMAGE
});

const setUploadProfileImage = ({ warehouseImage }) => ({
  warehouseImage,
  type: ActionTypes.SET_UPLOAD_PROFILE_IMAGE
});
export default {
  addUpdateWarehouses,
  fetchAllWarehouses,
  setWarehouses,
  setWarehouseById,
  deleteWarehouses,
  getWarehouseById,
  setWarehouseById,
  setLoader,
  uploadProfileImage,
  setUploadProfileImage
}
