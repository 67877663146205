import { Download, Printer } from 'feather-icons-react/build/IconComponents'
import React, { useEffect, useRef } from 'react'
import { Mail } from 'react-feather'
import { Link, useParams } from 'react-router-dom'
import ImageWithBasePath from '../../../core/img/imagewithbasebath'
import { CONSTANT_VARIABLES } from '../../../core/Common/Constant_Variables/constants'
import payslipActions from '../../../core/Services/hrm/payroll/actions'
import * as payslipSelectors from '../hrmSelectors'
import { useDispatch, useSelector } from 'react-redux'
import { Spin } from 'antd'
import { toWords } from 'number-to-words';
import moment from 'moment'
import jsPDF from 'jspdf'
import { useReactToPrint } from 'react-to-print'

const Payslip = () => {

    const { empId } = useParams();
    const dispatch = useDispatch();

    const payslipRef = useRef();

    const isPayslipLoading = useSelector(state => payslipSelectors.setPayslipLoader(state));
    const payslipData = useSelector(state => payslipSelectors.setPayslip(state));
    const dataSource = payslipData ? payslipData.toJS() : [];

    const handlePrint = useReactToPrint({
        content: () => payslipRef.current,
        documentTitle: `Payslip-${dataSource?.employeeCode}`,
    });

    useEffect(() => {
        if (empId) {
            dispatch(payslipActions.fetchAllPayslip({ employeeId: Number.parseInt(empId) }))
        }
    }, [])

    return (
        <div>
            {(isPayslipLoading) ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}
            <div className="page-wrapper">
                <div className="content mb-3">
                    <div className="pay-slip-box" id="pay-slip">
                        <div className="modal-dialog modal-dialog-centered stock-adjust-modal">
                            <div className="modal-content">
                                <div className="page-wrapper-new p-0">
                                    <div className="contents">
                                        <div className="modal-header border-0 custom-modal-header">
                                            <div className="page-header mb-0 w-100">
                                                <div className="add-item payslip-list d-flex justify-content-between">
                                                    <div className="page-title">
                                                        <h4>Payslip</h4>
                                                    </div>
                                                    <div className="page-btn d-flex align-items-center mt-3 mt-md-0">
                                                        <div className="d-block d-sm-flex align-items-center">
                                                            <Link to="#" className="btn btn-added me-2">
                                                                <Mail to={dataSource.email} className="me-2" />
                                                                Send Email
                                                            </Link>
                                                            <Link
                                                                to="#"
                                                                className="btn btn-added downloader mt-3 mb-3 m-sm-0"
                                                                onClick={() => handlePrint()}
                                                            >
                                                                <Download  className="me-2" />
                                                                Download
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-body custom-modal-body" ref={payslipRef}>
                                            <div className="card mb-0">
                                                <div className="card-body border-0">
                                                    <div className="payslip-month d-flex">
                                                        <div className="slip-logo">
                                                            <ImageWithBasePath src="assets/img/logo-small.png" alt="Logo" />
                                                        </div>
                                                        <div className="month-of-slip">
                                                            <h4>Payslip for the Month of {moment(dataSource.payForMonth).format('MMM YYYY')}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="emp-details d-flex">
                                                        <div className="emp-name-id">
                                                            <h6>
                                                                Emp Name : {dataSource.employeeName}<span></span>
                                                            </h6>
                                                            <h6>
                                                                Emp Id : <span>{dataSource.employeeCode}</span>
                                                            </h6>
                                                        </div>
                                                        <div className="emp-location-info">
                                                            <h6>
                                                                Location : <span>USA</span>
                                                            </h6>
                                                            <h6>
                                                                Pay Period : <span>{moment(dataSource.payForMonth).format('MMM YYYY')}</span>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="table-responsive">
                                                            <table className="w-100">
                                                                <thead>
                                                                    <tr className="paysilp-table-border">
                                                                        <th colSpan={2}>Earnings</th>
                                                                        <th colSpan={2}>Deduction</th>
                                                                    </tr>
                                                                </thead>
                                                                <thead>
                                                                    <tr className="paysilp-table-border">
                                                                        <th>Pay Type</th>
                                                                        <th>Amount</th>
                                                                        <th>Pay Type</th>
                                                                        <th>Amount</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="paysilp-table-borders">
                                                                    <tr>
                                                                        <td>Basic Salary</td>
                                                                        <td>{(dataSource && dataSource.netSalary ? (dataSource.basicSalary).toLocaleString() : 0.00)}</td>
                                                                        <td>PF</td>
                                                                        <td>{(dataSource && dataSource.netSalary ? (dataSource.pfDeduction).toLocaleString() : 0.00)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>HRA Allowance</td>
                                                                        <td>{(dataSource && dataSource.netSalary ? (dataSource.hraAllowance).toLocaleString() : 0.00)}</td>
                                                                        <td>Professional Tax</td>
                                                                        <td>{(dataSource && dataSource.netSalary ? (dataSource.professionalTaxDeduction).toLocaleString() : 0.00)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Conveyance</td>
                                                                        <td>{(dataSource && dataSource.netSalary ? (dataSource.conveyanceAllowance).toLocaleString() : 0.00)}</td>
                                                                        <td>TDS</td>
                                                                        <td>{(dataSource && dataSource.netSalary ? (dataSource.tdsDeduction).toLocaleString() : 0.00)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Medical Allowance</td>
                                                                        <td>{(dataSource && dataSource.netSalary ? (dataSource.medicalAllowance).toLocaleString() : 0.00)}</td>
                                                                        <td>Loans &amp; Others</td>
                                                                        <td>{(dataSource && dataSource.netSalary ? (dataSource.loansDeduction).toLocaleString() : 0.00)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Bonus</td>
                                                                        <td>{(dataSource && dataSource.netSalary ? (dataSource.bonusAllowance).toLocaleString() : 0.00)}</td>
                                                                        <td>&nbsp;</td>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                    <tr className="payslip-border-bottom">
                                                                        <th>Total Earnings</th>
                                                                        <td>{(dataSource && dataSource.netSalary ? (dataSource.totalAllowances).toLocaleString() : 0.00)}</td>
                                                                        <th>Total Deductions</th>
                                                                        <td>{(dataSource && dataSource.netSalary ? (dataSource.totalDeductions).toLocaleString() : 0.00)}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="emp-details d-flex justify-content-start">
                                                            <div className="emp-name-id pay-slip-salery">
                                                                <h6>Net Salary</h6>
                                                                <span>Inwords</span>
                                                            </div>
                                                            <div className="emp-location-info pay-slip-salery">
                                                                <h6>${(dataSource && dataSource.netSalary ? (dataSource.netSalary).toLocaleString() : 0.00)}</h6>
                                                                <span>{dataSource && dataSource.netSalary ? toWords(dataSource?.netSalary).toLocaleUpperCase() : 0.00}</span>
                                                            </div>
                                                        </div>
                                                        <div className="product-name-slip text-center">
                                                            <h4>{CONSTANT_VARIABLES.SERVE_SOLUTIONS}</h4>
                                                            <p>
                                                                81, Randall Drive,Hornchurch <br />
                                                                RM126TA.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Payslip
