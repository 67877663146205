export const ActionTypes = {
    ADD_UPDATE_EMPLOYEE: 'services/hrm/employee/add_update_employee',
    FETCH_EMPLOYEE: 'services/hrm/employee/fetch_employees',
    SET_EMPLOYEES: 'services/hrm/employee/set_employees',
    DELETE_EMPLOYEES: 'services/hrm/employee/delete_employees',
    GET_EMPLOYEE_BY_ID: 'services/hrm/employee/get_employee_by_id',
    SET_EMPLOYEE_BY_ID: 'services/employee/set_employee_by_id',
    SET_LOADER: 'services/hrm/employee/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllEmployees = ({ employeeParams }) => ({
    employeeParams,
    type: ActionTypes.FETCH_EMPLOYEE,
})

const setEmployees = ({ allEmployeeData }) => ({
    allEmployeeData,
    type: ActionTypes.SET_EMPLOYEES,
})

const deleteEmployees = ({ employeeParam }) => ({
    employeeParam,
    type: ActionTypes.DELETE_EMPLOYEES,
})

const addUpdateEmployees = ({ employeeData, actionName, employeeParams }) => ({
    employeeData,
    actionName,
    employeeParams,
    type: ActionTypes.ADD_UPDATE_EMPLOYEE,
})

export default {
    addUpdateEmployees,
    fetchAllEmployees,
    setEmployees,
    deleteEmployees,
    setLoader
};
