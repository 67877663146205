export const ActionTypes = {
    ADD_UPDATE_BRAND: 'services/brands/add_update_brand',
    FETCH_BRAND: 'services/brands/fetch_brands',
    SET_BRANDS: 'services/brands/set_brands',
    DELETE_BRANDS: 'services/brands/delete_brands',
    GET_BRAND_BY_ID: 'services/brands/get_brand_by_id',
    SET_BRAND_BY_ID: 'services/brands/set_brand_by_id',
    SET_LOADER: 'services/brands/set_loader',
    UPLOAD_PROFILE_IMAGE: 'services/brands/upload_profile_image',
    SET_UPLOAD_PROFILE_IMAGE: 'services/brands/set_upload_profile_image',
  }
  
  const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
  });
  
  const fetchAllBrands = ({ brandParams }) => ({
    brandParams,
    type: ActionTypes.FETCH_BRAND,
  })
  
  const setBrands = ({ allBrandData }) => ({
    allBrandData,
    type: ActionTypes.SET_BRANDS,
  })
  
  const deleteBrands = ({ brandParam }) => ({
    brandParam,
    type: ActionTypes.DELETE_BRANDS,
  })
  
  const getBrandById = ({ brandParam }) => ({
    brandParam,
    type: ActionTypes.GET_BRAND_BY_ID,
  })
  
  const setBrandById = ({ brandParam }) => ({
    brandParam,
    type: ActionTypes.SET_BRAND_BY_ID,
  })
  
  const addUpdateBrands = ({ brandData, actionName, UserParams }) => ({
    brandData,
    actionName,
    UserParams,
    type: ActionTypes.ADD_UPDATE_BRAND,
  })
  
  const uploadProfileImage = ({ brandImage }) => ({
    brandImage,
    type: ActionTypes.UPLOAD_PROFILE_IMAGE
  });
  
  const setUploadProfileImage = ({ brandImage }) => ({
    brandImage,
    type: ActionTypes.SET_UPLOAD_PROFILE_IMAGE
  });

  export default {
    addUpdateBrands,
    fetchAllBrands,
    setBrands,
    setBrandById,
    deleteBrands,
    getBrandById,
    setBrandById,
    setLoader,
    uploadProfileImage,
    setUploadProfileImage
  }
  