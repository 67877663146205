export const ActionTypes = {
    ADD_UPDATE_LEAVE: 'services/hrm/leave/add_update_leave',
    ADD_UPDATE_LEAVE_TYPE: 'services/hrm/leave/add_update_leaveType',
    FETCH_LEAVE: 'services/hrm/leave/fetch_leave',
    FETCH_LEAVE_TYPE: 'services/hrm/leave/fetch_leaveType',
    SET_LEAVE: 'services/hrm/leave/set_leave',
    SET_LEAVE_TYPE: 'services/hrm/leave/set_leaveType',
    DELETE_LEAVE: 'services/hrm/leave/delete_leave',
    DELETE_LEAVE_TYPE: 'services/hrm/leave/delete_leaveType',
    SET_LOADER: 'services/common/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllLeave = ({ leaveParams }) => ({
    leaveParams,
    type: ActionTypes.FETCH_LEAVE,
})

const fetchAllLeaveTypes = ({ leaveTypeParams }) => ({
    leaveTypeParams,
    type: ActionTypes.FETCH_LEAVE_TYPE,
})

const setLeave = ({ allLeaveData }) => ({
    allLeaveData,
    type: ActionTypes.SET_LEAVE,
})

const setLeaveTypes = ({ allLeaveTypeData }) => ({
    allLeaveTypeData,
    type: ActionTypes.SET_LEAVE_TYPE,
})

const deleteLeave = ({ leaveParam }) => ({
    leaveParam,
    type: ActionTypes.DELETE_LEAVE,
})

const deleteLeaveType = ({ leaveTypeParam }) => ({
    leaveTypeParam,
    type: ActionTypes.DELETE_LEAVE_TYPE,
})

const addUpdateLeave = ({ leaveData, actionName, UserParams }) => ({
    leaveData,
    actionName,
    UserParams,
    type: ActionTypes.ADD_UPDATE_LEAVE,
})

const addUpdateLeaveType = ({ leaveTypeData, actionName, leaveTypeParams }) => ({
    leaveTypeData,
    actionName,
    leaveTypeParams,
    type: ActionTypes.ADD_UPDATE_LEAVE_TYPE,
})

export default {
    addUpdateLeave,
    addUpdateLeaveType,
    fetchAllLeave,
    fetchAllLeaveTypes,
    setLeave,
    setLeaveTypes,
    deleteLeave,
    deleteLeaveType,
    setLoader
};
