import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, Form, Input, Switch, Select, Tabs, Radio } from 'antd';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import CustomFormLabel from '../../../common/CustomFormLabel';
import { PlusCircle } from "react-feather";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Flex, message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button } from 'react-bootstrap';
import commonActions from '../../../../core/Services/common/actions'
import * as commonSelectors from '../../../commonSelectors'

const SalaryInformationTab = (props) => {
    const { form, currentModalData, handleCustomFieldsData, isUpdate, employeeOptions } = props;
    const dispatch = useDispatch();

    const [payMonth, setPayMonth] = useState(null)
    const [value, setValue] = useState(1);

    const handleEmployeeChange = (selectedEmployeeId) => {
        form.setFieldValue({ employeeId: selectedEmployeeId })
    }

    const handlePayMonthChange = (date) => {
        setPayMonth(date);
        form.setFieldsValue({ payForMonth: date ? moment(date).format('YYYY-MM-DD') : null });
    };

    const onSalaryStatusChange = (e) => {
        setValue(e.target.value);
    }

    useEffect(() => {
        if (isUpdate && currentModalData && currentModalData.formData)
        {
            form.setFieldsValue({isPaid: currentModalData.formData.isPaid ? 1 : 2})
        }
    }, [isUpdate, currentModalData])

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    employeeId: currentModalData?.formData?.employeeId,
                    payForMonth: currentModalData && currentModalData.formData && currentModalData.formData.payForMonth ? moment(currentModalData.formData.payForMonth).format('YYYY-MM-DD') : null,
                    basicSalary: currentModalData?.formData?.basicSalary,
                }}
                style={{ marginTop: '20px' }}
            >

                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks add-product">
                            <div className="row">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="employeeId"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="Employee"
                                                popoverkey="Employee Name"
                                            />
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Employee Name Required",
                                            },
                                        ]}
                                    >
                                        <Select
                                            classNamePrefix="react-select"
                                            options={employeeOptions.map(s => ({ label: s.name, value: s.id }))}
                                            placeholder="Select Employee"
                                            className="input-select"
                                            onChange={handleEmployeeChange}
                                        />
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks add-product">
                            <div className="row">
                                <div className="col-lg-12 col-sm-10 col-10">
                                <Form.Item
                                        name="payForMonth"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="Pay Month"
                                                popoverkey="Pay Month"
                                            />
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Pay Month Required",
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            selected={payMonth}
                                            onChange={handlePayMonthChange}
                                            dateFormat="YYYY-MM-DD"
                                            placeholderText="Select Date"
                                            className="actions_addModalFormItem"
                                        />
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="">
                                    <Form.Item
                                        name="basicSalary"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="Basic Salary"
                                                popoverkey="Basic Salary"
                                            />
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Basic Salary Required",
                                            },
                                            {
                                                pattern: /^\d+$/,
                                                message: "Basic Salary must be a number",
                                            },
                                        ]}
                                    >
                                        <input type='text' placeholder='Add Basic Salary'/>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="isPaid"
                                        className="actions_addModalFormItem"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select",
                                            },
                                        ]}
                                    >
                                        <Radio.Group onChange={onSalaryStatusChange} value={value} style={{ display: 'flex', alignItems: 'center' }}>
                                            <Radio value={1}>Paid</Radio>
                                            <Radio value={2}>Unpaid</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default SalaryInformationTab;
