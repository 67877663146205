import React from "react";

import * as Icon from "react-feather";

export const SidebarData = [
  {
    label: "Main",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Main",
    submenuItems: [
      {
        label: "Dashboard",
        icon: <Icon.Grid />,
        submenu: true,
        showSubRoute: false,

        submenuItems: [
          { label: "Admin Dashboard", link: "/admin-dashboard" },
          { label: "Sales Dashboard", link: "/sales-dashboard" },
        ],
      },
    ],
  },
  {
    label: "POS",
    submenuOpen: true,
    submenuHdr: "Pos",
    submenu: true,
    showSubRoute: false,
    submenuItems: [
      {
        label: "Book Order",
        link: "/book-order",
        icon: <Icon.Package />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Cash Book",
        link: "/cash-book",
        icon: <Icon.Clipboard />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Advance Booking",
        link: "/advance-booking",
        icon: <Icon.Truck />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Cash Closing",
        icon: <Icon.DollarSign />,
        showSubRoute: false,
        submenu: true,
            submenuItems: [
              { label: "Cash Count Denomination", link: "/cash-denomination" },
              { label: "Today Expenses", link: "/today-expenses" },
              { label: "Cash Summary", link: "/cash-summary" },
            ],
      },
    ],
  },
  {
    label: "Receipe Management",
    submenuOpen: true,
    submenuHdr: "Receipe Management",
    submenu: true,
    showSubRoute: false,
    submenuItems: [
      {
        label: "Recepies",
        link: "/Recepies",
        icon: <Icon.Package />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Consumption Report",
        link: "/consumption-report",
        icon: <Icon.Clipboard />,
        showSubRoute: false,
        submenu: false,
      },
    ],
  },
  {
    label: "Inventory",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Inventory",
    submenuItems: [
      {
        label: "Products",
        link: "/products",
        icon: <Icon.Box />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Expired Products",
        link: "/expired-products",
        icon: <Icon.Codesandbox />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Low Stocks",
        link: "/low-stocks",
        icon: <Icon.TrendingDown />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Selling Type",
        link: "/sellingtype",
        icon: <Icon.Type />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Categories",
        link: "/categories",
        icon: <Icon.Codepen />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Sub Category",
        link: "/sub-categories",
        icon: <Icon.Speaker />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Brands",
        link: "/brand-list",
        icon: <Icon.Tag />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Units",
        link: "/units",
        icon: <Icon.Speaker />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Variant Attributes",
        link: "/variant-attributes",
        icon: <Icon.Layers />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Warranties",
        link: "/warranty",
        icon: <Icon.Bookmark />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Print Barcode",
        link: "/barcode",
        icon: <Icon.AlignJustify />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Print QR Code",
        link: "/qrcode",
        icon: <Icon.Maximize />,
        showSubRoute: false,
        submenu: false,
      },
    ],
  },
  {
    label: "Stock",
    submenuOpen: true,
    submenuHdr: "Stock",
    submenu: true,
    showSubRoute: false,
    submenuItems: [
      {
        label: "Manage Stock",
        link: "/manage-stocks",
        icon: <Icon.Package />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Stock Adjustment",
        link: "/stock-adjustment",
        icon: <Icon.Clipboard />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Stock Transfer",
        link: "/stock-transfer",
        icon: <Icon.Truck />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Vault",
        link: "/vault",
        icon: <Icon.Truck />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Demand Request",
        link: "/demand-request",
        icon: <Icon.Truck />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Requests",
        link: "/requests",
        icon: <Icon.Truck />,
        showSubRoute: false,
        submenu: false,
      },
    ],
  },
  {
    label: "Purchases",
    submenuOpen: true,
    submenuHdr: "Purchases",
    showSubRoute: false,
    submenuItems: [
      {
        label: "Purchase Quotations",
        link: "/purchase-quotations",
        icon: <Icon.ShoppingBag />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Purchases",
        link: "/purchase-list",
        icon: <Icon.ShoppingBag />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Purchase Order",
        link: "/purchase-order-report",
        icon: <Icon.FileMinus />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Goods Received Notes",
        link: "/purchase-received-notes",
        icon: <Icon.ShoppingBag />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Purchase Invoice",
        link: "/purchase-invoice",
        icon: <Icon.ShoppingBag />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Payment Vouchar",
        link: "/purchase-payment-vouchar",
        icon: <Icon.ShoppingBag />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Credit Notes",
        link: "/purchase-credit-notes",
        icon: <Icon.ShoppingBag />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Purchase Return",
        link: "/purchase-returns",
        icon: <Icon.RefreshCw />,
        showSubRoute: false,
        submenu: false,
      },
    ],
  },
  {
    label: "Sales",
    submenuOpen: true,
    submenuHdr: "Sales",
    submenu: false,
    showSubRoute: false,
    submenuItems: [
      {
        label: "POS",
        link: "/pos",
        icon: <Icon.HardDrive />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Sale Quotation",
        link: "/quotation-list",
        icon: <Icon.Save />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Sales Order",
        link: "/sales-list",
        icon: <Icon.ShoppingCart />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Sale Invoices",
        link: "/invoice-report",
        icon: <Icon.FileText />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Payment Receive",
        link: "/invoice-report",
        icon: <Icon.FileText />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Debit Notes",
        link: "/purchase-debit-notes",
        icon: <Icon.ShoppingBag />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Sales Return",
        link: "/sales-returns",
        icon: <Icon.Copy />,
        showSubRoute: false,
        submenu: false,
      },
    ],
  },
  {
    label: "Promo",
    submenuOpen: true,
    submenuHdr: "Promo",
    showSubRoute: false,
    submenuItems: [
      {
        label: "Coupons",
        link: "/coupons",
        icon: <Icon.ShoppingCart />,
        showSubRoute: false,
        submenu: false,
      },
    ],
  },
  {
    label: "Finance & Accounts",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Finance & Accounts",
    submenuItems: [
      {
        label: "Expenses",
        submenu: true,
        showSubRoute: false,
        icon: <Icon.FileText />,
        submenuItems: [
          { label: "Expenses", link: "/expense-list", showSubRoute: false },
          {
            label: "Expense Category",
            link: "/expense-category",
            showSubRoute: false,
          },
        ],
      },
    ],
  },

  {
    label: "People",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "People",

    submenuItems: [
      {
        label: "Customers",
        link: "/customers",
        icon: <Icon.User />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Suppliers",
        link: "/suppliers",
        icon: <Icon.Users />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Stores",
        link: "/stores",
        icon: <Icon.Home />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Warehouses",
        link: "/warehouse",
        icon: <Icon.Archive />,
        showSubRoute: false,
        submenu: false,
      },
    ],
  },

  {
    label: "HRM",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "HRM",
    submenuItems: [
      {
        label: "Employees",
        link: "/employees-grid",
        icon: <Icon.Users />,
        showSubRoute: false,
      },
      {
        label: "Departments",
        link: "/department-grid",
        icon: <Icon.User />,
        showSubRoute: false,
      },
      {
        label: "Designations",
        link: "/designation",
        icon: <Icon.UserCheck />,
        showSubRoute: false,
      },
      {
        label: "Shifts",
        link: "/shift",
        icon: <Icon.Shuffle />,
        showSubRoute: false,
      },

      {
        label: "Attendance",
        link: "#",
        icon: <Icon.Clock />,
        showSubRoute: false,
        submenu: true,

        submenuItems: [
          { label: "Employee", link: "/attendance-employee" },
          { label: "Admin", link: "/attendance-admin" },
        ],
      },
      {
        label: "Leaves",
        link: "#",
        icon: <Icon.Calendar />,
        showSubRoute: false,
        submenu: true,
        submenuItems: [
          { label: "Employee Leaves", link: "/leaves-employee" },
          { label: "Leave Types", link: "/leave-types" },
        ],
      },
      {
        label: "Holidays",
        link: "/holidays",
        icon: <Icon.CreditCard />,
        showSubRoute: false,
      },

      {
        label: "Payroll",
        link: "#",
        icon: <Icon.DollarSign />,
        showSubRoute: false,
        submenu: true,
        submenuItems: [
          { label: "Employee Salary", link: "/payroll-list" },
          { label: "Payslip", link: "/payslip" },
        ],
      },
    ],
  },
  {
    label: "Reports",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Reports",
    submenuItems: [
      {
        label: "Sales Report",
        link: "/sales-report",
        icon: <Icon.BarChart2 />,
        showSubRoute: false,
      },
      {
        label: "Purchase Report",
        link: "/purchase-report",
        icon: <Icon.PieChart />,
        showSubRoute: false,
      },
      {
        label: "Inventory Report",
        link: "/inventory-report",
        icon: <Icon.Inbox />,
        showSubRoute: false,
      },
      {
        label: "Invoice Report",
        link: "/invoice-report",
        icon: <Icon.File />,
        showSubRoute: false,
      },
      {
        label: "Supplier Report",
        link: "/supplier-report",
        icon: <Icon.UserCheck />,
        showSubRoute: false,
      },
      {
        label: "Customer Report",
        link: "/customer-report",
        icon: <Icon.User />,
        showSubRoute: false,
      },
      {
        label: "Expense Report",
        link: "/expense-report",
        icon: <Icon.FileText />,
        showSubRoute: false,
      },
      {
        label: "Income Report",
        link: "/income-report",
        icon: <Icon.BarChart />,
        showSubRoute: false,
      },
      {
        label: "Tax Report",
        link: "/tax-report",
        icon: <Icon.Database />,
        showSubRoute: false,
      },
      {
        label: "Profit & Loss",
        link: "/profit-loss-report",
        icon: <Icon.TrendingDown />,
        showSubRoute: false,
      },
    ],
  },

  {
    label: "User Management",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "User Management",
    submenuItems: [
      {
        label: "Users",
        link: "/users",
        icon: <Icon.UserCheck />,
        showSubRoute: false,
      },
    ],
  },

  {
    label: "Settings",
    submenu: true,
    showSubRoute: false,
    submenuHdr: "Settings",
    submenuItems: [
      {
        label: "General Settings",
        submenu: true,
        showSubRoute: false,
        icon: <Icon.Settings />,
        submenuItems: [
          { label: "Profile", link: "/general-settings" },
          { label: "Security", link: "/security-settings" },
          { label: "Notifications", link: "/notification" },
          { label: "Connected Apps", link: "/connected-apps" },
        ],
      },
      {
        label: "Website Settings",
        submenu: true,
        showSubRoute: false,
        icon: <Icon.Globe />,
        submenuItems: [
          {
            label: "System Settings",
            link: "/system-settings",
            showSubRoute: false,
          },
          {
            label: "Company Settings",
            link: "/company-settings",
            showSubRoute: false,
          },
          {
            label: "Localization",
            link: "/localization-settings",
            showSubRoute: false,
          },
          { label: "Prefixes", link: "/prefixes", showSubRoute: false },
          { label: "Preference", link: "/preference", showSubRoute: false },
          { label: "Appearance", link: "/appearance", showSubRoute: false },
          {
            label: "Social Authentication",
            link: "/social-authentication",
            showSubRoute: false,
          },
          {
            label: "Language",
            link: "/language-settings",
            showSubRoute: false,
          },
        ],
      },
      {
        label: "App Settings",
        submenu: true,

        showSubRoute: false,
        icon: <Icon.Smartphone />,
        submenuItems: [
          { label: "Invoice", link: "/invoice-settings", showSubRoute: false },
          { label: "Printer", link: "/printer-settings", showSubRoute: false },
          { label: "POS", link: "/pos-settings", showSubRoute: false },
          {
            label: "Custom Fields",
            link: "/custom-fields",
            showSubRoute: false,
          },
        ],
      },
      {
        label: "System Settings",
        submenu: true,
        showSubRoute: false,
        icon: <Icon.Monitor />,
        submenuItems: [
          { label: "Email", link: "/email-settings", showSubRoute: false },
          { label: "SMS Gateways", link: "/sms-gateway", showSubRoute: false },
          { label: "OTP", link: "/otp-settings", showSubRoute: false },
          {
            label: "GDPR Cookies",
            link: "/gdpr-settings",
            showSubRoute: false,
          },
        ],
      },
      {
        label: "Financial Settings",
        submenu: true,
        showSubRoute: false,
        icon: <Icon.DollarSign />,
        submenuItems: [
          {
            label: "Payment Gateway",
            link: "/payment-gateway-settings",
            showSubRoute: false,
          },
          {
            label: "Bank Accounts",
            link: "/bank-settings-grid",
            showSubRoute: false,
          },
          { label: "Tax Rates", link: "/tax-rates", showSubRoute: false },
          {
            label: "Currencies",
            link: "/currency-settings",
            showSubRoute: false,
          },
        ],
      },
      {
        label: "Other Settings",
        submenu: true,
        showSubRoute: false,
        icon: <Icon.Hexagon />,
        submenuItems: [
          { label: "Storage", link: "/storage-settings", showSubRoute: false },
          {
            label: "Ban IP Address",
            link: "/ban-ip-address",
            showSubRoute: false,
          },
        ],
      },
      {
        label: "Logout",
        link: "/signin",
        icon: <Icon.LogOut />,
        showSubRoute: false,
      },
    ],
  },

  {
    label: "Help",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Help",
    submenuItems: [
      {
        label: "Documentation",
        link: "#",
        icon: <Icon.FileText />,
        showSubRoute: false,
      },
      {
        label: "Changelog v2.0.7",
        link: "#",
        icon: <Icon.Lock />,
        showSubRoute: false,
      },
    ],
  },
];
