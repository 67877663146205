export const ActionTypes = {
    ADD_UPDATE_SELLINGTYPE: 'services/inventory/sellingType/add_update_sellingType',
    FETCH_SELLINGTYPE: 'services/inventory/sellingType/fetch_sellingType',
    SET_SELLINGTYPE: 'services/inventory/sellingType/set_sellingType', 
    DELETE_SELLINGTYPE: 'services/inventory/sellingType/delete_sellingType',
    GET_SELLINGTYPE_BY_ID: 'services/inventory/sellingType/get_sellingType_by_id',
    SET_SELLINGTYPE_BY_ID: 'services/inventory/sellingType/set_sellingType_by_id',
    SET_LOADER: 'services/inventory/inventory/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});
const fetchAllSellingType = ({ sellingTypeParams }) => ({
    sellingTypeParams,
    type: ActionTypes.FETCH_SELLINGTYPE,
});
const setSellingType = ({ allSellingTypeData }) => ({
    allSellingTypeData,
    type: ActionTypes.SET_SELLINGTYPE,
})
const deleteSellingTypeById = ({ sellingTypeParams }) => ({
    sellingTypeParams,
    type: ActionTypes.DELETE_SELLINGTYPE,
})

const getSellingTypeById = ({ sellingTypeParams }) => ({
    sellingTypeParams,
    type: ActionTypes.GET_SELLINGTYPE_BY_ID,
})

const setSellingTypeById = ({ sellingTypeParams }) => ({
    sellingTypeParams,
    type: ActionTypes.SET_SELLINGTYPE_BY_ID,
})
const addUpdateSellingType = ({ sellingTypeData, actionName, UserParams }) => ({
    sellingTypeData,
    actionName,
    UserParams,
    type: ActionTypes.ADD_UPDATE_SELLINGTYPE,
})
export default {
    addUpdateSellingType,
    fetchAllSellingType,
    setSellingType,
    deleteSellingTypeById,
    getSellingTypeById,
    setSellingTypeById,
    setLoader
};