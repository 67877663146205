import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setPurchaseReturns = (state, { allPurchaseReturnData }) => {
    if (!allPurchaseReturnData) return state;
    return state.merge({ allPurchaseReturnData });
}

const setPurchaseReturnById = (state, { purchaseReturnParam }) => {
    return state.merge({ purchaseReturnParam });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

const setQuotationNoById = (state, { QuotationData }) => {
    return state.merge({ QuotationData });
}

export const callbacks = [
    {
        actionType: ActionTypes.SET_PURCHASES_RETURN,
        actionFunction: setPurchaseReturns,
    },
    {
        actionType: ActionTypes.SET_PURCHASE_RETURN_BY_ID,
        actionFunction: setPurchaseReturnById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
    {
        actionType: ActionTypes.SET_QUOTATION_NO_BY_ID,
        actionFunction: setQuotationNoById,
    },
];
