export const purchaseLoader = state => {
    return state.services.purchase.getIn(['showLoader']);
 };
 export const setPurchases = state => {
    return state.services.purchase.getIn(['allPurchaseData']);
 };
 export const setPurchaseById = state => {
    return state.services.purchase.getIn(['purchaseData']);
 };

export const quotationLoader = state => {
    return state.services.quotations.getIn(['showLoader']);
 };

export const purchaseReturnLoader = state => {
    return state.services.purchaseReturn.getIn(['showLoader']);
 };
 export const setQuotations = state => {
    return state.services.quotations.getIn(['allQuotationData']);
 };
 export const setQuotationById = state => {
    return state.services.quotations.getIn(['QuotationParam']);
 };
 
 export const SetQuotationByQNById = state => {
    return state.services.purchase.getIn(['QuotationData']);
 };

 export const purchaseInvoiceLoader = state => {
   return state.services.purchaseInvoice.getIn(['showLoader']);
};

 export const setQuotationNoById = state => {
   return state.services.purchaseInvoice.getIn(['QuotationData']);
};

 export const setPurchaseInvoices = state => {
   return state.services.purchaseInvoice.getIn(['allPurchaseInvoiceData']);
};
export const setPurchaseInvoiceById = state => {
   return state.services.purchaseInvoice.getIn(['purchaseInvoiceParam']);
};

export const setPurchaseReceives = state => {
   return state.services.purchaseReceive.getIn(['allPurchaseReceiveData']);
};
export const setPurchaseReceiveById = state => {
   return state.services.purchaseReceive.getIn(['purchaseReceiveParam']);
};

export const setPurchaseReturns = state => {
   return state.services.purchaseReturn.getIn(['allPurchaseReturnData']);
};
export const setPurchaseReturnById = state => {
   return state.services.purchaseReturn.getIn(['purchaseReturnParam']);
};

export const setAssetTypes = state => {
   return state.services.assettypes.getIn(['allAssettypeData']);
};

export const setHeads = state => {
   return state.services.heads.getIn(['allHeadData']);
};

export const setCreditNotes = state => {
   return state.services.creditNotes.getIn(['allCreditNoteData']);
};

export const setCreditNoteById = state => {
   return state.services.creditNotes.getIn(['creditNoteParam']);
};

export const setPaymentVouchers = state => {
   return state.services.paymentVoucher.getIn(['allPaymentVoucherData']);
};

export const setPaymentVoucherById = state => {
   return state.services.paymentVoucher.getIn(['paymentVoucherParam']);
};