import React, { useState, useRef, useEffect, Fragment } from 'react';
import * as PeopleManagementSelectors from '../PeopleManagementSelectors';
import storeActions from '../../../core/Services/people/stores/actions';
import { useDispatch, useSelector } from "react-redux";
import { Modal } from 'antd';
import { Row, Col, Form, Input, Select, Upload, Image } from 'antd';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import CustomFormLabel from "../../common/CustomFormLabel";
import { PlusOutlined } from '@ant-design/icons';
import '../peopleManagement.css';
//import 'antd/dist/antd.css';
import './stores.css';
import TextArea from 'antd/es/input/TextArea';
import * as ApiResponseSelectors from '../../selectors';
import { string } from 'prop-types';
import { countriesList } from "../../../core/Common/Constant_Variables/common";
import axios from 'axios';

const AddStore = (props) => {
  const { currentModalData, isModalOpen, isUpdate, toggleModal, storeParams } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const imageRef = useRef(null);
  const [modal] = Modal.useModal();

  const [imageUrl, setImageUrl] = useState('');
  const [isError, setisError] = useState(false);
  const [imageURL, setimageURL] = useState("");
  const [state, setState] = useState({});
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([])
  const [value, setValue] = useState('')
  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));

  const profileData = useSelector(state => PeopleManagementSelectors.storeProfileImage(state));
  const profileImageURL = profileData ? profileData.toJS() : [];
  const [allcountryList, setAllcountryList] = useState([])

  useEffect(() => {
    setAllcountryList(countriesList());
  }, [countriesList]);


  useEffect(() => {
    if (isUpdate) {
      uploadFromURL(currentModalData.formData?.profileImgUrl)
    }
  }, [currentModalData]);

  const uploadFromURL = async (url) => {
    try {
      const response = await axios.get(url, { responseType: 'blob' });
      const file = new File([response.data], 'downloaded-image.png', { type: 'image/png' });
      const newFileList = [
        ...fileList,
        {
          uid: `-${fileList.length}`,
          name: file.name,
          status: 'done',
          url: URL.createObjectURL(file),
          originFileObj: file,
        },
      ];
      console.log(file);
      setFileList(newFileList);

      if (!newFileList.url || !newFileList.preview) {
        file.preview = await getBase64(newFileList);
      }
      setPreviewImage(file.url || file.preview);
    } catch (error) {
      console.error('Error downloading the image', error);
    }
  };

  const changeHandler = value => {
    setValue(value)
  }

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const handleRecord = (actionName, storeData) => {
    if (actionName === CONSTANT_VARIABLES.UPDATE) {

      storeData.id = currentModalData.formData.id;
      storeData.companyId = currentModalData.formData.companyId;
    }
    else {
      storeData.companyId = 0;
    }
    storeData.storeImage = fileList[0];
    dispatch(storeActions.addUpdateStores({ storeData, actionName, StoreParams: storeParams }));
    toggleModal();
  };

  const checkEmailValidator = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email) ? true : false;
  }

  return (
    <Modal
      open={isModalOpen}
      onClose={() => toggleModal(true)}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
          })
          .catch(info => {
            console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
          });
      }}
      onCancel={() => toggleModal(true)}
      title={
        isUpdate ? (
          <IntlMessages id="store.management.form.modal.title.update" />
        ) : (
          <IntlMessages id="store.management.form.modal.title.add" />
        )
      }
      okText={
        isUpdate ? (
          <IntlMessages id="store.management.form.modal.update" />
        ) : (
          <IntlMessages id="store.management.form.modal.add" />
        )
      }
      cancelText={<IntlMessages id="store.management.form.modal.cancelText" />}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        name="storeForm"
        className='storeActions_addModalWrapper'
        initialValues={{
          companyId: currentModalData.formData ? currentModalData.formData.companyId : 0,
          storeName: currentModalData.formData.storeName,
          storeEmail: currentModalData.formData.storeEmail,
          storePhone: currentModalData.formData.storePhone,
          storeAddress: currentModalData.formData.storeAddress,
          storeCityId: currentModalData.formData.storeCityId,
          storeCountryId: currentModalData.formData.storeCountryId,
          storeDescription: currentModalData.formData.storeDescription,
          profileImageURL: "",
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="profileImageURL"
              label={<IntlMessages id="store.management.form.label.uploadImage" />}
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    return isError
                      ? Promise.reject(<IntlMessages id="master.operator.form.label.uploadImage.warnings" />)
                      : Promise.resolve();
                  },
                }),
              ]}
            >
              <Upload
                listType="picture-circle"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
              {previewImage && (
                <Image
                  wrapperStyle={{
                    display: 'none',
                  }}
                  preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                  }}
                  src={previewImage}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <div className='row'>

          <div className="col-lg-6">
            <div className="input-blocks">
              <Form.Item
                name="storeName"
                className='storeActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="store.management.form.store.label.name"
                    popoverkey="store.management.form.label.popover.store.name"
                  />
                }
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="store.management.modal.validation.name" />,
                  },
                ]}
              >
                <Input placeholder={CONSTANT_VARIABLES.STORE_NAME} />
              </Form.Item>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="input-blocks">
              <Form.Item
                name="storePhone"
                className='storeActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="store.management.form.label.phone.number"
                    popoverkey="store.management.form.label.popover.phone.number"
                  />
                }
              >
                <Input placeholder={CONSTANT_VARIABLES.USER_PHONE_NUMBER} />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-blocks">
              <Form.Item
                name="storeEmail"
                className='storeActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="store.management.form.label.email"
                    popoverkey="store.management.form.label.popover.email"
                  />
                }
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="store.management.modal.validation.email" />,
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      return checkEmailValidator(value) ? Promise.resolve() : Promise.reject();
                    }
                  })
                ]}
              >
                <Input placeholder={CONSTANT_VARIABLES.USER_EMAIL} />

              </Form.Item>
            </div>
          </div>
          
          <div className="col-lg-6 col-sm-10 col-10">
            <div className="input-blocks">
              <Form.Item
                name="storeCountryId"
                className='storeActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="store.management.form.label.country"
                    popoverkey="store.management.form.label.popover.country"
                  />
                }
              >
                <Select
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  classNamePrefix="react-select"
                  options={allcountryList.map(f => ({ label: f.label, value: f.id }))}
                  placeholder={CONSTANT_VARIABLES.CUSTOMER_COUNTRY}
                  onChange={changeHandler}
                  className="input-select"
                />
              </Form.Item>
            </div>
          </div>

          <div className="col-lg-6 col-sm-10 col-10">
            <div className="input-blocks">
              <Form.Item
                name="storeCityId"
                className='storeActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="store.management.form.label.city"
                    popoverkey="store.management.form.label.popover.city"
                  />
                }
              >
                  <Select
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  classNamePrefix="react-select"
                  options={allcountryList.map(f => ({ label: f.label, value: f.id }))}
                  placeholder={CONSTANT_VARIABLES.CUSTOMER_CITY}
                  onChange={changeHandler}
                  className="input-select"
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="input-blocks">
              <Form.Item
                name="storeAddress"
                className='storeActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="store.management.form.label.address"
                    popoverkey="store.management.form.label.popover.address"
                  />
                }
              >
                <Input placeholder={CONSTANT_VARIABLES.CUSTOMER_ADDRESS} />
              </Form.Item>
            </div>
          </div>
          <div className="col-md-12">
            <div className="input-blocks">
              <Form.Item
                name="storeDescription"
                className='storeActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="store.management.form.label.description"
                    popoverkey="store.management.form.label.popover.description"
                  />
                }
              >
                <TextArea placeholder={CONSTANT_VARIABLES.CUSTOMER_DESCRIPTION} />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>

    </Modal >
  )
}

export default AddStore
