import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setProducts = (state, { allProductData }) => {
    if (!allProductData) return state;
    return state.merge({ allProductData });
}

const setProductById = (state, { productParam }) => {
    return state.merge({ productParam });
}
const setExpiredProducts = (state, { allExpiredProductData }) => {
    if (!allExpiredProductData) return state;
    return state.merge({ allExpiredProductData });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [{
        actionType: ActionTypes.SET_PRODUCTS,
        actionFunction: setProducts,
    },
    {
        actionType: ActionTypes.SET_PRODUCT_BY_ID,
        actionFunction: setProductById,
    },
    {
        actionType: ActionTypes.SET_EXPIRED_PRODUCTS,
        actionFunction: setExpiredProducts,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];