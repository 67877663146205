import React, { useState, useRef, useEffect, Fragment } from 'react';
import * as inventorySelectors from '../InventorySelectors';
import variantActions from '../../../core/Services/inventory/variant/actions.js';
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Modal } from 'antd';
import { Row, Col, Form, Input, Select, Upload, Image, Switch } from 'antd';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import CustomFormLabel from "../../common/CustomFormLabel";
import { PlusOutlined } from '@ant-design/icons';
import '../inventory.css';
import TextArea from 'antd/es/input/TextArea';
import * as ApiResponseSelectors from '../../selectors';
import { string } from 'prop-types';

const AddVariant = (props) => {
  const { currentModalData, isModalOpen, isUpdate, toggleModal, variantParams } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handleRecord = (actionName, variantData) => {
    if (actionName === CONSTANT_VARIABLES.UPDATE) {
        variantData.id = currentModalData.formData.id;
        variantData.companyId = currentModalData.formData.companyId;
    } else {
        variantData.companyId = 0;
    }
    variantData.isActive = variantData.isActive ? true : false;
    dispatch(variantActions.addUpdateVariants({ variantData, actionName, variantParams: variantParams }));
    toggleModal();
  };
    return (
        <Modal
        open={isModalOpen}
        onClose={() => toggleModal(true)}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
            })
            .catch(info => {
              console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
            });
        }}
        onCancel={() => toggleModal(true)}
        title={
          isUpdate ? (
            <IntlMessages id="variant.inventory.form.modal.title.update" />
          ) : (
            <IntlMessages id="variant.inventory.form.modal.title.add" />
          )
        }
        okText={
          isUpdate ? (
            <IntlMessages id="variant.inventory.form.modal.update" />
          ) : (
            <IntlMessages id="variant.inventory.form.modal.add" />
          )
        }
        cancelText={<IntlMessages id="variant.inventory.form.modal.cancelText" />}
        maskClosable={false}
      >
        <Form
          form={form}
          layout="vertical"
          name="categoryForm"
          className='categoryActions_addModalWrapper'
          initialValues={{
            companyId: currentModalData.formData ? currentModalData.formData.companyId : 0,
            variantName: currentModalData.formData?.variantName,
            variantValues: currentModalData.formData?.variantValues,
            isActive: currentModalData.formData?.isActive,
          }}
        >
  
          <div className='row'>
  
            <div className="col-lg-12">
              <div className="input-blocks">
                <Form.Item
                  name="variantName"
                  className='actions_addModalFormItem'
                  label={
                    <CustomFormLabel
                      labelkey="variant.inventory.form.variant.label.name"
                      popoverkey="variant.inventory.form.label.popover.name"
                    />
                  }
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="variant.inventory.modal.validation.name" />,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </div>
            
            <div className="col-lg-12">
              <div className="input-blocks">
                <Form.Item
                  name="variantValues"
                  className='actions_addModalFormItem'
                  label={
                    <CustomFormLabel
                      labelkey="variant.inventory.form.variant.label.variant"
                      popoverkey="variant.inventory.form.label.popover.variant.name"
                    />
                  }
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="variant.inventory.modal.validation.variant" />,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </div>
            <div className="col-lg-12">
              {/* <div className="input-blocks"> */}
              <div className="addCategory_form--status_area">
                <span className="status-label"> <IntlMessages id="category.inventory.modal.status" /></span>
                <Form.Item
                  name="isActive"
                  className='actions_addModalFormItem'
                  valuePropName="checked"
                >
                  <Switch size="small" />
                </Form.Item>
                {/* </div> */}
              </div>
            </div>
          </div>
        </Form>
  
      </Modal >

    )
}

export default AddVariant
