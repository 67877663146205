import Immutable from 'immutable';
import  {ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setSellingType = (state, { allSellingTypeData }) => {
    if (!allSellingTypeData) return state;
    return state.merge({ allSellingTypeData });
}

const setSellingTypeById = (state, { sellingTypeParams }) => {
    return state.merge({ sellingTypeParams });
}
const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_SELLINGTYPE,
        actionFunction: setSellingType,
    },
    {
        actionType: ActionTypes.SET_SELLINGTYPE_BY_ID,
        actionFunction: setSellingTypeById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];