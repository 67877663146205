import Immutable from 'immutable';
import { ActionTypes } from "./actions.js";
export const initialState = Immutable.fromJS({});

const setTodayExpenses = (state, { allTodayExpensesData }) => {
    if (!allTodayExpensesData) return state;
    return state.merge({ allTodayExpensesData });
}

const setTodayExpensesById = (state, { todayExpensesParam }) => {
    return state.merge({ todayExpensesParam });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_DENOMINATIONS,
        actionFunction: setTodayExpenses,
    },
    {
        actionType: ActionTypes.SET_DENOMINATION_BY_ID,
        actionFunction: setTodayExpensesById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
