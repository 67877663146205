export const ActionTypes = {
    ADD_UPDATE_VARIANT: 'services/variants/add_update_variant',
    FETCH_VARIANT: 'services/variants/fetch_variants',
    SET_VARIANTS: 'services/variants/set_variants',
    DELETE_VARIANTS: 'services/variants/delete_variants',
    GET_VARIANT_BY_ID: 'services/variants/get_variant_by_id',
    SET_VARIANT_BY_ID: 'services/variants/set_variant_by_id',
    SET_LOADER: 'services/variants/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllVariants = ({ variantParams }) => ({
    variantParams,
    type: ActionTypes.FETCH_VARIANT,
})

const setVariants = ({ allVariantData }) => ({
    allVariantData,
    type: ActionTypes.SET_VARIANTS,
})

const deleteVariants = ({ variantParam }) => ({
    variantParam,
    type: ActionTypes.DELETE_VARIANTS,
})

const getVariantById = ({ variantParam }) => ({
    variantParam,
    type: ActionTypes.GET_VARIANT_BY_ID,
})

const setVariantById = ({ variantParam }) => ({
    variantParam,
    type: ActionTypes.SET_VARIANT_BY_ID,
})

const addUpdateVariants = ({ variantData, actionName, UserParams }) => ({
    variantData,
    actionName,
    UserParams,
    type: ActionTypes.ADD_UPDATE_VARIANT,
})

export default {
    addUpdateVariants,
    fetchAllVariants,
    setVariants,
    deleteVariants,
    getVariantById,
    setVariantById,
    setLoader
}
