export const ActionTypes = {
    ADD_UPDATE_UNIT: 'services/inventory/units/add_update_unit',
    FETCH_UNIT: 'services/inventory/units/fetch_units',
    SET_UNITS: 'services/inventory/units/set_units',
    DELETE_UNITS: 'services/inventory/units/delete_units',
    GET_UNIT_BY_ID: 'services/inventory/units/get_unit_by_id',
    SET_UNIT_BY_ID: 'services/inventory/units/set_unit_by_id',
    SET_LOADER: 'services/inventory/inventory/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllUnits = ({ unitParams }) => ({
    unitParams,
    type: ActionTypes.FETCH_UNIT,
})

const setUnits = ({ allUnitData }) => ({
    allUnitData,
    type: ActionTypes.SET_UNITS,
})

const deleteUnits = ({ unitParam }) => ({
    unitParam,
    type: ActionTypes.DELETE_UNITS,
})

const getUnitById = ({ unitParam }) => ({
    unitParam,
    type: ActionTypes.GET_UNIT_BY_ID,
})

const setUnitById = ({ unitParam }) => ({
    unitParam,
    type: ActionTypes.SET_UNIT_BY_ID,
})

const addUpdateUnits = ({ unitData, actionName, UserParams }) => ({
    unitData,
    actionName,
    UserParams,
    type: ActionTypes.ADD_UPDATE_UNIT,
})

export default {
    addUpdateUnits,
    fetchAllUnits,
    setUnits,
    deleteUnits,
    getUnitById,
    setUnitById,
    setLoader
};