import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setCreditNotes = (state, { allCreditNoteData }) => {
    if (!allCreditNoteData) return state;
    return state.merge({ allCreditNoteData });
}

const setCreditNoteById = (state, { creditNoteParam }) => {
    return state.merge({ creditNoteParam });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

const setQuotationNoById = (state, { QuotationData }) => {
    return state.merge({ QuotationData });
}

export const callbacks = [
    {
        actionType: ActionTypes.SET_CREDIT_NOTES,
        actionFunction: setCreditNotes,
    },
    {
        actionType: ActionTypes.SET_CREDIT_NOTE_BY_ID,
        actionFunction: setCreditNoteById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
    {
        actionType: ActionTypes.SET_QUOTATION_NO_BY_ID,
        actionFunction: setQuotationNoById,
    },
];
