import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDebounce } from 'use-debounce';
import Table from "../../../core/pagination/datatable.js";
import AddReturnsModal from "./addreturns.js";
import IntlMessages from "../../../shared/components/utility/intlMessages.js";
import Swal from "sweetalert2";
import {
  ChevronUp,
  Filter,
  PlusCircle,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageWithBasePath from "../../../core/img/imagewithbasebath.js";
import CustomFormLabel from "../../common/CustomFormLabel";
import returnAction from '../../../core/Services/purchases/purchaseReturn/actions.js';
import { setToogleHeader } from "../../../core/redux/action.js";
import apiResponseAction from '../../../core/Services/apiResponse/actions.js';
import * as purchasesSelectors from '../purchasesSelector.js';
import * as ApiResponseSelectors from '../../selectors.js';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants.js";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const PurchasesReturnssList = () => {
  const dispatch = useDispatch();
  const [returnParams, setreturnParams] = useState({
    pageNo: 1,
    searchText: '',
  });
  const [isReturnsModalOpen, setReturnsModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [debouncedText] = useDebounce(returnParams, 1000);

  const isLoading = useSelector(state => purchasesSelectors.purchaseReturnLoader(state));
  const ReturnsData = useSelector(state => purchasesSelectors.setPurchaseReturns(state));
  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
  const dataSource = ReturnsData ? ReturnsData.toJS() : [];
  const data = useSelector((state) => state.toggle_header);

  useEffect(() => {
    if (apiResponseStatus == 200) {
      toggleUserModal();
    }
  }, [apiResponseStatus])

  useEffect(() => {
    dispatch(returnAction.fetchAllPurchaseReturns({ returnParams: debouncedText }));
  }, [dispatch]);

  const toggleReturnsModal = (value) => {
    setReturnsModalOpen();
  }

  const [selectedDate, setSelectedDate] = useState(new Date());

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const handleDataIntoExcel = (filename = 'Returnss.xlsx') => {
    if (dataSource && dataSource.length > 0) {

      const worksheet = XLSX.utils.json_to_sheet(dataSource);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = [['Sr.', 'supplierName', 'returnNo', 'returnDate', 'status', 'grandTotal', 'paid', 'due']];
  
  const tableData = dataSource.map((item, index) => [
    index + 1,
    item.supplierName,
    item.returnNo,
    item.returnDate,
    item.status,
    item.grandTotal,
    item.paid,
    item.due,
  ]);
  
    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    // Save the PDF using file-saver
    doc.save('Returnss.pdf');
  };

  const handleRefresh = () => {
    dispatch(returnAction.fetchAllPurchaseReturns({ returnParams }));
  }

  const columns = [
    {
      title:<IntlMessages id="purchasereturn.table.column.image" />,
      dataIndex: "img",
      render: (text, record) => (
       
          <Link to="#" className="product-img">
            <ImageWithBasePath alt="" src={record.img} />
          </Link>
       
      ),
      sorter: (a, b) => a.img.length - b.img.length,
    },
    {
      title:<IntlMessages id="purchasereturn.table.column.date" />,
      dataIndex: "date",
      sorter: (a, b) => a.date.length - b.date.length,
    },

    {
      title:<IntlMessages id="purchasereturn.table.column.supplier" />,
      dataIndex: "supplier",
      sorter: (a, b) => a.supplier.length - b.supplier.length,
    },
    {
      title:<IntlMessages id="purchasereturn.table.column.reference" />,
      dataIndex: "reference",
      sorter: (a, b) => a.reference.length - b.reference.length,
    },

    {
      title:<IntlMessages id="purchasereturn.table.column.status" />,
      dataIndex: "status",
      render: (text) => (
        <span
          className={`badges ${
            text === "Received" ? "status-badge" : "badge-bgdanger"
          }`}
        >
          {text}
        </span>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title:<IntlMessages id="purchasereturn.table.column.grandtotal" />,
      dataIndex: "grandTotal",
      sorter: (a, b) => a.grandTotal.length - b.grandTotal.length,
    },
    {
      title:<IntlMessages id="purchasereturn.table.column.paid" />,
      dataIndex: "paid",
      sorter: (a, b) => a.paid.length - b.paid.length,
    },
    {
      title:<IntlMessages id="purchasereturn.table.column.due" />,
      dataIndex: "due",
      sorter: (a, b) => a.due.length - b.due.length,
    },
    {
      title:<IntlMessages id="purchasereturn.table.column.paymentstatus" />,
      dataIndex: "paymentStatus",
      render: (text) => (
        <span
          className={`badges ${
            text === "Paid" ? "badge-linesuccess" : "badge-linedangered"
          }`}
        >
          {text}
        </span>
      ),
      sorter: (a, b) => a.paymentStatus.length - b.paymentStatus.length,
    },

    {
      title:<IntlMessages id="table.column.actions" />,
      dataIndex: "actions",
      key: "actions",
      render: () => (
        <div className="action-table-data">
        <div className="edit-delete-action">
          <Link className="me-2 p-2" to="#" data-bs-toggle="modal" data-bs-target="#edit-sales-new">
            <i data-feather="edit" className="feather-edit"></i>
          </Link>
          <Link className="confirm-text p-2" to="#" onClick={showConfirmationAlert}>
            <i data-feather="trash-2" className="feather-trash-2"></i>
          </Link>
        </div>													
      </div>
      ),
    },
  ];

  const handleModal = (returnData, actionName) => {

    const returnModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? returnData : {},
    };
    const status = 0;
    dispatch(apiResponseAction.setApiResponse({ apiStatus: status }))
    setCurrentModalData(returnModalObj);
    setReturnsModalOpen(true);
  };

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (row) => {

    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(returnAction.deleteReturnss({ returnParam: row }));
      } else {
        MySwal.close();
      }
    });
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4><IntlMessages id="purchases.returns.table.heading.purchases.returns" /></h4>
                <h6><IntlMessages id="purchases.returns.table.label.managepurchases.returns" /></h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link
                    onClick={() => handleGeneratePDF()}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={handleRefresh}>
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <a
                onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                className="btn btn-added"
              >
                <PlusCircle className="me-2" />
                <IntlMessages id="purchases.returns.table.button.addnewreturn" />
              </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
                <div className="search-path">
                  <Link
                    className={`btn btn-filter ${isFilterVisible ? "setclose" : ""
                      }`}
                    id="filter_search"
                  >
                    <Filter
                      className="filter-icon"
                      onClick={toggleFilterVisibility}
                    />
                    <span onClick={toggleFilterVisibility}>
                      <ImageWithBasePath
                        src="assets/img/icons/closes.svg"
                        alt="img"
                      />
                    </span>
                  </Link>
                </div>
              </div>
              {/* /Filter */}

              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                    <div className="input-blocks">
                      <button className="btn btn-filters ms-auto">
                        <i data-feather="search" className="feather-search" />{" "}
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {isReturnsModalOpen ? (
              <AddReturnsModal
                currentModalData={currentModalData}
                isModalOpen={isReturnsModalOpen}
                isUpdate={currentModalData.formData.id ? true : false}
                toggleModal={toggleReturnsModal}
              />
            ) : null}
            {/* /Filter */}
            <div className="table-responsive">
              <Table isLoading={isLoading} columns={columns} dataSource={dataSource} />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
    </>
  );
};

export default PurchasesReturnssList;