/* eslint no-unused-vars: "off" */
import Immutable from 'immutable';
import axios from './api';
import userTokenaxios from 'axios';
import imageUploadAxios from 'axios';
import qs from 'qs';
import getEnvDetail from '../Configurations/env.Config.json';
import { all_routes } from "../../Router/all_routes";

const Data = getEnvDetail['Production'].apiUrl;
const PLATFORM = Data;

let token;
const setToken = ({ value }) => {
  token = value;
};


const call = ({ method, route, data, params, api }) => {
  if (!api) api = PLATFORM;

  const operation = axios.request({
    baseURL: api,
    url: route,
    method,
    data,
    params,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
  });

  return new Promise((resolve, reject) => {
    operation
      .then(resp => {
        if (resp.status === 401) {
          // If the response status is 401, reroute to the sign-in page
          window.location.href = all_routes.signin;
          return; // stop further execution
        }
        if (resp.status >= 200 && resp.status < 300) {
          // Response was good! Let's parse it and resolve

          if (!resp.data) return resolve({ data: resp.data });

          const immutableJson = Immutable.fromJS(resp.data);
          const _data = immutableJson.get ? immutableJson.get('data') : immutableJson;
          if (_data) return resolve({ data: _data, meta: immutableJson.get && immutableJson.get('meta') });

          return resolve({ data: immutableJson, id: resp.data?.id });
        }

        // Response was bad, let's throw an error
        const error = new Error('SERVE-POS API Error!');
        error.details = Immutable.fromJS(resp.data);

        return reject(error);
      })
      .catch(err => {
        console.log(err, 'err');
        if (err?.response?.status === 401 || err.message==="Network Error") {
          // If the response status is 401, reroute to the sign-in page
          window.location.href = all_routes.signin;
          return; // stop further execution
        }
        const error = new Error('Error while fetching from SERVE-POS API');
        error.details = err;
        return reject(error);
      });
  });
};

const UploadImage = ({ method, route, data, params, api }) => {

  return new Promise((resolve, reject) => {
    imageUploadAxios.post(PLATFORM + route, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-api-version': 1,
        'Authorization': 'Bearer '+ localStorage.getItem('auth_token')
      }
    }).then(resp => {
      if (resp.status >= 200 && resp.status < 300) {
        // Response was good! Let's parse it and resolve

        if (!resp.data) return resolve({ data: resp.data });

        const immutableJson = Immutable.fromJS(resp.data);
        const _data = immutableJson.get ? immutableJson.get('data') : immutableJson;
        if (_data) return resolve({ data: _data, meta: immutableJson.get && immutableJson.get('meta') });

        return resolve({ data: immutableJson, id: resp.data?.id });
      }

      // Response was bad, let's throw an error
      const error = new Error('Serve-POS API Error!');
      error.details = Immutable.fromJS(resp.data);

      return reject(error);
    })
      .catch(err => {
        console.log(err, 'err');
        const error = new Error('Error while fetching from SERVER-POS API');
        error.details = err;
        return reject(error);
      });
  });
};

const signupUserCall = ({ method, route, data, params, api }) => {

  return new Promise((resolve, reject) => {
    userTokenaxios.post(PLATFORM + route, data, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-version': 1
      }
    }).then(resp => {
      if (resp.status >= 200 && resp.status < 300) {
        // Response was good! Let's parse it and resolve

        if (!resp.data) return resolve({ data: resp.data });

        const immutableJson = Immutable.fromJS(resp.data);
        const _data = immutableJson.get ? immutableJson.get('data') : immutableJson;
        if (_data) return resolve({ data: _data, meta: immutableJson.get && immutableJson.get('meta') });

        return resolve({ data: immutableJson, id: resp.data?.id });
      }

      // Response was bad, let's throw an error
      const error = new Error('Serve-POS API Error!');
      error.details = Immutable.fromJS(resp.data);

      return reject(error);
    })
      .catch(err => {
        console.log(err, 'err');
        const error = new Error('Error while fetching from SERVER-POS API');
        error.details = err;
        return reject(error);
      });
  });
};

const userTokenCall = ({ method, route, data, params, api }) => {

  return new Promise((resolve, reject) => {
    userTokenaxios.post(PLATFORM + route, {
      email: params.email,
      password: params.password
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-version': 1
      }
    }).then(resp => {
      if (resp.status >= 200 && resp.status < 300) {
        // Response was good! Let's parse it and resolve

        if (!resp.data) return resolve({ data: resp.data });

        const immutableJson = Immutable.fromJS(resp.data);
        const _data = immutableJson.get ? immutableJson.get('data') : immutableJson;
        if (_data) return resolve({ data: _data, meta: immutableJson.get && immutableJson.get('meta') });

        return resolve({ data: immutableJson, id: resp.data?.id });
      }

      // Response was bad, let's throw an error
      const error = new Error('Serve-POS API Error!');
      error.details = Immutable.fromJS(resp.data);

      return reject(error);
    })
      .catch(err => {
        console.log(err, 'err');
        const error = new Error('Error while fetching from SERVER-POS API');
        error.details = err;
        return reject(error);
      });
  });
};

const postUserToken = ({ route, api, params, data }) => {
  return userTokenCall({ method: 'POST', route, api, params, data });
};
const postSignupUser = ({ route, api, params, data }) => {
  return signupUserCall({ method: 'POST', route, api, params, data });
};

const postuploadtImage = ({ route, api, params, data }) => {
  return UploadImage({ method: 'POST', route, api, params, data });
};

const post = ({ route, api, params, data }) => {
  return call({ method: 'POST', route, api, params, data });
};
const put = ({ route, api, params, data }) => {
  console.log('Printing data in put request in tt api: ', data);
  return call({ method: 'PUT', route, api, params, data });
};

const get = ({ route, api, params }) => {
  return call({ method: 'GET', route, api, params });
};

const patch = ({ route, api, params, data }) => {
  return call({ method: 'PATCH', route, api, params, data });
};

const _delete = ({ route, api, params, data }) => {
  return call({ method: 'DELETE', route, api, params, data });
};

export default {
  call,
  userTokenCall,
  postSignupUser,
  postUserToken,
  UploadImage,
  postuploadtImage,
  post,
  put,
  get,
  patch,
  delete: _delete,
  setToken,
  PLATFORM,
};
