import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setSuppliers = (state, {allSupplierData}) => {
    if (!allSupplierData) return state;
    return state.merge({allSupplierData});
}

const setSupplierById = (state, {supplierParam}) => {
    return state.merge({supplierParam});
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

const setUploadProfileImage = (state, { supplierImage }) => {
    if (!supplierImage) return state;
    return state.merge({ supplierImage });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_SUPPLIERS,
        actionFunction: setSuppliers,
    },
    {
        actionType: ActionTypes.SET_SUPPLIERS_BY_ID,
        actionFunction: setSupplierById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
    {
        actionType: ActionTypes.SET_UPLOAD_PROFILE_IMAGE,
        actionFunction: setUploadProfileImage,
    },
];