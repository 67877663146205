export const ActionTypes = {
    ADD_UPDATE_ATTENDANCE: 'services/hrm/attendance/add_update_attendance',
    FETCH_ATTENDANCE: 'services/hrm/attendance/fetch_attendance',
    SET_ATTENDANCE: 'services/hrm/attendance/set_attendance',
    DELETE_ATTENDANCE: 'services/hrm/attendance/delete_attendance',
    GET_EMPLOYEE_ATTENDANCE_BY_ID: 'services/inventory/units/get_employee_attandance_by_id',
    SET_EMPLOYEE_ATTENDANCE_BY_ID: 'services/inventory/units/set_employee_attandance_by_id',
    SET_LOADER: 'services/hrm/attendance/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllAttendance = ({ attendanceParams }) => ({
    attendanceParams,
    type: ActionTypes.FETCH_ATTENDANCE,
})

const setAttendance = ({ allAttendanceData }) => ({
    allAttendanceData,
    type: ActionTypes.SET_ATTENDANCE,
})

const deleteAttendance = ({ attendanceParam }) => ({
    attendanceParam,
    type: ActionTypes.DELETE_ATTENDANCE,
})

const addUpdateAttendance = ({ attendanceData, actionName, UserParams }) => ({
    attendanceData,
    actionName,
    UserParams,
    type: ActionTypes.ADD_UPDATE_ATTENDANCE,
})

const getEmployeeAttendanceById = ({ employeeAttendanceId }) => ({
    employeeAttendanceId,
    type: ActionTypes.GET_EMPLOYEE_ATTENDANCE_BY_ID,
})

const setEmployeeAttendanceById = ({ employeeAttendance }) => ({
    employeeAttendance,
    type: ActionTypes.SET_EMPLOYEE_ATTENDANCE_BY_ID,
})

export default {
    addUpdateAttendance,
    fetchAllAttendance,
    setAttendance,
    deleteAttendance,
    getEmployeeAttendanceById,
    setEmployeeAttendanceById,
    setLoader
};