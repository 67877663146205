import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setUsers = (state, {allUserData}) => {
    if (!allUserData) return state;
    return state.merge({allUserData})
}

const setUsersData = (state, {userData}) => {
  if (!userData) return state;
  return state.merge({userData})
}

const setUserById = (state, {userParam}) => {
    return state.merge({userParam})
}

const setTokenByUsernamePassword = (state, {userLoginParam}) => {
    if(!userLoginParam) return state;
    return state.merge({userLoginParam})
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
  };

  const setUserRoles = (state, { allUserRole }) => {
    if (!allUserRole) return state;
    return state.merge({ allUserRole });
  };

  const setUploadProfileImage = (state, { userImage }) => {
    if (!userImage) return state;
    return state.merge({ userImage });
  };
  
  const setDefaultMenu = (state, { allDefaultMenu }) => {
    if (!allDefaultMenu) return state;
    return state.merge({ allDefaultMenu });
  };

  const setDefaultMenuByUserId = (state, { allUserMenu }) => {
    if (!allUserMenu) return state;
    return state.merge({ allUserMenu });
  };

export const callbacks =[
  {
    actionType: ActionTypes.SET_USER_DATA,
    actionFunction: setUsersData,
},
    {
        actionType: ActionTypes.SET_USERS,
        actionFunction: setUsers,
    },
    {
        actionType: ActionTypes.SET_USER_BY_ID,
        actionFunction: setUserById,
    },
    {
        actionType: ActionTypes.SET_TOKEN_BY_USERNAME_AND_PASSWORD,
        actionFunction: setTokenByUsernamePassword,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
      },
      {
        actionType: ActionTypes.SET_USER_ROLE,
        actionFunction: setUserRoles,
      },
      {
        actionType: ActionTypes.SET_UPLOAD_PROFILE_IMAGE,
        actionFunction: setUploadProfileImage,
      },
      {
        actionType: ActionTypes.SET_DEFAULT_MENU,
        actionFunction: setDefaultMenu,
      },
      {
        actionType: ActionTypes.SET_DEFAULT_MENU_BY_USER_ID,
        actionFunction: setDefaultMenuByUserId,
      },
]