export const ActionTypes = {
    ADD_UPDATE_ASSETTYPE: 'services/inventory/assettypes/add_update_assettype',
    FETCH_ASSETTYPE: 'services/inventory/assettypes/fetch_assettypes',
    SET_ASSETTYPES: 'services/inventory/assettypes/set_assettypes',
    DELETE_ASSETTYPES: 'services/inventory/assettypes/delete_assettypes',
    SET_LOADER: 'services/inventory/inventory/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllAssetTypes = ({ assettypeParams }) => ({
    assettypeParams,
    type: ActionTypes.FETCH_ASSETTYPE,
})

const setAssetTypes = ({ allAssettypeData }) => ({
    allAssettypeData,
    type: ActionTypes.SET_ASSETTYPES,
})

const deleteAssetTypes = ({ assettypeParam }) => ({
    assettypeParam,
    type: ActionTypes.DELETE_ASSETTYPES,
})

const addUpdateAssetTypes = ({ assettypeData, actionName, UserParams }) => ({
    assettypeData,
    actionName,
    UserParams,
    type: ActionTypes.ADD_UPDATE_ASSETTYPE,
})

export default {
    addUpdateAssetTypes,
    fetchAllAssetTypes,
    setAssetTypes,
    deleteAssetTypes,
    setLoader
};
