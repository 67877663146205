export const ActionTypes = {
    ADD_UPDATE_WARRANTY: 'services/warranties/add_update_warranty',
    FETCH_WARRANTY: 'services/warranties/fetch_warranties',
    SET_WARRANTIES: 'services/warranties/set_warranties',
    DELETE_WARRANTIES: 'services/warranties/delete_warranties',
    GET_WARRANTY_BY_ID: 'services/warranties/get_warranty_by_id',
    SET_WARRANTY_BY_ID: 'services/warranties/set_warranty_by_id',
    SET_LOADER: 'services/warranties/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllWarranties = ({ warrantyParams }) => ({
    warrantyParams,
    type: ActionTypes.FETCH_WARRANTY,
})

const setWarranties = ({ allWarrantyData }) => ({
    allWarrantyData,
    type: ActionTypes.SET_WARRANTIES,
})

const deleteWarranties = ({ warrantyParam }) => ({
    warrantyParam,
    type: ActionTypes.DELETE_WARRANTIES,
})

const getWarrantyById = ({ warrantyParam }) => ({
    warrantyParam,
    type: ActionTypes.GET_WARRANTY_BY_ID,
})

const setWarrantyById = ({ warrantyParam }) => ({
    warrantyParam,
    type: ActionTypes.SET_WARRANTY_BY_ID,
})

const addUpdateWarranties = ({ warrentyData, actionName, warrantyParams }) => ({
    warrentyData,
    actionName,
    warrantyParams,
    type: ActionTypes.ADD_UPDATE_WARRANTY,
})

export default {
    addUpdateWarranties,
    fetchAllWarranties,
    setWarranties,
    deleteWarranties,
    getWarrantyById,
    setWarrantyById,
    setLoader
}
