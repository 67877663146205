export const ActionTypes = {
  ADD_UPDATE_PURCHASE_INVOICE: 'services/purchases/purchaseInvoice/add_update_purchaseInvoice',
  FETCH_PURCHASE_INVOICE: 'services/purchases/purchaseInvoice/fetch_purchaseInvoices',
  SET_PURCHASES_INVOICE: 'services/purchases/purchaseInvoice/set_purchaseInvoices',
  DELETE_PURCHASES_INVOICE: 'services/purchases/purchaseInvoice/delete_purchaseInvoices',
  GET_PURCHASE_INVOICE_BY_ID: 'services/purchases/purchaseInvoice/get_purchaseInvoice_by_id',
  SET_PURCHASE_INVOICE_BY_ID: 'services/purchases/purchaseInvoice/set_purchaseInvoice_by_id',
  SET_QUOTATION_NO_BY_ID: 'services/purchases/purchaseInvoice/set_quotation_no_by_id',
  GET_QUOTATION_NO_BY_ID: 'services/purchases/purchaseInvoice/get_quotation_no_by_id',
  SET_LOADER: 'services/inventory/inventory/set_loader',
}

const setLoader = ({ showLoader }) => ({
  showLoader,
  type: ActionTypes.SET_LOADER,
});

const fetchAllPurchaseInvoices = ({ purchaseInvoiceParams }) => ({
  purchaseInvoiceParams,
  type: ActionTypes.FETCH_PURCHASE_INVOICE,
})

const setPurchaseInvoices = ({ allPurchaseInvoiceData }) => ({
  allPurchaseInvoiceData,
  type: ActionTypes.SET_PURCHASES_INVOICE,
})

const deletePurchaseInvoices = ({ purchaseInvoiceParam }) => ({
  purchaseInvoiceParam,
  type: ActionTypes.DELETE_PURCHASES_INVOICE,
})

const getQuotationNoById = ({ QnParam }) => ({
  QnParam,
  type: ActionTypes.GET_QUOTATION_NO_BY_ID,
})

const setQuotationNoById = ({ QuotationData }) => ({
  QuotationData,
  type: ActionTypes.SET_QUOTATION_NO_BY_ID,
})

const addUpdatePurchaseInvoice = ({ purchaseInvoiceData, actionName, UserParams }) => ({
  purchaseInvoiceData,
  actionName,
  UserParams,
  type: ActionTypes.ADD_UPDATE_PURCHASE_INVOICE,
})

const getPurchaseInvoiceById = ({ purchaseInvoiceParam }) => ({
  purchaseInvoiceParam,
  type: ActionTypes.GET_PURCHASE_INVOICE_BY_ID,
})

const setPurchaseInvoiceById = ({ purchaseInvoiceParam }) => ({
  purchaseInvoiceParam,
  type: ActionTypes.SET_PURCHASE_INVOICE_BY_ID,
})

export default {
  addUpdatePurchaseInvoice,
  fetchAllPurchaseInvoices,
  setPurchaseInvoices,
  deletePurchaseInvoices,
  getPurchaseInvoiceById,
  setPurchaseInvoiceById,
  setQuotationNoById,
  getQuotationNoById,
  setLoader
};
