import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setPayrolls = (state, { allPayrollData }) => {
    if (!allPayrollData) return state;
    return state.merge({ allPayrollData });
}

const setPayslip = (state, { allpayslipData }) => {
    if (!allpayslipData) return state;
    return state.merge({ allpayslipData });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_PAYROLLS,
        actionFunction: setPayrolls,
    },
    {
        actionType: ActionTypes.SET_PAYSLIP,
        actionFunction: setPayslip,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
