import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setCustomers = (state, { allCustomerData }) => {
    if (!allCustomerData) return state;
    return state.merge({ allCustomerData });
}

const setCustomerById = (state, { customerParam }) => {
    return state.merge({ customerParam });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

const setUploadProfileImage = (state, { customerImage }) => {
    if (!customerImage) return state;
    return state.merge({ customerImage });
  };
export const callbacks = [
    {
        actionType: ActionTypes.SET_CUSTOMERS,
        actionFunction: setCustomers,
    },
    {
        actionType: ActionTypes.SET_CUSTOMER_BY_ID,
        actionFunction: setCustomerById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
    {
        actionType: ActionTypes.SET_UPLOAD_PROFILE_IMAGE,
        actionFunction: setUploadProfileImage,
    },
];