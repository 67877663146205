export const setExpenses = state => {
    return state.services.expense.getIn(['allExpenseData']);
 };
 export const setExpenseById = state => {
    return state.services.expense.getIn(['expenseParam']);
 };
 export const setexpenseLoader = state => {
    return state.services.expense.getIn(['showLoader']);
 };
 export const setExpCategories = state => {
    return state.services.expensesCategory.getIn(['allExpCategoryData']);
 };
 export const setExpCategoryById = state => {
    return state.services.expensesCategory.getIn(['expCategoryParam']);
 };

 export const setLoader = state => {
    return state.services.expensesCategory.getIn(['showLoader']);
 };
