import Immutable from 'immutable';
import { ActionTypes } from "./actions";

export const initialState = Immutable.fromJS({});

const setExpenses = (state, { allExpenseData }) => {
    if (!allExpenseData) return state;
    return state.merge({ allExpenseData });
}

const setExpenseById = (state, { expenseParam }) => {
    return state.merge({ expenseParam });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_EXPENSES,
        actionFunction: setExpenses,
    },
    {
        actionType: ActionTypes.SET_EXPENSE_BY_ID,
        actionFunction: setExpenseById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
