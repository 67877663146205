export const ActionTypes = {
    ADD_UPDATE_DEPARTMENT: 'services/hrm/department/add_update_department',
    FETCH_DEPARTMENT: 'services/hrm/department/fetch_departments',
    SET_DEPARTMENTS: 'services/hrm/department/set_departments',
    DELETE_DEPARTMENTS: 'services/hrm/department/delete_departments',
    SET_LOADER: 'services/common/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllDepartments = ({ departmentParams }) => ({
    departmentParams,
    type: ActionTypes.FETCH_DEPARTMENT,
})

const setDepartments = ({ allDepartmentData }) => ({
    allDepartmentData,
    type: ActionTypes.SET_DEPARTMENTS,
})

const deleteDepartment = ({ departmentParam }) => ({
    departmentParam,
    type: ActionTypes.DELETE_DEPARTMENTS,
})

const addUpdateDepartments = ({ departmentData, actionName, departmentParams }) => ({
    departmentData,
    actionName,
    departmentParams,
    type: ActionTypes.ADD_UPDATE_DEPARTMENT,
})

export default {
    addUpdateDepartments,
    fetchAllDepartments,
    setDepartments,
    deleteDepartment,
    setLoader
};
