export const ActionTypes = {
    ADD_UPDATE_QUOTATION: 'services/purchases/quotations/add_update_quotation',
    FETCH_QUOTATION: 'services/purchases/quotations/fetch_quotations',
    SET_QUOTATIONS: 'services/purchases/quotations/set_quotations',
    DELETE_QUOTATIONS: 'services/purchases/quotations/delete_quotations',
    GET_QUOTATION_BY_ID: 'services/purchases/quotations/get_quotation_by_id',
    SET_QUOTATION_BY_ID: 'services/purchases/quotations/set_quotation_by_id',
    SET_LOADER: 'services/purchases/quotations/set_loader',
  }
  
  const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
  });
  
  const fetchAllQuotations = ({ quotationParams }) => ({
    quotationParams,
    type: ActionTypes.FETCH_QUOTATION,
  })
  
  const setQuotations = ({ allQuotationData }) => ({
    allQuotationData,
    type: ActionTypes.SET_QUOTATIONS,
  })
  
  const deleteQuotations = ({ quotationParam }) => ({
    quotationParam,
    type: ActionTypes.DELETE_QUOTATIONS,
  })
  
  const getQuotationById = ({ quotationParam }) => ({
    quotationParam,
    type: ActionTypes.GET_QUOTATION_BY_ID,
  })
  
  const setQuotationById = ({ quotationParam }) => ({
    quotationParam,
    type: ActionTypes.SET_QUOTATION_BY_ID,
  })
  
  const addUpdateQuotations = ({ quotationData, actionName, UserParams }) => ({
    quotationData,
    actionName,
    UserParams,
    type: ActionTypes.ADD_UPDATE_QUOTATION,
  })
  
  export default {
    addUpdateQuotations,
    fetchAllQuotations,
    setQuotations,
    setQuotationById,
    deleteQuotations,
    getQuotationById,
    setQuotationById,
    setLoader
  }