export const ActionTypes = {
  ADD_UPDATE_EXPCATEGORY: 'services/financeAccounts/expCategories/add_update_expCategory',
  FETCH_EXPCATEGORY: 'services/financeAccounts/expCategories/fetch_expCategories',
  SET_EXPCATEGORIES: 'services/financeAccounts/expCategories/set_expCategories',
  DELETE_EXPCATEGORIES: 'services/financeAccounts/expCategories/delete_expCategories',
  GET_EXPCATEGORY_BY_ID: 'services/financeAccounts/expCategories/get_expCategory_by_id',
  SET_EXPCATEGORY_BY_ID: 'services/financeAccounts/expCategories/set_expCategory_by_id',
  SET_LOADER: 'services/people/financeAccounts/set_loader',
}

const setLoader = ({ showLoader }) => ({
  showLoader,
  type: ActionTypes.SET_LOADER,
})

const fetchAllExpCategories = ({ expCategoryParams }) => ({
  expCategoryParams,
  type: ActionTypes.FETCH_EXPCATEGORY,
})

const setExpCategories = ({ allExpCategoryData }) => ({
  allExpCategoryData,
  type: ActionTypes.SET_EXPCATEGORIES,
})

const deleteExpCategoryById = ({ expCategoryParam }) => ({
  expCategoryParam,
  type: ActionTypes.DELETE_EXPCATEGORIES,
})

const getExpCategoryById = ({ expCategoryParam }) => ({
  expCategoryParam,
  type: ActionTypes.GET_EXPCATEGORY_BY_ID,
})

const setExpCategoryById = ({ expCategoryParam }) => ({
  expCategoryParam,
  type: ActionTypes.SET_EXPCATEGORY_BY_ID,
})

const addUpdateExpCategories = ({ expCategoryData, actionName, UserParams }) => ({
  expCategoryData,
  actionName,
  UserParams,
  type: ActionTypes.ADD_UPDATE_EXPCATEGORY,
})

export default {
  addUpdateExpCategories,
  fetchAllExpCategories,
  setExpCategories,
  deleteExpCategoryById,
  getExpCategoryById,
  setExpCategoryById,
  setLoader
}
