import Immutable from 'immutable';
import { ActionTypes } from "./actions.js";
export const initialState = Immutable.fromJS({});

const setQuotations = (state, { allQuotationData }) => {
    if (!allQuotationData) return state;
    return state.merge({ allQuotationData });
}

const setQuotationById = (state, { quotationParam }) => {
    return state.merge({ quotationParam });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_QUOTATIONS,
        actionFunction: setQuotations,
    },
    {
        actionType: ActionTypes.SET_QUOTATION_BY_ID,
        actionFunction: setQuotationById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
