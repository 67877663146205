import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../shifts/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdateShifts({ shiftData, actionName, shiftParams }) {
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/HRM/edit-shift`,
        data: shiftData,
      });
    } else {
      yield call(api.post, {
        route: '/HRM/create-shift',
        data: shiftData,
      });
    }
    
    yield put(Actions.fetchAllShifts({ shiftParams }));
    notification('success', actionName === 'update' ? CONSTANT_VARIABLES.SHIFT_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.SHIFT_CREATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllShifts({ shiftParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch shifts
    const { data } = yield call(api.get, {
      route: '/HRM/all-shifts',
      params: shiftParams,
    });

    // Store the fetched data in Redux store
    const allShiftData = data;
    yield put(Actions.setShifts({ allShiftData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deleteShiftById({ shiftParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/HRM/delete-shift/` + shiftParam.id,
    });
    yield put(Actions.fetchAllShifts({ shiftParam }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.SHIFT_DELETED_SUCCESSFULLY);
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_SHIFT, fetchAllShifts),
    takeLatest(ActionTypes.ADD_UPDATE_SHIFT, addUpdateShifts),
    takeLatest(ActionTypes.DELETE_SHIFTS, deleteShiftById)
  ]);
}