export const ActionTypes = {
  ADD_UPDATE_PURCHASE_RETURN: 'services/purchases/purchaseReturn/add_update_purchaseReturn',
  FETCH_PURCHASE_RETURN: 'services/purchases/purchaseReturn/fetch_purchaseReturns',
  SET_PURCHASES_RETURN: 'services/purchases/purchaseReturn/set_purchaseReturns',
  DELETE_PURCHASES_RETURN: 'services/purchases/purchaseReturn/delete_purchaseReturns',
  GET_PURCHASE_RETURN_BY_ID: 'services/purchases/purchaseReturn/get_purchaseReturn_by_id',
  SET_PURCHASE_RETURN_BY_ID: 'services/purchases/purchaseReturn/set_purchaseReturn_by_id',
  SET_QUOTATION_NO_BY_ID: 'services/purchases/purchaseReturn/set_quotation_no_by_id',
  GET_QUOTATION_NO_BY_ID: 'services/purchases/purchaseReturn/get_quotation_no_by_id',
  SET_LOADER: 'services/inventory/inventory/set_loader',
}

const setLoader = ({ showLoader }) => ({
  showLoader,
  type: ActionTypes.SET_LOADER,
});

const fetchAllPurchaseReturns = ({ purchaseReturnParams }) => ({
  purchaseReturnParams,
  type: ActionTypes.FETCH_PURCHASE_RETURN,
})

const setPurchaseReturns = ({ allPurchaseReturnData }) => ({
  allPurchaseReturnData,
  type: ActionTypes.SET_PURCHASES_RETURN,
})

const deletePurchaseReturns = ({ purchaseReturnParam }) => ({
  purchaseReturnParam,
  type: ActionTypes.DELETE_PURCHASES_RETURN,
})

const getQuotationNoById = ({ QnParam }) => ({
  QnParam,
  type: ActionTypes.GET_QUOTATION_NO_BY_ID,
})

const setQuotationNoById = ({ QuotationData }) => ({
  QuotationData,
  type: ActionTypes.SET_QUOTATION_NO_BY_ID,
})

const addUpdatePurchaseReturn = ({ purchaseReturnData, actionName, UserParams }) => ({
  purchaseReturnData,
  actionName,
  UserParams,
  type: ActionTypes.ADD_UPDATE_PURCHASE_RETURN,
})

const getPurchaseReturnById = ({ purchaseReturnParam }) => ({
  purchaseReturnParam,
  type: ActionTypes.GET_PURCHASE_RETURN_BY_ID,
})

const setPurchaseReturnById = ({ purchaseReturnParam }) => ({
  purchaseReturnParam,
  type: ActionTypes.SET_PURCHASE_BY_RETURN_ID,
})

export default {
  addUpdatePurchaseReturn,
  fetchAllPurchaseReturns,
  setPurchaseReturns,
  deletePurchaseReturns,
  getPurchaseReturnById,
  setPurchaseReturnById,
  setQuotationNoById,
  getQuotationNoById,
  setLoader
};
