export const ActionTypes = {
    ADD_UPDATE_PAYROLL: 'services/hrm/payroll/add_update_payroll',
    FETCH_PAYROLL: 'services/hrm/payroll/fetch_payrolls',
    SET_PAYROLLS: 'services/hrm/payroll/set_payrolls',
    FETCH_PAYSLIP: 'services/hrm/payroll/fetch_payslip',
    SET_PAYSLIP: 'services/hrm/payroll/set_payslip',
    DELETE_PAYROLLS: 'services/hrm/payroll/delete_payrolls',
    GET_PAYROLL_BY_ID: 'services/hrm/payroll/get_payroll_by_id',
    SET_PAYROLL_BY_ID: 'services/payroll/set_payroll_by_id',
    SET_LOADER: 'services/hrm/payroll/set_loader',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllPayrolls = ({ payrollParams }) => ({
    payrollParams,
    type: ActionTypes.FETCH_PAYROLL,
})

const setPayrolls = ({ allPayrollData }) => ({
    allPayrollData,
    type: ActionTypes.SET_PAYROLLS,
})
const fetchAllPayslip = ({ employeeId }) => ({
    employeeId,
    type: ActionTypes.FETCH_PAYSLIP,
})

const setPayslip = ({ allpayslipData }) => ({
    allpayslipData,
    type: ActionTypes.SET_PAYSLIP,
})

const deletePayrolls = ({ payrollParam }) => ({
    payrollParam,
    type: ActionTypes.DELETE_PAYROLLS,
})

const addUpdatePayrolls = ({ payrollData, actionName, payrollParams }) => ({
    payrollData,
    actionName,
    payrollParams,
    type: ActionTypes.ADD_UPDATE_PAYROLL,
})

export default {
    addUpdatePayrolls,
    fetchAllPayrolls,
    setPayrolls,
    deletePayrolls,
    fetchAllPayslip,
    setPayslip,
    setLoader
};
