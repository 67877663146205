import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { all_routes } from "./all_routes";

const ProtectedRoute = ({ element }) => {
    const token = localStorage.getItem('auth_token');
  
    return token ? element : <Navigate to={all_routes.signin} />;
};

export default ProtectedRoute;