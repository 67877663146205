import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../leave/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdateLeave({ leaveData, actionName, leaveParams }) {
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/HRM/edit-leave`,
        data: leaveData,
      });
    } else {
      yield call(api.post, {
        route: '/HRM/create-leave',
        data: leaveData,
      });
    }
    
    yield put(Actions.fetchAllLeave({ leaveParams }));
    notification('success', actionName === 'update' ? CONSTANT_VARIABLES.LEAVE_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.LEAVE_CREATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* addUpdateLeaveType({ leaveTypeData, actionName, leaveTypeParams }) {
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/HRM/edit-leavetype`,
        data: leaveTypeData,
      });
    } else {
      yield call(api.post, {
        route: '/HRM/create-leavetype',
        data: leaveTypeData,
      });
    }
    
    yield put(Actions.fetchAllLeaveTypes({ leaveTypeParams }));
    notification('success', actionName === 'update' ? "Leave Type Updated Successfully" : "Leave Type Created Successfully");
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllLeave({ leaveParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch leave records
    const { data } = yield call(api.get, {
      route: '/HRM/all-leavetypes',
      params: leaveParams,
    });

    // Store the fetched data in Redux store
    const allLeaveData = data;
    yield put(Actions.setLeave({ allLeaveData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* fetchAllLeaveType({ leaveTypeParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch leave records
    const { data } = yield call(api.get, {
      route: '/HRM/all-leavetypes',
      params: leaveTypeParams,
    });

    // Store the fetched data in Redux store
    const allLeaveTypeData = data;
    yield put(Actions.setLeaveTypes({ allLeaveTypeData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deleteLeaveById({ leaveParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/HRM/delete-leavetype/` + leaveParam.id,
    });
    yield put(Actions.fetchAllLeave({ leaveParam }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.LEAVE_DELETED_SUCCESSFULLY);
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function* deleteLeaveTypeById({ leaveTypeParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/HRM/delete-leavetype/` + leaveTypeParam.id,
    });
    yield put(Actions.fetchAllLeaveTypes({ leaveTypeParam }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', "Leave Type Deleted Successfully");
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_LEAVE, fetchAllLeave),
    takeLatest(ActionTypes.FETCH_LEAVE_TYPE, fetchAllLeaveType),
    takeLatest(ActionTypes.ADD_UPDATE_LEAVE, addUpdateLeave),
    takeLatest(ActionTypes.ADD_UPDATE_LEAVE_TYPE, addUpdateLeaveType),
    takeLatest(ActionTypes.DELETE_LEAVE, deleteLeaveById),
    takeLatest(ActionTypes.DELETE_LEAVE_TYPE, deleteLeaveTypeById)
  ]);
}
