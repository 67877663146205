export const ActionTypes = {
    ADD_UPDATE_STORES: 'services/people/stores/add_update_store',
    FETCH_STORES: 'services/people/stores/fetch_stores',
    SET_STORES: 'services/people/stores/set_stores',
    DELETE_STORES: 'services/people/stores/delete_stores',
    GET_STORES_BY_ID: 'services/people/stores/get_store_by_id',
    SET_STORES_BY_ID: 'services/people/stores/set_store_by_id',
    SET_LOADER: 'services/people/stores/set_loader',
    UPLOAD_PROFILE_IMAGE: 'services/people/stores/upload_profile_image',
    SET_UPLOAD_PROFILE_IMAGE: 'services/people/stores/set_upload_profile_image',
}

const addUpdateStores = ({ storeData, actionName, UserParams }) => ({
    storeData,
    actionName,
    UserParams,
    type: ActionTypes.ADD_UPDATE_STORES,
})

const fetchAllStores = ({ storeParams }) => ({
    storeParams,
    type: ActionTypes.FETCH_STORES,
})

const setStores = ({ allStoresData }) => ({
    allStoresData,
    type: ActionTypes.SET_STORES,
})

const deleteStores = ({ storeParam }) => ({
    storeParam,
    type: ActionTypes.DELETE_STORES
})

const getStoreById = ({ storeParam }) => ({
    storeParam,
    type: ActionTypes.GET_STORE_BY_ID
})

const setStoreById = ({ storeParam }) => ({
    storeParam,
    type: ActionTypes.SET_STORE_BY_ID
})

const uploadProfileImage = ({ storeImage }) => ({
    storeImage,
    type: ActionTypes.UPLOAD_PROFILE_IMAGE
});

const setUploadProfileImage = ({ storeImage }) => ({
    storeImage,
    type: ActionTypes.SET_UPLOAD_PROFILE_IMAGE
});

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

export default {
    addUpdateStores,
    fetchAllStores,
    setStores,
    setStoreById,
    deleteStores,
    getStoreById,
    setStoreById,
    setLoader,
    uploadProfileImage,
    setUploadProfileImage
}