export const setTokenByUsernamePassword = state => {
    return state.services.userManagement.getIn(['userLoginParam']);
  };

  export const userManagementLoader = state => {
    return state.services.userManagement.getIn(['showLoader']);
  };

  export const userData = state => {
     return state.services.userManagement.getIn(['allUserData']);
  };
  export const setUsersData = state => {
     return state.services.userManagement.getIn(['userData']);
  };

  export const userRoleData = state => {
    return state.services.userManagement.getIn(['allUserRole']);
 };

 export const userProfileImage = state => {
  return state.services.userManagement.getIn(['userImage']);
};

 export const setDefaultMenu = state => {
  return state.services.userManagement.getIn(['allDefaultMenu']);
};

 export const setDefaultMenuByUserId = state => {
  return state.services.userManagement.getIn(['allUserMenu']);
};
