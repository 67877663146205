import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../assettypes/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdateAssetTypes({ assettypeData, actionName, assettypeParams }) {
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/Inventory/edit-assettype`,
        data: assettypeData,
      });
    } else {
      yield call(api.post, {
        route: '/Inventory/create-assettype',
        data: assettypeData,
      });
    }
    
    yield put(Actions.fetchAllAssetTypes({ assettypeParams }));
    notification('success', actionName === 'update' ? CONSTANT_VARIABLES.ASSET_TYPE_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.ASSET_TYPE_CREATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllAssetTypes({ assettypeParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch asset types
    const { data } = yield call(api.get, {
      route: '/Purchases/all-assettypes',
      params: assettypeParams,
    });

    // Store the fetched data in Redux store
    const allAssettypeData = data;
    yield put(Actions.setAssetTypes({ allAssettypeData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deleteAssetTypeById({ assettypeParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/Inventory/delete-assettype/` + assettypeParam.id,
    });
    yield put(Actions.fetchAllAssetTypes({assettypeParam}));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.ASSET_TYPE_DELETED_SUCCESSFULLY);
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_ASSETTYPE, fetchAllAssetTypes),
    takeLatest(ActionTypes.ADD_UPDATE_ASSETTYPE, addUpdateAssetTypes),
    takeLatest(ActionTypes.DELETE_ASSETTYPES, deleteAssetTypeById)
  ]);
}
