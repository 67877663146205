export const ActionTypes = {
    ADD_UPDATE_SUPPLIERS: 'services/people/suppliers/add_update_supplier',
    FETCH_SUPPLIERS: 'services/people/suppliers/fetch_suppliers',
    SET_SUPPLIERS: 'services/people/suppliers/set_suppliers',
    DELETE_SUPPLIERS: 'services/people/suppliers/delete_suppliers',
    GET_SUPPLIERS_BY_ID: 'services/people/suppliers/get_supplier_by_id',
    SET_SUPPLIERS_BY_ID: 'services/people/suppliers/set_supplier_by_id',
    SET_LOADER: 'services/people/suppliers/set_loader',
    UPLOAD_PROFILE_IMAGE: 'services/people/suppliers/upload_profile_image',
    SET_UPLOAD_PROFILE_IMAGE: 'services/people/suppliers/set_upload_profile_image',
}

const addUpdateSuppliers = ({ supplierData, actionName, UserParams }) => ({
    supplierData,
    actionName,
    UserParams,
    type: ActionTypes.ADD_UPDATE_SUPPLIERS,
})

const fetchAllSuppliers = ({ supplierParams }) => ({
    supplierParams,
    type: ActionTypes.FETCH_SUPPLIERS,
})

const setSuppliers = ({ allSupplierData }) => ({
    allSupplierData,
    type: ActionTypes.SET_SUPPLIERS,
})

const deleteSuppliers = ({ supplierParam }) => ({
    supplierParam,
    type: ActionTypes.DELETE_SUPPLIERS
})

const getSupplierById = ({ supplierParam }) => ({
    supplierParam,
    type: ActionTypes.GET_SUPPLIER_BY_ID
})

const setSupplierById = ({ supplierParam }) => ({
    supplierParam,
    type: ActionTypes.SET_SUPPLIER_BY_ID
})

const uploadProfileImage = ({ supplierImage }) => ({
    supplierImage,
    type: ActionTypes.UPLOAD_PROFILE_IMAGE
});

const setUploadProfileImage = ({ supplierImage }) => ({
    supplierImage,
    type: ActionTypes.SET_UPLOAD_PROFILE_IMAGE
});

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

export default {
    addUpdateSuppliers,
    fetchAllSuppliers,
    setSuppliers,
    setSupplierById,
    deleteSuppliers,
    getSupplierById,
    setSupplierById,
    setLoader,
    uploadProfileImage,
    setUploadProfileImage
}