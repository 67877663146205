import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setPurchaseInvoices = (state, { allPurchaseInvoiceData }) => {
    if (!allPurchaseInvoiceData) return state;
    return state.merge({ allPurchaseInvoiceData });
}

const setPurchaseInvoiceById = (state, { purchaseInvoiceParam }) => {
    return state.merge({ purchaseInvoiceParam });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

const setQuotationNoById = (state, { QuotationData }) => {
    return state.merge({ QuotationData });
}

export const callbacks = [
    {
        actionType: ActionTypes.SET_PURCHASES_INVOICE,
        actionFunction: setPurchaseInvoices,
    },
    {
        actionType: ActionTypes.SET_PURCHASE_INVOICE_BY_ID,
        actionFunction: setPurchaseInvoiceById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
    {
        actionType: ActionTypes.SET_QUOTATION_NO_BY_ID,
        actionFunction: setQuotationNoById,
    },
];
