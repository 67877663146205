import { Map } from "immutable";

/**
 * Returns a reducer function composed of each of the reducers provided (in obj
 * form)
 */
export const combineReducers = (reducers) => {
  reducers = new Map(reducers);

  return (state, action) => {
    return reducers.map((reducer, key) => {
      return reducer(state ? state.get(key) : undefined, action);
    });
  };
};

export const createReducer = ({ initialState, callbacks }) => {
  return (state, action) => {
    return callbacks
      .filter((callback) => callback.actionType === action.type)
      .reduce(
        (prevState, callback) => {
          return callback.actionFunction(prevState, action);
        },
        state ? initialState.merge(state) : initialState
      );
  };
};
