import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../holiday/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdateHoliday({ holidayData, actionName, holidayParams }) {
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/HRM/edit-holiday`,
        data: holidayData,
      });
    } else {
      yield call(api.post, {
        route: '/HRM/create-holiday',
        data: holidayData,
      });
    }
    
    yield put(Actions.fetchAllHoliday({ holidayParams }));
    notification('success', actionName === 'update' ? "Holiday Updated Successfully" : "Holiday Added Successfully");
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllHoliday({ holidayParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch holiday records
    const { data } = yield call(api.get, {
      route: '/HRM/all-holidays',
      params: holidayParams,
    });

    // Store the fetched data in Redux store
    const allHolidayData = data;
    yield put(Actions.setHoliday({ allHolidayData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deleteHolidayById({ holidayParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/HRM/delete-holiday/` + holidayParam.id,
    });
    yield put(Actions.fetchAllHoliday({ holidayParam }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', "Holiday Deleted Successfully");
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_HOLIDAY, fetchAllHoliday),
    takeLatest(ActionTypes.ADD_UPDATE_HOLIDAY, addUpdateHoliday),
    takeLatest(ActionTypes.DELETE_HOLIDAY, deleteHolidayById)
  ]);
}
