import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setPaymentVouchers = (state, { allPaymentVoucherData }) => {
    if (!allPaymentVoucherData) return state;
    return state.merge({ allPaymentVoucherData });
}

const setPaymentVoucherById = (state, { paymentVoucherParam }) => {
    return state.merge({ paymentVoucherParam });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

const setQuotationNoById = (state, { QuotationData }) => {
    return state.merge({ QuotationData });
}

export const callbacks = [
    {
        actionType: ActionTypes.SET_PAYMENT_VOUCHERS,
        actionFunction: setPaymentVouchers,
    },
    {
        actionType: ActionTypes.SET_PAYMENT_VOUCHER_BY_ID,
        actionFunction: setPaymentVoucherById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
    {
        actionType: ActionTypes.SET_QUOTATION_NO_BY_ID,
        actionFunction: setQuotationNoById,
    },
];
