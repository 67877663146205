export const CONSTANT_VARIABLES = {
  SERVE_SOLUTIONS: 'Serve-Solutions',
  UPDATE: 'update',
  ADD: 'Add',
  SUPER_ADMIN: 'SuperAdmin',
  DATE_TIME_FORMAT_YEAR: 'MMM DD, YY',
  EXCEL_PRODUCT_FILE_NAME: 'product.csv',
  PDF_PRODUCT_FILE_NAME: 'product.pdf',
  VALIDATION_FAILED: 'Validation field',
  USER_LOGIN_SUCCESSFULLY: 'User login successfully.',
  USER_LOGIN_FAILED: 'User login failed.',
  USER_CREATED_SUCCESSFULLY: 'User created successfully.',
  USER_UPDATED_SUCCESSFULLY: 'User updated successfully.',
  USER_DELETED_SUCCESSFULLY: 'User deleted successfully.',
  USER_FIRST_NAME: 'Please First Name',
  USER_LAST_NAME: 'Please Last Name',
  USER_USER_NAME: 'Please User Name',
  USER_PASSWORD: 'Please Enter Password',
  USER_CONFIRM_PASSWORD: 'Please Enter Confirm Password',
  USER_PHONE_NUMBER: 'Please Enter phone number',
  USER_EMAIL: 'Please Enter Email',
  USER_ROLE: 'Please Select Role',
  USER_DESCRIPTION: 'Please Enter Description',
  UPLOAD_PROFILE_IMAGE_SUCCESSFULLY: 'image uploaded successfully.',
  ROLE_PERMISSION_CREATED_SUCCESSFULLY: 'Role permission created successfully.',


  CUSTOMER_NAME: 'Please Enter Customer Name',
  CUSTOMER_CITY: 'Please Enter City',
  CUSTOMER_COUNTRY: 'Please Enter Country',
  CUSTOMER_COMPANY: 'Please Select Company',
  CUSTOMER_ADDRESS: 'Please Enter Address',
  CUSTOMER_DESCRIPTION: 'Please Enter Description',

  CUSTOMER_CREATED_SUCCESSFULLY: 'Customer created successfully.',
  CUSTOMER_UPDATED_SUCCESSFULLY: 'Customer updated successfully.',
  CUSTOMER_DELETED_SUCCESSFULLY: 'Customer deleted successfully.',


  SUPPLIER_NAME: 'Please Enter Supplier Name',
  SUPPLIER_COMPANY: 'Select Supplier Company',
  SUPPLIER_CREATED_SUCCESSFULLY: 'Supplier created successfully.',
  SUPPLIER_UPDATED_SUCCESSFULLY: 'Supplier updated successfully.',
  SUPPLIER_DELETED_SUCCESSFULLY: 'Supplier deleted successfully.',

  STORE_NAME: 'Please Enter Store Name',
  STORE_CREATED_SUCCESSFULLY: 'Store created successfully.',
  STORE_UPDATED_SUCCESSFULLY: 'Store updated successfully.',
  STORE_DELETED_SUCCESSFULLY: 'Store deleted successfully.',


  WAREHOUSE_STATE: 'Please Enter State',
  WAREHOUSE_ADDRESS: 'Please Enter address',
  WAREHOUSE_ZIPCODE: 'Please Enter Zip Code',
  WAREHOUSE_NAME: 'Please Enter Warehouse Name',
  WAREHOUSE_COMPANY: 'Select Warehouse Company',
  WAREHOUSE_CONTACT_PERSON: 'Please Enter Contact Person',
  WAREHOUSE_WORK_PHONE_NUMBER: 'Please Enter work phone number',
  WAREHOUSE_CREATED_SUCCESSFULLY: 'Warehouse created successfully.',
  WAREHOUSE_UPDATED_SUCCESSFULLY: 'Warehouse updated successfully.',
  WAREHOUSE_DELETED_SUCCESSFULLY: 'Warehouse deleted successfully.',

  BRAND_CREATED_SUCCESSFULLY: 'Brand created successfully.',
  BRAND_UPDATED_SUCCESSFULLY: 'Brand updated successfully.',
  BRAND_DELETED_SUCCESSFULLY: 'Brand deleted successfully.',

  UNIT_CREATED_SUCCESSFULLY: 'Unit created successfully.',
  UNIT_UPDATED_SUCCESSFULLY: 'Unit updated successfully.',
  UNIT_DELETED_SUCCESSFULLY: 'Unit deleted successfully.',

  CATEGORY_CREATED_SUCCESSFULLY: 'Category created successfully.',
  CATEGORY_UPDATED_SUCCESSFULLY: 'Category updated successfully.',
  CATEGORY_DELETED_SUCCESSFULLY: 'Category deleted successfully.',


  SUB_CATEGORY_CREATED_SUCCESSFULLY: 'Sub category created successfully.',
  SUB_CATEGORY_UPDATED_SUCCESSFULLY: 'Sub category updated successfully.',
  SUB_CATEGORY_DELETED_SUCCESSFULLY: 'Sub category deleted successfully.',
  CATEGORY_SELCT: 'Select category',
  VARIANT_CREATED_SUCCESSFULLY: 'Variant created successfully.',
  VARIANT_UPDATED_SUCCESSFULLY: 'Variant updated successfully.',
  VARIANT_DELETED_SUCCESSFULLY: 'Variant deleted successfully.',

  WARRANTY_CREATED_SUCCESSFULLY: 'Warranty created successfully.',
  WARRANTY_UPDATED_SUCCESSFULLY: 'Warranty updated successfully.',
  WARRANTY_DELETED_SUCCESSFULLY: 'Warranty deleted successfully.',
  ADD_VARIANT_NAME: 'Add variant name',
  ENTER_VARIANT_VALUE: 'Enter variant value',

  PRODUCT_CREATED_SUCCESSFULLY: 'Product created successfully.',
  PRODUCT_UPDATED_SUCCESSFULLY: 'Product updated successfully.',
  PRODUCT_DELETED_SUCCESSFULLY: 'Product deleted successfully.',

  SUB_CATEGORY_CREATED_SUCCESSFULLY: 'Sub category created successfully.',
  SUB_CATEGORY_UPDATED_SUCCESSFULLY: 'Sub category updated successfully.',
  SUB_CATEGORY_DELETED_SUCCESSFULLY: 'Sub category deleted successfully.',

  CATEGORY_SELCT: 'Select category',
  VARIANT_CREATED_SUCCESSFULLY: 'Variant created successfully.',
  VARIANT_UPDATED_SUCCESSFULLY: 'Variant updated successfully.',
  VARIANT_DELETED_SUCCESSFULLY: 'Variant deleted successfully.',

  WARRANTY_CREATED_SUCCESSFULLY: 'Warranty created successfully.',
  WARRANTY_UPDATED_SUCCESSFULLY: 'Warranty updated successfully.',
  WARRANTY_DELETED_SUCCESSFULLY: 'Warranty deleted successfully.',
  ADD_VARIANT_NAME: 'Add variant name',
  ENTER_VARIANT_VALUE: 'Enter variant value',

  PRODUCT_CREATED_SUCCESSFULLY: 'Product created successfully.',
  PRODUCT_UPDATED_SUCCESSFULLY: 'Product updated successfully.',
  PRODUCT_DELETED_SUCCESSFULLY: 'Product deleted successfully.',

  SUB_CATEGORY_CREATED_SUCCESSFULLY: 'Sub category created successfully.',
  SUB_CATEGORY_UPDATED_SUCCESSFULLY: 'Sub category updated successfully.',
  SUB_CATEGORY_DELETED_SUCCESSFULLY: 'Sub category deleted successfully.',
  CATEGORY_SELCT: 'Select category',

  VARIANT_CREATED_SUCCESSFULLY: 'Variant created successfully.',
  VARIANT_UPDATED_SUCCESSFULLY: 'Variant updated successfully.',
  VARIANT_DELETED_SUCCESSFULLY: 'Variant deleted successfully.',
  SUB_CATEGORY_CREATED_SUCCESSFULLY: 'Sub category created successfully.',
  SUB_CATEGORY_UPDATED_SUCCESSFULLY: 'Sub category updated successfully.',
  SUB_CATEGORY_DELETED_SUCCESSFULLY: 'Sub category deleted successfully.',
  CATEGORY_SELCT: 'Select category',
  VARIANT_CREATED_SUCCESSFULLY: 'Variant created successfully.',
  VARIANT_UPDATED_SUCCESSFULLY: 'Variant updated successfully.',
  VARIANT_DELETED_SUCCESSFULLY: 'Variant deleted successfully.',

  WARRANTY_CREATED_SUCCESSFULLY: 'Warranty created successfully.',
  WARRANTY_UPDATED_SUCCESSFULLY: 'Warranty updated successfully.',
  WARRANTY_DELETED_SUCCESSFULLY: 'Warranty deleted successfully.',
  ADD_VARIANT_NAME: 'Add variant name',
  ENTER_VARIANT_VALUE: 'Enter variant value',

  PRODUCT_CREATED_SUCCESSFULLY: 'Product created successfully.',
  PRODUCT_UPDATED_SUCCESSFULLY: 'Product updated successfully.',
  PRODUCT_DELETED_SUCCESSFULLY: 'Product deleted successfully.',
  WARRANTY_CREATED_SUCCESSFULLY: 'Warranty created successfully.',
  WARRANTY_UPDATED_SUCCESSFULLY: 'Warranty updated successfully.',
  WARRANTY_DELETED_SUCCESSFULLY: 'Warranty deleted successfully.',
  ADD_VARIANT_NAME: 'Add variant name',
  ENTER_VARIANT_VALUE: 'Enter variant value',

  EXPENSE_CREATED_SUCCESSFULLY: 'Expense created successfully.',
  EXPENSE_UPDATED_SUCCESSFULLY: 'Expense updated successfully.',
  EXPENSE_DELETED_SUCCESSFULLY: 'Expense deleted successfully.',

  EXPENSE_CATEGORY_CREATED_SUCCESSFULLY: 'Expense category created successfully.',
  EXPENSE_CATEGORY_UPDATED_SUCCESSFULLY: 'Expense category updated successfully.',
  EXPENSE_CATEGORY_DELETED_SUCCESSFULLY: 'Expense category deleted successfully.',

  PURCHASE_CREATED_SUCCESSFULLY: 'Purchase created successfully.',
  PURCHASE_UPDATED_SUCCESSFULLY: 'Purchase updated successfully.',
  PURCHASE_DELETED_SUCCESSFULLY: 'Purchase deleted successfully.',


  QUOTATION_CREATED_SUCCESSFULLY: 'Quotation created successfully.',
  QUOTATION_UPDATED_SUCCESSFULLY: 'Quotation updated successfully.',
  QUOTATION_DELETED_SUCCESSFULLY: 'Quotation deleted successfully.',
  SELECT_PRODUCT: 'Please type product code and select',
  PURCHASE_RECEIVE_CREATED_SUCCESSFULLY: 'Purchase receive created successfully.',
  PURCHASE_RECEIVE_UPDATED_SUCCESSFULLY: 'Purchase receive updated successfully.',
  PURCHASE_RECEIVE_DELETED_SUCCESSFULLY: 'Purchase receive deleted successfully.',

  PURCHASE_INVOICE_CREATED_SUCCESSFULLY: 'Purchase invoice created successfully.',
  PURCHASE_INVOICE_UPDATED_SUCCESSFULLY: 'Purchase invoice updated successfully.',
  PURCHASE_INVOICE_DELETED_SUCCESSFULLY: 'Purchase invoice deleted successfully.',

  PURCHASE_RETURN_CREATED_SUCCESSFULLY: 'Purchase return created successfully.',
  PURCHASE_RETURN_UPDATED_SUCCESSFULLY: 'Purchase return updated successfully.',
  PURCHASE_RETURN_DELETED_SUCCESSFULLY: 'Purchase return deleted successfully.',

  ASSET_TYPE_CREATED_SUCCESSFULLY: 'Asset type created successfully.',
  ASSET_TYPE_UPDATED_SUCCESSFULLY: 'Asset type updated successfully.',
  ASSET_TYPE_DELETED_SUCCESSFULLY: 'Asset type deleted successfully.',

  EMPLOYEE_CREATED_SUCCESSFULLY: 'Employee created successfully.',
  EMPLOYEE_UPDATED_SUCCESSFULLY: 'Employee updated successfully.',
  EMPLOYEE_DELETED_SUCCESSFULLY: 'Employee deleted successfully.',

  DESIGNATION_CREATED_SUCCESSFULLY: 'Designation created successfully.',
  DESIGNATION_UPDATED_SUCCESSFULLY: 'Designation updated successfully.',
  DESIGNATION_DELETED_SUCCESSFULLY: 'Designation deleted successfully.',
  
  SELLINGTYPE_CREATED_SUCCESSFULLY: 'Selling created successfully.',
  SELLINGTYPE_UPDATED_SUCCESSFULLY: 'Selling updated successfully.',
  SELLINGTYPE_DELETED_SUCCESSFULLY: 'Selling deleted successfully.',


  DEBIT_NOTE_CREATED_SUCCESSFULLY: 'Debit note created successfully.',
  DEBIT_NOTE_UPDATED_SUCCESSFULLY: 'Debit note updated successfully.',
  DEBIT_NOTE_DELETED_SUCCESSFULLY: 'Debit note deleted successfully.',

  SALE_INVOICE_CREATED_SUCCESSFULLY: 'Sale invoice created successfully.',
  SALE_INVOICE_UPDATED_SUCCESSFULLY: 'Sale invoice updated successfully.',
  SALE_INVOICE_DELETED_SUCCESSFULLY: 'Sale invoice deleted successfully.',

  SALEQUOTATION_CREATED_SUCCESSFULLY: 'Sale quotation created successfully.',
  SALEQUOTATION_UPDATED_SUCCESSFULLY: 'Sale quotation updated successfully.',
  SALEQUOTATION_DELETED_SUCCESSFULLY: 'Sale quotation deleted successfully.',

  SALE_RECEIVE_CREATED_SUCCESSFULLY: 'Sale receive created successfully.',
  SALE_RECEIVE_UPDATED_SUCCESSFULLY: 'Sale receive updated successfully.',
  SALE_RECEIVE_DELETED_SUCCESSFULLY: 'Sale receive deleted successfully.',

  SALE_RETURN_CREATED_SUCCESSFULLY: 'Sale return created successfully.',
  SALE_RETURN_UPDATED_SUCCESSFULLY: 'Sale return updated successfully.',
  SALE_RETURN_DELETED_SUCCESSFULLY: 'Sale return deleted successfully.',

  SALE_ORDER_CREATED_SUCCESSFULLY: 'Sale order created successfully.',
  SALE_ORDER_UPDATED_SUCCESSFULLY: 'Sale order updated successfully.',
  SALE_ORDER_DELETED_SUCCESSFULLY: 'Sale order deleted successfully.',
  
  HOLIDAY_CREATED_SUCCESSFULLY: 'Holiday created successfully.',
  HOLIDAY_UPDATED_SUCCESSFULLY: 'Holiday updated successfully.',
  HOLIDAY_DELETED_SUCCESSFULLY: 'Holiday deleted successfully.',

  EMPLOYEELEAVE_CREATED_SUCCESSFULLY: 'Employee leave created successfully.',
  EMPLOYEELEAVE_UPDATED_SUCCESSFULLY: 'Employee leave updated successfully.',
  EMPLOYEELEAVE_DELETED_SUCCESSFULLY: 'Employee leave deleted successfully.',

}