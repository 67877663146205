import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Switch, Select, Tabs, Button } from 'antd';
const { TabPane } = Tabs;
import { CONSTANT_VARIABLES } from '../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../shared/components/utility/intlMessages';
import { Info, Lock } from "feather-icons-react/build/IconComponents";
import EmployeeInformationTab from './components/employeeInformationTab';
import PasswordTab from './components/passwordTab';
import OtherInformationTab from './components/otherInformationTab';
import employeeActions from '../../../core/Services/hrm/employee/actions'


const AddUpdateEmployeeModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, employeeParams, formOptionsData } = props;

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState("1");

    const handleRecord = (actionName, employeeData) => {
        debugger
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            employeeData.companyId = 1
        }
        else {
            employeeData.companyId = 1
        }

        dispatch(employeeActions.addUpdateEmployees({ employeeData, actionName, employeeParams: employeeParams }));
        toggleModal();
    };

    const handleNext = () => {
        form.validateFields()
            .then(() => {
                if (activeTab === "1") {
                    setActiveTab("2");
                } else if (activeTab === "2") {
                    setActiveTab("3");
                }
            })
            .catch((info) => {
                console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
            });
    };

    const handleTabChange = (nextTabKey) => {
        form.validateFields()
            .then(() => {
                setActiveTab(nextTabKey);
            })
            .catch((info) => {
                console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
            });
    };

    return (
        <Modal
            open={isModalOpen}
            width={900}
            zIndex={999999}
            onCancel={() => toggleModal(true)}
            footer={[
                activeTab !== "3" ? (
                    <Button  key="next" type="primary" onClick={handleNext} style={{marginRight:'5px'}}>
                        <IntlMessages id="product.inventory.form.modal.next" />
                    </Button>
                ) : (
                    <Button
                        key="submit"
                        type="primary"
                        style={{marginRight:'5px'}}
                        onClick={() => {
                            form.validateFields()
                                .then(values => {
                                    handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                                })
                                .catch(info => {
                                    console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                                });
                        }}
                    >
                        {isUpdate ? <IntlMessages id="product.inventory.form.modal.update" /> : <IntlMessages id="product.inventory.form.modal.add" />}
                    </Button>
                ),
                <Button color='orange' key="cancel" onClick={() => toggleModal(true)}>
                    <IntlMessages id="product.inventory.form.modal.cancelText" />
                </Button>,
            ]}
            title={isUpdate ? <IntlMessages id="hrm.employee.form.modal.title.update" /> : <IntlMessages id="hrm.employee.form.modal.title.add" />}
            okText={isUpdate ? <IntlMessages id="product.inventory.form.modal.update" /> : <IntlMessages id="product.inventory.form.modal.add" />}
            cancelText={<IntlMessages id="product.inventory.form.modal.cancelText" />}
            maskClosable={false}
        >
            <div className="card-body add-product pb-0">
                <Tabs activeKey={activeTab} onChange={handleTabChange}>
                    <TabPane tab={<span><Info className="add-info" /> Employee Information</span>} key="1">
                        <EmployeeInformationTab form={form} currentModalData={currentModalData} isUpdate = {isUpdate} optionsData = {formOptionsData}/>
                    </TabPane>
                    <TabPane tab={<span><Info className="add-info" /> Other Information</span>} key="2">
                        <OtherInformationTab form={form} currentModalData={currentModalData} isUpdate = {isUpdate} optionsData = {formOptionsData} />
                    </TabPane>
                    <TabPane tab={<span><Lock className="add-info" /> Password</span>} key="3">
                        <PasswordTab form={form} currentModalData={currentModalData} />
                    </TabPane>
                </Tabs>
            </div>
        </Modal>
    );
};

export default AddUpdateEmployeeModal;