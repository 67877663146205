import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { setToogleHeader } from "../../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import { CONSTANT_VARIABLES } from '../../../core/Common/Constant_Variables/constants';
import * as XLSX from 'xlsx';
import {
  ArrowLeft,
  ChevronUp,
  Download,
  Edit,
  Eye,
  Filter,
  PlusCircle,
  RotateCcw,
  Search,
  Sliders,
  StopCircle,
  User,
} from "react-feather";
import { payrollListData } from "../../../core/json/payrollList";
import Table from "../../../core/pagination/datatable";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Select from "react-select";
import commonActions from '../../../core/Services/common/actions'
import * as commonSelectors from '../../commonSelectors'
import payrollActions from '../../../core/Services/hrm/payroll/actions'
import * as payrollSelectors from '../hrmSelectors'
import AddUpdatePayrollModal from "./addUpdatePayrollModal";
import { useDebounce } from "use-debounce";
import { Modal, Popover, Spin } from "antd";
import jsPDF from "jspdf";
import { DollarSign } from "feather-icons-react/build/IconComponents";

const PayrollList = () => {

  const [payrollParams, setPayrollParams] = useState({
    pageNo: 1,
    searchText: '',
  });
  const [isPayrollModalOpen, setPayrollModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({})

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  const [debouncedText] = useDebounce(payrollParams, 1000);

  const isEmployeesLoading = useSelector(state => commonSelectors.setLoader(state));
  const employeesData = useSelector(state => commonSelectors.setAllEmployee(state));
  const dataSource = employeesData ? employeesData.toJS() : [];

  const isLoading = useSelector(state => payrollSelectors.setPayrollLoader(state));
  const allPayrollData = useSelector(state => payrollSelectors.setPayrolls(state))
  const datas = allPayrollData ? allPayrollData.toJS() : [];

  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleImagePreview = (url) => {
    setImageUrl(url);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const togglePayrollModal = (value) => {
    setPayrollModalOpen(!isPayrollModalOpen);
  };

  const handleModal = (payrollData, actionName) => {
    const payrollModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? payrollData : {},
    };
    const status = 0;
    setCurrentModalData(payrollModalObj);
    setPayrollModalOpen(true);
  };

  const filteredData = datas.filter((entry) => {
    return Object.keys(entry).some((key) => {
      return String(entry[key])
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  });
  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (row) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(payrollActions.deletePayrolls({ payrollParam: row }));
      } else {
        MySwal.close();
      }
    });
  };

  const columns = [
    {
      title: "Employee",
      dataIndex: "employeeName",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <div className="userimgname">
          <a href="#" className="product-img">
            <img src={record.employeeImageUrl} alt="product" />
          </a>
          <div className="emp-name">
            <a href="#">{text}</a>
            <p className="role">{record.designation}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Employee ID",
      dataIndex: "employeeId",
      sorter: (a, b) => a.employeeId.localeCompare(b.employeeId),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Salary",
      dataIndex: "salary",
      sorter: (a, b) => a.salary.length - b.salary.length,
    },
    {
      title: "Status",
      dataIndex: "isPaid",
      sorter: (a, b) => a.status.length - b.status.length,
      render: (status) => (
        <span
          className={`badge badge-line${status === true ? "success" : "danger"
            }`}
        >
          {status == true ? 'Paid' : 'Unpaid'}
        </span>
      ),
    },
    {
      title: "Action",
      render: (text, row) => (
        <div className="edit-delete-action data-view action-table-data">
          <Popover content="Pay Slip" trigger="hover">
            <Link to={`/payslip/${row.employeeId}`}>
            <i data-feather="dollar-sign" className="feather-dollar-sign"></i>
            </Link>
          </Popover>
          <a className="me-2" href="#" onClick={() => handleImagePreview(row.employeeImageUrl)}>
            <Eye className="action-eye" />
          </a>
          <a className="me-2" href="#" onClick={() => handleGeneratePDFForSingleEmployee(row)}>
            <Download className="action-download" />
          </a>
          <Link
            className="me-2 p-2"
            onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
            data-bs-target="#edit-units"
          >
            <i data-feather="edit" className="feather-edit"></i>
          </Link>
          <a
            className="confirm-text"
            href="#"
            onClick={() => showConfirmationAlert(row)}
          >
            <i data-feather="trash-2" className="feather-trash-2" />
          </a>
        </div>
      ),
    },
  ];

  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = [['Employee Name', 'Employee Id', 'Employee Code', 'Employee Image URL', 'Designation', 'Email', 'Salary', 'Status', 'Basic Salary', 'HRA Allowance', 'Conveyance Allowance', 'Medical Allowance', 'Bonus Allowance', 'PF Deduction', 'Professional Tax', 'TDS', 'Loans', 'Pay For Month']];

    const tableData = datas?.map((item, index) => [item.employeeName, item.employeeId, item.employeeCode, item.employeeImageUrl, item.designation, item.email, item.salary, item.isPaid ? 'Paid' : 'Unpaid', item.basicSalary, item.hraAllowance, item.conveyanceAllowance, item.medicalAllowance, item.bonusAllowance, item.pfDeduction, item.professionalTax, item.tds, item.loans, item.payForMonth]);

    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    doc.save('payroll.pdf');
  };

  const handleGeneratePDFForSingleEmployee = (item) => {
    const doc = new jsPDF();
    const headers = [['Employee Name', 'Employee Id', 'Employee Code', 'Employee Image URL', 'Designation', 'Email', 'Salary', 'Status', 'Basic Salary', 'HRA Allowance', 'Conveyance Allowance', 'Medical Allowance', 'Bonus Allowance', 'PF Deduction', 'Professional Tax', 'TDS', 'Loans', 'Pay For Month']];

    const tableData = [item.employeeName, item.employeeId, item.employeeCode, item.employeeImageUrl, item.designation, item.email, item.salary, item.isPaid ? 'Paid' : 'Unpaid', item.basicSalary, item.hraAllowance, item.conveyanceAllowance, item.medicalAllowance, item.bonusAllowance, item.pfDeduction, item.professionalTax, item.tds, item.loans, item.payForMonth];

    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    doc.save('payroll.pdf');
  };

  const handleDataIntoExcel = (filename = 'payroll.xlsx') => {
    if (datas && datas.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(datas);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };
  const handleRefresh = () => {
    dispatch(payrollActions.fetchAllPayrolls({ payrollParams }))
  }

  useEffect(() => {
    dispatch(commonActions.getAllEmployee());
  }, []);

  useEffect(() => {
    dispatch(payrollActions.fetchAllPayrolls({ payrollParams: debouncedText }))
  }, [dispatch, debouncedText, payrollParams])

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Payroll</h4>
                <h6>Manage Your Employees</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link onClick={() => handleGeneratePDF()}>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" onClick={handleRefresh} data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <a
                onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                className="btn btn-added"
              >
                <PlusCircle className="me-2" />
                Add New Payroll
              </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line x1={21} y1={21} x2="16.65" y2="16.65" />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              {/* /Filter */}
              {isPayrollModalOpen && (
                <AddUpdatePayrollModal
                  currentModalData={currentModalData}
                  isModalOpen={isPayrollModalOpen}
                  isUpdate={!!currentModalData.formData.id}
                  toggleModal={togglePayrollModal}
                  payrollParams={payrollParams}
                  employeeOptions={dataSource}
                />
              )}
              <Modal
                visible={isModalVisible}
                footer={null}
                onCancel={handleCancel}
                width={600} // adjust width as needed
              >
                <img src={imageUrl} alt="Employee" style={{ width: '100%' }} />
              </Modal>
              {(isLoading || isEmployeesLoading) ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}
              <div className="table-responsive">
                <Table columns={columns} dataSource={filteredData} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      {/* Add Payroll */}
      <div
        className="offcanvas offcanvas-end em-payrol-add"
        tabIndex={-1}
        id="offcanvasRight-add"
      >
        <div className="offcanvas-body p-0">
          <div className="page-wrapper-new">
            <div className="content">
              <div className="page-header justify-content-between">
                <div className="page-title">
                  <h4>Add New Payroll</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayrollList;
