import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setUploadProfileImage = (state, { uploadImageUrl }) => {
    if (!uploadImageUrl) return state;
    return state.merge({ uploadImageUrl });
};

const setAllStores = (state, { allStoresData }) => {
    if (!allStoresData) return state;
    return state.merge({ allStoresData });
};

const setAllWarehouses = (state, { allWarehousesData }) => {
    if (!allWarehousesData) return state;
    return state.merge({ allWarehousesData });
};

const setAllCategory = (state, { allCategoryData }) => {
    if (!allCategoryData) return state;
    return state.merge({ allCategoryData });
};

const setAllSubCategory = (state, { allSubCategoryData }) => {
    if (!allSubCategoryData) return state;
    return state.merge({ allSubCategoryData });
};

const setAllBrands = (state, { allBrandsData }) => {
    if (!allBrandsData) return state;
    return state.merge({ allBrandsData });
};

const setAllUnits = (state, { allUnitsData }) => {
    if (!allUnitsData) return state;
    return state.merge({ allUnitsData });
};

const setAllUsers = (state, { allUsersData }) => {
    if (!allUsersData) return state;
    return state.merge({ allUsersData });
};

const setAllVariants = (state, { allVariantsData }) => {
    if (!allVariantsData) return state;
    return state.merge({ allVariantsData });
};

const setAllSellingTypes = (state, { allSellingTypesData }) => {
    if (!allSellingTypesData) return state;
    return state.merge({ allSellingTypesData });
};

const setAllbarcodesymbologies = (state, { allBarCodeSymbData }) => {
    if (!allBarCodeSymbData) return state;
    return state.merge({ allBarCodeSymbData });
};

const setAllDisscountTypes = (state, { allDisscountTypesData }) => {
    if (!allDisscountTypesData) return state;
    return state.merge({ allDisscountTypesData });
};

const setAllTaxTypes = (state, { allTaxTypesData }) => {
    if (!allTaxTypesData) return state;
    return state.merge({ allTaxTypesData });
};

const setAllExpenseCategory = (state, { allExpenseCategoryData }) => {
    if (!allExpenseCategoryData) return state;
    return state.merge({ allExpenseCategoryData });
};

const setAllCities = (state, { allCitiesData }) => {
    if (!allCitiesData) return state;
    return state.merge({ allCitiesData });
};

const setAllStates = (state, { allStatesData }) => {
    if (!allStatesData) return state;
    return state.merge({ allStatesData });
};

const setAllCounties = (state, { allCountriesData }) => {
    if (!allCountriesData) return state;
    return state.merge({ allCountriesData });
};

const setAllCustomer = (state, { allCustomerData }) => {
    if (!allCustomerData) return state;
    return state.merge({ allCustomerData });
};

const setAllSuppliers = (state, { allSupplierData }) => {
    if (!allSupplierData) return state;
    return state.merge({ allSupplierData });
};

const setAllProductsBySearch = (state, { allProductsDataBySearch }) => {
    if (!allProductsDataBySearch) return state;
    return state.merge({ allProductsDataBySearch });
};

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};
const setAllQuotation= (state, { allQuotationData }) => {
    if (!allQuotationData) return state;
    return state.merge({ allQuotationData });
};
const setAllEmployee = (state, { allEmployeeData }) => {
    if (!allEmployeeData) return state;
    return state.merge({ allEmployeeData });
};

const setAllLeaveType = (state, { allLeaveTypeData }) => {
    if (!allLeaveTypeData) return state;
    return state.merge({ allLeaveTypeData });
};


const setAllDesignation = (state, { allDesignationData }) => {
    if (!allDesignationData) return state;
    return state.merge({ allDesignationData });
};

const setAllDepartment = (state, { allDepartmentData }) => {
    if (!allDepartmentData) return state;
    return state.merge({ allDepartmentData });
};

const setAllShift = (state, { allShiftData }) => {
    if (!allShiftData) return state;
    return state.merge({ allShiftData });
};

const setAllGender = (state, { allGenderData }) => {
    if (!allGenderData) return state;
    return state.merge({ allGenderData });
};

const setAllWeekoffs = (state, { allWeekoffData }) => {
    if (!allWeekoffData) return state;
    return state.merge({ allWeekoffData });
};

const setAllNationalities = (state, { allNationalityData }) => {
    if (!allNationalityData) return state;
    return state.merge({ allNationalityData });
};

const setAllBloodGroup = (state, { allBloodGroupData }) => {
    if (!allBloodGroupData) return state;
    return state.merge({ allBloodGroupData });
};

const setAllGrnById = (state, { allGrnData }) => {
    if (!allGrnData) return state;
    return state.merge({ allGrnData });
};

const setAllLeaveTypes = (state, { AllLeaveTypesData }) => {
    if (!AllLeaveTypesData) return state;
    return state.merge({ AllLeaveTypesData });
};

const setAllSaleQuotation= (state, { allSaleQuotationData }) => {
    if (!allSaleQuotationData) return state;
    return state.merge({ allSaleQuotationData });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_ALL_DESIGNATION_DRP,
        actionFunction: setAllDesignation,
    },
    {
        actionType: ActionTypes.SET_ALL_DEPARTMENT_DRP,
        actionFunction: setAllDepartment,
    },
    {
        actionType: ActionTypes.SET_ALL_SHIFTS_DRP,
        actionFunction: setAllShift,
    },
    {
        actionType: ActionTypes.SET_ALL_GENDER_DRP,
        actionFunction: setAllGender,
    },
    {
        actionType: ActionTypes.SET_ALL_WEEKOFFS_DRP,
        actionFunction: setAllWeekoffs,
    },
    {
        actionType: ActionTypes.SET_ALL_NATIONALITIES_DRP,
        actionFunction: setAllNationalities,
    },
    {
        actionType: ActionTypes.SET_ALL_BLOOD_GROUP_DRP,
        actionFunction: setAllBloodGroup,
    },
                                
    {
        actionType: ActionTypes.SET_ALL_EMPLOYEE_DRP,
        actionFunction: setAllEmployee,
    },    
    {
        actionType: ActionTypes.SET_ALL_QUOTATION,
        actionFunction: setAllQuotation,
    },
    {
        actionType: ActionTypes.SET_ALL_VARIANTS,
        actionFunction: setAllVariants,
    },
    {
        actionType: ActionTypes.SET_UPLOAD_PROFILE_IMAGE,
        actionFunction: setUploadProfileImage,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
    {
        actionType: ActionTypes.SET_ALL_STORES,
        actionFunction: setAllStores,
    },
    {
        actionType: ActionTypes.SET_ALL_WAREHOUSES,
        actionFunction: setAllWarehouses,
    },
    {
        actionType: ActionTypes.SET_ALL_CATEGORY,
        actionFunction: setAllCategory,
    },
    {
        actionType: ActionTypes.SET_ALL_SUBCATEGORY,
        actionFunction: setAllSubCategory,
    },
    {
        actionType: ActionTypes.SET_ALL_BRANDS,
        actionFunction: setAllBrands,
    },
    {
        actionType: ActionTypes.SET_ALL_UNITS,
        actionFunction: setAllUnits,
    },
    {
        actionType: ActionTypes.SET_ALL_USERS,
        actionFunction: setAllUsers,
    },
    {
        actionType: ActionTypes.SET_ALL_EXPENSE_CATEGORY,
        actionFunction: setAllExpenseCategory,
    },
    {
        actionType: ActionTypes.SET_ALL_SELLING_TYPES,
        actionFunction: setAllSellingTypes,
    },
    {
        actionType: ActionTypes.SET_ALL_BAR_CODE_SYMBOLOGIES,
        actionFunction: setAllbarcodesymbologies,
    },
    {
        actionType: ActionTypes.SET_ALL_DISSCOUNT_TYPES,
        actionFunction: setAllDisscountTypes,
    },
    {
        actionType: ActionTypes.SET_ALL_TAX_TYPES,
        actionFunction: setAllTaxTypes,
    },
    {
        actionType: ActionTypes.SET_ALL_CITY,
        actionFunction: setAllCities,
    },
    {
        actionType: ActionTypes.SET_ALL_STATE,
        actionFunction: setAllStates,
    },
    {
        actionType: ActionTypes.SET_ALL_COUNTRY,
        actionFunction: setAllCounties,
    },
    {
        actionType: ActionTypes.SET_ALL_CUSTOMER,
        actionFunction: setAllCustomer,
    },
    {
        actionType: ActionTypes.SET_ALL_SUPPLIER,
        actionFunction: setAllSuppliers,
    },
    {
        actionType: ActionTypes.SET_ALL_PRODUCTS_BY_SEARCH,
        actionFunction: setAllProductsBySearch,
    },
    {
        actionType: ActionTypes.SET_ALL_GRN_BY_ID,
        actionFunction: setAllGrnById,
    },
    {
        actionType: ActionTypes.SET_ALL_LEAVE_TYPE_DRP,
        actionFunction: setAllLeaveTypes,
    },
    {
        actionType: ActionTypes.SET_ALL_SALE_QUOTATION,
        actionFunction: setAllSaleQuotation,
    },
];
