export const ActionTypes = {
  ADD_UPDATE_USER: 'services/userManagement/add_update_user',
  FETCH_USERS: 'services/userManagement/fetch_users',
  SET_USERS: 'services/userManagement/set_users',
  DELETE_USERS: 'services/userManagement/delete_users',
  GET_USER_BY_ID: 'services/userManagement/get_user_by_id',
  SET_USER_BY_ID: 'services/userManagement/set_user_by_id',
  GET_TOKEN_BY_USERNAME_AND_PASSWORD: 'services/userManagement/get_token_by_username_Password',
  SET_TOKEN_BY_USERNAME_AND_PASSWORD: 'services/userManagement/set_token_by_username_Password',
  SET_LOADER: 'services/userManagement/set_loader',
  UPLOAD_PROFILE_IMAGE: 'services/userManagement/upload_profile_image',
  SET_UPLOAD_PROFILE_IMAGE: 'services/userManagement/set_upload_profile_image',
  GET_USER_ROLE: 'services/userManagement/get_user_role',
  SET_USER_ROLE: 'services/userManagement/set_user_role',
  GET_DEFAULT_MENU: 'services/userManagement/get_default_menu',
  SET_DEFAULT_MENU: 'services/userManagement/set_default_menu',
  ADD_USER_CLAIMS: 'services/userManagement/add_user_claims',
  SET_USER_DATA: 'services/userManagement/set_user_data',
  GET_DEFAULT_MENU_BY_USER_ID: 'services/userManagement/get_default_menu_by_user_id',
  SET_DEFAULT_MENU_BY_USER_ID: 'services/userManagement/set_default_menu_by_user_id',
  SIGNUP_USER:'services/userManagement/signup_user'
}

const signupUsers = ({ userData, navigate }) => ({
  userData,
  navigate,
  type: ActionTypes.SIGNUP_USER,
})

const addUpdateUsers = ({ userData, actionName, UserParams }) => ({
  userData,
  actionName,
  UserParams,
  type: ActionTypes.ADD_UPDATE_USER,
})

const fetchAllUsers = ({ userParams }) => ({
  userParams,
  type: ActionTypes.FETCH_USERS,
})

const setUsers = ({ allUserData }) => ({
  allUserData,
  type: ActionTypes.SET_USERS,
})

const setUsersData= ({ userData }) => ({
  userData,
  type: ActionTypes.SET_USER_DATA,
})

const deleteusers = ({ userParam }) => ({
  userParam,
  type: ActionTypes.DELETE_USERS
})

const getUserById = ({ userParam }) => ({
  userParam,
  type: ActionTypes.GET_USER_BY_ID
})

const setUserById = ({ userParam }) => ({
  userParam,
  type: ActionTypes.SET_USER_BY_ID
})

const getTokenByUsernamePassword = ({ userParam, navigate }) => ({
  userParam,
  navigate,
  type: ActionTypes.GET_TOKEN_BY_USERNAME_AND_PASSWORD
});

const setTokenByUsernamePassword = ({ userLoginParam }) => ({
  userLoginParam,
  type: ActionTypes.SET_TOKEN_BY_USERNAME_AND_PASSWORD
});

const uploadProfileImage = ({ userImage }) => ({
  userImage,
  type: ActionTypes.UPLOAD_PROFILE_IMAGE
});

const setUploadProfileImage = ({ userImage }) => ({
  userImage,
  type: ActionTypes.SET_UPLOAD_PROFILE_IMAGE
});

const setLoader = ({ showLoader }) => ({
  showLoader,
  type: ActionTypes.SET_LOADER,
});

const getUserRoles = () => ({
  type: ActionTypes.GET_USER_ROLE
});

const setUserRoles = ({ allUserRole }) => ({
  allUserRole,
  type: ActionTypes.SET_USER_ROLE
});

const getDefaultMenu = () => ({
  type: ActionTypes.GET_DEFAULT_MENU
});

const setDefaultMenu = ({ allDefaultMenu }) => ({
  allDefaultMenu,
  type: ActionTypes.SET_DEFAULT_MENU
});

const addUserClaims = ({ userClaims }) => ({
  userClaims,
  type: ActionTypes.ADD_USER_CLAIMS
});

const getDefaultMenuByUserId = () => ({
  type: ActionTypes.GET_DEFAULT_MENU_BY_USER_ID
});

const setDefaultMenuByUserId = ({ allUserMenu }) => ({
  allUserMenu,
  type: ActionTypes.SET_DEFAULT_MENU_BY_USER_ID
});

export default {
  addUpdateUsers,
  fetchAllUsers,
  setUsers,
  deleteusers,
  getUserById,
  setUserById,
  getTokenByUsernamePassword,
  setTokenByUsernamePassword,
  setLoader,
  uploadProfileImage,
  setUploadProfileImage,
  getUserRoles,
  setUserRoles,
  getDefaultMenu,
  setDefaultMenu,
  addUserClaims,
  setUsersData,
  getDefaultMenuByUserId,
  setDefaultMenuByUserId,
  signupUsers
}