export const ActionTypes = {
  ADD_UPDATE_PURCHASE_RECEIVE: 'services/purchases/purchaseReceive/add_update_purchaseReceive',
  FETCH_PURCHASE_RECEIVE: 'services/purchases/purchaseReceive/fetch_purchaseReceives',
  SET_PURCHASES_RECEIVE: 'services/purchases/purchaseReceive/set_purchaseReceives',
  DELETE_PURCHASES_RECEIVE: 'services/purchases/purchaseReceive/delete_purchaseReceives',
  GET_PURCHASE_RECEIVE_BY_ID: 'services/purchases/purchaseReceive/get_purchaseReceive_by_id',
  SET_PURCHASE_RECEIVE_BY_ID: 'services/purchases/purchaseReceive/set_purchaseReceive_by_id',
  SET_QUOTATION_NO_BY_ID: 'services/purchases/purchaseReceive/set_quotation_no_by_id',
  GET_QUOTATION_NO_BY_ID: 'services/purchases/purchaseReceive/get_quotation_no_by_id',
  SET_LOADER: 'services/inventory/inventory/set_loader',
}

const setLoader = ({ showLoader }) => ({
  showLoader,
  type: ActionTypes.SET_LOADER,
});

const fetchAllPurchaseReceives = ({ purchaseReceiveParams }) => ({
  purchaseReceiveParams,
  type: ActionTypes.FETCH_PURCHASE_RECEIVE,
})

const setPurchaseReceives = ({ allPurchaseReceiveData }) => ({
  allPurchaseReceiveData,
  type: ActionTypes.SET_PURCHASES_RECEIVE,
})

const deletePurchaseReceives = ({ purchaseReceiveParam }) => ({
  purchaseReceiveParam,
  type: ActionTypes.DELETE_PURCHASES_RECEIVE,
})

const getQuotationNoById = ({ QnParam }) => ({
  QnParam,
  type: ActionTypes.GET_QUOTATION_NO_BY_ID,
})

const setQuotationNoById = ({ QuotationData }) => ({
  QuotationData,
  type: ActionTypes.SET_QUOTATION_NO_BY_ID,
})

const addUpdatePurchaseReceive = ({ purchaseReceiveData, actionName, UserParams }) => ({
  purchaseReceiveData,
  actionName,
  UserParams,
  type: ActionTypes.ADD_UPDATE_PURCHASE_RECEIVE,
})

const getPurchaseReceiveById = ({ purchaseReceiveParam }) => ({
  purchaseReceiveParam,
  type: ActionTypes.GET_PURCHASE_RECEIVE_BY_ID,
})

const setPurchaseReceiveById = ({ purchaseReceiveParam }) => ({
  purchaseReceiveParam,
  type: ActionTypes.SET_PURCHASE_RECEIVE_BY_ID,
})

export default {
  addUpdatePurchaseReceive,
  fetchAllPurchaseReceives,
  setPurchaseReceives,
  deletePurchaseReceives,
  getPurchaseReceiveById,
  setPurchaseReceiveById,
  setQuotationNoById,
  getQuotationNoById,
  setLoader
};
