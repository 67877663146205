import Immutable from 'immutable';
import { ActionTypes } from "./actions";

export const initialState = Immutable.fromJS({});

const setWarranties = (state, { allWarrantyData }) => {
    if (!allWarrantyData) return state;
    return state.merge({ allWarrantyData });
}

const setWarrantyById = (state, { warrantyParam }) => {
    return state.merge({ warrantyParam });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_WARRANTIES,
        actionFunction: setWarranties,
    },
    {
        actionType: ActionTypes.SET_WARRANTY_BY_ID,
        actionFunction: setWarrantyById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
