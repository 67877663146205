import Immutable from 'immutable';
import { ActionTypes } from "./actions.js";
export const initialState = Immutable.fromJS({});

const setSaleQuotations = (state, { allSaleQuotationData }) => {
    if (!allSaleQuotationData) return state;
    return state.merge({ allSaleQuotationData });
}

const setSaleQuotationById = (state, { salequotationParam }) => {
    return state.merge({ salequotationParam });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_SALEQUOTATIONS,
        actionFunction: setSaleQuotations,
    },
    {
        actionType: ActionTypes.SET_SALEQUOTATION_BY_ID,
        actionFunction: setSaleQuotationById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
];
