export const ActionTypes = {
  ADD_UPDATE_TODAYEXPENSES: 'services/pos/cashclosing/todayExpenses/add_update_todayExpenses',
  FETCH_TODAYEXPENSES: 'services/pos/cashclosing/todayExpenses/fetch_todayExpenses',
  SET_TODAYEXPENSES: 'services/pos/cashclosing/todayExpenses/set_todayExpenses',
  DELETE_TODAYEXPENSES: 'services/pos/cashclosing/todayExpenses/delete_todayExpenses',
  GET_TODAYEXPENSES_BY_ID: 'services/pos/cashclosing/todayExpenses/get_todayExpenses_by_id',
  SET_TODAYEXPENSES_BY_ID: 'services/pos/cashclosing/todayExpenses/set_todayExpenses_by_id',
  SET_LOADER: 'services/pos/cashclosing/todayExpenses/set_loader',
}

const setLoader = ({ showLoader }) => ({
  showLoader,
  type: ActionTypes.SET_LOADER,
});

const fetchAllTodayExpenses = ({ todayExpensesParams }) => ({
  todayExpensesParams,
  type: ActionTypes.FETCH_TODAYEXPENSES,
})

const setTodayExpenses = ({ allTodayExpensesData }) => ({
  allTodayExpensesData,
  type: ActionTypes.SET_TODAYEXPENSES,
})

const deleteTodayExpenses = ({ todayExpensesParam }) => ({
  todayExpensesParam,
  type: ActionTypes.DELETE_TODAYEXPENSES,
})

const getTodayExpensesById = ({ todayExpensesParam }) => ({
  todayExpensesParam,
  type: ActionTypes.GET_TODAYEXPENSES_BY_ID,
})

const setTodayExpensesById = ({ todayExpensesParam }) => ({
  todayExpensesParam,
  type: ActionTypes.SET_TODAYEXPENSES_BY_ID,
})

const addUpdateTodayExpenses = ({ todayExpensesData, actionName, UserParams }) => ({
  todayExpensesData,
  actionName,
  UserParams,
  type: ActionTypes.ADD_UPDATE_TODAYEXPENSES,
})

export default {
  addUpdateTodayExpenses,
  fetchAllTodayExpenses,
  setTodayExpenses,
  setTodayExpensesById,
  deleteTodayExpenses,
  getTodayExpensesById,
  setTodayExpensesById,
  setLoader
}