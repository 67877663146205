import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, InputNumber, Modal, Select } from 'antd';
import ReactSelect from 'react-select';
import { useDebounce } from 'use-debounce';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import quotationActions from '../../../core/Services/sales/salequotations/actions';
import commonAction from '../../../core/Services/common/actions';
import IntlMessages from "../../../shared/components/utility/intlMessages";
import * as commonSelectors from '../../commonSelectors';
import TextArea from 'antd/es/input/TextArea.js';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import TextEditor from "../../inventory/texteditor";

const AddQuotation = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, quotationParams } = props;

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [quotationDetails, setQuotationDetails] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [productParams, setProductParams] = useState({ searchText: '' });
    const [debouncedText] = useDebounce(productParams.searchText, 1000);
    const [selectedDate, setSelectedDate] = useState(null);
    const [editorData, setEditorData] = useState();

    const productsBySearchDataSource = useSelector(state => commonSelectors.setAllProductsBySearch(state));
    const searchedOptions = productsBySearchDataSource ? productsBySearchDataSource.toJS() : [];

    const customerDataSource = useSelector(state => commonSelectors.setAllCustomer(state));
    const customer = customerDataSource ? customerDataSource.toJS() : [];


    useEffect(() => {
        dispatch(commonAction.getAllCustomer());
    }, []);

    useEffect(() => {
        if (debouncedText) {
            dispatch(commonAction.getAllProductsBySearch({ productParams: debouncedText }));
        }
    }, [debouncedText, dispatch]);

    useEffect(() => {
        if (isUpdate) {
            setSelectedDate(currentModalData.formData.saleQuotationDate ? moment(currentModalData.formData.saleQuotationDate).format('YYYY-MM-DD')  : null);
            setQuotationDetails(currentModalData.formData.saleQuotationDetails);
            setEditorData(currentModalData.formData.saleQuotationNotes)
        }
    }, [currentModalData ]);
    const handleDateChange = (date) => {
        setSelectedDate(date);
        form.setFieldsValue({ saleQuotationDate: date ? moment(date).format('YYYY-MM-DD') : null });
    };

    const handleProductChange = (selectedProduct) => {
        if (selectedProduct) {
            const product = quotationDetails != undefined ? quotationDetails.filter(p => p.productName == selectedProduct.productName).length : 0;
            if (product > 0) {
                return;
            }
            if (quotationDetails != undefined) {

                setQuotationDetails(prevDetails => [
                    ...prevDetails,
                    {
                        saleQuotationId: 0,
                        productId: selectedProduct.value,
                        productName: selectedProduct.productName,
                        quantity: 1,
                        unitPrice: selectedProduct.purchasePrice,
                        disscount: 0,
                        taxPercentage: 0,
                        taxAmount: 0,
                        totalCost: selectedProduct.purchasePrice,
                        imagesUrls:""
                    }
                ]);

            }
            else {
                setQuotationDetails([
                    {
                        saleQuotationId: 0,
                        productId: selectedProduct.value,
                        productName: selectedProduct.productName,
                        quantity: 1,
                        unitPrice: selectedProduct.purchasePrice,
                        disscount: 0,
                        taxPercentage: 0,
                        taxAmount: 0,
                        totalCost: selectedProduct.purchasePrice,
                        imagesUrls:""
                    }]);
            }
        }
    };

    const handleProductSearch = textValue => {
        setProductParams({
            ...productParams,
            searchText: textValue,
        });
    };

    const handleQuantityChange = (value, index) => {
        const updatedDetails = [...quotationDetails];
        const product = updatedDetails[index];
        product.quantity = value;
        product.totalCost = (product.quantity * product.unitPrice) - product.disscount + product.taxAmount;
        setQuotationDetails(updatedDetails);
    };

    const handledisscountChange = (value, index) => {
        const updatedDetails = [...quotationDetails];
        const product = updatedDetails[index];
        product.disscount = value;
        product.totalCost = (product.quantity * product.unitPrice) - product.disscount + product.taxAmount;
        setQuotationDetails(updatedDetails);
    };

    const handleTaxPercentageChange = (value, index) => {
        const updatedDetails = [...quotationDetails];
        const product = updatedDetails[index];
        product.taxPercentage = value;
        product.taxAmount = ((product.taxPercentage / 100) * product.unitPrice * product.quantity);
        product.totalCost = (product.quantity * product.unitPrice) - product.disscount + product.taxAmount;
        setQuotationDetails(updatedDetails);
    };

    const handleRemoveProduct = (index) => {
        const updatedDetails = [...quotationDetails];
        updatedDetails.splice(index, 1);
        setQuotationDetails(updatedDetails);
    };

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setEditorData(data);
      };

    const handleRecord = (actionName, salequotationData) => {
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            salequotationData.id = currentModalData.formData.id;
            salequotationData.companyId = currentModalData.formData.companyId;
            salequotationData.saleQuotationDetails = quotationDetails;
            salequotationData.saleQuotationNotes = editorData
        }else{
            salequotationData.companyId = 1;
            salequotationData.saleQuotationDetails = quotationDetails;
            salequotationData.saleQuotationNotes = editorData
        }
     ;
        dispatch(quotationActions.addUpdateSaleQuotations({ salequotationData, actionName, QuotationParams: quotationParams }));
        toggleModal();
    };

    return (
        <Modal
            open={isModalOpen}
            width={1000}
            zIndex={99999}
            onClose={() => toggleModal(true)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    <IntlMessages id="sale.quotation.inventory.form.modal.title.update" />
                ) : (
                    <IntlMessages id="sale.quotation.inventory.form.modal.title.add" />
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="quotation.inventory.form.modal.update" />
                ) : (
                    <IntlMessages id="quotation.inventory.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="quotation.inventory.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name="quotationForm"
                className="quotationActions_addModalWrapper"
                initialValues={{
                    companyId: currentModalData.formData ? currentModalData.formData.companyId : 0,
                    customerId: currentModalData.formData ? currentModalData.formData.customerId : 0,
                    saleQuotationDate: currentModalData.formData ? currentModalData.formData.saleQuotationDate : null,
                    otherTax: currentModalData.formData ? currentModalData.formData.otherTax : 0,
                    disscount: currentModalData.formData ? currentModalData.formData.disscount : 0,
                    shippingCost: currentModalData.formData ? currentModalData.formData.shippingCost : 0,
                    saleQuotationNotes: currentModalData.formData ? currentModalData.formData.saleQuotationNotes : '',
                    quotationDetails: currentModalData.formData ? currentModalData.formData.saleQuotationDetails
                        : [{
                            "saleQuotationId": 0,
                            "productId": 0,
                            "quantity": 0,
                            "unitPrice": 0,
                            "disscount": 0,
                            "taxPercentage": 0,
                            "taxAmount": 0,
                            "totalCost": 0
                        }]
                }}
            >
                <div className="row mt-5">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-blocks add-product">
                            <div className="row align-items-center">
                                <div className="col-lg-10">
                                    <div className="input-blocks">
                                        <Form.Item
                                            name="customerId"
                                            label={
                                                <IntlMessages id="purchases.quotations.form.label.customer.name" />
                                            }
                                            rules={[
                                                {
                                                    required: true,
                                                    message: <IntlMessages id="purchases.quotations.form.validation.customer.name" />,
                                                },
                                            ]}
                                        >
                                            <Select
                                                options={customer?.map(f => ({ label: f.name, value: f.id }))}
                                                classNamePrefix="react-select"
                                                className="input-select"
                                                placeholder={<IntlMessages id="sale.purchases.quotations.form.placeholder.choose" />}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="saleQuotationDate"
                                label={<IntlMessages id="sale.quotations.form.label.sale.date" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="sale.quotations.form.validation.sale.date" />,
                                    },
                                ]}
                            >
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    dateFormat="YYYY-MM-DD"
                                    placeholderText="Select Date"
                                    className="actions_addModalFormItem"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="input-blocks">
                            <ReactSelect
                                options={searchedOptions?.map(product => ({
                                    label: product.productName,
                                    value: product.id,
                                    purchasePrice: product.purchasePrice,
                                    productName: product.productName,
                                }))}
                                isClearable
                                onInputChange={handleProductSearch}
                                onChange={handleProductChange}
                                placeholder={CONSTANT_VARIABLES.SELECT_PRODUCT}
                                components={{
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null,
                                }}
                            />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="modal-body-table">
                            <div className="table-responsive">
                                <table className="table datanew">
                                    <thead>
                                        <tr>
                                            <th><IntlMessages id="purchases.quotations.table.column.product" /></th>
                                            <th><IntlMessages id="purchases.quotations.table.column.qty" /></th>
                                            <th><IntlMessages id="purchases.quotations.table.column.purchase.price" /></th>
                                            <th><IntlMessages id="purchases.quotations.table.column.disscount" /></th>
                                            <th><IntlMessages id="purchases.quotations.table.column.tax" /></th>
                                            <th><IntlMessages id="purchases.quotations.table.column.tax.amount" /></th>
                                            <th><IntlMessages id="purchases.quotations.table.column.total.cost" /></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {quotationDetails?.map((product, index) => (
                                            <tr key={index}>
                                                <td className="p-3">{product.productName}</td>
                                                <td className="p-3">
                                                    <Input
                                                        type="number"
                                                        min={1}
                                                        value={product.quantity}
                                                        onChange={(e) => handleQuantityChange(Number(e.target.value), index)}
                                                    />
                                                </td>
                                                <td className="p-3">
                                                    {product.unitPrice}
                                                </td>
                                                <td className="p-3">
                                                    <Input
                                                        type="number"
                                                        min={0}
                                                        value={product.disscount}
                                                        onChange={(e) => handledisscountChange(Number(e.target.value), index)}
                                                    />
                                                </td>
                                                <td className="p-3">
                                                    <Input
                                                        type="number"
                                                        min={0}
                                                        value={product.taxPercentage}
                                                        onChange={(e) => handleTaxPercentageChange(Number(e.target.value), index)}
                                                    />
                                                </td>
                                                <td className="p-3">{product.taxAmount}</td>
                                                <td className="p-3">{product.totalCost}</td>
                                                <td className="p-3">
                                                    <i
                                                        data-feather="trash-2"
                                                        className="feather-trash-2"
                                                        onClick={() => handleRemoveProduct(index)}
                                                    ></i>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="otherTax"
                                label={<IntlMessages id="purchases.quotations.form.label.order.tax" />}
                                initialValue={0}
                                rules={[
                                    {
                                        type: 'number',
                                        min: 0,
                                        message: 'The quantity must be a valid number',
                                    },
                                ]}
                            >
                                <InputNumber min={0} className="input_number" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="disscount"
                                label={<IntlMessages id="purchases.quotations.form.label.disscount" />}
                                initialValue={0}
                                rules={[
                                    {
                                        type: 'number',
                                        min: 0,
                                        message: 'The quantity must be a valid number',
                                    },
                                ]}
                            >
                                <InputNumber min={0} className="input_number" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="shippingCost"
                                label={<IntlMessages id="purchases.quotations.form.label.shipping" />}
                                initialValue={0}
                                rules={[
                                    {
                                        type: 'number',
                                        min: 0,
                                        message: 'The quantity must be a valid number',
                                    },
                                ]}
                            >
                                <InputNumber min={0} className="input_number" />
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="input-blocks summer-description-box">
                        <Form.Item
                            name="saleQuotationNotes"
                            label={ <IntlMessages id="purchases.purchase.form.purchase.label.notes" />
                            }
                        >
                            <TextEditor data={editorData}
                                onChange={handleEditorChange} />
                        </Form.Item>

                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default AddQuotation;
